<template lang="pug">
  .action-moderation-list
    SectionHeader(:header="header")

    Tabs(:tabs="sections" v-model="activeTabIndex").action__tabs

    .action__body
      .action-moderation-list__items(v-if="isTournaments && list.length")
        .action-moderation-list__item(v-for="tournament of list")
          TournamentCard(:tournament="tournament" view="item" type="moderation")

      .action-moderation-list__items(v-else-if="!isTournaments && list.length")
        .action-moderation-list__item(v-for="duel of list")
          DuelCard(:duel="duel" view="item")

      .action__empty(v-else)
        | Ничего нет

</template>

<script>
import Tabs from '@/components/Tabs/Tabs';
import TournamentCard from '@/components/TournamentCard/TournamentCard';
import DuelCard from '@/components/DuelCard/DuelCard';
import SectionHeader from '@/components/SectionHeader/SectionHeader';
import { mapActions } from 'vuex';

export default {
  name: 'ActionModerationList',

  components: {
    Tabs,
    TournamentCard,
    DuelCard,
    SectionHeader,
  },

  data: () => ({
    activeTabIndex: 0,
    tournamentsList: [],
    duelsList: [],
  }),

  computed: {
    gameName() {
      return this.$route.params.section;
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Модерация`,
        game: this.gameName,
      };
    },

    isTournaments() {
      if (this.gameName === 'lichess') {
        return true;
      } else {
        return false;
      }
    },

    list() {
      if (this.isTournaments) {
        return this.tournamentsList;
      } else {
        return this.duelsList;
      }
    },

    sections() {
      if (this.gameName === 'lichess') {
        return [{ id: 'tournaments', title: 'Турниры' }];
      } else {
        return [{ id: 'duels', title: 'Дуэли' }];
      }
    },
  },

  created() {
    this.initList();
  },

  watch: {
    activeTabIndex() {
      console.log('active tab index');
      if (this.isTournaments()) {
        console.log('tournaments');
        this.getTournaments();
      } else {
        console.log('duels');
        this.getDuels();
      }
    },
  },

  methods: {
    ...mapActions('games', ['fetchTournaments', 'fetchDuels']),

    initList() {
      if (this.isTournaments) {
        this.getTournaments();
      } else {
        this.getDuels();
      }
    },

    async getTournaments() {
      this.$loading.start();

      try {
        const { data } = await this.fetchTournaments({
          game: this.$route.params.section,
          query: {
            filters: [
              {
                name: 'state',
                type: 'string_list',
                values: [{ text: 'moderation' }],
              },
            ],
            sort: {
              name: 'date',
              order: 'asc',
            },
          },
        });

        this.tournamentsList = data;
      } catch (e) {
        this.$error.handle(e);
      }

      this.$loading.finish();
    },

    async getDuels() {
      this.$loading.start();

      try {
        const { data } = await this.fetchDuels({
          game: this.$route.params.section,
          query: {
            filters: [
              {
                name: 'state',
                type: 'string_list',
                values: [{ text: 'moderation' }],
              },
            ],
            sort: {
              name: 'date',
              order: 'asc',
            },
          },
        });

        this.duelsList = data;
      } catch (e) {
        this.$error.handle(e);
      }

      this.$loading.finish();
    },

    onTournamentClick(tournament) {
      this.$router.push(`/pubg-mobile/moderation/tournament/${tournament.id}`);
    },

    onDuelClick(duel) {
      this.$router.push(`/pubg-mobile/moderation/duel/${duel.password}`);
    },
  },
};
</script>

<style lang="scss">
.tournament-card {
  margin: 24px 0;
  //max-width: 700px;
  cursor: pointer;
}

.action-moderation-list {
  &__items {
    width: 100%;
    padding-top: 12px;

    .tournament-card {
      max-width: 100%;
    }
  }

  &__item {
    margin-top: 24px;
  }
}
</style>
