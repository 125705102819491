<template lang="pug">
  .section__wrapper
    SectionSidebar

    .section__content
      .section__body
        router-view

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SectionSidebar from '@/components/SectionSidebar/SectionSidebar'

export default {
  name: 'Section',

  components: {
    SectionSidebar,
  },

  computed: {
    ...mapState('games', ['gamesByName']),
    ...mapGetters('navigation', ['sections']),

    sectionName() {
      return this.$route.params.section || this.$route.path?.match(/\/(.*?)\//)?.[1]
    },

    actionName() {
      return this.$route.params.action
    },

    actionRoute() {
      let route = this.$route.path.replace(`/${this.sectionName}/`, '')

      if (route.includes('/')) {
        route = route.split('/')[0]
      }

      return route
    },

    sectionImage() {
      return this.gamesByName[this.sectionName]?.extra_data?.image_url || 'https://www.flaticon.com/svg/static/icons/svg/1828/1828884.svg'
    },

    sectionAction() {
      const path = this.$route.path.split('/')

      return path[path.length - 1]
    },
  },

  watch: {
    actionRoute() {
      this.checkIfAvailable()
    },
  },

  created() {
    this.checkIfAvailable()
  },

  methods: {
    checkIfAvailable() {
      const sectionsCount = Object.keys(this.sections).length
      const section = this.sections[this.sectionName]

      if (sectionsCount) {
        if (!section) {
          return this.$router.push('/')
        }

        const actionIndex = section.actions.findIndex(action => action.route === `/${this.actionRoute}`)
        const isCurrentActionAvailable = actionIndex !== -1

        if (!isCurrentActionAvailable) {
          return this.$router.push('/')
        }
      } else {
        setTimeout(this.checkIfAvailable, 100)
      }
    }
  }
}
</script>

<style lang="scss">

.section {
  &__wrapper {
    display: flex;
    min-height: calc(100vh - 80px);

    @media screen and (max-width: $breakpointMobile) {
      flex-direction: column;
    }
  }

  &__content {
    position: relative;
    padding-top: 40px;
    width: 100%;
    background-color: $blue-grey70;
  }

  &__body {
    &.--padded {
      padding: 0 32px 0;

      @media screen and (max-width: $breakpointMobile) {
        padding: 0 16px 0;
      }
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    left: 338px;
    background: #141E2B;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;

    @media screen and (max-width: $breakpointMobile) {
      bottom: 48px;
      justify-content: center;
    }
  }
}

.action {
  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 32px 0;
    margin-bottom: 184px;
    min-height: calc(100vh - 250px);

    @media screen and (max-width: $breakpointTablet) {
      flex-direction: column;
    }

    @media screen and (max-width: $breakpointMobile) {
      padding: 0 16px 0;
    }
  }

  &__section {
    max-width: 760px;
    width: 100%;

    &-title {
      @include heading2;

      color: $white;
      padding-bottom: 16px;
      margin-bottom: 32px;
    }
  }

  &__grid {
    display: grid;
    column-gap: 2%;
    row-gap: 32px;
  }

  &__bottom {
    width: 100%;
    margin-top: 32px;
  }

  &__tabs {
    z-index: 3;
    top: 79px;
    height: 78px !important;
    background-color: #495c7440;
    padding: 0 32px 0;
    width: fit-content;
    box-shadow: 0px 4px 8px #0000001f;
    border-radius: 0 30px 30px 0px;

    @media screen and (max-width: $breakpointMobile) {
      top: 47px;
      padding: 0 16px 0;
    }
  }

  &__empty {
    @include heading5;

    color: $white;
    margin-top: 26px;
  }
}

</style>
