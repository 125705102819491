<template lang="pug">
  .tournament-parser
    SectionHeader(:header="header")

    .action__body
      .action__section
        .tournament-parser__caption Выберите файл и он автоматически загрузится на сервер
        FileUpload(:url="url" :key="url")

</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import FileUpload from '@/components/FileUpload/FileUpload'
import { mapActions } from 'vuex'

export default {
  name: 'TournamentParser',

  components: {
    SectionHeader,
    FileUpload,
  },

  data() {
    return {}
  },

  computed: {
    url() {
      const path = this.$route.path

      if (path.includes('mega-tournament-parser')) {
        return '/admin/update-mega-tournament-templates'
      } else if (path.includes('daily-tournament-parser')) {
        return '/admin/create-tournament-from-templates'
      } else if (path.includes('weekly-tournament-parser')) {
        return '/admin/create-tournaments-for-week'
      } else if (path.includes('daily-instant-tournament-parser')) {
        return '/admin/create-tournaments-for-day'
      }

      return '/admin/update-tournament-templates'
    },

    title() {
      const path = this.$route.path

      if (path.includes('mega-tournament-parser')) {
        return 'Изменение автогенерации мега-турниров'
      } else if (path.includes('daily-tournament-parser')) {
        return 'Изменение автогенерации турниров на сегодня'
      } else if (path.includes('weekly-tournament-parser')) {
        return 'Автогенерация турниров на неделю'
      } else if (path.includes('daily-instant-tournament-parser')) {
        return 'Автогенерация турниров из шаблонов сразу'
      }

      return 'Изменение автогенерации турниров'
    },

    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / ${this.title}`,
        game: this.gameName,
      }
    },
  },

  methods: {
    ...mapActions('modal', ['showModal']),
  },
}
</script>

<style lang="scss">
.tournament-parser {
  &__caption {
    @include caption;

    color: $white;
    margin-bottom: 12px;
    line-height: 120%;
  }

  .file_upload {
    max-width: 400px;
  }
}
</style>
