import Vue from 'vue'

const state = () => ({
  winners: [],
})

const mutations = {
  sortWinners(state) {
    state.winners?.sort((winner1, winner2) => winner1.place - winner2.place)
  },

  setWinners(state, payload) {
    state.winners = payload
    this.commit('moderation/sortWinners')
  },

  setWinner(state, payload) {
    if (typeof payload.points === 'string') {
      payload.points = +payload.points
    }
    const winnerIndex =
      state.winners.findIndex(winner => winner.name === payload.name)
    Vue.set(state.winners, winnerIndex, payload)
    this.commit('moderation/setWinners', [ ...state.winners ])
    this.commit('moderation/sortWinners')
  },

  deleteWinner(state, payload) {
    Vue.delete(state.winners, payload)

    this.commit('moderation/sortWinners')
  },
}

const getters = {
  winners(state) {
    return state.winners
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
