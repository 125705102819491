<template lang="pug">
  .action-ban
    SectionHeader(:header="header")

    .action__body
      .action__section
        .action__grid
          .action__form-field(v-for="(item, i) of items" :key="i")
            InputSmart(:item="item")

      .action__bottom
        Button(is-primary @click="onCheckButtonClick") Возобновить

</template>

<script>
import { mapActions } from 'vuex'
import InputSmart from '@/components/InputSmart/InputSmart'
import CheckboxInput from '@/components/CheckboxInput/CheckboxInput'
import SectionHeader from '@/components/SectionHeader/SectionHeader'

export default {
  name: 'ActionResetMonitoring',

  components: {
    InputSmart,
    CheckboxInput,
    SectionHeader,
  },

  data: () => ({
    items: [
      {
        title: 'ID турнира',
        value: null,
        type: 'number',
        placeholder: 'Введите ID турнира'
      },
    ]
  }),

  computed: {
    sectionName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: 'Возобновление мониторинга',
        game: this.sectionName,
      }
    },
  },

  created() {
    this.$forceUpdate()
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onCheckButtonClick() {
      this.$loading.start()

      try {
        const tournamentId = this.items[0].value
        const { data } = await this.$axios.post(`/admin/games/tournaments/${tournamentId}/monitoring`)

        if (data.data) {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'success',
              title: 'Успех',
              text: 'Всё прошло как нужно! :)',
            },
          })
        }
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    }
  },
}
</script>

<style lang="scss">
</style>
