<template lang="pug">
  .tournaments-create
    SectionHeader(:header="header")

    .section__body(class="--padded")
      CreateBrawlStarsTournament(v-if="gameName === 'brawl-stars'" :tournamentData="tournament")
      CreatePubgTournament(v-else-if="gameName === 'pubg-desktop'" :tournamentData="tournament")
      ActionLichessModerationTournament(v-else-if="gameName === 'lichess'" :tournamentData="tournament")
      CreateClashRoyaleTournament(v-else-if="gameName === 'clash-royale'" :tournamentData="tournament")
      CreatePubgMobileTournament(v-else :tournamentData="tournament")

</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import CreatePubgMobileTournament from '@/components/CreatePubgMobileTournament/CreatePubgMobileTournament'
import CreateBrawlStarsTournament from '@/components/CreateBrawlStarsTournament/CreateBrawlStarsTournament'
import CreatePubgTournament from '@/components/CreatePubgTournament/CreatePubgTournament'
import ActionLichessModerationTournament from '@/views/actions/LichessModerationTournament'
import CreateClashRoyaleTournament from '@/components/CreateClashRoyaleTournament/CreateClashRoyaleTournament'
import NominationsModeration from '@/components/NominationsModeration/NominationsModeration'

export default {
  name: 'TournamentHolding',

  components: {
    SectionHeader,
    CreatePubgMobileTournament,
    CreateBrawlStarsTournament,
    CreatePubgTournament,
    ActionLichessModerationTournament,
    CreateClashRoyaleTournament,
    NominationsModeration,
  },

  data: () => ({
    tournament: {},
  }),

  computed: {
    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: this.tournament.title,
        image: this.tournament.image_url,
        back: `/${this.gameName}/events`,
        description: [
          {
            text: `#${this.tournamentId}`,
            link: `${process.env.VUE_APP_DOMAIN}/${this.gameName}/tournaments/${this.tournamentId}`
          },
          { text: this.tournamentDate },
          { text: this.membersCountText },
        ],
      }
    },

    tournamentId() {
      return this.$route.params.id
    },

    tournamentDate() {
      return this.$moment.format(this.tournament.date, 'lll')
    },

    membersCountText() {
      if (this.tournament?.tournament_player_settings?.entry_type !== 'player') {
        return `Команд: ${this.tournament?.teams?.length}`
      }

      return `Игроков: ${this.tournament?.players?.length}`
    },
  },

  watch: {
    tournament: {
      deep: true,
      handler() {
        const stageId = this.tournament?.mega_tournament_stage_id

        if (!stageId || this.tournament.state !== 'moderation') {
          return
        }

        this.checkForPrevStage(stageId)
      },
    },
  },

  async created() {
    this.$loading.start()

    const { data } = await this.$axios.get(
      `/games/${this.gameName}/tournaments/${this.tournamentId}`
    )
    this.tournament = data.data

    this.$loading.finish()
  },

  methods: {
    async checkForPrevStage(stageId) {
      this.$loading.start()

      try {
        const megaTournament = await this.$axios.get(`/games/${this.gameName}/mega-tournaments/get-by-stage/${stageId}`)
        const stages = await this.$axios.get(`/games/${this.gameName}/mega-tournaments/${megaTournament?.data?.data?.id}/stages`)
        this.stages = stages?.data?.data || []
      } catch (e) {
        this.$error.handle(e)
      }

      const prev = this.stages.find(stg => stg.id === stageId - 1)
      let winners = []

      if (!prev) {
        return
      }

      for (let room of prev.rooms) {
        try {
          const { data } = await this.$axios.get(`/games/${this.gameName}/tournaments/${room.id}`)
          const roomTournament = data.data

          winners = [...winners, ...roomTournament.players]
        } catch (e) {
          this.$error.handle(e)
        }
      }

      for (let player of this.tournament.players) {
        const prevPlayer = winners.find(winner => winner.id === player.id)

        player.points += prevPlayer?.points || null
      }

      this.$loading.finish()
    },
  },
}
</script>

<style lang="scss">
.tournaments-create {
  margin-bottom: 40px;

  &__caption {
    color: #a3bcc7;
    margin-bottom: 8px;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-align: center;
  }
}
</style>
