<template lang="pug">
  .action-create-tournament
    SectionHeader(:header="header")

    Tabs(:tabs="gameSections" v-model="activeTabIndex").action__tabs

    .action__body
      .action-create-tournament__body
        .action__section
          .action__grid(v-if="currentSection.id === 'nominations'" :key="'nominations_' + nominations.length")
            TournamentNominations

          .action__grid(v-else :key="gameName")
            .action__form-field(v-if="!field.isHidden" v-for="(field, index) of sectionFields" :key="index")
              InputSmart(:item="field")

        .action-create-tournament__buttons
          Button(is-primary @click="onCreateTournamentClick")
            | Создать

          Button(is-secondary @click="onCopyTournamentClick" v-if="copyAvailable")
            | Копировать турнир 

          Button(is-secondary @click="resetAll")
            | Сбросить всё

        p.action-create-tournament__title(v-if="showTemplates")
          | Заполнить из шаблона

        .action-create-tournament__templates(v-if="showTemplates")
          .action-create-tournament__template(v-for="templateTournament of templateTournaments" :key="templateTournament.id")
            Button(is-secondary @click="onCopyTournamentClick(templateTournament.id)")
              | {{ templateTournament.name }}

</template>

<script>
import TournamentNominations from '@/components/TournamentNominations/TournamentNominations';
import SectionHeader from '@/components/SectionHeader/SectionHeader';
import Tabs from '@/components/Tabs/Tabs';
import InputSmart from '@/components/InputSmart/InputSmart';
import gameData from '@/data/games/tournaments';

import TournamentMixin from '@/mixins/Tournament';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import TournamentService from '@/services/TournamentsService';

export default {
  name: 'ActionCreateTournament',

  components: {
    SectionHeader,
    InputSmart,
    Tabs,
    TournamentNominations,
  },

  mixins: [TournamentMixin],

  data: () => ({
    activeTabIndex: 0,
    gameFields: [],
    rules: null,
    templatesTournaments: {},
    fieldsToMap: {},
  }),

  computed: {
    ...mapState('user', ['token']),
    ...mapGetters('nominations', ['nominations']),

    game() {
      return gameData[this.gameName] || gameData['pubg-mobile'];
    },

    gameName() {
      return this.$route.params.section;
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Создание турнира`,
        game: this.gameName,
      };
    },

    gameSections() {
      return this.game.sections;
    },

    currentSection() {
      return this.game.sections[this.activeTabIndex];
    },

    copyAvailable() {
      return this.gameName in this.fieldsToMap
    },

    templateTournaments() {
      return this.templatesTournaments[this.gameName] || [];
    },

    showTemplates() {
      return (
        process.env.NODE_ENV === 'production' &&
        this.templateTournaments.length > 0
      );
    },

    sectionFields() {
      return this.gameFields.filter(
        (field) => field.section === this.currentSection.id
      );
    },

    tournamentData() {
      let tournament = {};

      this.gameFields.forEach((field) => {
        let value = field.value;

        if (!field.isHidden) {
          tournament[field.key] = value;
        }
      });

      return tournament;
    },
  },

  watch: {
    gameName() {
      this.initPage();
    },

    gameFields: {
      handler(fields) {
        fields.forEach((field) => {
          if (field.show) {
            const cond =
              this.tournamentData[field.show.key] !== field.show.value;

            field.isHidden = cond ? true : false;
          }
        });

        this.$forceUpdate();
      },
      deep: true,
    },
  },

  created() {
    this.initPage();
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapMutations('nominations', ['setNominations']),

    resetAll() {
      const ok = confirm('Сбросить все поля турнира к порям по умолчанию?');
      if (!ok) return;
      this.initPage();
    },

    initPage() {
      const fields = this.game.fields();
      fields.forEach((field) => (field.isHidden = false));
      this.gameFields = fields;

      this.activeTabIndex = 0;
      this.setNominations([]);

      this.loadTemplateTournaments();
      this.loadFieldsToMap();
      this.$forceUpdate();

      // Для примера, пока не удаляем
      //TournamentService.getTournamentTemplate(this.gameName, this.token);
    },

    fieldMapper(tournamentData, field, index) {
      console.log('fieldMap:', field, index, this.fieldsToMap[this.gameName][index])
      const fieldToMap = this.fieldsToMap[this.gameName][index];
      if (!fieldToMap) return field;

      const defaultMapFunction = (key, tournament) => {
        const result = key.split('.').reduce((o, i) => o[i], tournament);
        return () => result;
      };
      const mapFunction =
        fieldToMap.mapFunction || defaultMapFunction(field.key, tournamentData);
      console.log('result:', index, mapFunction(tournamentData))
      return { ...field, value: mapFunction(tournamentData) };
    },

    async onCopyTournamentClick(id = 0) {
      const promptText = `Введите ID турнира${
        process.env.NODE_ENV === 'production' ? '' : ' (на dev)'
      } для копирования`;
      if (!id) id = prompt(promptText, id);
      id = +id;
      if (!id) return;
      let { data } = (
        await TournamentService.getTournament(this.gameName, id)
      ).data;

      if (this.copyAvailable) {
        this.gameFields = this.gameFields.map((field, index) =>
          this.fieldMapper(data, field, index)
        );
      }
      this.copyNominations(data.prize_settings.nominations);

      this.$forceUpdate();
    },

    async loadTemplateTournaments() {
      this.templatesTournaments = await TournamentService.getTournamentTemplates();
    },

    async loadFieldsToMap() {
      this.fieldsToMap = await TournamentService.getTournamentCopySettings();
    },

    copyNominations(nominations) {
      if (!nominations) return;
      this.setNominations(
        nominations.map((nomination) => ({
          title: {
            title: 'Название номинации',
            type: 'text',
            value: nomination.title,
          },
          prize: {
            title: 'Приз в номинации',
            type: 'number',
            value: nomination.amount,
          },
          currency: nomination.currency,
          icon: {
            src: nomination.icon_name,
            color: nomination.icon_color,
          },
        }))
      );
    },

    onCreateTournamentClick() {
      const requiredFields = this.getRequiredFields();

      if (
        typeof this.tournamentData['prize_settings.flat_prizes'] === 'object'
      ) {
        this.tournamentData['prize_settings.flat_prizes'] = this.tournamentData[
          'prize_settings.flat_prizes'
        ].join(',');
      }

      if (
        this.tournamentData['prize_settings.prize_winner_type'] === 'top' &&
        this.tournamentData['prize_settings.prize_distribution_type'] ===
          'flat' &&
        this.tournamentData['prize_settings.flat_prizes'].split(',').length !==
          +this.tournamentData['prize_settings.win_place_count']
      ) {
        return this.showModal({
          component: 'ModalMessage',
          data: {
            text:
              'Количество заданных наград должно совпадать с количеством призовых мест',
          },
        });
      }

      const prizePoolType = this.tournamentData[
        'prize_settings.prize_pool_type'
      ];
      const prizeCurrency = this.tournamentData[
        'prize_settings.prize_currency'
      ];
      let prizePool;

      if (prizePoolType === 'fixed') {
        prizePool = this.tournamentData['prize_pool'];
      } else if (prizePoolType === 'not_fixed_with_guarantee') {
        prizePool = this.tournamentData['prize_settings.guaranted_prize'];
      }

      if (prizeCurrency === 'money') prizePool /= 100;

      if (prizeCurrency === 'tickets' && prizePool > 500) {
        return this.showModal({
          component: 'ModalMessage',
          data: { text: 'Призовой фонд не может превышать 500 билетов' },
        });
      } else if (prizeCurrency === 'money' && prizePool > 20000) {
        return this.showModal({
          component: 'ModalMessage',
          data: { text: 'Призовой фонд не может превышать 20000 евро' },
        });
      }

      if (requiredFields.length) {
        return this.showModal({
          component: 'ModalMessage',
          data: {
            text:
              'Следующие поля обязательны для заполнения: ' +
              requiredFields.join(', '),
          },
        });
      }

      this.tournamentData['prize_settings.nominations'] = this.nominations?.map(
        (nom) => {
          return {
            title: nom.title.value,
            currency: nom.currency,
            amount:
              nom.currency === 'money'
                ? nom.prize.value * 100
                : nom.prize.value,
            icon_name: nom.icon.src,
            icon_color: nom.icon.color,
          };
        }
      );

      const tournament = this.refactorTournament(this.tournamentData);
      this.createTournament(tournament);
    },
  },
};
</script>

<style lang="scss" scoped>
.action__section {
  margin-top: 32px;
  margin-bottom: 20px;
}

.action-create-tournament {
  &__body {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $breakpointMobile) {
      flex-direction: column;
    }
  }

  &__buttons {
    margin: 10px 0 0 0;
    display: grid;
    grid-gap: 20px;
    width: min-content;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: $breakpointMobile) {
      grid-template-columns: 1fr;
    }
  }

  &__templates {
    display: flex;
    align-items: center;
    gap: 14px;
    max-width: 760px;
    flex-wrap: wrap;
  }

  &__title {
    color: #839ab5;
    font-weight: 600;
    margin-bottom: 14px;
    margin-top: 20px;
  }
}
</style>
