<template lang="pug">
  .clash-royale-account
    .modal__header Аккаунт Clash Royale

    .clash-royale-account__body
      Steps(:steps="stepsTitles" :current-step-index="stepIndex")

      .clash-royale-account__step(v-if="stepIndex === 0")
        .clash-royale-account__item
          FormInput(title="Введите игровой тег" placeholder="#XXXXXX" v-model="playerTag")
        .clash-royale-account__item
          ImageSlider(:slides="tagSlides")

      .clash-royale-account__step(v-if="stepIndex === 1")
        .clash-royale-account__confirm-title Это нужный аккаунт?
        ClashRoyaleGameAccount(:player="playerData")

      .clash-royale-account__step(v-if="stepIndex === 2")
        .fourth-step__first
          InfoMessage(type="success") Aккаунт успешно привязан!

      .clash-royale-account__item(v-if="errorMessage")
        InfoMessage(type="error") {{ errorMessage }}

    .modal__footer
      Button(is-primary :is-disabled="isButtonDisabled" @click="onNextStepClick") Далее

</template>

<script>
import { mapMutations } from 'vuex'
import ImageSlider from '@/components/ImageSlider/ImageSlider'
import FormInput from '@/components/InputForm/InputForm'
import Steps from '@/components/Steps/Steps'
import InfoMessage from '@/components/InfoMessage/InfoMessage'
import ClashRoyaleGameAccount from '@/components/ClashRoyaleAccount/ClashRoyaleGameAccount'

const CDN_URL = 'https://cdn.vvvgamers.com/games/clash-royale/verification'

export default {
  name: 'ClashRoyaleAccount',

  props: {
    playerId: {
      type: Number,
      required: true,
    },
  },

  components: {
    FormInput,
    ImageSlider,
    Steps,
    InfoMessage,
    ClashRoyaleGameAccount,
  },

  data() {
    return {
      steps: [
        {
          title: 'Тег',
          action: () => this.searchGameAccount(),
        },
        {
          title: 'Подтверждение',
          action: () => this.linkGameAccount(),
        },
        {
          title: 'Успех',
          action: () => this.toggle(),
        },
      ],

      errorMessage: '',
      stepIndex: 0,
      playerTag: '',
      playerData: {},

      tagSlides: [
        {
          text: 'Запустите Clash Royale и нажмите на никнейм',
          image: CDN_URL + '/slider_tag/1.gif',
        },
        {
          text: 'Скопируйте тег игрока, нажав на него под ником',
          image: CDN_URL + '/slider_tag/2.gif',
        },
        {
          text: 'Вставьте тег игрока и нажмите кнопку «Далее»',
          image: CDN_URL + '/slider_tag/3.gif',
        },
      ],
    }
  },

  computed: {
    stepsTitles() {
      return this.steps.map((step) => step.title)
    },

    isButtonDisabled() {
      if (this.stepIndex === 0 && !this.playerTag) {
        return true
      }

      return false
    },

    refactoredTag() {
      let tag = this.playerTag

      if (!tag.startsWith('#')) {
        tag = '#' + this.playerTag
      }

      return tag.toUpperCase()
    },
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    onNextStepClick() {
      this.errorMessage = ''

      this.steps[this.stepIndex].action()
    },

    async searchGameAccount() {
      this.$loading.start()

      try {
        const { data } = await this.$axios.get(
          '/games/clash-royale/account/info',
          {
            params: { tag: this.refactoredTag },
          }
        )

        if (!data.error) {
          this.playerData = data.data
          this.stepIndex += 1
        } else {
          this.errorMessage = 'Ошибка. Проверьте правильность введённого тега'
        }
      } catch (e) {
        this.errorMessage = 'Ошибка. Проверьте правильность введённого тега'
      }

      this.$loading.finish()
    },

    async linkGameAccount() {
      this.$loading.start()

      try {
        const { data } = await this.$axios.post('/games/clash-royale/account/admin/link', {
          tag: this.refactoredTag,
          userId: this.playerId,
        })

        if (data.data) {
          this.stepIndex += 1
        } else {
          this.errorMessage = 'Ошибка. Попробуйте чуть позже'
        }
      } catch (e) {
        this.errorMessage = e
      }

      this.$loading.finish()
    },

    async onPreviousStepClick() {
      this.errorMessage = ''

      if (this.stepIndex === 1) {
        this.playerTag = ''
        this.stepIndex -= 1
      }
    },
  },
}
</script>

<style lang="scss">
.modal.modal--clashroyaleaccount {
  padding-bottom: 0;
  max-width: 736px;
}

.clash-royale-account {
  &__body {
    margin: 40px 0;
  }

  &__step {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__item {
    width: 100%;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $blue-grey30;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &.--no-padding {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__confirm-title {
    @include heading5;

    font-size: 20px;
    color: $white;
    margin-bottom: 16px;
  }

  .steps {
    margin-bottom: 40px;
  }

  .modal__footer {
    .button:last-child {
      margin-left: 16px;
    }
  }

  .info-message.--error {
    margin-top: 16px;
  }
}
</style>
