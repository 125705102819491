<template lang="pug">
  .update-banners
    SectionHeader(:header="header")

    Tabs(:tabs="actionTabs" v-model="activeTabIndex").action__tabs

    .action__body(v-if="activeTabIndex === 0")
      .action__section
        .action__grid
          .action__form-field
            InputSmart(:item="lang")

          .action__grid.update-banners__grid(v-if="banners.length")
            .update-banners__banner(v-for="(banner, i) in banners")
              InputSmart(
                v-for="(prop, index) in banner[currentLang]"
                :key="`${currentLang}_index`"
                :item="prop"
                v-model="prop.value"
                :type="prop.type"
              )

          .action__empty(v-else) Баннеров пока что нет

      .update-banners__bottom.action__bottom
        Button(is-primary @click="onUpdateClick") Добавить баннер

    .action__body(v-if="activeTabIndex === 1")
      .action__section
        .action__grid
          .action__form-field
            InputSmart(:item="bannersSelect")

      .update-banners__bottom.action__bottom
        Button(is-primary @click="onDeleteClick") Удалить баннер

</template>

<script>
import { mapActions } from 'vuex';
import InputSmart from '@/components/InputSmart/InputSmart';
import CheckboxInput from '@/components/CheckboxInput/CheckboxInput';
import SectionHeader from '@/components/SectionHeader/SectionHeader';
import Tabs from '@/components/Tabs/Tabs';

export default {
  name: 'UpdateBanners',

  components: {
    InputSmart,
    CheckboxInput,
    SectionHeader,
    Tabs,
  },

  data() {
    return {
      actionTabs: [
        { title: 'Добавить баннер', id: 'add' },
        { title: 'Удалить баннер', id: 'delete' },
      ],

      activeTabIndex: 0,

      action: {
        title: 'Действие',
        value: 'add',
        type: 'select',
        options: [
          { title: 'Добавить новый баннер', value: 'add' },
          { title: 'Удалить баннер', value: 'delete' },
        ],
        withEmptyValue: false,
      },

      lang: {
        title: 'Язык',
        value: 'ru',
        type: 'select',
        options: [
          { title: 'Русский + основная информация', value: 'ru' },
          { title: 'Английский', value: 'en' },
          { title: 'Немецкий', value: 'de' },
          { title: 'Испанский', value: 'es' },
          { title: 'Итальянский', value: 'it' },
        ],
        withEmptyValue: false,
      },

      bannersSelect: {},

      banners: [
        {
          ru: {
            order: {
              title: 'Очередность',
              value: null,
              placeholder: 'Чем меньше число, тем ближе к началу',
              type: 'number',
            },
            background_image_url: { //webp
              title: 'Картинка',
              value: null,
              type: 'image',
            },
            title: {
              title: 'Заголовок',
              value: '',
              placeholder: 'Главный текст',
              type: 'text',
            },
            subtitle: {
              title: 'Подзаголовок',
              value: '',
              placeholder: 'Текст под главным текстом',
              type: 'text',
            },
            btn1_text: {
              title: 'Текст на главной кнопке',
              value: '',
              type: 'text',
            },
            btn1_url: {
              title: 'Ссылка главной кнопки',
              value: '',
              placeholder: 'Можно в таком формате: /tournaments',
              type: 'text',
            },
            btn2_text: {
              title: 'Текст на второй кнопке',
              value: '',
              type: 'text',
            },
            btn2_url: {
              title: 'Ссылка второй кнопки',
              value: '',
              placeholder: 'Можно в таком формате: /tournaments',
              type: 'text',
            },
          },

          en: {
            title: {
              title: 'Заголовок',
              value: '',
              placeholder: 'Главный текст на английском',
              type: 'text',
            },
            subtitle: {
              title: 'Подзаголовок',
              value: '',
              placeholder: 'Текст под главным текстом на английском',
              type: 'text',
            },
            btn1_text: {
              title: 'Текст главной кнопки',
              value: '',
              type: 'text',
            },
            btn2_text: {
              title: 'Текст второй кнопки',
              value: '',
              type: 'text',
            },
          },

          de: {
            title: {
              title: 'Заголовок',
              value: '',
              placeholder: 'Главный текст на немецком',
              type: 'text',
            },
            subtitle: {
              title: 'Подзаголовок',
              value: '',
              placeholder: 'Текст под главным текстом на немецком',
              type: 'text',
            },
            btn1_text: {
              title: 'Текст главной кнопки',
              value: '',
              type: 'text',
            },
            btn2_text: {
              title: 'Текст второй кнопки',
              value: '',
              type: 'text',
            },
          },

          es: {
            title: {
              title: 'Заголовок',
              value: '',
              placeholder: 'Главный текст на испанском',
              type: 'text',
            },
            subtitle: {
              title: 'Подзаголовок',
              value: '',
              placeholder: 'Текст под главным текстом на испанском',
              type: 'text',
            },
            btn1_text: {
              title: 'Текст главной кнопки',
              value: '',
              type: 'text',
            },
            btn2_text: {
              title: 'Текст второй кнопки',
              value: '',
              type: 'text',
            },
          },

          it: {
            title: {
              title: 'Заголовок',
              value: '',
              placeholder: 'Главный текст на итальянском',
              type: 'text',
            },
            subtitle: {
              title: 'Подзаголовок',
              value: '',
              placeholder: 'Текст под главным текстом на итальянском',
              type: 'text',
            },
            btn1_text: {
              title: 'Текст главной кнопки',
              value: '',
              type: 'text',
            },
            btn2_text: {
              title: 'Текст второй кнопки',
              value: '',
              type: 'text',
            },
          },
        },
      ],
    };
  },

  computed: {
    gameName() {
      return this.$route.params.section;
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Обновление баннеров`,
        game: this.gameName,
      };
    },

    currentLang() {
      return this.lang.value;
    },
  },

  created() {
    this.$forceUpdate();
    this.getBanners();
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async getBanners() {
      const { data } = await this.$axios.get('/banners');
      this.bannersData = data.data;

      const bannerOptions = this.bannersData.map((banner) => {
        const title = banner.title || '[Англ] ' + banner.extra_data.en.title;

        return { title, value: banner.id };
      });

      this.bannersSelect = {
        title: 'Выбор баннера по заголовку',
        value: bannerOptions[0].value,
        type: 'select',
        options: bannerOptions,
        withEmptyValue: false,
      };
    },

    async onUpdateClick() {
      this.$loading.start();

      try {
        const banners = JSON.parse(JSON.stringify(this.banners));

        this.banners.forEach((banner, i) => {
          Object.keys(banner.ru).forEach((key) => {
            banners[i].ru[key] = this.banners[i].ru[key].value;
          });

          Object.keys(banner.en).forEach((key) => {
            banners[i].en[key] = this.banners[i].en[key].value;
          });
        });

        banners.forEach((banner, i) => {
          let en = JSON.parse(JSON.stringify(banner)).en;

          banners[i] = {
            ...banners[i].ru,
            extra_data: { en },
          };

          delete banner.en;
          delete banner.ru;
        });

        await this.$axios.post(`/banners`, banners[0]);
        await this.getBanners();

        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            title: 'Успех',
            text: 'Всё прошло как нужно! :)',
          },
        });
      } catch (e) {
        this.$error.handle(e);
      }

      this.$loading.finish();
    },

    async onDeleteClick() {
      try {
        const id = this.bannersSelect.value;
        await this.$axios.delete('/banners/' + id);
        await this.getBanners();

        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            title: 'Успех',
            text: 'Всё прошло как нужно! :)',
          },
        });
      } catch (e) {
        this.$error.handle(e);
      }
    },
  },
};
</script>

<style lang="scss">
.update-banners {
  &__bottom {
    display: flex;

    .button:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.update-banners {
  &__close {
    display: flex;
    justify-content: flex-end;

    &-button {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      background: $blue-grey50;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        transition: transform 0.2s $easing;
      }

      &:hover {
        background: $blue-grey30;
        svg {
          transform: rotate(90deg);
        }
      }

      &:active {
        background: $blue-grey20;
      }
    }
  }

  &__banner {
    max-width: 465px;
    border: 4px dashed #232f3f;
    padding: 18px 20px;
    border-radius: 16px;
    background: #ffffff05;

    &__title {
      @include heading4;

      color: $white;
      margin-bottom: 24px;
    }

    .input-smart {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    .input-number__label,
    .input-form__label,
    .image-upload__label {
      background-color: $blue-grey50;
      background: $blue-grey50;
      width: 412px;

      @media screen and (max-width: $breakpointMobile) {
        width: 100%;
      }
    }

    .image-upload__label > .button {
      background-color: $blue-grey50;
    }
  }

  .action__tabs {
    margin-bottom: 24px;
  }
}
</style>
