<template lang="pug">
  .layout
    GlobalLoader

    Header
    .layout__content
      Sidebar
      .layout__page
        slot

    transition(name="modal-fade-scale" duration="200")
      .layout__modal-container(v-if="isModalVisible")
        .layout__overlay(v-if="isModalVisible" @click="onOverlayClick")
        Modal

</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Header from '@/components/Header/Header'
import Sidebar from '@/components/Sidebar/Sidebar'
import Modal from '@/components/Modal/Modal'
import GlobalLoader from '@/components/GlobalLoader/GlobalLoader'

export default {
  name: 'BaseLayout',

  components: {
    Header,
    Sidebar,
    Modal,
    GlobalLoader,
  },

  computed: {
    ...mapState('modal', { isModalVisible: 'isVisible' }),
  },

  methods: {
    ...mapMutations('modal', { toggleModal: 'toggle' }),

    onOverlayClick() {
      this.toggleModal()
    },
  }
}
</script>

<style lang="scss">
@import '../assets/styles/reset.scss';
@import '../assets/styles/common.scss';
@import '../assets/styles/fonts.scss';

.layout {
  &__content {
    padding-top: 80px;

    /* overflow-x: hidden; */ // Иначе sticky не работает внутри
    max-width: 100vw;
  }

  &__page {
    max-width: calc(100% - 66px);
    width: 100%;
    transition: 0.2s $easing;
    position: relative;
    margin-left: 66px;

    .main-sidebar.--opened ~ & {
      max-width: calc(100% - 256px);
      margin-left: 256px;
    }
  }

  &__overlay {
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    cursor: pointer;
  }

  &__modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 101;
    overflow-y: auto;
  }

  .fade-scale-enter-active,
  .fade-scale-leave-active {
    transition: all 0.2s $easing;
  }

  .fade-scale-enter,
  .fade-scale-leave-to {
    opacity: 0;
    transform: scale(0.75, 0.75);
  }

  .modal-fade-scale-enter-active .modal,
  .modal-fade-scale-leave-active .modal {
    transition: all 0.2s $easing;
  }

  .modal-fade-scale-enter .modal,
  .modal-fade-scale-leave-to .modal {
    opacity: 0;
    transform: scale(0.75, 0.75);
  }

  .button-table {
    display: none;
    margin-top: 32px;
  }

  @media screen and (max-width: $breakpointHeaderMobile) {
    &__page {
      max-width: 100%;
      margin-left: 0;
    }

    &__content {
      padding-top: 48px;
      padding-bottom: 48px;
    }
  }

  @media screen and (max-width: $breakpointDesktopMedium) {
    .main-sidebar.--opened ~ &__page {
      max-width: calc(100% - 66px);
      margin-left: 66px;
    }
  }
}
</style>