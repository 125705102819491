<template lang="pug">
  .action-create-tournament
    SectionHeader(:header="header")

    Tabs(:tabs="gameSections" v-model="activeTabIndex").action__tabs

    .action__body
      .action-create-tournament__body
        .action__section
          .tournament-stages__stages(v-if="isStages")
            .tournament-stages__stage(
              v-for="(stage, index) in stageTabs"
              :class="{ '--active': index === activeStageIndex }"
              @click="onStageClick(index)"
            ) {{ stage }}
            .tournament-stages__stage-add(v-if="stageTabs.length < 4" @click="onAddStageClick")
              InlineSvg(:src="require('@/assets/tr-info/info-add-on-turn.svg')")

          .action__grid(:key="gameName")
            .action__form-field(v-if="!field.isHidden" v-for="(field, index) of sectionFields" :key="index")
              .form-double(v-if="field.type === 'double'")
                .form-double__title(v-if="field.title") {{ field.title }}

                .form-double__body
                  InputSmart(:item="field.fields[0]")
                  InputSmart(:item="field.fields[1]")

              InputSmart(v-else :item="field")

        .action-create-tournament__buttons
          Button(is-primary @click="onCreateTournamentClick")
            | Создать

          Button(is-secondary @click="onCopyTournamentClick" v-if="templateTournaments.length > 0")
            | Копировать турнир 
          
          Button(
            v-if="isStages && activeStageIndex !== 0 && stageTabs.length > 1" is-secondary
            @click="onDeleteStageClick"
          ) Удалить этап
        
        p.action-create-tournament__title(v-if="showTemplates")
          | Заполнить из шаблона

        .action-create-tournament__templates(v-if="showTemplates")
          .action-create-tournament__template(v-for="templateTournament of templateTournaments" :key="templateTournament.id")
            Button(is-secondary @click="onCopyTournamentClick(templateTournament.id)")
              | {{ templateTournament.name }}

</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader';
import Tabs from '@/components/Tabs/Tabs';
import InputSmart from '@/components/InputSmart/InputSmart';
import megaGameData from '@/data/games/mega-tournaments';
import stageData from '@/data/games/mega-tournaments/stages';

import TournamentMixin from '@/mixins/Tournament';
import { mapActions } from 'vuex';

import TournamentService from '@/services/TournamentsService';

export default {
  name: 'ActionCreateMegaTournament',

  components: {
    SectionHeader,
    InputSmart,
    Tabs,
  },

  mixins: [TournamentMixin],

  data() {
    return {
      activeTabIndex: 0,
      gameFields: [],
      rules: '',
      templatesTournaments: {},
      fieldsToMap: {},
      activeStageIndex: 0,
      stageTabs: ['Этап 1'],
      stageData,
    };
  },

  computed: {
    game() {
      return megaGameData[this.gameName] || megaGameData['pubg-mobile'];
    },

    gameStage() {
      return this.stageData[this.gameName]?.fields();
    },

    gameName() {
      return this.$route.params.section;
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Создание мега турнира`,
        game: this.gameName,
      };
    },

    gameSections() {
      return this.game.sections;
    },

    currentSection() {
      return this.game.sections[this.activeTabIndex];
    },

    sectionFields() {
      if (this.isStages) {
        return this.gameFields.filter(
          (field) =>
            field.isStaged &&
            field.section === this.currentSection.id &&
            field.stageId === this.activeStageIndex
        );
      }

      return this.gameFields.filter(
        (field) => field.section === this.currentSection.id
      );
    },

    tournamentData() {
      const fields = this.gameFields;
      let tournament = {};

      fields.forEach((field) => {
        if (field.type === 'double') {
          const [field1, field2] = field.fields;

          tournament[field1.key] = field1.value;
          tournament[field2.key] = field2.value;
        } else {
          const value = field.value;

          if (!field.isHidden) {
            tournament[field.key] = value;
          }
        }
      });

      return tournament;
    },

    isStages() {
      return this.currentSection.id === 'stages';
    },

    stageTabsLength() {
      return this.stageTabs.length;
    },

    templateTournaments() {
      return this.templatesTournaments[this.gameName] || [];
    },

    showTemplates() {
      return (
        process.env.NODE_ENV === 'production' &&
        this.templateTournaments.length > 0
      );
    },
  },

  watch: {
    gameName() {
      this.initPage();
    },

    gameFields: {
      handler(fields) {
        fields.forEach((field) => {
          if (field.show) {
            const cond =
              this.tournamentData[field.show.key] !== field.show.value;

            field.isHidden = cond ? true : false;
          }
        });

        this.$forceUpdate();
      },
      deep: true,
    },

    stageTabsLength() {
      this.stageTabs = this.stageTabs.map((tab, i) => {
        if (i + 1 === this.stageTabsLength) {
          return 'Финал';
        }

        return `Этап ${i + 1}`;
      });
    },
  },

  created() {
    this.initPage();
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    initPage() {
      const gameFields = this.game.fields();
      const stageFields = this.gameStage.map((field) => {
        if (field.type === 'double') {
          field.fields = field.fields.map((f) => ({
            ...f,
            key: f.key.replace('<index>', `0`),
            show: f.show
              ? { key: f.show.key.replace('<index>', `0`), value: f.show.value }
              : undefined,
          }));

          return {
            ...field,
          };
        } else {
          return {
            ...field,
            key: field.key.replace('<index>', `0`),
            show: field.show
              ? {
                  key: field.show.key.replace('<index>', `0`),
                  value: field.show.value,
                }
              : undefined,
          };
        }
      });

      gameFields.forEach((field) => (field.isHidden = false));

      this.gameFields = gameFields.concat(stageFields);

      this.loadTemplateTournaments();
      this.loadFieldsToMap();

      this.activeTabIndex = 0;
      this.$forceUpdate();
    },

    onDeleteStageClick() {
      this.gameFields = this.gameFields.filter(
        (field) => field.stageId !== this.activeStageIndex
      );

      this.stageTabs.splice(this.activeStageIndex, 1);

      this.gameFields.forEach((field) => {
        if (field.stageId > this.activeStageIndex) {
          field.stageId -= 1;
          field.key = field.key.replace(
            this.activeStageIndex.toString,
            field.stageId
          );
        }
      });

      this.activeStageIndex -= 1;
    },

    fieldMapper(tournamentData, field, index) {
      const fieldToMap = this.fieldsToMap[this.gameName][index];
      if (!fieldToMap) return field;

      const defaultMapFunction = (key, tournament) => {
        const result = key.split('.').reduce((o, i) => {
          if (!o) return;
          if (+i || +i === '0') return o[+i];
          return o[i];
        }, tournament);
        return () => result;
      };
      const mapFunction =
        fieldToMap.mapFunction || defaultMapFunction(field.key, tournamentData);
      return { ...field, value: mapFunction(tournamentData) };
    },

    async onCopyTournamentClick(id = 0) {
      const promptText = `Введите ID турнира${
        process.env.NODE_ENV === 'production' ? '' : ' (на dev)'
      } для копирования`;
      if (!id) id = prompt(promptText, id);
      id = +id;
      if (!id) return;
      let { data } = (
        await TournamentService.getMegaTournament(this.gameName, id)
      ).data;

      if (this.templateTournaments?.length) {
        this.gameFields = this.gameFields.map((field, index) =>
          this.fieldMapper(data, field, index)
        );
      }

      this.$forceUpdate();
    },

    async loadTemplateTournaments() {
      this.templatesTournaments = await TournamentService.getTournamentTemplates(
        true
      );
    },

    async loadFieldsToMap() {
      this.fieldsToMap = await TournamentService.getTournamentCopySettings(
        true
      );
    },

    onCreateTournamentClick() {
      const requiredFields = this.getRequiredFields();

      if (requiredFields.length) {
        let fieldsString = requiredFields.join(', ');

        return this.showModal({
          component: 'ModalMessage',
          data: {
            text: 'Следующие поля обязательны для заполнения: ' + fieldsString,
          },
        });
      }

      Object.keys(this.tournamentData).forEach((key) => {
        if (key.includes('prize_settings.prize_pool_type')) {
          let keyStart = '';

          if (key.startsWith('stages.')) {
            const [first, second] = key.split('.');

            keyStart = `${first}.${second}.`;
          }

          const prizePoolType = this.tournamentData[
            keyStart + 'prize_settings.prize_pool_type'
          ];
          const prizeCurrency = this.tournamentData[
            keyStart + 'prize_settings.prize_currency'
          ];
          let prizePool;

          if (prizePoolType === 'fixed') {
            prizePool = this.tournamentData[keyStart + 'prize_pool'];
          } else if (prizePoolType === 'not_fixed_with_guarantee') {
            prizePool = this.tournamentData[
              keyStart + 'prize_settings.guaranted_prize'
            ];
          }

          if (prizeCurrency === 'money') prizePool /= 100;

          if (prizeCurrency === 'tickets' && prizePool > 500) {
            return this.showModal({
              component: 'ModalMessage',
              data: { text: 'Призовой фонд не может превышать 500 билетов' },
            });
          } else if (prizeCurrency === 'money' && prizePool > 20000) {
            return this.showModal({
              component: 'ModalMessage',
              data: { text: 'Призовой фонд не может превышать 20000 евро' },
            });
          }
        }
      });

      const tournament = this.refactorTournament(this.tournamentData);

      tournament.stages.forEach((stage, i) => {
        stage.prize_settings.rake = tournament.prize_settings.rake;
        stage.prize_settings.entry_fee_currency =
          tournament.prize_settings.entry_fee_currency;

        if (this.gameName !== 'lichess') {
          tournament.stages[i].is_anticheat_required = false;
        }
      });

      this.createMegaTournament(tournament);
    },

    onStageClick(index) {
      this.activeStageIndex = index;
    },

    onAddStageClick() {
      // if (this.stageTabs.length === 4) {
      //   return;
      // }

      const stageIndex = this.stageTabs.length;

      const stageFields = stageData[this.gameName].fields().map((field) => {
        if (field.type === 'double') {
          const fields = field.fields.map((f) => {
            const currentStageIndex = f.key.split('.')[1];

            return {
              ...f,
              stageId: stageIndex,
              key: f.key.replace(currentStageIndex, stageIndex),
              show: f.show
                ? {
                    key: f.show.key.replace(currentStageIndex, stageIndex),
                    value: f.show.value,
                  }
                : undefined,
            };
          });

          return {
            ...field,
            fields: fields,
            stageId: stageIndex,
          };
        } else {
          return {
            ...field,
            key: field.key.replace('<index>', stageIndex),
            show: field.show
              ? {
                  key: field.show.key.replace('<index>', stageIndex),
                  value: field.show.value,
                }
              : undefined,
            stageId: stageIndex,
          };
        }
      });

      this.stageTabs.push('Этап');
      this.gameFields = this.gameFields.concat(stageFields);
      this.activeStageIndex = stageIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.action__grid {
  margin-top: 32px;
}

.action-create-tournament {
  &__body {
    display: flex;
    flex-direction: column;
  }

  &__buttons {
    margin: 30px 0 0 0;
    display: grid;
    grid-gap: 20px;
    width: min-content;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: $breakpointMobile) {
      grid-template-columns: 1fr;
    }

    @media screen and (max-width: $breakpointMobile) {
      margin: 32px 0 0;
    }
  }

  &__templates {
    display: flex;
    align-items: center;
    gap: 14px;
    max-width: 760px;
    flex-wrap: wrap;
  }

  &__title {
    color: #839ab5;
    font-weight: 600;
    margin-bottom: 14px;
    margin-top: 20px;
  }
}

.form-double {
  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: #829ab5;
    margin: 0 8px 8px 4px;
    letter-spacing: 0.3px;
    font-family: 'Roboto', sans-serif;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}

.tournament-stages {
  &__stages {
    display: flex;
    margin-top: 16px;
  }

  &__stage {
    padding: 16px;
    color: $white;
    cursor: pointer;

    &-add {
      display: flex;
      align-items: center;
      padding: 0 16px;
      cursor: pointer;

      & svg {
        width: 32px;
        height: 32px;
      }
    }

    &.--active {
      border-bottom: 2px solid #ffb300;
    }
  }
}
</style>
