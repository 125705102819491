<template lang="pug">
  .tournaments-filters
    .tournaments-filters__filter-container
      select.tournaments-filters__filter(v-model="createdBy")
        option(
          v-for="option in createdByOptions"
          :key="option.value"
          :value="option.value"
          :selected="option.value === createdBy"
        )
          | {{ option.title }}
    .tournaments-filters__filter-container
      input.tournaments-filters__filter(type="date" v-model="tournamentsDate")
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "TournamentsFilters",

  data() {
    return {
      createdBy: 'all',
      tournamentsDate: undefined,
    }
  },

  computed: {
    ...mapState('user', ['user']),

    createdByOptions() {
      return [
        {
          value: null,
          title: 'Not booked'
        },
        {
          value: 'all',
          title: 'Not set',
        },
        {
          value: this.user?.id || 99999999,
          title: 'By me',
        },
        {
          value: 'any',
          title: 'Booked',
        }
      ]
    },
  },

  watch: {
    createdBy: {
      immediate: true,
      handler() {
        this.$emit('setFilters', this.calcFilters())
      },
    },
  },

  methods: {
    calcFilters() {
      const filters = []
      if (this.createdBy === this.user?.id) {
        filters.push({
          name: 'created_by',
          type: 'string',
          values: [{text: this.createdBy}],
        })
      } else if (this.createdBy === 'any') {
        filters.push({
          name: 'created_by',
          type: 'number',
          values: [{from: 1}],
        })
      } else if (!this.createdBy) {
        filters.push({
          name: 'created_by',
          type: 'string',
          values: [{text: this.createdBy}],
        })
      }
      return filters
    },
  },
}
</script>

<style lang="scss" scoped>
.tournaments-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 50px 20px;

  &__filter {
    background-color: $blue-grey60;
    height: 48px;
    min-width: 300px;
    padding: 5px 20px;
    margin-right: 20px;
    color: $white;
    border-radius: 12px;
    cursor: pointer;

    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
      cursor: pointer;
    }

    &-container {
      background-color: $blue-grey60;
      border-radius: 12px;
      margin-right: 8px;
      width: max-content;
    }
  }
}
</style>