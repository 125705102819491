import actionsList from '../data/navigation/actions'

const state = () => ({
  allSections: {},
  sections: {}
})

const getters = {
  sections(state) {
    return state.sections
  }
}

const actions = {
  async setSections({ commit }, payload) {
    try {
      const gamesList = payload

      commit('setSections', gamesList)
      commit('setActions')
    } catch (e) {
      console.error(e)
    }
  },
}

const mutations = {
  setSections(state, payload) {
    let sections = {}

    payload
      .filter(game => game.isAdminSupported)
      .forEach(section => sections[section.name] = section)

    sections.other = {
      name: 'other',
      extra_data: {
        title: 'Управление',
        icon: require('@/assets/img/icons/star.svg'),
        image_url: 'https://cdn.vvvgamers.com/images/banners/all-games-banner.jpg',
      }
    }

    state.allSections = sections
  },

  setActions(state) {
    const sections = state.allSections

    Object.values(sections).forEach(section => {
      const actions = actionsList[section.name]

      if (section.name !== 'other') {
        section.actions = [
          ...actionsList.gameDefault,
          ...actions || []
        ]
      } else {
        section.actions = actions
      }
    })

    state.allSections = sections
  },

  setAvailableSections(state, payload) {
    const userRole = payload
    const sections = {}

    Object.values(state.allSections).forEach(section => {
      const actions = state.allSections[section.name].actions

      actions.forEach(action => {
        const isUserRoleIncluded = action.roles.includes(userRole)

        if (isUserRoleIncluded) {
          if (!sections[section.name]) {
            sections[section.name] = section
            sections[section.name].actions = []
          }

          sections[section.name].actions.push(action)
        }
      })
    })

    state.sections = sections
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
