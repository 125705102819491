<template lang="pug">
  .duels-create
    SectionHeader(:header="header")

    .section__body(class="--padded")
      Steps(:steps="stepTitles" :current-step-index="currentStepIndex")

      .duels-create__step.duels-create__create(v-if="duel && currentStepIndex === 0")
        .duels-create__create-inputs
          CopyInput.duels-create__create-input(value="VVV Duel" title="Название турнира")
          CopyInput.duels-create__create-input(:value="duel.id.toString()" title="Пароль")
          CopyInput.duels-create__create-input(:value="duel.extra_data.config.map" title="Карта")
          CopyInput.duels-create__create-input(:value="duel.extra_data.config.mode" title="Режим")

        .duels-create__create-data
          InputSmart(:item="lobbyIdInput" v-model="lobbyIdInput.value" :type="lobbyIdInput.type")
          Button(is-primary @click="onNextStepButtonClick" :is-disabled="!isNextStepAvailable") Отправить данные

      .duels-create__step(v-if="currentStepIndex === 1")
        .duels-create__caption Запустите бой в игре в это время — {{ duelDate }} — и после нажмите на кнопку ниже
        Button(is-primary @click="onNextStepButtonClick" :is-disabled="!isNextStepAvailable" style="margin-bottom: 16px;") Дуэль началась
        .moderation-duel__section
          .moderation-duel__section-header
            span Игроки

          .moderation-duel__section-body
            .moderation-duel__players
              PlayerRow(:key="accountPlayer.id" :player="accountPlayer")
              PlayerRow(:key="opponentPlayer.id" :player="opponentPlayer")

      .duels-create__step(v-if="currentStepIndex === 2")
        .duels-create__caption Как только матч в игре завершится, сохраните скриншот результатов дуэли и нажмите кнопку ниже
        Button(is-primary @click="onNextStepButtonClick" :is-disabled="!isNextStepAvailable") Дуэль завершилась

      .duels-create__step(v-if="currentStepIndex === 3")
        .duels-create__caption
          | Нажмите на аватарку победителя

        DuelPlayers(v-model="activePlayer" v-bind="duelPlayers")

        .duels-create__buttons
          Button(is-primary @click="onNextStepButtonClick" :is-disabled="!isNextStepAvailable") Отправить результаты
          Button(is-primary @click="onCancelDuelClick").duels-create__buttons-draw Ничья

      .duels-create__step(v-if="currentStepIndex === 4")
        .duels-create__caption Дуэль завершена! :)


</template>

<script>
import Steps from '@/components/Steps/Steps'
import CopyInput from '@/components/CopyInput/CopyInput'
import InputSmart from '@/components/InputSmart/InputSmart'
import PlayerRow from '@/components/WinnerRow/WinnerRow'
import { mapActions, mapGetters } from 'vuex'
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import DuelPlayers from '@/components/DuelPlayers/DuelPlayers'

export default {
  name: 'ActionDuelsCreate',

  components: {
    SectionHeader,
    Steps,
    CopyInput,
    InputSmart,
    PlayerRow,
    DuelPlayers,
  },

  data: () => ({
    duel: {},

    lobbyIdInput: {
      title: 'ID созданного лобби',
      type: 'number',
      value: null,
    },

    activePlayer: '',
  }),

  computed: {
    ...mapGetters('moderation', ['winners']),

    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: this.duel.title,
        image: this.duel.image_url,
        back: `/${this.gameName}/events`,
        description: [
          {
            text: `#${this.duel.id}`,
            link: `${process.env.VUE_APP_DOMAIN}/${this.gameName}/duels/${this.duelPassword}`
          },
          { text: this.duelDate }
        ],
      }
    },

    duelPassword() {
      return this.$route.params.password
    },

    duelDate() {
      return this.$moment.format(this.duel.date, 'lll')
    },

    stepTitles() {
      return ['Создание', 'Старт',  'Завершение', 'Модерация']
    },

    currentStepIndex() {
      if (this.duel?.state === 'waiting_for_lobby' && !this.lobbyId) {
        return 0
      } else if (this.duel?.state === 'waiting_for_lobby') {
        return 1
      } else if (this.duel?.state === 'in_progress') {
        return 2
      } else if (this.duel?.state === 'moderation') {
        return 3
      } else {
        return 4
      }
    },

    lobbyId() {
      return this.duel?.extra_data?.game?.game?.extra_data?.id
    },

    isNextStepAvailable() {
      if (this.currentStepIndex === 0 && !this.lobbyIdInput.value) {
        return false
      } else if (this.currentStepIndex === 3 && !this.activePlayer) {
        return false
      }

      return true
    },

    accountPlayer() {
      return {
        username: this.duel?.account.game_username,
      }
    },

    opponentPlayer() {
      return {
        username: this.duel?.opponent.game_username || 'Аккаунт отвязан',
      }
    },

    duelPlayers() {
      return {
        account: this.duel?.account,
        opponent: this.duel?.opponent,
      }
    },

    accountGameUsername() {
      return this.duel?.account?.game_username
    },

    opponentGameUsername() {
      return this.duel?.opponent?.game_username
    },
  },

  created() {
    this.initDuel()
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async initDuel() {
      this.$loading.start()

      try {
        const { data } =
          await this.$axios.get(`/games/${this.gameName}/duels/${this.duelPassword}`)

        this.duel = data.data
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    async onNextStepButtonClick() {
      this.$loading.start()

      try {
        if (this.currentStepIndex === 0) {
          await this.$axios.post(`/admin/games/${this.gameName}/duels/${this.duel.id}/lobby`, {
            lobbyID: this.lobbyIdInput.value.toString(),
            lobbyPassword: this.duel.id.toString(),
          })
        } else if (this.currentStepIndex === 1) {
          await this.$axios.put(`/admin/games/${this.gameName}/duels/${this.duel.id}`, {
            state: 'in_progress'
          })
        } else if (this.currentStepIndex === 2) {
          await this.$axios.put(`/admin/games/${this.gameName}/duels/${this.duel.id}`, {
            state: 'moderation'
          })
        } else if (this.currentStepIndex === 3) {
          const winners = []
          const platformUsername =
              this.activePlayer === this.duel?.account?.game_username ? this.duel?.account.full_name : this.duel?.opponent.full_name

          winners.push({
            platform_username: platformUsername,
            username: this.activePlayer,
            points: 40,
          })

          await this.$axios.post(
            `/admin/games/${this.gameName}/duels/${this.duel.id}/moderation`,
            { winners },
          )
        }

        setTimeout(async () => {
          await this.initDuel()
        }, 1000)
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    async onCancelDuelClick() {
      this.$loading.start()

      try {
        await this.$axios.post(`/admin/games/${this.gameName}/duels/${this.duel.id}/cancel`, {
          cancel_reason: 'Ничья',
          is_draw: true,
        })

        setTimeout(async () => {
          await this.initDuel()
        }, 1000)
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/include/participiants.scss';

.duels-create {
  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60vh;
    margin-bottom: 50px;
  }

  &__wrapper {
    padding-top: 24px;
  }

  &__players {
    height: 112px;
    width: 100%;
    background: #232F3F;
    border-radius: 8px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;

    &-wrapper {
      display: flex;
      height: 100%;

      @media screen and (max-width: $breakpointMobile) {
        flex-direction: column;
        padding: 16px;
        align-items: center;
      }
    }

    &-left, &-right {
      display: flex;
      align-items: center;
      margin: 0 30px;

      &-nickname {
        @include heading4;

        color: $white;
        margin: 0 16px;
      }

      &-image {
        object-fit: cover;
        width: 71px;
        height: 71px;
        border-radius: 50%;
        cursor: pointer;
      }

      &-selected {
        width: 71px;
        height: 71px;
        background: #1C2735;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media screen and (max-width: $breakpointMobile) {
        margin: 0;
      }
    }

    &-center {
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #1c2735;
      transform: skew(-20deg);

      &-text {
        @include heading3;

        color: $white;
        transform: skew(20deg);
      }

      @media screen and (max-width: $breakpointMobile) {
        margin: 12px 0;
      }
    }

    @media screen and (max-width: $breakpointMobile) {
      height: auto;
    }
  }

  &__caption {
    color: #a3bcc7;
    margin-bottom: 8px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  &__image img {
    border-radius: 24px;
    //max-width: 760px;
    width: 100%;
  }

  &__buttons {
    display: flex;

    @media screen and (max-width: $breakpointMobile) {
      flex-direction: column;
      align-items: center;
    }

    .button {
      width: 100%;
    }

    &-draw {
      margin-left: 8px;

      @media screen and (max-width: $breakpointMobile) {
        margin: 8px 0 0;
      }
    }
  }

  .section__header {
    margin-bottom: 18px;
  }

  &__step {
    margin: 18px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__create {
    &-inputs {
      padding-bottom: 16px;
    }

    &-input:not(:last-child) {
      margin-bottom: 16px;
    }

    &-data {
      margin: 16px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .button {
        margin-top: 12px;
      }
    }
  }

  .moderation-duel {
    &__section {
      width: 100%;
      margin-bottom: 16px;

      &-header {
        display: flex;
        justify-content: space-around;
        padding: 16px 0;
        border-top: 1px solid $blue-grey50;
        border-bottom: 1px solid $blue-grey50;

        & span {
          @include caption-small;

          font-size: 14px;
          color: $blue-grey10;
        }
      }

      &-body {
        margin-top: 16px;
        //padding-right: 32px;
      }
    }

    &__done {
      @include heading4;

      color: $white;
    }
  }

  .player-row:not(:last-child) {
    margin-bottom: 8px;
  }
}

.account-list {
  &__list {
    max-width: 660px;
    width: 100%;
  }

  &__element {
    padding: 20px 24px;
    background: $blue-grey60;
    border-radius: 8px;
    margin-top: 8px;
    color: $white;
  }
}
</style>
