<template lang="pug">
  div
    .section-preheader(v-if="header.back")
      .section-preheader__back(@click="$router.push(header.back)")
        InlineSvg(:src="require('@/assets/img/icons/arrow-left.svg')")
        | Вернуться

    .section-header(:class="{ '--underlined': header.isUnderlined }")
      .section-header__wrapper
        .section-header__title
          .section-header__title-heading(v-html="heading")

        .section-header__description(v-if="header.description && header.description.length")
          .section-header__description-item(v-for="(item, index) in header.description")
            .section-header__description-text(:class="{ 'section-header__link': item.link }" @click="onLinkClick(item.link)")
              | {{ item.text }}

            .section-header__description-text(v-if="index < header.description.length - 1") ·
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SectionHeader',

  props: {
    header: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    isMega: false,
  }),

  computed: {
    ...mapState('games', ['gamesByName']),

    img() {
      if (this.header.game && !this.header.image) {
        return this.gamesByName[this.header.game]?.extra_data?.image_url
      }

      return this.header.image
    },

    heading() {
      const parts = this.header?.title?.replace('undefined / ', '').split(' / ')

      if (parts?.length === 1) {
        return parts[0]
      }

      return `${parts?.[0]} <span style="color: #839ab5">/ ${parts?.[1]}</span>`
    }
  },

  methods: {
    onLinkClick(link) {
      if (!link) {
        return
      }

      window.open(link, '_blank')
    },

    onArrowClick() {
      this.mode = !this.mode
      this.$emit('input', this.mode)
    }
  }
}
</script>

<style scoped lang="scss">
.section-preheader {
  padding: 0 32px 0;
  margin-bottom: 34px;

  &__back {
    @include heading6;

    font-size: 18px;
    color: $primary60;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;

    svg {
      margin-right: 11px;

      g {
        fill: $primary60;
      }
    }

    @media screen and (max-width: $breakpointMobile) {
      padding: 0 16px 0;
    }
  }
}

.section-header {
  padding: 0 32px 0;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;

  &.--underlined {
    padding-bottom: 16px;
  }

  &__title {
    display: flex;
    align-items: center;

    &-heading {
      @include heading3;

      color: $white;
      width: 100%;
    }

    &-arrow {
      margin-left: 18px;
      cursor: pointer;
    }
  }

  &__description {
    display: flex;
    margin-top: 16px;

    &-item {
      display: flex;
    }

    &-text {
      margin: 0 4px;
      color: $blue-grey10;

      @include caption-small;
    }
  }

  &__image {
    height: 64px;
    width: 64px;
    object-fit: cover;
    border-radius: 50%;
  }

  &__link {
    color: $primary60;
    cursor: pointer;
  }

  @media screen and (max-width: $breakpointMobile) {
    padding: 0 16px 0;
  }
}
</style>
