import moment from 'moment';

export default {
  title: 'Standoff 2',

  sections: [
    { id: 'general', title: 'Основное' },
    { id: 'prize_pool', title: 'Призовой фонд' },
    { id: 'prize_pool_distribution', title: 'Распределение ПФ' },
    { id: 'game_process', title: 'Игра' },
    { id: 'other', title: 'Остальное' },
    { id: 'nominations', title: 'Номинации' },
  ],

  fields: function() {
    return [
      {
        title: 'Название турнира',
        value: '',
        type: 'text',
        key: 'title',
        description:
          'Это название будет отображено только на платформе. Название лобби в игре — «VVV Tournament»',
        section: 'general',
      },

      {
        title: 'Пароль к турниру',
        value: '',
        type: 'text',
        key: 'password',
        description: `Пароль был сгенерирован автоматически`,
        section: 'general',
        required: true,
      },

      {
        title: 'Дата начала',
        value: '',
        type: 'date',
        key: 'date',
        handler(date) {
          return moment(date)
            .subtract(3, 'hours')
            .format('YYYY-MM-DD HH:mm');
        },
        section: 'general',
        required: true,
      },

      {
        title: 'Мин. рейтинг',
        value: '',
        type: 'select',
        options: [
          { title: 'All', value: '' },
          { title: 'V', value: 'V' },
          { title: 'VV', value: 'VV' },
          { title: 'VVV', value: 'VVV' },
          { title: 'VVVV', value: 'VVVV' },
          { title: 'VVVVV', value: 'VVVVV' },
        ],
        withEmptyValue: false,
        key: 'min_rating_group',
        section: 'general',
      },

      {
        title: 'Макс. рейтинг',
        value: '',
        type: 'select',
        options: [
          { title: 'All', value: '' },
          { title: 'V', value: 'V' },
          { title: 'VV', value: 'VV' },
          { title: 'VVV', value: 'VVV' },
          { title: 'VVVV', value: 'VVVV' },
          { title: 'VVVVV', value: 'VVVVV' },
        ],
        withEmptyValue: false,
        key: 'max_rating_group',
        section: 'general',
      },

      {
        title: 'Регион',
        value: 'Europe',
        type: 'select',
        options: [
          { title: 'America', value: 'America' },
          { title: 'Asia', value: 'Asia' },
          { title: 'Europe', value: 'Europe' }
        ],
        withEmptyValue: false,
        key: 'region',
        section: 'general',
      },

      {
        title: 'Блокировать другие ГЕО',
        value: false,
        type: 'checkbox',
        key: 'extra_data.block_geo',
        section: 'general',
      },

      {
        title: 'Дата начала регистрации',
        value: null,
        type: 'date',
        key: 'registration_starts_at',
        section: 'general',
        handler(date) {
          return moment(date).subtract(3, 'hours').format('YYYY-MM-DD HH:mm')
        },
      },

      {
        title: 'Дата закрытия регистрации',
        value: null,
        type: 'date',
        key: 'registration_ends_at',
        section: 'general',
        handler(date) {
          return moment(date)
            .subtract(3, 'hours')
            .format('YYYY-MM-DD HH:mm');
        },
      },

      {
        title: 'Закрытый турнир',
        value: false,
        type: 'checkbox',
        key: 'is_private',
        section: 'general',
      },

      {
        title: 'Пароль для записи в турнир',
        value: '',
        type: 'text',
        show: {
          key: 'is_private',
          value: true,
        },
        required: true,
        key: 'private_password',
        section: 'general',
      },

      {
        title: 'Спонсорский турнир',
        value: false,
        type: 'checkbox',
        key: 'is_sponsored',
        section: 'general',
      },

      {
        title: 'ID спонсора',
        value: '',
        type: 'number',
        show: {
          key: 'is_sponsored',
          value: true,
        },
        required: true,
        key: 'sponsor_id',
        section: 'general',
      },

      {
        title: 'Спонсорский турнир (SKA)',
        value: false,
        type: 'checkbox',
        key: 'is_sponsored',
        section: 'general',
      },

      // prize_pool

      {
        title: 'Стоимость входа',
        value: 100,
        type: 'number',
        key: 'entry_fee',
        currencyField: 'prize_settings.entry_fee_currency',
        section: 'prize_pool',
        required: true,
      },

      {
        title: 'Валюта входного билета',
        value: 'coins',
        type: 'select',
        options: [
          { title: 'Coins', value: 'coins' },
          { title: 'EUR', value: 'money' },
        ],
        withEmptyValue: false,
        key: 'prize_settings.entry_fee_currency',
        section: 'prize_pool',
      },

      {
        title: 'Тип призового фонда',
        value: 'not_fixed_with_guarantee',
        type: 'select',
        options: [
          { title: 'Фиксированный', value: 'fixed' },
          { title: 'Нефиксированный', value: 'not_fixed' },
          {
            title: 'Нефиксированный с гарантированным призом',
            value: 'not_fixed_with_guarantee',
          },
        ],
        withEmptyValue: false,
        key: 'prize_settings.prize_pool_type',
        section: 'prize_pool',
      },

      {
        title: 'Сумма фиксированного призового фонда',
        value: null,
        type: 'number',
        key: 'prize_pool',
        currencyField: 'prize_settings.prize_currency',
        show: {
          key: 'prize_settings.prize_pool_type',
          value: 'fixed',
        },
        section: 'prize_pool',
        required: true,
      },

      {
        title: 'Сумма гарантированного призового фонда',
        value: null,
        type: 'number',
        key: 'prize_settings.guaranted_prize',
        currencyField: 'prize_settings.prize_currency',
        show: {
          key: 'prize_settings.prize_pool_type',
          value: 'not_fixed_with_guarantee',
        },
        description: `Гарантированный приз — это сумма, которая в любом случае будет разыграна в турнире`,
        section: 'prize_pool',
        required: true,
      },

      {
        title: 'Валюта призового фонда',
        value: 'coins',
        type: 'select',
        options: [
          { title: 'Coins', value: 'coins' },
          { title: 'EUR', value: 'money' },
          { title: 'Tickets', value: 'tickets' },
        ],
        withEmptyValue: false,
        key: 'prize_settings.prize_currency',
        section: 'prize_pool',
      },

      {
        title: 'Утешительный приз',
        value: false,
        type: 'checkbox',
        key: 'has_consolation_prize',
        notSend: true,
        section: 'prize_pool',
      },

      {
        title: 'Валюта утешительного приза',
        value: 'coins',
        type: 'select',
        options: [
          { title: 'Coins', value: 'coins' },
          { title: 'EUR', value: 'money' },
          { title: 'Tickets', value: 'tickets' },
        ],
        withEmptyValue: false,
        show: {
          key: 'has_consolation_prize',
          value: true,
        },
        key: 'prize_settings.consolation_prize.currency',
        section: 'prize_pool',
      },

      {
        title: 'Сумма утешительного приза',
        value: null,
        type: 'number',
        key: 'prize_settings.consolation_prize.amount',
        show: {
          key: 'has_consolation_prize',
          value: true,
        },
        section: 'prize_pool',
      },

      // prize_pool_distribution

      {
        title: 'Prizes distribution',
        value: 'mixed',
        type: 'select',
        options: [
          { title: 'By place in the top', value: 'place' },
          { title: 'By the number of points', value: 'point' },
          { title: 'By place in the top and number of points', value: 'mixed' },
        ],
        withEmptyValue: false,
        key: 'prize_settings.place_distribution_type',
        section: 'prize_pool_distribution',
      },

      {
        title: 'Тип распределения через топ',
        value: 'top',
        type: 'select',
        options: [
          { title: 'Top', value: 'top' },
          { title: 'Percentage', value: 'percentage' },
        ],
        withEmptyValue: false,
        key: 'prize_settings.prize_winner_type',
        section: 'prize_pool_distribution',
      },

      {
        title: 'Количество призовых мест ',
        value: 5,
        type: 'number',
        key: 'prize_settings.win_place_count',
        show: {
          key: 'prize_settings.prize_winner_type',
          value: 'top',
        },
        section: 'prize_pool_distribution',
        required: true,
      },

      {
        title: 'Процент призовых мест',
        value: 10,
        type: 'number',
        key: 'prize_settings.win_place_count',
        show: {
          key: 'prize_settings.prize_winner_type',
          value: 'percentage',
        },
        section: 'prize_pool_distribution',
        required: true,
      },

      {
        title: 'Тип распределения ПФ',
        value: 'bezier',
        type: 'select',
        options: [
          { title: 'Bezier', value: 'bezier' },
          { title: 'Flat', value: 'flat' },
          { title: 'Double', value: 'double' },
        ],
        withEmptyValue: false,
        key: 'prize_settings.prize_distribution_type',
        section: 'prize_pool_distribution',
      },

      {
        title: 'Точки на Кривой Безье',
        value: '0,8/0,7/0,3/0,1',
        type: 'text',
        key: 'prize_settings.bezier_points',
        handler(points) {
          let data = [];

          points.split('/').forEach((point) => {
            point = point.split(',');
            data.push({ x: parseFloat(point[0]), y: parseFloat(point[1]) });
          });

          return data;
        },
        valueToField(value) {
          return value.map((point) => `${point.x},${point.y}`).join('/');
        },
        show: {
          key: 'prize_settings.prize_distribution_type',
          value: 'bezier',
        },
        section: 'prize_pool_distribution',
        required: true,
      },

      {
        title: 'Награды',
        value: '',
        type: 'text',
        key: 'prize_settings.flat_prizes',
        show: {
          key: 'prize_settings.prize_distribution_type',
          value: 'flat',
        },
        handler(points) {
          let data = [];

          data = points.split(',').map(Number);

          return data;
        },
        valueToField(value) {
          return value?.join('/');
        },
        placeholder: 'Через запятую: xxx,yyy,zzz,hhh',
        section: 'prize_pool_distribution',
        required: true,
      },

      // other

      {
        title: 'Ссылка на картинку турнира',
        value: 'https://cdn.vvvgamers.com/images/tournaments/pubg-mobile/1.png',
        type: 'text',
        key: 'image_url',
        section: 'other',
        required: true,
      },

      {
        title: 'Тип турнира',
        value: 'player',
        type: 'select',
        options: [
          { title: 'Одиночный', value: 'player' },
          { title: 'Командный', value: 'team' },
        ],
        withEmptyValue: false,
        key: 'tournament_player_settings.entry_type',
        section: 'other',
      },

      {
        title: 'Игроков в команде',
        value: 2,
        type: 'number',
        key: 'tournament_player_settings.team_size',
        section: 'other',
        show: {
          key: 'tournament_player_settings.entry_type',
          value: 'team',
        },
        required: true,
      },

      {
        title: 'Минимальное кол-во участников',
        value: 5,
        type: 'number',
        key: 'tournament_player_settings.min_players_count',
        section: 'other',
        required: true,
      },

      {
        title: 'Максимальное кол-во участников',
        value: 100,
        // max: 100,
        type: 'number',
        key: 'tournament_player_settings.max_players_count',
        section: 'other',
      },

      {
        title: 'Комиссия платформы (в процентах)',
        value: 5,
        max: 100,
        type: 'number',
        key: 'prize_settings.rake',
        section: 'other',
        required: true,
      },

      {
        title: 'Правила (RU)',
        value: '',
        type: 'textarea',
        key: 'rules_ru',
        description: `Для выделения жирным определенного участка текста, возьмите его в звёздочки. Пример: *этот текст будет выделен*`,
        handler(rules) {
          const startsWithBold = rules.startsWith('*');
          if (startsWithBold) {
            rules = '^' + rules;
          }

          const boldWords = rules.split('*');
          if (boldWords.length) {
            boldWords.forEach((p, index) => {
              if (index % 2 !== 0) {
                boldWords[index] = '<b>' + p + '</b>';
              }
            });

            rules = boldWords.join('');
          }

          let paragraphs = rules.split('\n');
          if (paragraphs[0]) {
            rules = paragraphs.map((p) => `<p>${p}</p>`).join('');
          }

          if (startsWithBold) {
            rules = rules.replace('^', '');
          }

          return rules;
        },
        valueToField(value) {
          return value?.replace(/<p>|<\/p>/g, '').replace(/<b>|<\/b>/, '*');
        },
        section: 'other',
      },

      {
        title: 'Правила (EN)',
        value: '',
        type: 'textarea',
        key: 'rules_en',
        description: `Для выделения жирным определенного участка текста, возьмите его в звёздочки. Пример: *этот текст будет выделен*`,
        handler(rules) {
          const startsWithBold = rules.startsWith('*');
          if (startsWithBold) {
            rules = '^' + rules;
          }

          const boldWords = rules.split('*');
          if (boldWords.length) {
            boldWords.forEach((p, index) => {
              if (index % 2 !== 0) {
                boldWords[index] = '<b>' + p + '</b>';
              }
            });

            rules = boldWords.join('');
          }

          let paragraphs = rules.split('\n');
          if (paragraphs[0]) {
            rules = paragraphs.map((p) => `<p>${p}</p>`).join('');
          }

          if (startsWithBold) {
            rules = rules.replace('^', '');
          }

          return rules;
        },
        valueToField(value) {
          return value?.replace(/<p>|<\/p>/g, '').replace(/<b>|<\/b>/, '*');
        },
        section: 'other',
      },

      {
        title: 'Регламент (RU)',
        value: '',
        type: 'text',
        key: 'rules_link_ru',
        section: 'other',
      },

      {
        title: 'Регламент (EN)',
        value: '',
        type: 'text',
        key: 'rules_link_en',
        section: 'other',
      },

      {
        title: 'YouTube трансляция',
        value: '',
        type: 'text',
        key: 'stream_links.youtube',
        placeholder: 'Ссылка будет видна игрокам на странице турнира',
        section: 'other',
      },

      {
        title: 'Twitch трансляция',
        value: '',
        type: 'text',
        key: 'stream_links.twitch',
        placeholder: 'Ссылка будет видна игрокам на странице турнира',
        section: 'other',
      },

      {
        title: 'Ссылка на TikTok',
        value: '',
        type: 'text',
        key: 'stream_links.tiktok',
        placeholder: 'Ссылка будет видна игрокам на странице турнира',
        section: 'other',
      },

      {
        title: 'Ссылка на Telegram',
        value: '',
        type: 'text',
        key: 'stream_links.telegram',
        placeholder: 'Ссылка будет видна игрокам на странице турнира',
        section: 'other',
      },

      {
        title: 'Короткая ссылка для турнира',
        value: '',
        type: 'text',
        key: 'alias',
        placeholder: 'По этой ссылке можно будет дополнительно открыть турнир',
        section: 'other',
      },

      // game_process

      {
        title: 'Режим',
        value: 'Team deathmatch',
        type: 'select',
        options: [
          { title: 'Team deathmatch', value: 'Team deathmatch' },
          { title: 'Defuse', value: 'Defuse' },
          { title: 'Escalation', value: 'Escalation' },
          { title: 'Arcade', value: 'Arcade' },
          { title: 'Competitive', value: 'Competitive' },
          { title: 'Allies', value: 'Allies' },
          { title: 'Arms race', value: 'Arms race' }
        ],
        withEmptyValue: false,
        key: 'extra_data.config.mode',
        section: 'game_process',
      },

      {
        title: 'Map',
        value: 'Arena',
        type: 'select',
        options: [
          { title: 'Arena', value: 'Arena' },
          { title: 'Sand Yards', value: 'Sand Yards' },
          { title: 'Training Outside', value: 'Training Outside' },
          { title: 'Village', value: 'Village' },
        ],
        withEmptyValue: false,
        show: {
          key: 'extra_data.config.mode',
          value: 'Team deathmatch',
        },
        key: 'extra_data.config.map',
        section: 'game_process',
      },

      {
        title: 'Map',
        value: 'Arena',
        type: 'select',
        options: [
          { title: 'Arena', value: 'Arena' },
          { title: 'Sand Yards', value: 'Sand Yards' },
          { title: 'Training Outside', value: 'Training Outside' },
          { title: 'Village', value: 'Village' },
        ],
        withEmptyValue: false,
        show: {
          key: 'extra_data.config.mode',
          value: 'Arms race',
        },
        key: 'extra_data.config.map',
        section: 'game_process',
      },

      {
        title: 'Map',
        value: 'Province',
        type: 'select',
        options: [
          { title: 'Province', value: 'Province' },
          { title: 'Rust', value: 'Rust' },
          { title: 'Sakura', value: 'Sakura' },
          { title: 'Sandstone', value: 'Sandstone' },
          { title: 'Zone 9', value: 'Zone 9' },
        ],
        withEmptyValue: false,
        show: {
          key: 'extra_data.config.mode',
          value: 'Defuse',
        },
        key: 'extra_data.config.map',
        section: 'game_process',
      },

      {
        title: 'Map',
        value: 'Province',
        type: 'select',
        options: [
          { title: 'Province', value: 'Province' },
          { title: 'Rust', value: 'Rust' },
          { title: 'Sakura', value: 'Sakura' },
          { title: 'Sandstone', value: 'Sandstone' },
          { title: 'Zone 9', value: 'Zone 9' },
        ],
        withEmptyValue: false,
        show: {
          key: 'extra_data.config.mode',
          value: 'Escalation',
        },
        key: 'extra_data.config.map',
        section: 'game_process',
      },

      {
        title: 'Map',
        value: 'Province',
        type: 'select',
        options: [
          { title: 'Province', value: 'Province' },
          { title: 'Rust', value: 'Rust' },
          { title: 'Sakura', value: 'Sakura' },
          { title: 'Sandstone', value: 'Sandstone' },
          { title: 'Zone 9', value: 'Zone 9' },
        ],
        withEmptyValue: false,
        show: {
          key: 'extra_data.config.mode',
          value: 'Arcade',
        },
        key: 'extra_data.config.map',
        section: 'game_process',
      },

      {
        title: 'Map',
        value: 'Province',
        type: 'select',
        options: [
          { title: 'Province', value: 'Province' },
          { title: 'Rust', value: 'Rust' },
          { title: 'Sakura', value: 'Sakura' },
          { title: 'Sandstone', value: 'Sandstone' },
          { title: 'Zone 9', value: 'Zone 9' },
        ],
        withEmptyValue: false,
        show: {
          key: 'extra_data.config.mode',
          value: 'Competitive',
        },
        key: 'extra_data.config.map',
        section: 'game_process',
      },

      {
        title: 'Map',
        value: 'Province',
        type: 'select',
        options: [
          { title: 'Province', value: 'Province' },
          { title: 'Rust', value: 'Rust' },
          { title: 'Sakura', value: 'Sakura' },
          { title: 'Sandstone', value: 'Sandstone' },
          { title: 'Zone 9', value: 'Zone 9' },
        ],
        withEmptyValue: false,
        show: {
          key: 'extra_data.config.mode',
          value: 'Allies',
        },
        key: 'extra_data.config.map',
        section: 'game_process',
      },
    ];
  },
};