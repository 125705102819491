<template lang="pug">
.game-currencies
  SectionHeader(:header="header")

  .game-currencies__list
    .game-currencies__element(v-for="order in orders" :key="order.id")
      p
        | Начислить игроку&nbsp;
        b.game-currencies__element-game {{ order.content.items[0].brand }}
        | &nbsp;с ID {{ order.order_data.userId }}&nbsp;
        template(v-if="order.order_data.zoneId && order.order_data.zoneId !== ''")
          | и Zone ID {{ order.order_data.zoneId }}&nbsp;
        span.game-currencies__element-product {{ order.content.items[0].title }}
      Button(is-primary @click="complete(order.id)") Начислено
</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader';

export default {
  name: "GameCurrencies",

  components: {
    SectionHeader
  },

  data () {
    return {
      orders: []
    }
  },

  computed: {
    header() {
      return {
        title: 'Покупки игровой валюты',
      };
    },
  },

  mounted() {
    this.loadOrders()
  },

  methods: {
    async loadOrders() {
      this.$loading.start()

      try {
        const { data } = await this.$axios.get('/admin/v-orders')

        this.orders = data.data.orders
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    async complete(orderId) {
      try {
        await this.$axios.get(`/admin/v-orders/${orderId}`)
      } catch (e) {
        this.$error.handle(e)
      }
      this.loadOrders()
    }
  }
}
</script>

<style scoped lang="scss">
.game-currencies {
  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    padding: 8px 24px;
  }

  &__element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background: #141e2b;
    border-radius: 8px;
    color: white;

    &-game {
      color: #48b050;
    }

    &-product {
      color: #bf0012;
      font-weight: 900;
      font-size: 20px;
    }
  }
}
</style>