<template lang="pug">
  .edit-tournament
    SectionHeader(:header="header")

    .action__body
      .action__section
        .action__grid(v-if="!isEditing")
          .action__form-field(v-for="(item, i) of items" :key="i")
            InputSmart(:item="item")

        .action__grid(v-else)
          .action__form-field(v-for="(field, i) of fields" :key="i")
            InputSmart(:item="field")

      .action__bottom(v-if="!isEditing")
        Button(is-primary :is-disabled="isButtonDisabled" @click="onEditClick") Редактировать

      .action__bottom.--row(v-else)
        Button(is-primary @click="onSendClick") Отправить данные
        Button(is-secondary @click="onBackClick") Назад

</template>

<script>
import { mapActions, mapState } from 'vuex'
import InputSmart from '@/components/InputSmart/InputSmart';
import SectionHeader from '@/components/SectionHeader/SectionHeader';
import moment from 'moment';
import dot from 'dot-object';
import _ from 'lodash';

export default {
  name: 'EditTournament',

  components: {
    InputSmart,
    SectionHeader,
  },

  data() {
    return {
      isEditing: false,
      tournamentData: {},
      game: null,

      items: {
        tournamentId: {
          title: 'ID турнир',
          value: null,
          type: 'number',
          placeholder: 'Введите ID',
        },
      },

      fields: {
        title: {
          title: 'Название турнира',
          value: '',
          type: 'text',
          key: 'title',
        },

        date: {
          title: 'Дата начала',
          value: '',
          type: 'date',
          key: 'date',
          handler(date) {
            return moment(date)
              .subtract(3, 'hours')
              .format('YYYY-MM-DD HH:mm');
          },
        },

        minPlayersCount: {
          title: 'Минимальное кол-во участников',
          value: 5,
          type: 'number',
          key: 'tournament_player_settings.min_players_count',
        },

        streamLink: {
          title: 'YouTube трансляция',
          value: '',
          type: 'text',
          key: 'stream_links.youtube',
          placeholder: 'Ссылка будет видна игрокам на странице турнира',
        },

        rules: {
          title: 'Правила (RU)',
          value: '',
          type: 'textarea',
          key: 'rules_ru',
          description: `Для выделения жирным определенного участка текста, возьмите его в звёздочки. Пример: *этот текст будет выделен*`,
          handler(rules) {
            const startsWithBold = rules.startsWith('*');
            if (startsWithBold) {
              rules = '^' + rules;
            }

            const boldWords = rules.split('*');
            if (boldWords.length) {
              boldWords.forEach((p, index) => {
                if (index % 2 !== 0) {
                  boldWords[index] = '<b>' + p + '</b>';
                }
              });

              rules = boldWords.join('');
            }

            let paragraphs = rules.split('\n');
            if (paragraphs[0]) {
              rules = paragraphs.map((p) => `<p>${p}</p>`).join('');
            }

            if (startsWithBold) {
              rules = rules.replace('^', '');
            }

            return rules;
          },
        },

        isRated: {
          title: 'Рейтинг считается',
          value: true,
          type: 'checkbox',
          key: 'is_rated',
          section: 'general',
        },
      },
    };
  },

  computed: {
    ...mapState('games', ['gamesById']),

    header() {
      return {
        title: 'Редактировать турнир',
      };
    },

    buttonText() {
      return this.isEditing ? 'Отправить данные' : 'Редактировать';
    },

    isButtonDisabled() {
      return !this.items?.tournamentId?.value;
    },
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onSendClick() {
      this.$loading.start()

      try {
        const newTournamentData = {};

        Object.values(this.fields).forEach((field) => {
          if (!field.value && !Number.isInteger(field.value)) {
            return;
          }

          if (field.key === 'rules_ru') {
            newTournamentData.rules_array = []

            return newTournamentData.rules_array.push({
              language: 'ru',
              text: field.handler(field.value),
            })
          }

          if (field.key === 'rules_ru') {
            newTournamentData.rules_array = []

            return newTournamentData.rules_array.push({
              language: 'ru',
              text: field.handler(field.value),
            })
          }

          if (field.handler) {
            return (newTournamentData[field.key] = field.handler(field.value));
          }

          newTournamentData[field.key] = field.value;
        });

        dot.object(newTournamentData);

        newTournamentData.tournament_player_settings = _.merge(
          this.tournamentData.tournament_player_settings,
          newTournamentData.tournament_player_settings
        );

        console.log('Новые данные турнира:', newTournamentData)

        console.log('Новые данные турнира:', newTournamentData)

        await this.$axios.put(
          `/games/${this.game?.name}/tournaments/${this.items.tournamentId.value}`,
          newTournamentData
        )

        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            title: 'Успех',
            text: 'Данные отправлены!',
          },
        });
      } catch (e) {
        this.$error.handle(e);
      }

      this.$loading.finish()
    },

    async onEditClick() {
      this.$loading.start()

      try {
        const { data } = await this.$axios.get(
          `/games/lichess/tournaments/${this.items.tournamentId.value}`
        )

        this.tournamentData = data.data;
        this.isEditing = true;
        this.game = this.gamesById[this.tournamentData.game_id]

        this.fields.title.value = this.tournamentData.title;
        this.fields.date.value = this.tournamentData.date;
        this.fields.minPlayersCount.value = this.tournamentData.tournament_player_settings?.min_players_count;
        this.fields.streamLink.value = this.tournamentData.stream_links?.youtube;
        this.fields.rules.value = this.tournamentData.rules;
        this.fields.isRated.value = this.tournamentData.is_rated;
      } catch (e) {
        this.$error.handle(e);
      }

      this.$loading.finish()
    },

    onBackClick() {
      this.tournamentData = {};
      this.isEditing = false;
      this.items.tournamentId.value = null;
    },
  },
};
</script>

<style lang="scss">
.--row {
  display: flex;

  .button {
    margin-right: 8px;
  }
}
</style>
