<template lang="pug">
  .create-pm-tournament
    Steps(:steps="stepTitles" :current-step-index="currentStepIndex")

    .create-pm-tournament__step.create-pm-tournament__create(v-if="tournament && currentStepIndex === 0")
      .create-pm-tournament__create-inputs
        CopyInput.create-pm-tournament__create-input(value="VVV Tournament" title="Название турнира")
        CopyInput.create-pm-tournament__create-input(:value="tournament.extra_data.config.password" title="Пароль")
        CopyInput.create-pm-tournament__create-input(:value="tournament.extra_data.config.map" title="Карта")
        CopyInput.create-pm-tournament__create-input(:value="tournament.extra_data.config.mode" title="Режим")

      .create-pm-tournament__create-data
        InputSmart(:item="lobbyIdInput" v-model="lobbyIdInput.value" :type="lobbyIdInput.type")
        Button(is-primary @click="onNextClick" :is-disabled="!isNextAvailable") Отправить данные

    .create-pm-tournament__step(v-if="currentStepIndex === 1")
      .tournaments-create__caption Запустите бой в игре в нужное время ({{ date }}) и после нажмите на кнопку ниже
      .tournaments-create__caption Минимальное необходимое для старта количество игроков: {{ minPlayersCount }}

      .create-pm-tournament__buttons
        Button(is-primary @click="onNextClick" :is-disabled="!isNextAvailable") Турнир начался
        Button(is-secondary @click="isEdit = true") Редактировать данные

      .moderation-tournament__section
        .moderation-tournament__section-header
          span Игроки

        .moderation-tournament__section-body
          .moderation-tournament__players
            WinnerRow(
              v-for="player in tournamentMembers"
              :key="player.id"
              :winner="player"
            )

    .create-pm-tournament__step(v-if="currentStepIndex === 2")
      .tournaments-create__caption Как только матч в игре завершится, сохраните скриншоты всего ТОПа игроков и нажмите на эту кнопку
      Button(is-primary @click="onNextClick" :is-disabled="!isNextAvailable") Турнир завершился

    .create-pm-tournament__step(v-if="currentStepIndex === 3")
      TournamentModeration(:tournament="tournament" @update="updateTournament")

    .create-pm-tournament__step(v-if="currentStepIndex === 4")
      .tournaments-create__caption Турнир завершён!

</template>

<script>
import Steps from '@/components/Steps/Steps'
import CopyInput from '@/components/CopyInput/CopyInput'
import InputSmart from '@/components/InputSmart/InputSmart'
import WinnerRow from '@/components/WinnerRow/WinnerRow'
import Tabs from '@/components/Tabs/Tabs'
import TournamentModeration from '@/components/TournamentModeration/TournamentModeration'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CreatePubgMobileTournament',

  components: {
    Steps,
    CopyInput,
    InputSmart,
    WinnerRow,
    Tabs,
    TournamentModeration
  },

  props: {
    tournamentData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tournament: {},
      lobbyIdInput: {
        title: 'ID созданного лобби',
        type: 'text',
        value: null,
      },
      isEdit: false,
    }
  },

  computed: {
    ...mapGetters('moderation', ['winners']),

    game() {
      return this.$route.params.section
    },

    id() {
      return this.$route.params.id
    },

    date() {
      return this.$moment.format(this.tournament.date, 'lll')
    },

    isTeams() {
      return this.tournament.tournament_player_settings?.entry_type !== 'player'
    },

    stepTitles() {
      return ['Создание лобби', 'Старт', 'Завершение', 'Модерация']
    },

    currentStepIndex() {
      if (
          (this.tournament?.state === 'open' && !this.tournament?.extra_data?.state?.game?.extra_data?.id)
          || this.isEdit
      ) {
        return 0
      } else if (this.tournament?.state === 'open') {
        return 1
      } else if (this.tournament?.state === 'in_progress') {
        return 2
      } else if (this.tournament?.state === 'moderation') {
        return 3
      } else {
        return 4
      }
    },

    isNextAvailable() {
      if (this.currentStepIndex === 0 && !this.lobbyIdInput.value) {
        return false
      }

      return true
    },

    tournamentMembers() {
      if (this.isTeams) {
        return this.tournament?.teams?.map(team => {
          const membersNames = team.members?.map(member => member.game_account?.username)

          return {
            name: `Команда: ${membersNames.join(', ')}`,
            points: null,
            place: null,
          }
        })
      }

      const players = this.tournament?.players

      return players?.map(player => ({ name: player.game_account?.username }))
    },

    minPlayersCount() {
      return this.tournament.tournament_player_settings?.min_players_count
    },
  },

  watch: {
    tournamentData() {
      this.tournament = this.tournamentData
    },
  },

  mounted() {
    if (this.game === 'pubg-new-state') {
      this.lobbyIdInput.value = 'vvvgamers'
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapMutations('moderation', ['setWinners', 'deleteWinner']),

    async updateTournament() {
      this.$loading.start()

      try {
        const { data } = await this.$axios.get(`/games/${this.game}/tournaments/${this.id}`)

        this.tournament = data.data
        this.$forceUpdate()
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    async onNextClick() {
      this.$loading.start()

      const url = `/games/${this.game}/tournaments/${this.id}`

      try {
        switch (this.currentStepIndex) {
          case 0:
            await this.$axios.post(url + '/lobby', { id: this.lobbyIdInput.value })
            this.isEdit = false; break

          case 1:
            await this.$axios.put(url, { state: 'in_progress' }); break

          case 2:
            await this.$axios.put(url, { state: 'moderation' }); break
        }

        setTimeout(async () => await this.updateTournament(), 1000)
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/styles/include/participiants.scss';

.winner-row {
  margin-bottom: 12px;
}

.create-pm-tournament {
  &__buttons {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .button:first-child {
      margin-bottom: 8px;
      width: 100%;
    }
  }

  &__step {
    margin: 18px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__create {
    &-inputs {
      padding-bottom: 16px;
    }

    &-input:not(:last-child) {
      margin-bottom: 16px;
    }

    &-data {
      margin: 16px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .button {
        margin-top: 12px;
      }
    }
  }

  .moderation-tournament {
    &__section {
      width: 100%;
      margin-bottom: 16px;

      &-header {
        display: flex;
        justify-content: space-around;
        padding: 16px 0;
        border-top: 1px solid $blue-grey50;
        border-bottom: 1px solid $blue-grey50;

        & span {
          @include caption-small;

          font-size: 14px;
          color: $blue-grey10;
        }
      }

      &-body {
        margin-top: 16px;
        //padding-right: 32px;
      }
    }

    &__players:not(:last-child) {
      padding-bottom: 16px;
      border-bottom: 2px solid $green60;
      margin-bottom: 16px;
    }

    &__done {
      @include heading4;

      color: $white;
    }
  }

  .player-row:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
