<template lang="pug">
  .modal-message
    .modal__header {{ title }}

    InfoMessage(:type="type" :with-icon="type !== 'plain'")
      div(v-html="text")

    .modal__footer
      Button(
        v-for="(button, index) in modalButtons"
        :key="index"
        v-bind="button.props"
        @click="onAction(button)"
      )
        | {{ button.title }}

</template>

<script>
import InfoMessage from '@/components/InfoMessage/InfoMessage'

export default {
  name: '',

  components: {
    InfoMessage
  },

  props: {
    type: {
      type: String,
      default: 'error',
    },

    buttons: {
      type: Array,
      default: () => [],
    },

    text: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: 'Ошибка',
    },
  },

  data() {
    return {}
  },

  computed: {
    modalButtons() {
      if (this.buttons.length) {
        return this.buttons.map((button) => {
          if (!button.props) {
            button.props = { isPrimary: true }
          }

          return button
        })
      }

      return [{ props: { isPrimary: true }, title: 'Ясно' }]
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/toggle')
    },

    onAction({ action, shouldClose = true }) {
      action?.()

      if (shouldClose) {
        this.closeModal()
      }
    },
  },
}
</script>

<style lang="scss">
.modal.modal--modalmessage {
  padding-bottom: 0;
  max-width: 500px;

  .modal__footer {
    display: flex;
    justify-content: flex-end;
  }

  .info-message {
    margin: 24px 0;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .button + .button {
    margin-left: 16px;
  }
}
</style>
