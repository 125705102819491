import axios from '@/plugins/axios';
class UsersService {
  constructor() {
    if (UsersService.instance) {
      return UsersService.instance;
    }
    
    UsersService.instance = this;

    return this;
  }

  getInfluencerRequests() {
    return axios.get('/influencers/pending');
  }

  acceptInfluencerRequest(id) {
    return axios.post(`/influencers/${id}/process`, {
      verdict: 'verified',
    })
  }

  declineInfluencerRequest(id) {
    return axios.post(`/influencers/${id}/process`, {
      verdict: 'declined',
    })
  }

  getUserById(id) {
    return axios.get(`/user/${id}`);
  }
}

export default new UsersService();
