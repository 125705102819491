<template lang="pug">
  .action-multi-account
    SectionHeader(:header="header")

    .action__body
      .action__section
        .action__grid(:key="gameName")
          .action__form-field(v-for="(item, i) of items" :key="")
            InputSmart(:item="item")

      .action__bottom
        Button(is-primary @click="onCheckButtonClick") Проверить

</template>

<script>
import { mapActions } from 'vuex'
import InputSmart from '@/components/InputSmart/InputSmart'
import SectionHeader from '@/components/SectionHeader/SectionHeader'

export default {
  name: 'ActionMultiAccount',

  components: {
    InputSmart,
    SectionHeader,
  },

  data: () => ({
    items: [
      {
        title: 'Имя пользователя',
        value: '',
        placeholder: 'Введите имя пользователя Lichess',
        type: 'text',
      },
    ],
  }),

  computed: {
    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Проверка на мульти-аккаунт`,
        game: this.gameName,
      }
    },
  },

  created() {
    this.$forceUpdate()
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onCheckButtonClick() {
      this.$loading.start()

      try {
        const { data } = await this.$axios.post(`/admin/games/${this.gameName}/check-duplicates`, {
          username: this.items[0].value,
        })

        if (data.data) {
          this.showModal({
            component: 'ObjectView',
            data: {
              title: 'Найденные ники',
              text: data.data[0] ? 'Вот, что удалось найти' : 'Дубликатов найдено не было',
              object: data.data,
            },
          })
        }
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    }
  },
}
</script>

<style lang="scss">
</style>