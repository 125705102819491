<template lang="pug">
  .header-menu
    .header-menu__section(v-for="(section, index) in items" :key="index")

      .header-menu__item(v-for="(item, i) in section" :key="i" v-if="!item.isHidden")
        div.header-menu__item-wrapper
          a.header-menu__link(@click.prevent="onAction(item)")
            .header-menu__ico-box
              .header-menu__ico-wrap
                img.header-menu__ico(:src="require(`${item.ico}`)")
            span.header-menu__text {{ item.text }}
</template>

<script>
import { mapState } from 'vuex'
import sections from './data/sections.json'

export default {
  name: 'HeaderMenu',

  data() {
    return {
      sections,
    }
  },

  computed: {
    ...mapState('user', ['user', 'token']),

    items() {
      const items = [...this.sections]

      return items
    },
  },

  mounted() {
    document.addEventListener('click', this.onClickOutside)
  },

  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside)
  },

  methods: {
    onAction({ action, route }) {
      if (action === 'logout') {
        this.$store.dispatch('user/logout')
        window.location.href = 'https://vvvgamers.com'
      }

      if (route) {
        this.$router.push(route)
      }
    },

    onClickOutside() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.header-menu {
  box-sizing: border-box;
  position: absolute;
  top: 80px;
  right: 4px;
  margin-top: 10px;
  border: 1px solid $blue-grey40;
  border-radius: 8px;
  background: $blue-grey70;
  padding: 8px;
  width: 280px;
  color: $white;

  &__section:not(:last-child) {
    padding-bottom: 8px;
    border-bottom: 1px solid $blue-grey40;
    margin-bottom: 8px;
  }

  &__item {
    height: 48px;
    box-sizing: border-box;
    border-radius: 8px;
    transition: background-color 0.3s $easing;

    &:hover {
      background: $blue-grey60;
    }

    &-wrapper {
      padding: 0 8px;
    }
  }

  &__link {
    @include text-display100;

    display: flex;
    align-items: center;
    height: 48px;
    cursor: pointer;
  }

  &__ico-box {
    height: 100%;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__ico-wrap {
    width: 20px;
    height: 20px;
  }

  &__text {
    margin-left: 16px;
  }

  @media (max-width: $breakpointHeaderMobile) {
    top: 44px;
  }
}
</style>
