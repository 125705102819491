<template lang="pug">
  .pubg-account
    .modal__header Аккаунт {{ game.extra_data.title }}
    form(@submit.prevent="onSubmit")
      .pubg-account__body
        template(v-for="(input, index) in fields")
          FormInput(
            :key="index"
            v-bind="input"
            v-model="input.value"
            @input="onFieldInput(index, input)"
            @blur="onFieldBlur(index, input)"
          )

      .modal__footer
        transition(name="fade" duration="200")
          InfoMessage(v-if="errorMessage" type="error")
            | {{ errorMessage }}
        Button(is-primary :is-disabled="isButtonDisabled" type="submit") Привязать

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import FormInput from '@/components/InputForm/InputForm'
import ImageUpload from '@/components/ImageUpload/ImageUpload'

export default {
  name: 'PubgMobileAccount',

  components: { ImageUpload, FormInput },

  props: {
    playerId: {
      type: Number,
      required: true,
    },

    gameName: {
      type: String,
      default: 'pubg-mobile'
    },
  },

  data() {
    return {
      errorMessage: '',

      fields: {
        id: {
          type: 'text',
          value: '',
          title: 'ID аккаунта',
          placeholder: 'ID аккаунта',
          isRequired: true,
          error: '',
          mask: '##############',
        },

        username: {
          type: 'text',
          value: '',
          title: 'Никнейм в игре',
          placeholder: 'Никнейм в игре',
          isRequired: true,
          error: '',
        },
      },
    }
  },

  computed: {
    ...mapState('games', ['gamesByName']),

    isButtonDisabled() {
      if (this.errorMessage) {
        return true
      }

      if (
        Object.values(this.fields).some((field) => {
          return field.error
        })
      ) {
        return true
      }

      return Object.values(this.fields).some(
        (field) => field.isRequired && !field.value
      )
    },

    game() {
      return this.gamesByName[this.gameName]
    },
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapMutations('modal', ['toggle']),

    async onSubmit() {
      this.errorMessage = ''

      Object.keys(this.fields).forEach((key) => this.onFieldBlur(key))

      if (this.isButtonDisabled) {
        return
      }

      this.$loading.start()

      try {
        await this.$axios.post(`/games/${this.gameName}/account/admin/link`, {
          id: this.fields.id.value,
          username: this.fields.username.value,
          userId: this.playerId,
        })

        this.toggle(false)
      } catch (e) {
        this.errorMessage = e
        console.error('pubg account error', e)
      }

      this.$loading.finish()
    },

    onFieldInput(name) {
      this.errorMessage = ''
      this.$set(this.fields[name], 'error', '')
    },

    onFieldBlur(name) {
      const field = this.fields[name]

      if (!field.value && field.isRequired) {
        field.error = 'Обязательное поле'
      }

      if (name === 'id' && field.value.length < 8) {
        field.error = 'Введите не меньше 8 цифр'
      }

      if (name === 'username' && /[\u180C\u3164]/.test(field.value)) {
        field.error = 'Никнейм не должен содержать невидимые символы'
      }

      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss">
.modal.modal--pubgmobileaccount {
  padding-bottom: 0;
  max-width: 614px;
}
.pubg-account {
  &__body {
    padding: 20px 24px;
    margin: 0 -24px;
    overflow-y: auto;
    max-height: calc(100vh - 180px);

    @media (max-width: $breakpointMobile) {
      padding: 20px 16px;
      margin: 0 -16px;
      max-height: calc(100vh - 145px);
    }
  }

  .input-form,
  .image-upload {
    padding-bottom: 26px;
  }

  &__warning {
    margin-bottom: 16px;
  }

  &__warning + &__warning {
    margin-bottom: 24px;
  }

  .button {
    height: fit-content;
  }

  .modal__footer {
    flex-wrap: wrap;
  }

  .info-message.--error {
    width: 100%;
    margin-bottom: 16px;
  }
}
</style>
