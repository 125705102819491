<template lang="pug">
  .modal(:class="[`modal--${component.toLowerCase()}`, {'modal--large': hasTooLargeHeight}]")
    a.modal__close(v-if="canClose" @click.prevent="toggle(false)")
      InlineSvg(:src="require('@/assets/img/icons/close.svg')")
    component(:is="component" v-bind="componentData")

</template>

<script>
import { mapMutations, mapState } from 'vuex'
import ModalMessage from '@/components/ModalMessage/ModalMessage'
import ObjectView from '@/components/ObjectView/ObjectView'
import WinnerEdit from '@/components/WinnerEdit/WinnerEdit'
import ReportsModal from '@/components/ReportsModal/ReportsModal'
import ClashRoyaleAccount from '@/components/ClashRoyaleAccount/ClashRoyaleAccount'
import BrawlStarsAccount from '@/components/BrawlStarsAccount/BrawlStarsAccount'
import PubgDesktopAccount from '@/components/PubgDesktopAccount/PubgDesktopAccount'
import PubgMobileAccount from '@/components/PubgMobileAccount/PubgMobileAccount'
import IconModal from '@/components/IconModal/IconModal'

export default {
  name: 'Modal',

  components: {
    ReportsModal,
    ModalMessage,
    ObjectView,
    WinnerEdit,
    ClashRoyaleAccount,
    BrawlStarsAccount,
    PubgDesktopAccount,
    PubgMobileAccount,
    IconModal,
  },

  data() {
    return {
      hasTooLargeHeight: false,
    }
  },

  computed: {
    ...mapState('modal', ['component', 'componentData', 'canClose']),
  },

  watch: {
    component: {
      handler(value) {
        if (!value) {
          return
        }

        this.checkHeight()
      },
      immediate: true,
    },
  },

  mounted() {
    window.addEventListener('resize', this.checkHeight)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkHeight)
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    checkHeight() {
      this.$nextTick(() => {
        const MODAL_MARGIN = 10
        this.hasTooLargeHeight = this.$el.offsetHeight + MODAL_MARGIN > window.innerHeight
      })
    },
  },
}
</script>

<style lang="scss">
.modal {
  width: 100%;
  max-width: 800px;
  padding: 24px;
  background: $blue-grey70;
  border: 1px solid $blue-grey60;
  height: fit-content;
  border-radius: 16px;
  position: relative;
  top: 50vh;
  left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: -150% -150%;
  z-index: 101;

  &--large {
    top: 24px;
    transform: translateX(-50%);
    transform-origin: -150% 0;
    margin-bottom: 24px;
  }

  &__footer {
    padding: 11px 24px;
    margin: 0 -24px;
    border-top: 1px solid $blue-grey30;
    display: flex;
    justify-content: flex-end;

    .button:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: $blue-grey60;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    svg {
      transition: transform 0.2s $easing;
    }

    &:hover {
      background: $blue-grey40;
      svg {
        transform: rotate(90deg);
      }
    }

    &:active {
      background: $blue-grey20;
    }
  }

  &__header {
    @include heading3;

    padding: 0 24px 24px;
    margin: 0 -24px;
    color: $white;
    position: relative;
    min-height: 56px;

    &::before {
      content: '';
      position: absolute;
      display: block;
      left: -1px;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: $blue-grey40;
    }
  }

  @media screen and (max-width: $breakpointMobile) {
    width: 100% !important;
    padding: 24px 16px;

    &__close {
      right: 16px;
      top: 16px;
    }

    &__footer {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .button {
        width: 100%;

        &:not(:last-child) {
          margin: 0 0 8px 0;
        }
      }
    }
  }
}
</style>
