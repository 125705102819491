<template lang="pug">
  .reports-modal

    .reports-modal__header
      .reports-modal__header-row
        strong Логин на сайте
        span
          a(v-if="player.full_name" :href="getPlayerProfileLink(player)" target="_blank") {{ player.full_name }}

      .reports-modal__header-row
        strong Никнейм в игре
        span
          PlayerGameName(v-if="tournament.game_id === 1" :account="player.game_account" :game="gamesByName.lichess")
          template(v-else) {{ player.game_account.username }}

      .reports-modal__header-row
        strong Очки репутации
        span {{ player.ban_points }}

    .reports-modal__body
      .reports-modal__report(v-for="report in player.reports" :key="report.id")
        Button(v-if="report && report.extra_data && report.extra_data.link" is-secondary icon="arrow-up-right" @click="onReportClick(report)") Подозрительная партия
        Button(v-if="report && report.extra_data && report.extra_data.fileDownloadLink" is-secondary icon="arrow-up-right" @click="onReportClick(report)") Видео-доказательство
        strong {{ getReportTypeText(report.type) }}
        span {{ report.reason }}



    .modal__footer
      Button.reports-modal__action-ban(is-secondary @click="onAction('ban')") Забанить
      Button.reports-modal__action-warn(is-secondary @click="onAction('warn')") Предупредить
      Button.reports-modal__action-justify(is-secondary @click="onAction('justify')") Оправдать


</template>

<script>
import PlayerGameName from '@/components/PlayerGameName/PlayerGameName'
import { mapState } from 'vuex'
export default {
  name: 'LichessReports',
  components: { PlayerGameName },

  props: {
    callback: {
      type: Function,
      default: () => {}
    },

    player: {
      type: Object,
      required: true
    },

    tournament: {
      type: Object,
      required: true
    }
  },

  data: () => ({}),

  computed: {
    ...mapState('games', ['gamesByName'])
  },

  methods: {
    async onAction(action) {
      this.$loading.start()

      try {
        await this.$axios.post(
          `/admin/games/tournaments/${this.tournament.id}/reports`,
          {
            account_id: this.player.id,
            verdict: action
          }
        )

        this.callback()
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    onReportClick(report) {
      if (report.extra_data.fileDownloadLink) {
        window.open(report.extra_data.fileDownloadLink, '_blank')
      } else {
        window.open(report.extra_data.link, '_blank')
      }

    },

    getReportTypeText(type) {
      return (
        {
          unfair_play: 'Нечестная игра',
          unfair_behavior: 'Нечестное поведение',
          other: 'Другое'
        }[type] || type
      )
    },

    getPlayerProfileLink(player) {
      return `${process.env.VUE_APP_DOMAIN}/user/id/${player.id}`
    },
  }
}
</script>

<style lang="scss">
.modal.modal--lichessreports {
  padding-bottom: 0;
  max-width: 573px;

  .modal__footer {
    display: flex;
    justify-content: flex-end;
  }

  .button + .button {
    margin-left: 16px;
  }
}

.reports-modal {
  &__action {
    &-ban.button {
      background: $red70;
    }
    &-warn.button {
      background: $orange70;
    }
    &-justify.button {
      background: $green70;
    }
  }

  &__report {
    background: $blue-grey60;
    border-radius: 16px;
    padding: 20px;
    margin-bottom: 16px;

    .button {
      background: $blue-grey40;
      margin-bottom: 16px;

      &:hover {
        background: $blue-grey30 !important;
      }
    }

    strong {
      @include caption-small;

      color: $blue-grey10;
      display: block;
      margin-bottom: 8px;
    }

    span {
      color: $white;
    }
  }

  &__body {
    @include scroll;

    max-height: calc(100vh - 250px);
    padding-top: 24px;
    border-top: 1px solid $blue-grey30;
  }

  &__header {
    color: $white;

    &-row {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      height: 20px;
      line-height: 20px;

      strong {
        @include caption-small;

        color: $blue-grey10;
        width: 156px;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}
</style>
