import dot from 'dot-object'
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('modal', ['showModal']),

    async createTournament(tournament) {
      console.log('Будет создан турнир со следующими параметрами', tournament)

      this.$loading.start()

      try {
        const { data } = await this.$axios.post(`/admin/games/${this.gameName}/tournaments`, tournament)

        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            title: 'Успех',
            text: 'Турнир создан! Подробную информацию можно посмотреть в консоли браузера',
            buttons: [
              {
                title: 'Объект турнира',
                props: { isSecondary: true },
                action: () => {
                  this.showModal({
                    component: 'ObjectView',
                    data: {
                      object: tournament,
                    },
                  })
                }
              },
              {
                title: 'Открыть турнир',
                props: { isPrimary: true },
                action: () => {
                  window.open(`${process.env.VUE_APP_DOMAIN}/${this.gameName}/tournaments/${data.data.id}`, '_blank')
                }
              },
            ]
          },
        })
      } catch (e) {
        this.showModal({
          component: 'ModalMessage',
          data: {
            text: e.response?.data?.message || 'Ошибка сохранения',
            buttons: [
              {
                title: 'Объект турнира',
                props: { isSecondary: true },
                shouldClose: false,
                action: () => {
                  this.showModal({
                    component: 'ObjectView',
                    data: {
                      object: tournament,
                    },
                  })
                }
              }
            ]
          },
        })
      }

      this.$loading.finish()
    },

    async createMegaTournament(tournament) {
      console.log('Будет создан турнир со следующими параметрами', tournament)

      this.$loading.start()

      try {
        const { data } = await this.$axios.post(`/admin/games/${this.gameName}/mega-tournaments`, tournament)

        console.log(data)

        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            title: 'Успех',
            text: 'Турнир создан! Подробную информацию можно посмотреть в консоли браузера',
            buttons: [
              {
                title: 'Объект турнира',
                props: { isSecondary: true },
                action: () => {
                  this.showModal({
                    component: 'ObjectView',
                    data: {
                      object: tournament,
                    },
                  })
                }
              },
            ]
          },
        })
      } catch (e) {
        this.showModal({
          component: 'ModalMessage',
          data: {
            text: e.response?.data?.message || 'Ошибка сохранения',
            buttons: [
              {
                title: 'Объект турнира',
                props: { isSecondary: true },
                shouldClose: false,
                action: () => {
                  this.showModal({
                    component: 'ObjectView',
                    data: { object: tournament },
                  })
                }
              }
            ]
          },
        })
      }

      this.$loading.finish()
    },

    refactorTournament(tournament) {
      const tournamentKeys = Object.keys(tournament)
      tournament.rules_array = []
      tournament.rules_link_array = []
      // console.log('tournament', tournament, tournamentKeys)

      tournamentKeys.forEach(key => {
        // prize_pool
        if (
          key.includes('prize_settings.prize_currency') &&
          tournament[key] === 'money'
        ) {
          if (key.startsWith('stages')) {
            const stageIndex = key.split('.')[1]
            const prizeType = tournament[`stages.${stageIndex}.prize_settings.prize_pool_type`]

            switch (prizeType) {
              case 'not_fixed_with_guarantee':
                tournament[`stages.${stageIndex}.prize_settings.guaranted_prize`] *= 100; break
              case 'fixed':
                tournament[`stages.${stageIndex}.prize_pool`] *= 100;
            }
          } else {
            const prizeType = tournament[`prize_settings.prize_pool_type`]

            switch (prizeType) {
              case 'not_fixed_with_guarantee':
                tournament[`prize_settings.guaranted_prize`] *= 100; break
              case 'fixed':
                tournament[`prize_pool`] *= 100;
            }
          }
        }

        // consolation prize
        if (
          key.includes('prize_settings.consolation_prize.currency') &&
          tournament[key] === 'money'
        ) {
          tournament['prize_settings.consolation_prize.amount'] *= 100
        }

        // entry fee
        if (
          key.includes('prize_settings.entry_fee_currency') &&
          tournament[key] === 'money'
        ) {
          if (key.startsWith('stages')) {
            const stageIndex = key.split('.')[1]

            tournament[`stages.${stageIndex}.entry_fee`] *= 100
            tournament[`stages.${stageIndex}.entry_fee`] = +tournament[`stages.${stageIndex}.entry_fee`].toFixed(2)
          } else {
            tournament[`entry_fee`] *= 100
            tournament[`entry_fee`] = +tournament[`entry_fee`].toFixed(2)
          }
        }

        // flat prizes
        if (
          key.includes('prize_settings.prize_distribution_type') &&
          tournament[key] === 'flat'
        ) {
          if (key.startsWith('stages')) {
            const stageIndex = key.split('.')[1]

            if (tournament[`stages.${stageIndex}.prize_settings.prize_currency`] === 'money') {
              tournament[`stages.${stageIndex}.prize_settings.flat_prizes`] =
                tournament[`stages.${stageIndex}.prize_settings.flat_prizes`].split(',').map(value => value * 100).join(',')
            }
          } else {
            if (tournament[`prize_settings.prize_currency`] === 'money') {
              tournament[`prize_settings.flat_prizes`] =
                tournament[`prize_settings.flat_prizes`].split(',').map(value => value * 100).join(',')
            }
          }
        }

        if (
          key.includes('rules_ru')
        ) {
          tournament.rules_array.push({
            language: 'ru',
            text: tournament.rules_ru
          })

          delete tournament.rules_ru
        }

        if (
          key.includes('rules_en')
        ) {
          tournament.rules_array.push({
            language: 'en-US',
            text: tournament.rules_en
          })

          delete tournament.rules_en
        }

        if (
          key.includes('rules_link_ru')
        ) {
          tournament.rules_link_array.push({
            language: 'ru',
            text: tournament.rules_link_ru
          })

          delete tournament.rules_link_ru
        }

        if (
          key.includes('rules_link_en')
        ) {
          tournament.rules_link_array.push({
            language: 'en-US',
            text: tournament.rules_link_en
          })

          delete tournament.rules_link_en
        }

        if (!tournament[key] && tournament[key] != '0') {
          delete tournament[key]
        }
      })

      this.gameFields.forEach(field => {
        if (field.notSend) {
          delete tournament[field.key]
        }

        if (tournament[field.key] && field.handler) {
          tournament[field.key] = field.handler(tournament[field.key])
        }
      })

      dot.object(tournament) // dot notation to nested object

      return tournament
    },

    getRequiredFields() {
      const fields = []

      this.gameFields.forEach(field => {
        if (field.required && !field.value && !field.isHidden && !Number.isInteger(field.value)) {
          // console.log(field.title, field)

          fields.push(field.title)
        }
      })

      return fields
    },
  }
}
