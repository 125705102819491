<template lang="pug">
  .player__account
    .player__nickname-wrapper
      .player__nickname {{ player.name }}
      .player__tag {{ player.tag }}

    .player__level
      img.player__level-image(src="https://cdn.vvvgamers.com/games/clash-royale/verification/level.svg")
      p.player__level-text {{ player.expLevel }}

    .player__trophies
      img.player__trophies-image(src="https://cdn.vvvgamers.com/games/clash-royale/verification/trophies.svg")
      p.player__trophies-text {{ player.trophies }}
</template>

<script>
export default {
  name: 'ClashRoyaleGameAccount',

  props: {
    player: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.player {
  &__account {
    background: $blue-grey60;
    border-radius: 12px;
    width: 100%;
    display: flex;
    padding: 24px;
  }

  &__nickname {
    @include heading4;

    color: $white;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;

    &-wrapper {
      margin-right: 24px;
    }

    @media (max-width: $breakpointMobile) {
      max-width: 123px;
    }
  }

  &__tag {
    @include caption;

    color: $blue-grey10;
  }

  &__level {
    position: relative;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;

    &-image {
      position: absolute;
      left: 1px;
    }

    &-text {
      @include heading4;

      color: $white;
      z-index: 1;
    }
  }

  &__trophies {
    display: flex;
    justify-content: center;
    align-items: center;

    &-text {
      @include heading4;

      color: $amber60;
      margin-left: 8px;
    }
  }
}
</style>
