<template lang="pug">
  .copy-input
    .copy-input__title(v-if="title") {{ title }}

    .copy-input__bottom
      .copy-input__box
        InlineSvg(v-if="icon" :src="require(`@/assets/img/icons/${icon}.svg`)" width="24")
        input.copy-input__input(
          type="text"
          readonly
          :value="isCopied ? 'Скопировано' :value"
          ref="input"
        )

      Button(is-square icon="copy" @click="onCopyClick" title="Скопировать")
</template>

<script>
export default {
  name: 'CopyInput',

  props: {
    value: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: 'link',
    },

    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isCopied: false,
    }
  },

  computed: {
    canCopy() {
      return document.queryCommandSupported('copy')
    },
  },

  methods: {
    onCopyClick() {
      if (!this.canCopy || this.isCopied) {
        return
      }

      this.$nextTick(() => {
        this.$refs.input.focus()
        this.$refs.input.select()

        try {
          document.execCommand('copy')

          this.isCopied = true
          setTimeout(() => {
            this.isCopied = false
          }, 5e3)
        } catch (e) {
          console.error('copy input error ', e)
        }
      })
    },
  },
}
</script>

<style lang="scss">
.copy-input {
  display: flex;
  flex-direction: column;

  &__input {
    padding: 0;
    margin: 0;
    bottom: 0;
    background: 0;
    border: 0;
    color: #fff;
    width: 100%;
  }

  &__title {
    @include caption-small;

    color: $grey20;
    margin-bottom: 16px;
  }

  &__bottom {
    display: flex;
  }

  &__box {
    display: flex;
    align-items: center;
    background: $blue-grey60;
    border-radius: 8px;
    padding: 0 16px;
    height: 48px;
    max-width: 302px;
    width: 100%;
    @include text-display100;

    color: $white;
    margin-right: 16px;
    @include text-overflow;

    svg {
      margin-right: 16px;

      path {
        fill: $grey20;
      }
    }
  }

  button.button--square {
    min-width: 48px;
    min-height: 48px;
  }
}
</style>
