<template lang="pug">
  .action-coins
    SectionHeader(:header="header")

    .action__body
      .action__section
        .action__grid
          .action__form-field
            InputSmart(:item="items.type")

          .action__form-field
            .input-smart__title
              | {{ items.startDate.title }}
            DatePicker(
              v-bind="items.startDate"
              v-model="items.startDate.value"
              @input="onDateInput"
            )

          .action__form-field
            .input-smart__title
              | {{ items.endDate.title }}
            DatePicker(
              v-bind="items.endDate"
              v-model="items.endDate.value"
              @input="onDateInput"
            )

      .action__bottom
        Button(is-primary @click="onCheckButtonClick") Download report

</template>

<script>
import { mapActions } from 'vuex'
import InputSmart from '@/components/InputSmart/InputSmart'
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import DatePicker from '@/components/DatePicker/DatePicker'
import FileSaver from 'file-saver'

export default {
  name: 'ActionLawyersReport',

  components: {
    InputSmart,
    SectionHeader,
    DatePicker,
  },

  data: () => ({
    items: {
      type: {
        title: 'Report type',
        value: 'tournament',
        type: 'select',
        options: [
          { title: 'Tournaments', value: 'tournament' },
          { title: 'Duels', value: 'duel' },
          { title: 'Subscriptions', value: 'subscription' },
          { title: 'All Purchases', value: 'purchases' },
          { title: 'Balances', value: 'balances' },
          { title: 'Withdrawals', value: 'withdrawals' },
          { title: 'Payins', value: 'payins' },
          { title: 'Bonuses', value: 'bonuses' },
          { title: 'Store', value: 'marketplace' },
          { title: 'Marketplace (Digital)', value: 'digital-marketplace' },
        ],
        withEmptyValue: false,
      },

      startDate: {
        title: 'Date from',
        value: null,
        type: 'date',
      },

      endDate: {
        title: 'Date to',
        value: null,
        type: 'date',
      },
    },
  }),

  computed: {
    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Report`,
        game: this.gameName,
      }
    },
  },

  created() {
    this.$forceUpdate()
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onCheckButtonClick() {
      this.$loading.start()

      try {
        const type = this.items.type.value

        const [startDay, startMonth, startYear] = this.items.startDate.value.split('.')
        const [endDay, endMonth, endYear] = this.items.endDate.value.split('.')

        const startDate = `${startYear}-${startMonth}-${startDay}`
        const endDate = `${endYear}-${endMonth}-${endDay}`

        const { data } = await this.$axios.get(`/admin/lawyers/report/${type}?date_start=${startDate}&date_end=${endDate}`)

        const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, 'report.csv')

        if (data) {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'success',
              title: 'Success',
              text: 'Report received!',
            },
          })
        }
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    onDateInput() {
    },
  },
}
</script>

<style lang="scss">
</style>
