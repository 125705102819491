<template lang="pug">
  button.button(
    :class="classModifiers"
    v-bind="$attrs"
    :disabled="isDisabled"
    :type="$attrs.type || 'button'"
    @click="onClick"
  )
    .button__body(v-if="isSquare")
      img(v-if="icon && icon.type === 'png'" :src="require(`@/assets/img/icons/png/${this.icon.name}.png`)")
      InlineSvg(v-else-if="icon" :src="require(`@/assets/img/icons/${this.icon.name || icon }.svg`)")
      slot

    .button__body(v-else)
      .button__icon(v-if="icon")
        img(v-if="icon.type === 'png'" :src="require(`@/assets/img/icons/png/${this.icon.name}.png`)")
        InlineSvg(v-else="" :src="require(`@/assets/img/icons/${this.icon.name || icon }.svg`)")
      span.button__text(v-if="$slots.default")
        slot
</template>

<script>
export default {
  name: 'Button',

  props: {
    isSecondary: {
      type: Boolean,
      default: false,
    },

    isSquare: {
      type: Boolean,
      default: false,
    },

    isPrimary: {
      type: Boolean,
      default: false,
    },

    isBordered: {
      type: Boolean,
      default: false,
    },

    isWhite: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: [String, Object],
      default: null,
    },
  },

  data() {
    return {}
  },

  computed: {
    classModifiers() {
      return {
        'button--secondary': this.isSecondary,
        'button--primary': this.isPrimary,
        'button--square': this.isSquare,
        'button--bordered': this.isBordered,
        'button--white': this.isWhite,
        'button--disabled': this.isDisabled,
        'button--active': this.isActive,
      }
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss">
button.button {
  cursor: pointer;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  padding: 0;

  &--disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &--square {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    outline: none;
    border-radius: 8px;
    background: $blue-grey60;
    transition: 0.2s $easing;
    cursor: pointer;

    &.button--active {
      background: $blue-grey40;

      svg,
      path,
      rect {
        fill: $primary60;
      }
    }

    .button__body {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--secondary {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-width: fit-content;
    border-radius: 8px;
    background: $blue-grey60;
    transition: 0.2s $easing;

    &.button--active {
      background: $primary60;
    }

    .button {
      &__body {
        height: 24px;
        display: flex;
        align-items: center;
      }

      &__icon {
        margin-right: 8px;
      }

      &__text {
        font-size: 18px;
        font-weight: 500;
        color: #667f9f;
        font-family: 'Roboto', sans-serif;
      }
    }
  }

  &--primary {
    padding: 12px 24px;
    display: flex;
    justify-content: center;
    width: fit-content;
    background: $gradient-primary;
    border-radius: 8px;
    transition: 0.2s $easing;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      z-index: 0;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(90deg, #1565c0 0%, #1e88e5 100%);
      border-radius: 8px;
      opacity: 0;
      transition: 0.2s $easing;
    }

    .button {
      &__body {
        height: 24px;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
      }
      &__text {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        font-family: 'Roboto', sans-serif;
      }
      &__icon {
        margin-right: 8px;
        position: relative;
        top: -1px;
        z-index: 2;
      }
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &--bordered {
    border: 2px solid $primary60;
    border-radius: 8px;
    padding: 0 24px;
    background: transparent;
    transition: background 0.2s $easing;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px;

    &.button--white {
      border-color: white;
    }

    .button {
      &__body {
        height: 24px;
        display: flex;
        align-items: center;
      }

      &__icon {
        margin-right: 8px;
        display: flex;
      }

      &__text {
        @include heading6;

        color: $white;
        transition: color 0.2s $easing;
      }
    }

    &:hover {
      background: $gradient-primary;
      background: $primary60;

      /* border-color: transparent; */

      &.button--white {
        background: white;

        .button__text {
          color: $blue-grey90;
        }

        svg > * {
          fill: $blue-grey90;
        }
      }
    }
  }

  &:not(.button--primary):not(.button--bordered):not(.button--active) {
    &:hover {
      background: #324761;
    }
    &:not(.button--active):active {
      background: $blue-grey20;
    }
  }
}
</style>
