<template lang="pug">
  .sync-translations
    SectionHeader(:header="header")

    .action__body
      .action__section
        .sync-translations__caption После нажатия на кнопку переводы на сайте синхронизируются с crowdin
        Button(is-primary @click="onSyncTranslations") Обновить

</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import { mapActions } from 'vuex'

export default {
  name: 'TournamentParser',

  components: {
    SectionHeader,
  },

  data() {
    return {}
  },

  computed: {
    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Синхронизация переводов`,
        game: this.gameName,
      }
    },
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onSyncTranslations() {
      this.$loading.start()

      try {
        await this.$axios.post('/languages/sync')

        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            title: 'Всё прошло успешно',
            text: 'Переводы синхронизированы!'
          }
        })
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },
  },
}
</script>

<style lang="scss">
.sync-translations {
  &__caption {
    @include caption;

    color: $white;
    margin-bottom: 12px;
    line-height: 120%;
  }

  .file_upload {
    max-width: 400px;
  }
}
</style>
