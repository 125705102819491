<template lang="pug">
  .action-influencers-verification
    SectionHeader(:header="header")

    .action__body
      template(v-if="!targetInfluencer")
        .action-influencers-verification__text
          | Строки ниже - это заявки от инфлюенсеров, которые ещё не проходили верификацию. Чтобы верфицировать или отклонить их, нажмите на соответствующую строку. Вы попадёте на карточку инфлюенсера, где сможете увидеть введённые им данные и выбрать соответствующее действие в зависимости от них.
        .action__section
          .action__grid
            template(v-if="influencers.length")
              div(v-for="influencer in influencers" :key="influencer.id")
                .action-influencers-verification__request(@click="setTargetInfluencer(influencer.id)")
                  .action-influencers-verification__request-email {{ influencer.contact_email }}
            template(v-else)
              .action-influencers-verification__no-requests Заявок пока нет
      template(v-else)
        .action-influencers-verification__subtitle Пользователь на платформе
        a.action-influencers-verification__user(:href="userLink") {{ influencerData.username }} (Перейти в профиль)

        .action-influencers-verification__subtitle Каналы в соц. сетях
        .action-influencers-verification__field(v-for="social in step1Fields")
          img.action-influencers-verification__field-icon(:src="social.icon")
          .action-influencers-verification__field-value(v-if="targetInfluencer.contact_networks[social.title.toLowerCase()]")
            | {{ targetInfluencer.contact_networks[social.title.toLowerCase()] }}
          .action-influencers-verification__field-value-no(v-else)
            | Не указано

        .action-influencers-verification__subtitle Статистика
        .action-influencers-verification__stats
          div(v-for="stat in targetInfluencer.statistics.filter(item => item.url !== '')" :key="targetInfluencer.id + stat.url")
            a(:href="stat.url" target="_blank")
              img.action-influencers-verification__stats-screen(:src="stat.url")

        .action-influencers-verification__subtitle Контакты инфлюенсера
        .action-influencers-verification__field
          img.action-influencers-verification__field-icon(src="https://cdn.vvvgamers.com/images/other/info/icons/email.png")
          .action-influencers-verification__field-value(v-if="targetInfluencer.contact_email")
            | {{ targetInfluencer.contact_email }}
          .action-influencers-verification__field-value-no(v-else)
            | Не указано
        .action-influencers-verification__field(v-for="social in step3Fields")
          img.action-influencers-verification__field-icon(:src="social.icon")
          .action-influencers-verification__field-value(v-if="targetInfluencer.contact_networks[social.title.toLowerCase()]")
            | {{ targetInfluencer.contact_networks[social.title.toLowerCase()] }}
          .action-influencers-verification__field-value-no(v-else)
            | Не указано

        .action-influencers-verification__buttons
          button.action-influencers-verification__button(@click="goBackToList")
            | Назад
          button.action-influencers-verification__button.action-influencers-verification__button--primary(@click="verifyRequest")
            | Верифицировать
          button.action-influencers-verification__button.action-influencers-verification__button--red(@click="declineRequest")
            | Отклонить

</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import UsersService from "@/services/UsersService";
import { mapActions } from "vuex";

export default {
  name: 'InfluencersVerification',

  components: {
    SectionHeader,
  },

  data() {
    return {
      influencers: [],
      influencerData: {},
      targetInfluencerId: undefined,
      step1Fields: [
        {
          title: 'DISCORD',
          icon: 'https://cdn.vvvgamers.com/images/other/info/icons/discord.png',
        },
        {
          title: 'YOUTUBE',
          icon: 'https://cdn.vvvgamers.com/images/other/info/icons/youtube.png',
        },
        {
          title: 'TWITCH',
          icon: 'https://cdn.vvvgamers.com/images/other/info/icons/twitch.png',
        },
        {
          title: 'TIKTOK',
          icon: 'https://cdn.vvvgamers.com/images/other/info/icons/tiktok.png',
        },
        {
          title: 'INSTAGRAM',
          icon: 'https://cdn.vvvgamers.com/images/other/info/icons/instagram.png',
        },
      ],
      step3Fields: [
        {
          title: 'DISCORD',
          icon: 'https://cdn.vvvgamers.com/images/other/info/icons/discord.png',
        },
        {
          title: 'TELEGRAM',
          icon: 'https://cdn.vvvgamers.com/images/other/info/icons/telegram.png',
        },
        {
          title: 'WHATSAPP',
          icon: 'https://cdn.vvvgamers.com/images/other/info/icons/whatsapp.png',
        },
        {
          title: 'INSTAGRAM',
          icon: 'https://cdn.vvvgamers.com/images/other/info/icons/instagram.png',
        },
      ],
    }
  },

  computed: {
    header() {
      return {
        title: "Верификация инфлюенсеров",
      }
    },

    targetInfluencer() {
      return this.influencers.find(influencer => influencer.id === this.targetInfluencerId)
    },

    userLink() {
      return `https://vvvgamers.com/user/${this.influencerData.alias}`
    },
  },

  mounted() {
    this.getInfluencersRequests()
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async getInfluencersRequests() {
      this.$loading.start()

      try {
        const { data } = await UsersService.getInfluencerRequests()

        this.influencers = data.data
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    async getInfluencerData(id) {
      try {
        const { data } = await UsersService.getUserById(id)

        this.influencerData = data.data
      } catch (e) {
        this.$error.handle(e)
      }
    },

    setTargetInfluencer(id) {
      this.targetInfluencerId = id
      this.getInfluencerData(this.targetInfluencer.account_id)
    },

    goBackToList() {
      this.targetInfluencerId = undefined
      this.influencerData = {}
    },

    verifyRequest() {
      UsersService.acceptInfluencerRequest(this.targetInfluencerId)
        .then(response => {
          if (!response.data.error) {
            this.showModal({
              component: 'ModalMessage',
              data: {
                type: 'success',
                title: 'Успешно',
                text: 'Заявка пользователя была верифицирована',
              },
            })
            this.goBackToList()
            this.getInfluencersRequests()
          } else {
            this.showModal({
              component: 'ModalMessage',
              data: {
                type: 'error',
                title: 'Ошибка',
                text: `Заявка пользователя не была верифицирована, обратитесь в разработку. Сообщите, что пытались верифицировать заявку с id = ${this.targetInfluencerId}.`,
              },
            })
          }
        })
        .catch(() => {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'error',
              title: 'Ошибка',
              text: `Заявка пользователя не была верифицирована, обратитесь в разработку. Сообщите, что пытались верифицировать заявку с id = ${this.targetInfluencerId}.`,
            },
          })
        })
    },

    declineRequest() {
      UsersService.declineInfluencerRequest(this.targetInfluencerId)
        .then(response => {
          if (!response.data.error) {
            this.showModal({
              component: 'ModalMessage',
              data: {
                type: 'success',
                title: 'Успешно',
                text: 'Заявка пользователя была отклонена',
              },
            })
            this.goBackToList()
            this.getInfluencersRequests()
          } else {
            this.showModal({
              component: 'ModalMessage',
              data: {
                type: 'error',
                title: 'Ошибка',
                text: `Заявка пользователя не была отклонена, обратитесь в разработку. Сообщите, что пытались отклонить заявку с id = ${this.targetInfluencerId}.`,
              },
            })
          }
        })
        .catch(() => {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'error',
              title: 'Ошибка',
              text: `Заявка пользователя не была отклонена, обратитесь в разработку. Сообщите, что пытались отклонить заявку с id = ${this.targetInfluencerId}.`,
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
.action-influencers-verification{
  &__no-requests {
    color: $white;
    width: 100%;
    text-align: center;
    margin: auto;
    padding: 40px;
    background-color: $white10;
    border-radius: 8px;
  }

  &__request {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;
    width: 100%;
    margin: auto;
    padding: 10px 40px;
    background-color: $white10;
    border: 1px solid $white10;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: transparent;
    }
  }

  &__text {
    color: $white;
    background-color: $light-blue60-10;
    border-radius: 8px;
    padding: 20px;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  &__subtitle {
    color: $white;
    padding: 10px 0;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  &__user {
    color: $primary60;
    text-decoration: underline;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 24px;
  }

  &__field {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    background-color: $light-blue60-10;
    border-radius: 8px;
    padding: 10px 20px;

    &-value {
      color: $white;
      font-size: 16px;
      line-height: 18px;

      &-no {
        color: $white20;
      }
    }
  }

  &__stats {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-bottom: 10px;

    &-screen {
      width: 100%;
      height: 170px;
      object-fit: cover;
      border-radius: 12px;

      @media (min-width: $breakpointTablet) {
        height: 327px;
      }

      @media (min-width: $breakpointDesktop) {
        height: 337px;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 60px 0 20px;
  }

  &__button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: transparent;
    border: 1px solid $blue-grey10;
    border-radius: 8px;
    color: $white;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    cursor: pointer;

    &--red {
      background-color: $red60;
      border: 1px solid $red60;
    }

    &--primary {
      background-color: $primary60;
      border: 1px solid $primary60;
    }
  }
}
</style>
