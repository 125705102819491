<template lang="pug">
  .reports-moderation
    .reports-moderation__section-body(v-if="reportedPlayers.length")
      .reports-moderation__players
        .reports-moderation__player(
          v-for="player in reportedPlayers"
          :key="player.id"
          @click="onPlayerClick(player)"
        )
          .reports-moderation__player-place {{ player.place > 0 ? player.place : '?' }}
          .reports-moderation__player-name {{ player.game_account ? player.game_account.username :  `[lichess не привязан] ${player.full_name}` }}
          .reports-moderation__player-reports(v-if="player.reports" :class="{'--red': player.hasNewReports}")
            span {{ player.reports.length }}

      .reports-moderation__players
        .reports-moderation__player(
          v-for="player in unreportedPlayers"
          :key="player.id"
          class="--done"
        )
          .reports-moderation__player-place {{ player.place > 0 ? player.place : '?' }}
          .reports-moderation__player-name {{ player.game_account ? player.game_account.username :  `[не привязан] ${player.full_name}` }}
          .reports-moderation__player-reports
            span {{ player.reports && player.reports.length || 0 }}

    .action__empty(v-else)
      | Репортов нет или они уже промодерированы
</template>

<script>
import { mapActions } from 'vuex'

const sortPlayers = (a, b) => {
  if (a.place < 1) {
    return 1
  }

  if (a.place < b.place || b.place < 1) {
    return -1
  }
}

export default {
  name: 'ReportsModeration',

  props: {
    tournament: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      reports: [],
    }
  },

  computed: {
    reportedPlayers() {
      return this.tournament.players
          ?.map(player => {
            const reports = this.reports.find(report => player.id === report.id)

            if (reports) {
              player.reports = reports.reports
              player.ban_points = reports.ban_points
              player.status = reports.status
              player.place = reports.place
              player.hasNewReports = reports.status === 'new' || reports.status === 'active'
            }

            return player
          })
          .filter(player => player.reports && player.hasNewReports)
          .sort(sortPlayers)
    },

    unreportedPlayers() {
      return this.tournament.players
          ?.filter(player => {
            return (
                (!player.reports || !player.hasNewReports) &&
                player.status === 'active'
            )
          })
          .sort(sortPlayers)
    },
  },

  mounted() {
    this.getReports()
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async getReports() {
      this.$loading.start()

      try {
        const { data } = await this.$axios.get(`/admin/games/tournaments/${this.tournament.id}/reports`)

        this.reports = data.data
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    onPlayerClick(player) {
      this.showModal({
        component: 'ReportsModal',
        data: {
          callback: this.reportCallback,
          player,
          tournament: this.tournament
        }
      })
    },

    reportCallback() {
      this.getReports()
      this.$store.commit('modal/toggle')
    },
  },
}
</script>

<style lang="scss">
.action__empty {
  text-align: center;
}

.reports-moderation {
  &__players {
    padding-bottom: 16px;
    border-bottom: 2px solid $green60;
    margin-bottom: 16px;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__player {
    padding: 12px;
    display: flex;
    border-radius: 8px;
    color: $white;
    background: $blue-grey60;
    margin-bottom: 8px;
    cursor: pointer;
    transition: 0.2s $easing;
    align-items: center;

    &.--done {
      cursor: default;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: $blue-grey50;

      &.--done {
        background: $blue-grey60;
      }
    }

    &-reports {
      padding: 7px 8px;
      background: $green60-20;
      color: $green60;
      border-radius: 8px;
      height: 32px;
      line-height: 18px;

      &.--red {
        background: $red60-20;
        color: $red60;
      }
    }

    &-place {
      width: 26px;
      border-right: 1px solid $blue-grey40;
      height: 24px;
      line-height: 24px;
    }

    &-name {
      padding: 0 16px;
      width: 100%;
    }
  }
}
</style>