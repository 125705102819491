<template lang="pug">
  nav.tabs
    ul.tabs__menu
      li.tabs__item(v-for="(item, index) in tabs" v-if="item" :key="item.id || index" :class="[{'--active': tabIsActive(index)}, {'--disabled': item.isDisabled}]")
        a.tabs__link(@click.prevent="onItemClick(index)") {{ item.title }}
</template>

<script>
export default {
  name: '',

  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    onItemClick(index) {
      this.$emit('input', index);
    },

    tabIsActive(index) {
      return this.$attrs.value === index;
    },
  },
};
</script>

<style lang="scss">
.tabs {
  height: 100%;
  display: flex;
  width: auto;

  &__menu {
    @include scroll-none;

    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    overflow: auto;
    margin-right: 10px;
  }

  &__link {
    @include heading6;

    width: 100%;
    padding: 0 12px;
    color: #839ab5;
    font-weight: 600;
    position: relative;
    transition: all 0.2s $easing;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    user-select: none;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 18px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $primary60;
      opacity: 0;
      transition: all 0.2s $easing;
    }

    &:hover {
      @include heading4;

      color: $white;
      transform: translateY(-10px);
      transition: all 0.2s $easing;

      &::after {
        background: $primary60;
        transition: all 0.2s $easing;
      }
    }
  }

  &__item {
    height: 100%;
    &.--active {
      .tabs {
        &__link {
          @include heading4;

          color: $white;
          transform: translateY(-10px);
          transition: all 0.2s $easing;

          &::after {
            background: $primary60;
            opacity: 1;
            transition: all 0.2s $easing;
          }
        }
      }
    }
    &.--disabled {
      opacity: 0.3;
      user-select: none;
      cursor: not-allowed;

      .tabs__link {
        pointer-events: none;
      }
    }
  }

  &__button {
    height: fit-content;
    position: relative;
    margin: auto 0;
  }
}

@media screen and (max-width: 719px) {
  .nav {
    width: calc(100% + 16px);
  }
}
</style>
