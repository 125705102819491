import pubgMobile from './pubg-mobile'
import lichess from './lichess'
import clashRoyale from './clash-royale'
import pubgDesktop from './pubg-desktop'
import brawlStars from './brawl-stars'
import dota2 from './dota2'
import freeFire from './free-fire'
import standoff from './standoff'
import pubgNewState from './pubg-new-state'
import testNewGame from './test-new-game'
import valorant from './valorant'
import callOfDuty from './call-of-duty'
import amongUs from './among-us'

export default {
  'pubg-mobile': pubgMobile,
  'clash-royale': clashRoyale,
  'brawl-stars': brawlStars,
  lichess,
  'pubg-desktop': pubgDesktop,
  dota2,
  'free-fire': freeFire,
  standoff,
  'pubg-new-state': pubgNewState,
  'mobile-legends': testNewGame,
  'valorant': valorant,
  'call-of-duty-pc': callOfDuty,
  'call-of-duty-mobile': callOfDuty,
  'among-us': amongUs
}
