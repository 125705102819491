<template lang="pug">
  .referral
    SectionHeader(:header="header")

    .action__body
      .action__section
        .action__grid
          .action__form-field(v-for="(item, i) of items" :key="i")
            InputSmart(:item="item")

      .action__bottom
        Button(is-primary @click="onCheckButtonClick") Изменить

</template>

<script>
import { mapActions, mapState } from 'vuex'
import InputSmart from '@/components/InputSmart/InputSmart'
import SectionHeader from '@/components/SectionHeader/SectionHeader'

export default {
  name: 'Referral',

  components: {
    InputSmart,
    SectionHeader,
  },

  data: () => ({
    items: {
      id: {
        title: 'ID игрока',
        value: null,
        type: 'number',
        placeholder: 'Введите ID',
      },

      action: {
        title: 'Действие',
        value: true,
        type: 'select',
        options: [
          { title: 'Включить рефералку', value: true },
          { title: 'Выключить рефералку', value: false },
        ],
        withEmptyValue: false,
      },
    }
  }),

  computed: {
    ...mapState('games', ['gamesByName']),

    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Управление рефералкой`,
        game: this.gameName,
      }
    },
  },

  created() {
    this.$forceUpdate()
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onCheckButtonClick() {
      this.$loading.start()

      try {
        const playerId = this.items.id.value
        const referralState = this.items.action.value

        const { data } = await this.$axios.post(`/admin/user/${playerId}/referral`, {
          referralState,
        })

        if (data.data) {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'success',
              title: 'Успех',
              text: 'Всё прошло как нужно!',
            },
          })
        }
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    }
  },
}
</script>

<style lang="scss">
</style>
