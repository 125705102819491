<template lang="pug">
  .action-crud-tournament
    SectionHeader(:header="header")

    .action-crud-tournament__open-tournament-block
      InputNumber.action-crud-tournament__tournament-id-input(v-model="tournamentId" placeholder="ID турнира")
      Button(is-primary @click="openTournament" :isDisabled="!tournamentId")
              | Открыть турнир 
    
    Tabs.action-crud-tournament__tabs(v-if="tournament" :tabs="gameSections" v-model="activeTabIndex")

    .action-crud-tournament__grid(v-if="tournament && currentSection.id === 'nominations'" :key="'nominations_' + nominations.length")
      TournamentNominations
            
    .action-crud-tournament__grid(v-else-if="tournament" :key="tournament.id")
      .action-crud-tournament__form-field(v-for="(field, index) of sectionFields" :key="index")
        InputSmart(v-if="!field.isHidden" :item="field")
    
    .action-crud-tournament__save-button(v-if="tournament")
      Button(is-primary @click="onUpdateTournamentClick")
        | Сохранить

</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import SectionHeader from '@/components/SectionHeader';
import InputNumber from '@/components/InputNumber';
import InputSmart from '@/components/InputSmart';
import Tabs from '@/components/Tabs';
import TournamentNominations from '@/components/TournamentNominations';

import gameData from '@/data/games/tournaments';

import TournamentService from '@/services/TournamentsService';

export default {
  name: 'ActionCRUDTournament',

  components: {
    SectionHeader,
    InputNumber,
    InputSmart,
    Tabs,
    TournamentNominations,
  },

  data() {
    return {
      action: 'UPDATE',
      fieldsToMap: {},
      gameFields: [],
      tournamentId: undefined,
      activeTabIndex: 0,
      tournament: undefined,
    };
  },

  computed: {
    ...mapGetters('nominations', ['nominations']),

    gameName() {
      return this.$route.params.section;
    },

    game() {
      return gameData[this.gameName];
    },

    gameSections() {
      return this.game.sections;
    },

    currentSection() {
      return this.gameSections[this.activeTabIndex];
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Редактирование турнира`,
        game: this.gameName,
      };
    },

    sectionFields() {
      return this.gameFields.filter(
        (field) => field.section === this.currentSection.id
      );
    },

    tournamentData() {
      let tournament = {};

      this.gameFields.forEach((field) => {
        let value = field.value;

        if (!field.isHidden) {
          tournament[field.key] = value;
        }
      });

      return tournament;
    },
  },

  mounted() {
    const fields = this.game.fields();
    fields.forEach((field) => (field.isHidden = false));
    this.gameFields = fields;
    this.loadFieldsToMap();
    this.setNominations([]);
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapMutations('nominations', ['setNominations']),

    async loadFieldsToMap() {
      this.fieldsToMap = await TournamentService.getTournamentCopySettings();
    },

    fieldMapper(tournamentData, field) {
      const DATE_FIELDS = ['registration_ends_at', 'date']
      let value = tournamentData;

      for (const key of field.key.split('.')) {
        if (key in value) {
          value = value[key];
        } else {
          return { ...field };
        }
      }

      if (field.valueToField) {
        value = field.valueToField(value);
      }

      if (field.currencyField) {
        let currency = tournamentData;
        for (const key of field.currencyField.split('.')) {
          currency = currency[key];
        }
        if (currency === 'money') {
          value /= 100;
        }
      }

      if (DATE_FIELDS.includes(field.key) && value) {
        value = moment(value)
            .add(3, 'hours')
            .format('YYYY-MM-DD HH:mm');
      }

      return { ...field, value };
    },

    openTournament() {
      if (!this.tournamentId) return;

      TournamentService.getTournament(this.gameName, this.tournamentId)
        .then((response) => {
          this.tournament = response.data?.data;
          this.gameFields = this.gameFields.map((field) => this.fieldMapper(this.tournament, field));
          this.loadNominations(this.tournament.prize_settings.nominations);
        })
        .catch((err) => {
          console.error(err);
          alert('Ошибка загрузки турнира');
        });
    },

    loadNominations(nominations) {
      if (!nominations) return;
      this.setNominations(
        nominations.map((nomination) => ({
          title: {
            title: 'Название номинации',
            type: 'text',
            value: nomination.title,
          },
          prize: {
            title: 'Приз в номинации',
            type: 'number',
            value:
              nomination.currency === 'money'
                ? nomination.amount / 100
                : nomination.amount,
          },
          currency: nomination.currency,
          icon: {
            src: nomination.icon_name,
            color: nomination.icon_color,
          },
        }))
      );
    },

    onUpdateTournamentClick() {
      if (
        this.tournamentData['prize_settings.prize_winner_type'] === 'top' &&
        this.tournamentData['prize_settings.prize_distribution_type'] ===
          'flat' &&
        this.tournamentData['prize_settings.flat_prizes'].split(',').length !==
          +this.tournamentData['prize_settings.win_place_count']
      ) {
        return this.showModal({
          component: 'ModalMessage',
          data: {
            text:
              'Количество заданных наград должно совпадать с количеством призовых мест',
          },
        });
      }

      this.tournamentData['prize_settings.nominations'] = this.nominations?.map(
        (nomination) => {
          return {
            title: nomination.title.value,
            currency: nomination.currency,
            amount:
              nomination.currency === 'money'
                ? nomination.prize.value * 100
                : nomination.prize.value,
            icon_name: nomination.icon.src,
            icon_color: nomination.icon.color,
          };
        }
      );

      const tournament = TournamentService.tournamentFieldsToStandardForm(
        this.tournamentData,
        this.gameFields
      );

      TournamentService.updateTournament(
        this.gameName,
        this.tournament.id,
        tournament
      )
        .then(() => {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'success',
              title: 'Успех',
              text: 'Данные отправлены!',
            },
          });
        })
        .catch((err) => {
          console.error(err);
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'error',
              title: 'Ошибка',
              text: 'Ошибка сохранения!',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.action-crud-tournament {
  &__tournament-id-input {
    margin-left: 32px;
    margin-right: 8px;
  }

  &__open-tournament-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__tabs {
    margin-top: 32px;
    height: 78px !important;
    background-color: #495c7440;
    padding: 0 32px 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 0px 4px 8px #0000001f;
    border-radius: 0 30px 30px 0px;
  }

  &__grid {
    display: grid;
    column-gap: 2%;
    row-gap: 32px;
    padding: 10px 32px;
  }

  &__save-button {
    margin-top: 16px;
    margin-left: 32px;
  }
}
</style>
