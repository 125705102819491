import moment from 'moment'

export default {
  title: 'Clash Royale',

  sections: [
    {id: 'general', title: 'Основное',},
    {id: 'prize_pool', title: 'Призовой фонд',},
    {id: 'prize_pool_distribution', title: 'Распределение ПФ',},
    {id: 'stages', title: 'Этапы',},
  ],

  fields: function() {
    return [
      {
        title: 'Название этапа',
        value: '',
        type: 'text',
        key: 'stages.<index>.title',
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        title: 'Даты проведения',
        type: 'double',
        section: 'stages',
        isStaged: true,
        stageId: 0,
        fields: [
          {
            placeholder: 'Начало',
            value: null,
            type: 'date',
            key: 'stages.<index>.date',
            section: 'stages',
            handler(date) {
              return moment(date).subtract(3, 'hours').format('YYYY-MM-DD HH:mm')
            },
            required: true,
            isStaged: true,
            stageId: 0,
          },

          {
            placeholder: 'Конец',
            value: null,
            type: 'date',
            key: 'stages.<index>.ends_at',
            section: 'stages',
            handler(date) {
              return moment(date).subtract(3, 'hours').format('YYYY-MM-DD HH:mm')
            },
            required: true,
            isStaged: true,
            stageId: 0,
          },
        ],
      },

      {
        type: 'double',
        section: 'stages',
        isStaged: true,
        stageId: 0,
        fields: [
          {
            title: 'Тип этапа',
            value: 'group',
            type: 'select',
            options: [
              { title: 'Группа', value: 'group' },
              { title: 'Четвертьфинал', value: 'quarter' },
              { title: 'Полуфинал', value: 'semi-final' },
              { title: 'Финал', value: 'final' },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.round_type',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },

          {
            title: 'Количестко комнат',
            value: 3,
            type: 'number',
            key: 'stages.<index>.room_count',
            section: 'stages',
            required: true,
            isStaged: true,
            stageId: 0,
          },
        ],
      },

      {
        type: 'double',
        section: 'stages',
        isStaged: true,
        stageId: 0,
        fields: [
          {
            title: 'Тип призового фонда',
            value: 'not_fixed_with_guarantee',
            type: 'select',
            options: [
              { title: 'Фиксированный', value: 'fixed' },
              { title: 'Нефиксированный', value: 'not_fixed' },
              { title: 'Нефикс. с гарантированным', value: 'not_fixed_with_guarantee' },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.prize_settings.prize_pool_type',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },

          {
            title: 'Валюта призового фонда',
            value: 'coins',
            type: 'select',
            options: [
              { title: 'Coins', value: 'coins' },
              { title: 'EUR', value: 'money' },
              { title: 'Tickets', value: 'tickets' },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.prize_settings.prize_currency',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },
        ]
      },

      {
        title: 'Сумма фиксированного призового фонда',
        value: null,
        type: 'number',
        key: 'stages.<index>.prize_pool',
        show: {
          key: 'stages.<index>.prize_settings.prize_pool_type',
          value: 'fixed'
        },
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        title: 'Сумма гарантированного призового фонда',
        value: null,
        type: 'number',
        key: 'stages.<index>.prize_settings.guaranted_prize',
        show: {
          key: 'stages.<index>.prize_settings.prize_pool_type',
          value: 'not_fixed_with_guarantee'
        },
        description: `Гарантированный приз — это сумма, которая в любом случае будет разыграна в турнире`,
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        type: 'double',
        section: 'stages',
        isStaged: true,
        stageId: 0,
        fields: [
          {
            title: 'Распределение призов',
            value: 'place',
            type: 'select',
            options: [
              { title: 'По месту в топе', value: 'place' },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.prize_settings.place_distribution_type',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },

          {
            title: 'Тип распределения фонда',
            value: 'top',
            type: 'select',
            options: [
              { title: 'Количество', value: 'top' },
              { title: 'Проценты', value: 'percentage' },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.prize_settings.prize_winner_type',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },
        ]
      },

      {
        title: 'Количество призовых мест ',
        value: 10,
        type: 'number',
        key: 'stages.<index>.prize_settings.win_place_count',
        show: {
          key: 'stages.<index>.prize_settings.prize_winner_type',
          value: 'top'
        },
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        title: 'Процент призовых мест',
        value: 10,
        type: 'number',
        key: 'stages.<index>.prize_settings.win_place_count',
        show: {
          key: 'stages.<index>.prize_settings.prize_winner_type',
          value: 'percentage'
        },
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        title: 'Тип распределения ПФ',
        value: 'bezier',
        type: 'select',
        options: [
          { title: 'Bezier', value: 'bezier' },
          { title: 'Flat', value: 'flat' },
          { title: 'Double', value: 'double' },
        ],
        withEmptyValue: false,
        key: 'stages.<index>.prize_settings.prize_distribution_type',
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        title: 'Точки на Кривой Безье',
        value: '0,8/0,7/0,3/0,1',
        type: 'text',
        key: 'stages.<index>.prize_settings.bezier_points',
        placeholder: 'x,y/x,y/x,y/x,y',
        description: `
        Формат для ввода: x,y/x,y/x,y/x,y<br><br>
        Шаблоны:<br><br>
        0,11/0,3/4.6,0.6/10.8,0.5
        <br>
        0,11/0,3/4.4,0.7/10.4,0.6
      `,
        handler(points) {
          let data = []

          points.split('/').forEach(point => {
            point = point.split(',')
            data.push({ x: parseFloat(point[0]), y: parseFloat(point[1]) })
          })

          return data
        },
        show: {
          key: 'stages.<index>.prize_settings.prize_distribution_type',
          value: 'bezier'
        },
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        title: 'Награды',
        value: '',
        type: 'text',
        key: 'stages.<index>.prize_settings.flat_prizes',
        show: {
          key: 'stages.<index>.prize_settings.prize_distribution_type',
          value: 'flat'
        },
        handler(points) {
          let data = []

          data = points.split(',').map(Number)

          return data
        },
        placeholder: 'Через запятую: xxx,yyy,zzz,hhh',
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        type: 'double',
        section: 'stages',
        isStaged: true,
        stageId: 0,
        fields: [
          {
            title: 'Игровой режим',
            value: 'Обычный',
            type: 'select',
            options: [
              { title: 'Обычный', value: 'Обычный' },
              { title: 'Двойной эликсир', value: 'Двойной эликсир' },
              { title: 'Тройной эликсир', value: 'Тройной эликсир' },
              { title: 'Внезапная смерть', value: 'Внезапная смерть' },
              { title: 'С выбором карт', value: 'С выбором карт' },
              { title: 'Двойной эликсир с выбором карт', value: 'Двойной эликсир с выбором карт' },
              { title: 'Тройной выбор карт', value: 'Тройной выбор карт' },
              { title: 'Ограбление с выбором карт', value: 'Ограбление с выбором карт' },
              { title: 'Кабаньи бега', value: 'Кабаньи бега' },
              { title: 'Гонки дровосеков', value: 'Гонки дровосеков' },
              { title: 'Бал стенобоев', value: 'Бал стенобоев' },
              { title: 'Шествие призраков', value: 'Шествие призраков' },
              { title: 'Захват эликсира', value: 'Захват эликсира' },
              { title: 'Драконья охота', value: 'Драконья охота' },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.extra_data.config.game_mode',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },

          {
            title: 'Уровень короля',
            value: 11,
            type: 'select',
            options: [
              { title: '11', value: 11 },
              { title: '12', value: 12 },
              { title: '13', value: 13 },
              { title: '14', value: 14 },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.extra_data.config.level',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },
        ],
      },

      {
        type: 'double',
        section: 'stages',
        isStaged: true,
        stageId: 0,
        fields: [
          {
            title: 'Длительность',
            value: 30,
            type: 'select',
            options: [
              { title: '30 минут', value: 30 },
              { title: '1 час', value: 60 },
              { title: '2 часа', value: 120 },
              { title: '3 часа', value: 180 },
              { title: '4 часа', value: 240 },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.extra_data.config.duration',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },

          {
            title: 'Ограничение по проигрышам',
            value: 0,
            type: 'select',
            options: [
              { title: 'Нет ограничений', value: 0 },
              { title: '3', value: 3 },
              { title: '4', value: 4 },
              { title: '5', value: 5 },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.extra_data.config.max_loses_count',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },
        ],
      },
    ]
  }
}
