<template lang="pug">
  .quests__section
    .quests__section-header
      .quests__section-title Всего шаблонов:
      .quests__section-counter {{ quests.length }}

    .quests__section-body
      .quests__quest(v-for="quest in quests" :key="quest.id")
        .quests__quest-left
          InlineSvg(:src="require(`@/assets/img/quests/${getQuestIcon(quest)}.svg`)").quests__quest-icon
          .quests__quest-label(:class="`quests__quest-label--${getQuestLabel(quest)}`") {{ getQuestLabel(quest) }}
          .quests__quest-title {{ getQuestTitle(quest) }}

        .quests__quest-right
          Currency(:type="quest.prize_currency" is-reversed) {{ prizeAmount(quest) }}
          .quests__quest-delete(@click="deleteQuest(quest.id)") &#10060;

</template>

<script>
import QuestsService from "@/services/QuestsService";
import Currency from '@/components/Currency/Currency'

export default {
  name: 'Quests',

  components: {
    Currency,
  },

  data() {
    return {
      quests: [],
    }
  },

  mounted() {
    this.getTemplates()
  },

  methods: {
    async getTemplates() {
      const { data } = await this.$axios.get('/tasks/templates')

      this.quests = data.data
    },

    getQuestTitle(quest) {
      return quest.extra_data?.text?.ru?.title || quest.extra_data?.text?.en?.title || quest.type
    },

    getQuestLabel(quest) {
      return quest.period
    },

    getQuestIcon(quest) {
      return quest.extra_data?.image_url || 'medal'
    },

    deleteQuest(id) {
      QuestsService.deleteQuest(id)
        .then(() => {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'success',
              title: 'Success',
              text: 'The task was deleted successfully',
            },
          })
          this.$emit('updateQuests')
        })
    },

    prizeAmount(quest) {
      return quest.prize_currency === 'money' ? quest.prize_amount / 100 : quest.prize_amount
    },
  },
}
</script>

<style lang="scss">
.quests {
  &__info {
    margin-bottom: 12px;

    a {
      text-decoration: underline;
    }

    .button {
      display: block;
      margin-top: 16px;
    }
  }

  &__section {
    &-header {
      display: flex;
      padding: 24px 0;
      border-bottom: 1px solid $blue-grey40;
    }

    &-title {
      @include heading4;

      color: $white;
    }

    &-counter {
      @include heading5;

      color: $blue-grey10;
      margin-left: 11px;
      position: relative;
      top: 4px;
    }

    &-body {
      padding: 24px 0;
    }
  }

  &__quest {
    display: flex;
    justify-content: space-between;
    background-color: $blue-grey60;
    min-height: 68px;
    padding: 0 24px;
    border-radius: 8px;

    &-left {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &-right {
      display: grid;
      grid-template-columns: 1fr max-content;
      grid-gap: 8px;
      margin-right: 8px;
      align-items: center;
      max-width: 140px;
    }

    &-delete {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 8px;
      background-color: $red40;
      font-size: 12px;
      cursor: pointer;
    }

    &-icon {
      width: 100%;
      height: 100%;
      max-width: 32px;
      max-height: 32px;
      margin-right: 20px;

      path {
        fill: #9bafca;
      }
    }

    &-title {
      @include hint;

      color: $white;
    }

    &-label {
      @include hint-small;
      margin-right: 10px;

      color: $white;

      &--weekly {
        color: $orange70;
      }

      &--daily {
        color: $green70;
      }

      &--forever {
        color: $purple20;
      }
    }

    &-claim {
      @include button2;

      color: $primary60;
      cursor: pointer;
      min-width: 110px;
    }

    &.--claimed {
      .quests__quest-title {
        color: $green50;
      }

      .quests__quest-icon path {
        fill: $green50;
      }
    }

    &.--completed {
      .quests__quest-title {
        color: $primary60;
      }

      .quests__quest-icon path {
        fill: $primary60;
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media (max-width: $breakpointMobile) {
    &__tabs {
      margin-bottom: 32px;
    }

    &__section {
      &-body {
        padding: 16px 0;
      }
    }

    &__quest {
      flex-direction: column;
      padding: 20px 16px;

      &-right {
        margin-top: 24px;
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
      }

      &-icon {
        margin-right: 16px;
      }
    }
  }
}

.flip-list-move {
  transition: transform 0.7s;
}
</style>