<template lang="pug">
  .statistics
    .statistics__header
      InputSmart(
        :item="items.type"
        v-model="items.type.value"
        :options="items.type.options"
        :with-empty-value="items.type.withEmptyValue"
      ).statistics__header-select

      DatePicker(
        v-if="items.type.value === 'monthly'"
        v-bind="items.month"
        v-model="items.month.value"
        @input="onMonthInput"
      ).statistics__header-date-picker

    .statistics__body(v-if="items.type.value === 'waiting'")
      .statistics__section(v-for="section in sectionsCurrent")
        .statistics__section-header
          img.statistics__section-image(:src="section.icon")

          .statistics__section-title
            | {{ section.title }}

        .statistics__section-info
          .statistics__section-param(v-for="param in section.params")
            .statistics__section-value
              | {{ param.value }}

            .statistics__section-text
              | {{ param.text }}

    .statistics__body(v-else)
      .statistics__section(v-for="section in sectionsMonthly")
        .statistics__section-header
          img.statistics__section-image(:src="section.icon")

          .statistics__section-title
            | {{ section.title }}

        .statistics__section-info
          .statistics__section-param(v-for="param in section.params")
            .statistics__section-value
              | {{ param.value }}

            .statistics__section-text
              | {{ param.text }}

</template>

<script>
import { mapActions, mapState } from 'vuex'
import InputSmart from "@/components/InputSmart/InputSmart"
import DatePicker from '@/components/DatePicker/DatePicker'

export default {
  name: 'HomeStatistics',

  components: {
    InputSmart,
    DatePicker,
  },

  data: () => ({
    items: {
      type: {
        value: 'waiting',
        type: 'select',
        options: [
          { title: 'Ожидают внимания', value: 'waiting' },
          { title: 'Выполнено за месяц', value: 'monthly' },
        ],
        withEmptyValue: false,
      },

      month: {
        title: 'Выбери месяц',
        type: 'date',
        value: '',
        isMonthOnly: true,
      },
    },

    paramsCurrent: {
      accountVerifications: 0,
      lichessLinkings: 0,
      lichessModerations: 0,
      pubgmLinkings: 0,
      pubgmModerations: 0,
    },

    paramsMonthly: {
      accountReview: 0,
      gameAccountReview: 0,
      pubgmTournamentModeration: 0,
      pubgmArcadeModeration: 0,
      pubgmDuelModeration: 0,
      lichessTournamentModeration: 0,
    },
  }),

  computed: {
    ...mapState('user', ['user']),
    ...mapState('games', ['gamesByName']),

    sectionsCurrent() {
      return [
        {
          title: 'Общее',
          icon: require('@/assets/img/icons/star.svg'),
          params: [
            { value: this.paramsCurrent.accountVerifications, text: 'Верификаций', }
          ],
        },

        {
          title: 'Lichess',
          icon: this.gamesByName['lichess']?.extra_data?.icon,
          params: [
            { value: this.paramsCurrent.lichessLinkings, text: 'Привязок', },
            { value: this.paramsCurrent.lichessModerations, text: 'Модераций', },
          ],
        },

        {
          title: 'PUBG Mobile',
          icon: this.gamesByName['pubg-mobile']?.extra_data?.icon,
          params: [
            { value: this.paramsCurrent.pubgmLinkings, text: 'Привязок', },
            { value: this.paramsCurrent.pubgmModerations, text: 'Модераций', }
          ],
        },
      ]
    },

    sectionsMonthly() {
      return [
        {
          title: 'Общее',
          icon: 'https://www.flaticon.com/svg/static/icons/svg/1828/1828884.svg',
          params: [
            { value: this.paramsMonthly.accountReview, text: 'Верификаций аккаунтов', },
            { value: this.paramsMonthly.gameAccountReview, text: 'Привязок игровых аккаунтов', },
          ],
        },

        {
          title: 'Lichess',
          icon: this.gamesByName['lichess']?.extra_data?.icon,
          params: [
            { value: this.paramsMonthly.lichessTournamentModeration, text: 'Модераций турниров', },
          ],
        },

        {
          title: 'PUBG Mobile',
          icon: this.gamesByName['pubg-mobile']?.extra_data?.icon,
          params: [
            { value: this.paramsMonthly.pubgmTournamentModeration, text: 'Модераций турниров', },
            { value: this.paramsMonthly.pubgmArcadeModeration, text: 'Модераций аркад', },
            { value: this.paramsMonthly.pubgmDuelModeration, text: 'Модераций дуэлей', },
          ],
        },
      ]
    }
  },

  created() {
    setTimeout(this.init, 1000)
  },

  methods: {
    ...mapActions('games', ['fetchTournaments', 'fetchDuels']),

    async init() {
      this.$loading.start()

      await this.initAccountVerifications()
      await this.initLichessLinkings()
      await this.initLichessModerations()
      await this.initPubgmLinkings()
      await this.initPubgmModerations()

      this.$loading.finish()
    },

    async initAccountVerifications() {
      if (!['ceo'].includes(this.user?.role)) {
        return
      }

      try {
        const { data } = await this.$axios.get(`/user/account/pending-verification`)

        this.paramsCurrent.accountVerifications = data.data.length
      } catch (e) {
        this.$error.handle(e)
      }
    },

    async initLichessLinkings() {
      if (!['admin', 'ceo', 'moderator-lichess'].includes(this.user?.role)) {
        return
      }

      try {
        const { data } = await this.$axios.get(`/games/lichess/account/pending-verification`)

        this.paramsCurrent.lichessLinkings = data.data.length
      } catch (e) {
        this.$error.handle(e)
      }
    },

    async initLichessModerations() {
      try {
        const { data } = await this.fetchTournaments({
          game: 'lichess',
          query: {
            filters: [{
              name: 'state',
              type: 'string_list',
              values: [{ text: 'moderation' }],
            }],
            sort: { name: 'date', order: 'asc', },
          },
        })

        this.paramsCurrent.lichessModerations = data.length
      } catch (e) {
        this.$error.handle(e)
      }
    },

    async initPubgmLinkings() {
      if (!['admin', 'ceo', 'moderator-pubg-mobile', 'senior-moderator-pubg-mobile'].includes(this.user?.role)) {
        return
      }

      try {
        const { data } = await this.$axios.get(`/games/pubg-mobile/account/pending-verification`)

        this.paramsCurrent.pubgmLinkings = data.data.length
      } catch (e) {
        this.$error.handle(e)
      }
    },

    async initPubgmModerations() {
      try {
        const { data } = await this.fetchTournaments({
          game: 'pubg-mobile',
          query: {
            filters: [{
              name: 'state',
              type: 'string_list',
              values: [{ text: 'moderation' }],
            }],
            sort: { name: 'date', order: 'asc', },
          },
        })

        this.paramsCurrent.pubgmModerations = data.length
      } catch (e) {
        this.$error.handle(e)
      }
    },

    async initMonthlyStats() {
      this.$loading.start()

      const selectedDate = this.items.month.value
      const selectedMonth = selectedDate.split('.')[1]
      const selectedYear = selectedDate.split('.')[2]

      const daysCountByMonth = {
        '01': '31',
        '02': '28',
        '03': '31',
        '04': '30',
        '05': '31',
        '06': '30',
        '07': '31',
        '08': '31',
        '09': '30',
        '10': '31',
        '11': '30',
        '12': '31',
      }

      const startDate = `${selectedYear}-${selectedMonth}-01`
      const endDate = `${selectedYear}-${selectedMonth}-${daysCountByMonth[selectedMonth]}`
      const userId = this.user.id

      const { data } =
          await this.$axios.get(
              `/admin/moderators/report?date_start=${startDate}&date_end=${endDate}&moderator_id=${userId}`
          )

      const moderatorStatistics = data?.data?.[0]

      this.paramsMonthly.accountReview = moderatorStatistics?.account_review || 0
      this.paramsMonthly.gameAccountReview = moderatorStatistics?.game_account_review || 0
      this.paramsMonthly.pubgmTournamentModeration = moderatorStatistics?.pubg_other_map_moderation || 0
      this.paramsMonthly.pubgmArcadeModeration = moderatorStatistics?.pubg_all_weapons_moderation || 0
      this.paramsMonthly.pubgmDuelModeration = moderatorStatistics?.pubg_duel_moderation || 0
      this.paramsMonthly.lichessTournamentModeration = moderatorStatistics?.lichess_moderation || 0

      this.$loading.finish()
    },

    onMonthInput() {
      this.initMonthlyStats()
    },
  },
}
</script>

<style scoped lang="scss">
.statistics {
  font-family: "Futura PT";
  border-radius: 16px;
  background-color: $blue-grey70;
  color: $white;

  &__header {
    padding: 8px 16px;
    display: flex;

    &-select {
      width: fit-content;
    }

    &-date-picker {
      width: fit-content;
      margin-left: 8px;
    }

    @media screen and (max-width: $breakpointMobile) {
      flex-direction: column;

      &-date-picker {
        margin: 8px 0 0;
        width: 100%;
      }

      &-select {
        width: 100%;
      }
    }
  }

  &__body {
    display: flex;
    justify-content: center;

    @media screen and (max-width: $breakpointMobile) {
      flex-direction: column;
      padding: 14px 16px 16px;
    }
  }

  &__section {
    position: relative;
    padding: 24px 48px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    &-header {
      display: flex;
      margin-bottom: 20px;

      @media screen and (max-width: $breakpointMobile) {
        margin: 0 16px 0 0;
      }
    }

    &-image {
      max-height: 24px;
      margin-right: 12px;
      position: relative;
      bottom: 1px;
    }

    &-title {
      @include heading6;

      display: flex;
      align-items: center;
      font-size: 16px;

      @media screen and (max-width: $breakpointMobile) {
        display: none;
      }
    }

    &-param {
      width: 120px;

      @media screen and (max-width: $breakpointMobile) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }

    &-info {
      display: flex;
      width: 100%;

      @media screen and (max-width: $breakpointMobile) {
        flex-direction: column;
      }
    }

    &-value {
      @include heading5;

      font-family: "Futura PT Bold";
      margin-bottom: 8px;

      @media screen and (max-width: $breakpointMobile) {
        margin-bottom: 0;
      }
    }

    &-text {
      @include caption;

      color: $blue-grey10;
      font-size: 13px;
    }

    &:not(:first-child)::after {
      content: '';
      background: $blue-grey40;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 48px;
      width: 1px;
      margin-bottom: 42px;

      @media screen and (max-width: $breakpointMobile) {
        display: none;
      }
    }

    @media screen and (max-width: $breakpointMobile) {
      padding: 16px 0;
      flex-direction: row;

      &:not(:last-child) {
        border-bottom: 1px solid $blue-grey40;
      }
    }
  }
}
</style>
