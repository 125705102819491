import axios from '@/plugins/axios'

export const state = () => ({
  token: null,
  user: null,
})

export const getters = {
  isAuthorized(state) {
    return !!state.token && !!state.user?.id
  },

  isJustUser({ user }) {
    return user.role === 'user'
  },
}

export const actions = {
  logout({ commit }) {
    commit('setUserData', null)
    commit('setToken', null)
  },

  async fetchUser({ commit }) {
    const token = localStorage.getItem('token')

    try {
      const { data } = await axios.get('/user')

      if (data.data) {
        commit('setUserData', data.data)
        commit('setToken', token)

        this.commit('navigation/setAvailableSections', data.data.role, { root: true })
      }
    } catch (e) {
      console.error(e)
      commit('setToken', null)
    }
  },
}

export const mutations = {
  setToken(state, token) {
    localStorage.setItem('token', token)
    state.token = token
  },

  setUserData(state, payload) {
    state.user = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
