<template lang="pug">
  .steps
    template(v-for="(step, i) in steps")
      .steps__step(:class="{ '--current': i === currentStepIndex, '--completed': i < currentStepIndex }")
        .steps__step-circle
          .steps__step-circle-inner
        .steps__step-title
          | {{ step }}
      .steps__step-line(
        v-if="i < steps.length - 1"
        :class="{ '--completed': i < currentStepIndex }"
        :style="{ width: lineWidth + '%', left: (lineWidth * i) + '%' }"
      )
</template>

<script>
export default {
  name: 'Steps',

  props: {
    steps: {
      type: Array,
      required: true,
    },

    currentStepIndex: {
      type: Number,
      required: true,
    },
  },

  computed: {
    lineWidth() {
      return 100 / (this.steps.length - 1)
    },
  },
}
</script>

<style lang="scss">
.steps {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  position: relative;

  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    &-circle {
      background: $blue-grey70;
      padding: 0 16px;
      z-index: 1;

      &-inner {
        border: 2px solid $blue-grey30;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: all 0.2s $easing;
        position: relative;

        .--current &,
        .--completed & {
          border: 2px solid $primary60;
        }

        &::before {
          transform: scale(0, 0);
          content: '';
          position: absolute;
          bottom: 0;
          left: 3px;
          right: 0;
          top: 3px;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background: $primary60;
          transition: transform 0.2s $easing;
        }

        .--completed &::before {
          transform: scale(1, 1);
        }
      }
    }

    &:first-child {
      .steps__step-circle {
        width: calc(50% + 26px);
        display: flex;
        justify-content: flex-end;
        align-self: flex-start;
      }
    }

    &:last-child {
      .steps__step-circle {
        width: calc(50% + 26px);
        display: flex;
        justify-content: flex-start;
        align-self: flex-end;
      }
    }

    &-title {
      @include text-display120;

      text-align: center;
      color: $blue-grey10;
      transition: color 0.2s $easing;
      margin-top: 12px;

      .--current & {
        color: $white;
      }

      @media (max-width: $breakpointMobile) {
        display: none;
      }
    }

    &-line {
      height: 1px;
      background: $blue-grey30;
      transition: background 0.2s $easing;
      position: absolute;
      top: 10px;

      &.--completed {
        background: $primary60;
      }
    }
  }
}
</style>
