<template lang="pug">
  .pubg-account.pubg-desktop-accaunt
    .modal__header Аккаунт PUBG Desktop
    form(@submit.prevent="onSubmit")
      .pubg-account__body
        template(v-for="(input, index) in items")
          FormInput(
            v-if="input.type === 'text'"
            :key="index"
            v-bind="input"
            v-model="input.value"
            @input="onFieldInput(index, input)"
            @blur="onFieldBlur(index, input)"
          )

      .modal__footer
        transition(name="fade" duration="200")
          InfoMessage(v-if="errorMessage" type="error")
            | {{ errorMessage }}
        Button(is-primary :is-disabled="isSubmitDisabled" type="submit") Привязать

</template>

<script>
import PubgMobileAccount from '@/components/PubgMobileAccount/PubgMobileAccount'

export default {
  name: 'PubgDesktopAccount',

  extends: PubgMobileAccount,

  props: {
    playerId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      errorMessage: '',

      items: {
        username: {
          type: 'text',
          value: '',
          title: 'Никнейм в игре',
          placeholder: 'Никнейм в игре',
          isRequired: true,
          error: '',
        },
      },
    }
  },

  computed: {
    isSubmitDisabled() {
      return !this.items.username.value?.length
    }
  },

  methods: {
    async onSubmit() {
      this.errorMessage = ''

      Object.keys(this.fields).forEach((key) => this.onFieldBlur(key))

      if (this.isSubmitDisabled) {
        return
      }

      this.$loading.start()

      try {
        await this.$axios.post('/games/pubg-desktop/account/admin/link', {
          id: this.items.username.value,
          username: this.items.username.value,
          userId: this.playerId,
        })

        this.toggle(false)
      } catch (e) {
        this.errorMessage = e
        console.error('pubg desktop account error', e)
      }

      this.$loading.finish()
    },
  },
}
</script>

<style lang="scss">
.modal.modal--pubgdesktopaccount {
  padding-bottom: 0;
  max-width: 614px;
}
</style>
