<template lang="pug">
  router-link.duel-card(
    :class="{ '--list': isList}"
    :to="`/pubg-mobile/events/duels/${duel.password}`"
  )
    .duel-card__wrap.duel-card__wrap--card
      .duel-card__item-info
        .duel-card__item-avatar-section
          .duel-card__item-avatar-wrap(class="--template")
            img(:src="duel.account.avatar")

        .duel-card__duel-info
          .duel-card__user-wrap
            .duel-card__duel-name {{ duel.title }}

            .duel-card__duel-id {{ `#${duel.id}` }}
      .duel-card__params
        .duel-card__param
          .duel-card__params-title Дата
          .duel-card__pr-fund
            .duel-card__param-val {{ date }}

        .duel-card__param
          .duel-card__params-title Ранг
          .duel-card__rating
            InlineSvg.duel-card__param-icon(:src="require('@/assets/img/icons/shape.svg')")
            span.duel-card__param-val
              template Ранг

        .duel-card__param
          .duel-card__params-title Призовой фонд
          .duel-card__pr-fund
            InlineSvg.duel-card__param-icon(:src="require('@/assets/img/icons/coins.svg')")
            .duel-card__param-val {{ prizePool }}
</template>

<script>
import PlayerGameName from '@/components/PlayerGameName/PlayerGameName'
import PlayerRank from '@/components/PlayerRank/PlayerRank'

export default {
  name: 'DuelCard',

  components: {
    PlayerRank,
    PlayerGameName,
  },

  props: {
    duel: {
      type: Object,
      required: true,
    },

    view: {
      type: String,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    isList() {
      return this.view === 'list'
    },

    account() {
      return this.duel?.account || {}
    },

    date() {
      return this.$moment.format(this.duel.date, 'lll')
    },

    config() {
      return this.duel.extra_data?.config
    },

    dateText() {
      const date = this.parsedDate

      if (this.$dateFns.isYesterday(date)) {
        return `Вчера, ${this.$dateFns.format(date, 'HH:mm')}`
      }

      if (this.$dateFns.isToday(date)) {
        return `Сегодня, ${this.$dateFns.format(date, 'HH:mm')}`
      }

      if (this.$dateFns.isTomorrow(date)) {
        return `Завтра, ${this.$dateFns.format(date, 'HH:mm')}`
      }

      return this.$dateFns.format(date, 'd MMMM, HH:mm')
    },

    prizePool() {
      if (this.duel?.currency === 'coins') {
        return this.duel?.prize_pool
      } else {
        return this.duel?.prize_pool + ' €'
      }
    }
  },

  methods: {},
}
</script>

<style lang="scss">
.duel-card {
  width: 100%;
  transition: box-shadow 0.2s $easing;

  &__wrap {
    .--list & {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: $breakpointDesktopWide) {
        flex-direction: column;
      }
    }
  }

  &__wrap--card {
    background: $blue-grey60;
    border-radius: 8px;
    cursor: pointer;
  }

  &__item-info {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    position: relative;
    border-bottom: 1px solid $blue-grey40;

    .--list & {
      width: calc(100%);
      padding: 0;
      border: none;

      @media (max-width: $breakpointDesktopWide) {
        width: auto;
        padding: 16px 24px;
        border-bottom: 1px solid #334153;
      }
    }
  }

  &__item-avatar-section {
    position: relative;

    .--list & {
      padding: 28px 32px;
      border-right: 1px solid $blue-grey50;

      @media (max-width: $breakpointDesktopWide) {
        border: 0;
        padding: 0;
      }
    }
  }

  &__item-avatar-wrap {
    position: relative;
    width: 56px;
    height: 56px;
    overflow: hidden;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.--template {
      border: 1px dashed $grey20;
    }

    img,
    svg {
      object-fit: cover;
    }
  }

  &__item-dot-wrap {
    position: absolute;
    width: 16px;
    height: 16px;
    background: $blue-grey70;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    @include flex-center;

    .--list & {
      width: 21px;
      height: 21px;
      right: 2px;
      bottom: 2px;
    }
  }

  &__item-dot {
    width: 8px;
    height: 8px;
    background: $green50;
    border-radius: 50%;

    .--list & {
      width: 13px;
      height: 13px;
    }
  }

  &__duel-info {
    display: flex;
  }

  &__user-wrap {
    //margin-top: 3px;
    margin-left: 16px;
  }

  &__duel-name {
    @include heading4;

    color: $white;
  }

  &__player-nick {
    @include heading6;

    margin-top: 8px;
    color: $primary60;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    a {
      text-decoration: underline;
    }
  }

  &__dot {
    display: block;
    line-height: 50%;
    margin: 0 5px;
    margin-top: -9px;
  }

  &__game-name {
    position: relative;
    color: $blue-grey10;
    margin-right: 5px;
  }

  &__params {
    display: flex;
    padding: 0 24px;

    .--list & {
      padding: 0;

      @media (max-width: $breakpointDesktopWide) {
        padding: 24px 0;
      }
    }
  }

  &__params-title {
    @include caption-small;

    color: $blue-grey20;
    margin-bottom: 12px;
  }

  &__item-date {
    @include heading4;

    color: $white;
  }

  &__param {
    padding: 24px 0;
    white-space: nowrap;
    margin-right: 48px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $breakpointMobile) {
      margin-right: 32px;

      &:not(:last-child) {
        padding-bottom: 0;
      }
    }

    .--list & {
      border-right: 1px solid $blue-grey50;
      margin-right: 0;
      padding: 0 29px;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-width: 185px;

      &:last-child {
        border-right: none;
      }

      &:first-child {
        border-left: 1px solid $blue-grey50;

        @media (max-width: $breakpointDesktopWide) {
          border-left: none;
        }
      }
    }
  }

  &__rating {
    background: $amber60-10;
    border-radius: 34px;
    color: $amber60;
    display: flex;
    padding: 4px 0 4px 12px;
    width: fit-content;
    height: 32px;
    align-items: center;

    > span {
      display: flex;
      align-items: center;
      line-height: 20px;
    }

    .player-rank > span {
      margin: 0;
    }
  }

  &__map {
    background: $green60-10;
    border-radius: 34px;
    color: $green60;
    display: flex;
    padding: 4px 0;
    width: fit-content;
  }

  &__time {
    background: $red60-10;
    border-radius: 34px;
    color: $red60;
    display: flex;
    padding: 4px 0;
    width: fit-content;
  }

  &__pr-fund {
    background: $cyan60-10;
    border-radius: 34px;
    color: $cyan60;
    display: flex;
    padding: 4px 0 4px 12px;
    width: fit-content;
  }

  &__param-ico-wrap {
    @include flex-center;

    width: 24px;
    height: 24px;
    margin: 0 8px 0 12px;
  }

  &__param-icon {
    margin-right: 8px;
  }

  &__param-val {
    margin-right: 12px;
  }

  &__duel-id {
    @include text-display100;

    color: $blue-grey20;
    position: absolute;
    right: 24px;
    top: 35px;

    .--list & {
      display: none;
    }

    @media (max-width: $breakpointMobile) {
      display: none;
    }
  }

  &__follow-btn {
    margin: auto 0 auto auto;

    .--list & {
      padding: 32px 8px 32px 32px;
    }

    button {
      background: transparent;
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
    }
  }

  @media (max-width: 1530px) {
    &__item-create-date-wrap {
      padding-right: 16px;
    }
    &__rating-wrap {
      padding: 24px 16px;
    }
    &__pr-fund-wrap {
      padding: 24px 16px;
    }
  }

  @media (max-width: $breakpointDesktopWide) {
    flex-direction: column;

    &__item-avatar-section {
      border: 0;
      padding: 0;
    }

    &__item-info {
      padding: 16px 24px;
      border-bottom: 1px solid #334153;
    }

    &__item-create-date-wrap {
      padding: 0;
      border: 0;
      padding-right: 16px;
    }
  }

  @media (max-width: $breakpointMobile) {
    min-width: 312px;
    width: 312px;
    align-self: stretch;

    &__wrap {
      height: 100%;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &__params {
      padding: 0 16px;
      display: flex;
      flex-wrap: wrap;
    }

    &__player-nick {
      span:first-child {
        width: 100%;
      }
    }

    &__dot {
      display: none;
    }

    &__ingame-nick {
      margin-top: 8px;
    }

    &__game-name {
      margin-top: 8px;
      margin-bottom: 0;
    }

    &__duel-name {
      margin-bottom: 8px;
    }

    &__item-create-date-wrap {
      width: 100%;
    }
  }

  &:hover {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 10px 1px rgba(0, 0, 0, 0.14), 0 1px 14px 2px rgba(0, 0, 0, 0.12);
  }
}
</style>
