import { render, staticRenderFns } from "./Ban.vue?vue&type=template&id=5ca4d8f3&lang=pug"
import script from "./Ban.vue?vue&type=script&lang=js"
export * from "./Ban.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports