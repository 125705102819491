import store from '../store'

const loading = {
  start() {
    store.commit('setLoadingStatus', true)
  },

  finish() {
    store.commit('setLoadingStatus', false)
  },
}

export default loading