<template lang="pug">
  .edit-members
    SectionHeader(:header="header")

    .action__body
      .action__section
        .action__grid
          .action__form-field(v-for="(item, i) of items" :key="i")
            InputSmart(:item="item")

          .action__form-field(v-if="isAdd")
            InputSmart(:item="eventPassword")

      .action__bottom
        Button(is-primary @click="onCheckButtonClick") Изменить

</template>

<script>
import { mapActions, mapState } from 'vuex'
import InputSmart from '@/components/InputSmart/InputSmart'
import SectionHeader from '@/components/SectionHeader/SectionHeader'

export default {
  name: 'EditMembers',

  components: {
    InputSmart,
    SectionHeader,
  },

  data: () => ({
    items: {
      eventType: {
        title: 'Событие',
        value: 'tournaments',
        type: 'select',
        options: [
          { title: 'Турнир', value: 'tournaments' },
          { title: 'Мега-турнир', value: 'mega-tournaments' },
        ],
        withEmptyValue: false,
      },

      eventId: {
        title: 'ID события',
        value: null,
        type: 'number',
        placeholder: 'Введите ID',
      },

      actionType: {
        title: 'Действие',
        value: true,
        type: 'select',
        options: [
          { title: 'Добавить в турнир', value: true },
          { title: 'Удалить из турнира', value: false },
        ],
        withEmptyValue: false,
      },

      playerId: {
        title: 'ID игроков через запятую',
        value: null,
        type: 'text',
        placeholder: 'Максимум 50',
      },
    },

    eventPassword: {
      title: 'Пароль от события',
      value: null,
      type: 'text',
      placeholder: 'Если турнир приватный',
    },
  }),

  computed: {
    ...mapState('games', ['gamesByName']),

    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Изменение участников турнира`,
        game: this.gameName,
      }
    },

    isAdd() {
      return this.items.actionType.value
    },
  },

  created() {
    this.$forceUpdate()
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onCheckButtonClick() {
      this.$loading.start()

      const playerIds = this.items.playerId.value?.split(',').map(player => player.trim())

      if (playerIds.length > 50) {
        return this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'error',
            title: 'Ошибка',
            text: 'Максимум 50 игроков',
          },
        })
      }

      const eventType = this.items.eventType.value
      const eventId = this.items.eventId.value
      const actionType = this.items.actionType.value ? 'join' : 'leave'
      const payload = { entry_method: 'balance', private_password: this.eventPassword.value }

      try {
        for (const playerId of playerIds) {
          const url = `/admin/games/${this.gameName}/${eventType}/${eventId}/${actionType}/${playerId}`
          await this.$axios.post(url, payload)
        }

        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            title: 'Успех',
            text: 'Все прошло без ошибок!',
          },
        })
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    }
  },
}
</script>

<style lang="scss">
</style>
