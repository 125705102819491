<template lang="pug">
  .moderation-duel
    SectionHeader(:header="header")

    .moderation-duel__body
      .moderation-duel__wrapper
        .moderation-duel__players
          .moderation-duel__players-wrapper
            .moderation-duel__players-left
              .moderation-duel__players-left-nickname {{ accountGameUsername || 'Нет аккаунта' }}

              .moderation-duel__players-left-selected(v-if="activePlayer === accountGameUsername" @click="onPlayerClick('')")
                InlineSvg(:src="require('@/assets/img/icons/double-check.svg')")
              img.moderation-duel__players-left-image(v-else @click="onPlayerClick(accountGameUsername)" :src="accountAvatar")

            .moderation-duel__players-center
              .moderation-duel__players-center-text VS

            .moderation-duel__players-right
              .moderation-duel__players-left-selected(v-if="activePlayer === opponentGameUsername" @click="onPlayerClick('')")
                InlineSvg(:src="require('@/assets/img/icons/double-check.svg')")
              img.moderation-duel__players-right-image(v-else @click="onPlayerClick(opponentGameUsername)" :src="opponentAvatar")

              .moderation-duel__players-right-nickname {{ opponentGameUsername || 'Нет аккаунта' }}

        .moderation-duel__image(v-if="winnersImage")
          img(:src="'data:image/jpeg;base64,' + winnersImage")
        .action__empty(v-else)
          | Картинки нет

    .section__footer
      Button(is-primary @click="onConfirmDuelClick" :is-disabled="!canConfirm") Завершить модерацию

</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader'

export default {
  name: 'ActionModerationDuel',

  components: { SectionHeader },

  data: () => ({
    duel: {},
    activePlayer: '',
  }),

  computed: {
    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: this.duel.title,
        game: this.gameName,
        back: `/${this.gameName}/moderation`,
        description: [
          {
            text: '#' + this.duelId,
            link: `${process.env.VUE_APP_DOMAIN}/${this.gameName}/duels/${this.duel?.password}`
          },
          { text: this.duelDate }
        ],
      }
    },

    duelPassword() {
      return this.$route.params.password
    },

    duelDate() {
      return this.$moment.format(this.duel.date, 'lll')
    },

    duelId() {
      return this.duel?.id
    },

    account() {
      return this.duel?.account
    },

    opponent() {
      return this.duel?.opponent
    },

    accountGameUsername() {
      return this.account?.game_username
    },

    opponentGameUsername() {
      return this.opponent?.game_username
    },

    accountAvatar() {
      return this.account?.avatar
    },

    opponentAvatar() {
      return this.opponent?.avatar
    },

    winnersImage() {
      const base64 = this.duel.extra_data?.game?.game?.winners_image

      return base64
    },

    canConfirm() {
      return this.activePlayer
    }
  },

  created() {
    this.initDuel()
  },

  methods: {
    async initDuel() {
      this.$loading.start()

      try {
        const { data } = await this.$axios.get(`/games/pubg-mobile/duels/${this.duelPassword}`)

        this.duel = data.data
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    onDuelLinkClick() {
      window.open(`${process.env.VUE_APP_DOMAIN}/pubg-mobile/duels/${this.duelPassword}`)
    },

    onBackButtonClick() {
      this.$router.push('/pubg-mobile/moderation')
    },

    onPlayerClick(username) {
      if (username === undefined) {
        return
      }

      this.activePlayer = username
    },

    async onConfirmDuelClick() {
      this.$loading.start()

      try {
        const winners = []
        const platformUsername =
          this.activePlayer === this.account.game_username ? this.account.full_name : this.opponent.full_name

        winners.push({
          platform_username: platformUsername,
          username: this.activePlayer,
          points: 40,
        })

        const { data } = await this.$axios.post(
          `/admin/games/pubg-mobile/duels/${this.duelId}/moderation`,
          winners
        )

        if (data.data) {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'success',
              title: 'Успех',
              text: 'Данные о дуэли успешно отправлены!',
            },
          })
        }
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/include/participiants.scss';

.moderation-duel {
  &__body {
    padding: 0 32px 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60vh;
    margin-bottom: 50px;
  }

  &__wrapper {
    padding-top: 24px;
  }



  &__image img {
    border-radius: 24px;
    //max-width: 760px;
    width: 100%;
  }

  .section__header {
    margin-bottom: 22px;
  }
}
</style>
