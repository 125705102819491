<template lang="pug">
  .edit-player
    .modal__header Победитель «{{ winner.name }}»

    .edit-player__body
      InputSmart(
        v-for="item in fields"
        :key="JSON.stringify(item)"
        :item="item"
        v-model="item.value"
        :type="item.type"
        class="edit-player__input"
      )

    .modal__footer
      Button(is-primary @click="onAction") Сохранить

</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import InputSmart from '@/components/InputSmart/InputSmart'

export default {
  name: 'WinnerEdit',

  components: {InputSmart},

  props: {
    winner: {
      type: Object,
      required: false,
    }
  },

  computed: {
    ...mapGetters('moderation', ['winners']),

    fields() {
      return {
        place: {
          title: 'Место',
          type: 'number',
          value: this.winner.place,
        },

        points: {
          title: 'Очки',
          type: 'number',
          value: this.winner.points,
        },
      }
    },
  },

  methods: {
    ...mapMutations('moderation', ['setWinner']),
    ...mapMutations('modal', ['toggle']),

    onAction() {
      this.setWinner({
        ...this.winner,
        name: this.winner.name,
        place: this.fields.place.value,
        points: this.fields.points.value,
      })

      this.toggle()
    },
  },
}
</script>

<style lang="scss">
.modal.modal--winneredit {
  padding-bottom: 0;
  max-width: 532px;

  .modal__footer {
    display: flex;
    justify-content: flex-end;
  }

  .info-message {
    margin: 24px 0;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .button + .button {
    margin-left: 16px;
  }
}

.edit-player {
  &__body {
    margin: 36px 0;
  }

  &__input:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
