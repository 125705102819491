<template lang="pug">
.action-marketplace
  SectionHeader(:header="header")

  .action__body
    .action__section
      .marketplace__ordersList(v-if="orders")
        .marketplace__order(v-for="order in orders" :key="order.id")
          OrderCard(:order="order")

  .pagination
    Pagination(:pagesCount="pagesCount" :perPage="pageSize" :currentPage="page" @pageChange="setPage" @perPageChange="setPerPage")
</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import Pagination from '@/components/Marketplace/Pagination/Pagination'
import OrderCard from '@/components/Marketplace/OrderCard'

export default {
  name: 'Marketplace',

  components: {
    OrderCard,
    SectionHeader,
    Pagination,
  },

  data () {
    return {
      page: 0,
      pageSize: 12,
      orders: undefined,
      ordersAmount: 0
    }
  },

  computed: {
    header() {
      return {
        title: 'Заказы'
      }
    },

    pagesCount () {
      return Math.ceil((this.ordersAmount || 0) / this.pageSize)
    }
  },

  mounted () {
    this.getOrders()
  },

  methods: {
    async getOrders () {
      this.$loading.start()

      try {
        const { data } = await this.$axios.get(
            '/marketplace/orders-list',
            {
              params: {
                page: this.page + 1,
                size: this.pageSize,
                orderBy: 'id',
                orderByDirection: 'DESC'
              },
            }
        )

        this.orders = data.data.orders
        this.ordersAmount = data.data.count
      } catch (e) {
        alert('Ошибка получения списка заказов')
      }

      this.$loading.finish()
    },

    setPage (pageNumber) {
      this.page = pageNumber
      this.getOrders()
    },

    setPerPage (perPage) {
      this.pageSize = perPage
      this.setPage(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.marketplace {
  &__order {
    height: 100%;
  }

  &__ordersList {
    color: $white;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    align-items: center;

    @media (min-width: $breakpointTablet) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
