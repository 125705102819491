<template lang="pug">
  .link-account
    SectionHeader(:header="header")

    .action__body
      .action__section
        .action__grid
          .action__form-field(v-for="(field, i) of fields" :key="i")
            InputSmart(:item="field")

      .action__bottom
        Button(is-primary :is-disabled="isButtonDisabled" @click="onLink")
          | Привязать

</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import InputSmart from '@/components/InputSmart/InputSmart'
import { mapActions } from 'vuex'

export default {
  name: 'LinkAccount',

  components: {
    SectionHeader,
    InputSmart,
  },

  data() {
    return {
      fields: {
        playerId: {
          title: 'ID игрока',
          value: null,
          type: 'number',
          placeholder: 'Введите ID',
        },
      },
    }
  },

  computed: {
    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Привязка аккаунта`,
        game: this.gameName,
      }
    },

    isButtonDisabled() {
      return !this.fields.playerId.value
    },
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onLink() {
      switch (this.gameName) {
        case 'lichess':
          try {
            const { data } = await this.$axios.get('/games/lichess/oauth/url')

            if (data.data) {
              window.location.href = data.data
            }
          } catch (e) {
            this.$error.handle(e)
          }

          break

        case 'pubg-mobile':
          this.showModal({
            component: 'PubgMobileAccount',
            data: { playerId: this.fields.playerId.value }
          })

          break

        case 'pubg-desktop':
          this.showModal({
            component: 'PubgDesktopAccount',
            data: { playerId: this.fields.playerId.value }
          })

          break

        case 'clash-royale':
          this.showModal({
            component: 'ClashRoyaleAccount',
            data: { playerId: this.fields.playerId.value }
          })

          break

        case 'brawl-stars':
          this.showModal({
            component: 'BrawlStarsAccount',
            data: { playerId: this.fields.playerId.value }
          })

          break

        default:
          this.showModal({
            component: 'PubgMobileAccount',
            data: {
              playerId: this.fields.playerId.value,
              gameName: this.gameName,
            }
          })

          break
      }
    },
  },
}
</script>

<style lang="scss">
</style>
