import numeral from 'numeral'
import { mapActions, mapGetters, mapState } from 'vuex'
import { EVENT_STATUSES, TEAM_COMP_STATUSES } from '@/utils/constants'
import { isUserAdult } from '@/utils/utils'
// import rules from '@/utils/rules'

export default {
  data() {
    return {
      isJoiningRequestLoading: false,
      addedFriendTournamentId: 0,
    }
  },

  computed: {
    ...mapState('games', ['gamesById', 'gamesByName']),
    ...mapState('user', ['user', 'verification']),
    ...mapGetters('user', [
      'isAnticheatEnabled',
      'gameAccount',
      'isAuthorized',
    ]),

    commission() {
      const total = this.entryFee

      return Math.ceil((total / 100) * this.tournament.prize_settings.rake)
    },

    entryFeeMinusCommission() {
      const total = this.entryFee

      return total - this.commission
    },

    entryFee() {
      let result
      if (this.isPaid) {
        result = Math.ceil(this.tournament.entry_fee / 100)
      } else {
        result = this.tournament.entry_fee
      }

      return result
    },

    canSendReport() {
      return this.game.name === 'lichess'
    },

    joiningRequirements() {
      if (!this.user) {
        return null
      }

      if (!this.hasAccess && this.isPaid && this.tournament.state === 'open') {
        if (
          !this.verification ||
          this.user.verification_status === 'not_verified'
        ) {
          return {
            title: 'Необходимо указать контактную информацию',
            text:
              'Заполнение контактной информации позволит играть в турнирах на деньги и выводить их с платформы',
            button: {
              title: 'Заполнить данные',
              action: () => {
                this.$router.push('/profile/personal-data')
              },
            },
          }
        }

        if (!isUserAdult(this.verification, this.$dateFns)) {
          return {
            title: 'Для участия в этом турнире вы должны быть старше 16 лет',
            text: '',
            button: {
              title: 'Найти другой турнир',
              action: () => {
                this.$router.push(
                  this.$route.path.split('/').slice(0, -1).join('/')
                )
              },
            },
          }
        }
      }

      return null
    },

    game() {
      if (this.$route.params.game) {
        return this.gamesByName[this.$route.params.game]
      }

      return this.gamesById[this.tournament?.game_id]
    },

    maxPlayersCount() {
      if (isNaN(parseInt(this.tournament.max_players_count))) {
        return '∞'
      }

      return this.tournament.max_players_count - this.tournament.players?.length
    },

    isLichessTeamRequired() {
      return this.isLichess && this.lichessTeam
    },

    isPubg() {
      return this.game.name === 'pubg-mobile'
    },

    isLichess() {
      return this.game.name === 'lichess'
    },

    isClashRoyale() {
      return this.game.name === 'clash-royale'
    },

    isBrawlStars() {
      return this.game.name === 'brawl-stars'
    },

    lichessTeam() {
      return this.tournament?.extra_data?.config?.conditions?.teamMember?.teamId
    },

    webcamLink() {
      return this.tournament?.extra_data?.webcam?.link
    },

    statusText() {
      if (this.$route.name === 'game-team-competitions-id') {
        return `Соревнование ${TEAM_COMP_STATUSES[
          this.tournament.state
          ].toLowerCase()}`
      }
      return `Турнир ${EVENT_STATUSES[this.tournament.state].toLowerCase()}`
    },

    isCounterVisible() {
      return this.$dateFns.differenceInDays(this.startsAt, new Date()) < 1
    },

    isPhased() {
      return this.$route.name.includes('phased-tournaments')
    },

    statusClass() {
      if (!this.tournaments) {
        return
      }

      const state = this.tournament.state

      return {
        'page-head__entry-state--opened': state === 'open',
        'page-head__entry-state--closed': ['completed', 'cancelled'].includes(
          state
        ),
        'page-head__entry-state--started': state === 'in_progress',
      }
    },

    timeText() {
      const date = this.startsAt

      return this.$dateFns.format(date, 'HH:mm')
    },

    dateText() {
      const date = this.startsAt

      const time = this.$dateFns.format(date, 'HH:mm')

      if (this.$dateFns.isYesterday(date)) {
        return `Вчера, ${time}`
      }

      if (this.$dateFns.isToday(date)) {
        return `Сегодня, ${time}`
      }

      if (this.$dateFns.isTomorrow(date)) {
        return `Завтра, ${time}`
      }

      return this.$dateFns.format(date, 'd MMMM, HH:mm')
    },

    endDateText() {
      const date = this.endsAt
      const time = this.$dateFns.format(date, 'HH:mm')

      if (this.$dateFns.isYesterday(date)) {
        return `Вчера, ${time}`
      }

      if (this.$dateFns.isToday(date)) {
        return `Сегодня, ${time}`
      }

      if (this.$dateFns.isTomorrow(date)) {
        return `Завтра, ${time}`
      }

      return this.$dateFns.format(date, 'd MMMM, HH:mm')
    },

    startsAt() {
      const date = this.tournament.date.replace(' ', 'T') + 'Z'

      return this.$dateFns.parseISO(date)
    },

    endsAt() {
      const date = this.tournament.ends_at.replace(' ', 'T') + 'Z'

      return this.$dateFns.parseISO(date)
    },

    hasAccess() {
      if (this.isTeamCompetition) {
        return !!this.teams?.find((team) => team.id === this.user?.team_id)
      }

      return (
        this.user?.id &&
        this.tournament?.players?.some((player) => player.id === this.user?.id)
      )
    },

    hasAnticheat() {
      if (!this.tournament.is_anticheat_required) {
        return true
      }

      return !this.$device.isWindows || this.isAnticheatEnabled
    },

    canJoinTournament() {
      const LATE_JOIN_MINUTES_AFTER_TOURNAMENT_START = 15
      const BRAWL_MINUTES_BEFORE_TOURNAMENT_START_CLOSE = -10
      const BRAWL_MINUTES_BEFORE_TOURNAMENT_START_OPEN = -120
      const PHASED_MINUTES_BEFORE_TOURNAMENT_START_CLOSE = -15

      const diff = this.$dateFns.differenceInMinutes(new Date(), this.startsAt)

      if (this.$route.name === 'game-phased-tournaments-id') {
        return (
          diff < PHASED_MINUTES_BEFORE_TOURNAMENT_START_CLOSE &&
          this.tournament.state === 'open'
        )
      } else if (this.game.name === 'brawl-stars') {
        return (
          diff < BRAWL_MINUTES_BEFORE_TOURNAMENT_START_CLOSE &&
          diff > BRAWL_MINUTES_BEFORE_TOURNAMENT_START_OPEN &&
          this.tournament.state === 'open' &&
          !this.isTournamentFull
        )
      } else if (['lichess', 'brawl-stars'].includes(this.game.name)) {
        return (
          diff < LATE_JOIN_MINUTES_AFTER_TOURNAMENT_START &&
          ['open', 'in_progress'].includes(this.tournament.state) &&
          !this.isTournamentFull
        )
      } else {
        return this.tournament.state === 'open' && !this.isTournamentFull
      }
    },

    headWarning() {
      let warning = null

      if (this.tournament?.state === 'open') {
        if (this.hasAccess) {
          if (
            this.isPubg &&
            this.tournament.extra_data?.config?.map !== 'Эрангель'
          ) {
            warning = `Матч пройдет на карте "${this.tournament.extra_data?.config?.map}", обновите ее до последней версии, в противном случае вы не сможете участвовать в турнире`
          }
        } else if (this.$route.name === 'game-phased-tournaments-id') {
          warning = 'Регистрация в турнир закрывается за 15 минут до начала'
        } else if (this.isTournamentFull) {
          warning =
            'Максимальное число участников. Запись откроется, если кто-то покинет турнир'
        } else if (this.isBrawlStars) {
          warning =
            'Регистрация в турнир открывается за 2 часа до начала турнира и закрывается за 10 минут до начала'
        }
      }

      return warning
        ? {
          text: warning,
        }
        : null
    },

    isTournamentFull() {
      return (
        this.tournament?.players_count >= this.tournament?.max_players_count
      )
    },

    isPaid() {
      return this.tournament?.prize_settings?.entry_fee_currency === 'money'
    },

    maxPrizePool() {
      return (
        Math.floor(
          this.entryFee -
          (this.entryFee / 100) * this.tournament?.prize_settings?.rake
        ) * this.tournament?.max_players_count
      )
    },

    hasAddedGameFriend() {
      return this.addedFriendTournamentId === this.tournament.id
    },

    isDoublePrize() {
      const prize = this.tournament?.prize_settings

      if (!prize) {
        return false
      }

      return prize.prize_distribution_type === 'double'
    },

    doublePrize() {
      if (!this.isDoublePrize) {
        return null
      }

      return Math.floor(
        (this.entryFee -
          (this.entryFee / 100) * this.tournament?.prize_settings?.rake) *
        2
      )
    },

    bannedPlayersList() {
      if (this.tournament?.state !== 'completed') {
        return null
      }

      return this.tournament.players.filter((player) => {
        return (
          (player.is_on_moderation ||
            player.ban_reason ||
            player.is_prize_removed) &&
          !player.place
        )
      })
    },

    withRewardsPlaces() {
      return (
        !this.tournament.prize_settings?.place_distribution_type ||
        ['mixed', 'place'].includes(
          this.tournament.prize_settings.place_distribution_type
        )
      )
    },

    withRewardsPoints() {
      return ['mixed', 'point'].includes(
        this.tournament.prize_settings.place_distribution_type
      )
    },

    isFreeroll() {
      return (
        this.tournament.prize_settings?.prize_currency === 'money' &&
        this.tournament.prize_settings?.entry_fee_currency === 'coins'
      )
    },
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    formatPrice(number) {
      return numeral(number).format('0,0').replace(/,/g, ' ')
    },

    getRankText(eventName = 'tournament') {
      return this[eventName].rating_group
    },

    onWebcamClick() {
      window.open(this.webcamLink)
    },

    onCreateDuelClick() {
      if (!this.isAuthorized) {
        this.showModal({
          component: 'Auth',
          data: {
            mode: 'login',
            afterLogin: () => {
              this.onCreateDuelClick()
            },
          },
        })

        return
      }

      this.showModal({
        component: 'ModalCheckRequirements',
        data: {
          duel: { state: 'create' },
          onSuccess: () => {
            this.showModal({ component: 'CreateDuel' })
          },
        },
        shouldShow: false,
      })
    },

    async joinTournamentRequest(tournamentType, method = 'balance') {
      if (this.isJoiningRequestLoading) {
        return
      }
      this.isJoiningRequestLoading = true

      this.$nuxt.$loading.start()

      try {
        const { data } = await this.$axios.post(
          `/games/${this.game.name}/${tournamentType}/${this.tournament.id}/join`,
          {
            entryMethod: method,
          }
        )

        this.showModal({
          component: 'EnterTournamentSuccess',
          data: {
            tournament: this.tournament,
            isPhasedTournament: tournamentType === 'mega-tournaments',
          },
        })

        this.sendAnalytics(data.data)
      } catch (e) {
        console.error('tournament join error', e)

        this.handleJoiningError(
          e.response?.data?.message || 'Что-то пошло не так'
        )

        this.$ga.offlineEvent({
          event: 'tournament_join_fail',
          cd3: this.game.name,
          cd4: this.tournament.title,
          cd5: this.isPaid ? 'paid' : 'freeroll',
          cd7: e.response?.data?.message,
        })
      }

      this.$nuxt.$loading.finish()
      this.isJoiningRequestLoading = false
    },

    joinTournament(tournamentType = 'tournaments') {
      if (!this.user) {
        this.showModal({
          component: 'Auth',
          data: {
            afterLogin: () => {
              this.joinTournament()
            },
          },
        })

        return
      }

      this.showModal({
        component: 'ModalCheckRequirements',
        data: {
          tournament: this.tournament,
          onSuccess: () => {
            if (this.isPaid) {
              this.showModal({
                component: 'ModalPayForParticipation',
                data: {
                  title: 'Оплата вступления в турнир',
                  text: 'участие в турнире',
                  tournament: this.tournament,
                  afterPay: (method = 'balance') => {
                    this.joinTournamentRequest(tournamentType, method)
                  },
                },
              })
            } else {
              this.joinTournamentRequest(tournamentType)
            }
          },
        },
        shouldShow: false,
      })

      // this.showModal({
      //   component: 'ModalPayForParticipation',
      //   data: {
      //     title: 'Оплата вступления в турнир',
      //     text: 'участие в турнире',
      //     tournament: this.tournament,
      //     afterPay: (method = 'balance') => {
      //       this.joinTournamentRequest(tournamentType, method)
      //     },
      //   },
      // })
      //
      // if (this.isPaid) {
      //   this.showModal({
      //     component: 'ModalPayForParticipation',
      //     data: {
      //       title: 'Оплата вступления в турнир',
      //       text: 'участие в турнире',
      //       tournament: this.tournament,
      //       afterPay: (method = 'balance') => {
      //         this.joinTournamentRequest(tournamentType, method)
      //       },
      //     },
      //   })
      // } else {
      //   this.joinTournamentRequest(tournamentType)
      // }
    },

    handleJoiningError(message) {
      let text = message
      let buttons

      if (message.startsWith('Рейтинговая группа турнира')) {
        buttons = [
          {
            title: 'Посмотреть подходящие',
            action: () => {
              this.$router.push(
                `/${this.game.name}/tournaments?rating_group=${
                  this.gameAccount(this.game.name).extra_data?.rating_group ||
                  'any'
                }`
              )
            },
          },
        ]
      } else if (message.startsWith('Вы должны сыграть минимум')) {
        const ratedCount =
          this.user.game_accounts.find((acc) => acc.game === this.game.name)
            ?.extra_data?.data?.perfs?.blitz?.games || 0
        const ratedNeededCount = this.tournament.extra_data.config.conditions
          ? this.tournament.extra_data.config.conditions.nbRatedGame.nb
          : this.tournament.extra_data.config['conditions.nbRatedGame.nb']

        text = `Вам необходимо сыграть ещё ${
          ratedNeededCount - ratedCount
        } рейтинговых партий в Blitz на Lichess, чтобы присоединиться к турниру!`
      } else if (message === 'Пользователь не найден') {
        text = 'Не привязан игровой аккаунт'
        buttons = [
          {
            title: 'Привязать',
            action: () => {
              this.$router.push('/profile/game-accounts')
            },
          },
        ]
      } else if (message === 'Недостаточно монет!') {
        buttons = [
          {
            title: 'Закрыть',
            props: { isSecondary: true },
          },
          {
            title: 'Проверить баланс',
            action: () => {
              this.$router.push('/profile/balance')
            },
          },
        ]
      }

      this.showModal({
        component: 'ModalMessage',
        data: {
          text,
          buttons,
        },
      })
    },

    async leaveTournament(tournamentType) {
      this.$nuxt.$loading.start()

      try {
        await this.$axios.post(
          `/games/${this.game.name}/${tournamentType}/${this.tournament.id}/leave`
        )
      } catch (e) {
        this.showModal({
          component: 'ModalMessage',
          data: {
            text: e.response.data.message,
          },
        })
      }

      this.$nuxt.$loading.finish()
    },

    onEnterEventClick() {
      if (this.isLichess) {
        window.open(
          'https://lichess.org/tournament/' +
          this.tournament.extra_data.state.id
        )
      } else if (this.isClashRoyale) {
        window.open('https://link.clashroyale.com/')
      } else if (this.isBrawlStars) {
        window.open(
          `https://link.brawlstars.com/invite/gameroom/ru?tag=${this.brawlStarsRoomCode}`
        )
      }
    },

    onAddInGameFriendClick() {
      window.open(
        'https://link.brawlstars.com/invite/friend/en?tag=98G8YGYQU&token=rgp8ma4p'
      )

      this.$cookiz.set('added_friend_tournament_id', this.tournament.id)
      this.addedFriendTournamentId = this.tournament.id
    },

    onJoinTeamClick() {
      window.open(`https://lichess.org/team/${this.lichessTeam}`)
    },

    sendAnalytics({ isFirst, paymentID }) {
      const commission = Math.ceil(
        (this.entryFee / 100) * this.tournament.prize_settings.rake
      )

      this.$ga.offlineEvent({
        event: 'tournament_registration',
        cm1: this.isPaid ? 0 : this.entryFee,
        cm2: this.isPaid ? this.entryFee : 0,
        cm3: this.commission,
        cd3: this.game.name,
        cd4: this.tournament.title,
        cd5: this.isPaid ? 'paid' : 'freeroll',
      })

      this.$ga.offlineEvent({
        event: 'purchase',
        cm1: this.isPaid ? 0 : this.entryFee,
        cm2: this.isPaid ? this.entryFee : 0,
        cm3: commission,
        cd3: this.game.name,
        cd4: this.tournament.title,
        cd5: this.isPaid ? 'paid' : 'freeroll',
        cd6: this.gameAccount(this.game.name)?.rating_groups?.default,
        cd8: 'tournament_fee',
        transactionId: paymentID,
        transactionTotal: this.isPaid ? this.entryFee : 0,
        transactionProducts: [
          {
            sku: this.tournament.id,
            name: 'tournament',
            category: this.game.name,
            price: this.isPaid ? this.entryFee : 0,
            quantity: 1,
          },
        ],
      })

      if (isFirst) {
        this.$ga.onlineEvent({
          ec: 'activation',
          ea: 'first_tournament_registration',
          cm1: this.isPaid ? 0 : this.entryFee,
          cm2: this.isPaid ? this.entryFee : 0,
          cm3: this.commission,
          cd3: this.game.name,
          cd4: this.tournament.title,
          cd5: this.isPaid ? 'paid' : 'freeroll',
          cd6: this.gameAccount(this.game.name)?.rating_groups?.default,
        })
      }
    },
  },
}
