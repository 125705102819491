<template lang="pug">
  .currency(:class="{ '--reversed': isReversed }")
    .currency__text(v-if="isReversed")
      slot
    .currency__icon
      InlineSvg.currency__ticket(
        v-if="type === 'tickets'"
        :height="size"
        :width="size"
        :src="require('@/assets/img/icons/ticket.svg')"
      )
      InlineSvg.currency__euro(
        v-else-if="type === 'money'"
        :height="size"
        :width="size"
        :src="require('@/assets/img/icons/euro.svg')"
      )
      InlineSvg.currency__coins(v-else :height="size" :width="size" :src="require('@/assets/img/icons/coins.svg')")
    .currency__text(v-if="!isReversed")
      slot

</template>

<script>
export default {
  name: 'Currency',

  props: {
    type: {
      type: String,
      default: 'coins',
    },

    size: {
      type: String,
      default: '24',
    },

    isReversed: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.currency {
  display: inline-flex;
  align-items: center;

  &__text {
    @include heading4;

    color: $white;
    font-size: 18px;
  }

  &.--hide {
    .currency__text {
      background: rgba(255, 255, 255, 0.3);
      filter: blur(6px);
      border-radius: 4px;
    }
  }

  &.--tickets {
    svg {
      margin-right: 2px;
    }
  }

  &.--reversed {
    svg {
      margin-left: 4px;
      margin-right: 0;
    }
  }

  &__icon {
    height: auto;
    margin-right: 2px;
  }

  svg {
    display: block;
    height: 100%;
  }

  &__coins path {
    fill: $amber50;
  }

  &__euro path {
    fill: $green50;
  }

  @media (max-width: $breakpointMobile) {
    &__icon {
      margin-right: 0;
    }
  }
}
</style>
