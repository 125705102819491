<template lang="pug">
  .info-message(:class="classModifiers")
    InlineSvg(v-if="withIcon && type === 'info'" :src="require('@/assets/img/icons/info.svg')")
    InlineSvg(v-if="withIcon && ['error', 'warning'].includes(type)" :src="require('@/assets/img/icons/warning.svg')")
    InlineSvg(v-if="withIcon && type === 'success'" :src="require('@/assets/img/icons/success.svg')")
    slot
</template>

<script>
export default {
  name: 'InfoMessage',

  props: {
    type: {
      type: String,
      default: 'info',
    },

    withIcon: {
      type: Boolean,
      default: true,
    },
  },

  data({ type }) {
    return {
      isPasswordVisible: false,
      classModifiers: {
        '--warning': type === 'warning',
        '--error': type === 'error',
        '--info': type === 'info',
        '--success': type === 'success',
      },
    }
  },

  methods: {},
}
</script>

<style lang="scss">
.info-message {
  @include heading6;

  width: 100%;
  padding: 16px 51px 16px 56px;
  position: relative;
  border-radius: 8px;
  background: $blue-grey60;
  color: $blue-grey05;

  svg {
    position: absolute;
    left: 18px;
    top: 14px;
  }

  &.--info {
    padding: 24px 24px 24px 81px;
    min-height: 74px;
  }

  &.--warning {
    background: $yellow60-20;
    color: $amber60;
    svg path,
    svg rect {
      fill: $yellow40;
    }
  }

  &.--error {
    background: $red60-20;
    color: $red50;
  }

  &.--success {
    background: $green60-20;
    color: $green50;
  }
}
</style>
