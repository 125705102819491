<template lang="pug">
.offerwall-complete
  SectionHeader(:header="header")

  .offerwall-complete__body
    InputSmart.offerwall-complete__field(:item="userItem")
    InputSmart.offerwall-complete__field(:item="offerItem")

    Button(is-primary @click="complete") Выполнил
</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader';
import InputSmart from '@/components/InputSmart/InputSmart'
import { mapActions } from "vuex";

export default {
  name: "GameCurrencies",

  components: {
    SectionHeader,
    InputSmart
  },

  data () {
    return {
      userItem: {
        title: 'ID пользователя',
        value: '',
        type: 'text',
        placeholder: 'Введите ID пользователя',
      },
      offerItem: {
        title: 'ID оффера',
        value: '',
        type: 'text',
        placeholder: 'Введите ID оффера (из нашей БД, колонка id)',
      }
    }
  },

  computed: {
    header() {
      return {
        title: 'Выполнение оффера',
      };
    },
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async complete() {
      try {
        await this.$axios.get(`/offerwall/any/callback?offer=${this.offerItem.value}&user=${this.userItem.value}`)
        this.userItem.value = ''
        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            title: 'Success',
            text: 'Выполнение оффера отправлено',
          },
        })
      } catch (e) {
        alert('Ошибка обработки постбэка')
        this.$error.handle(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.offerwall-complete {
  &__body {
    padding: 8px 24px;
  }

  &__field {
    margin: 24px 0;
    max-width: 400px;
  }
}
</style>