<template lang="pug">
  .moderation-nominations
    .moderation-nominations__body(v-if="nominations && nominations.length")
      .moderation-nomination(v-for="nomination in nominations")
        .moderation-nomination__title «{{ nomination.title }}»

        InputSmart(
          :item="nomination.winner"
          v-model="nomination.winner.value"
          :type="nomination.winner.type"
        )

    .moderation-nominations__bottom(v-if="nominations && nominations.length")
      Button(is-primary @click="onSendClick") Отправить номинации
      Button(is-secondary @click="onResetClick") Сбросить номинации

    .action__empty(v-else)
      | Номинаций нет или они уже промодерированы

</template>

<script>
import { mapState, mapActions } from 'vuex'
import InputSmart from '@/components/InputSmart/InputSmart'

export default {
  name: 'NominationsModeration',

  props: {
    tournament: {
      type: Object,
      required: true,
    },
  },

  components: {
    InputSmart,
  },

  data() {
    return {
      nominations: [],
    }
  },

  computed: {
    ...mapState('games', ['gamesById']),

    tournamentNicknamesOptions() {
      return (this.tournament.players || [])
        .filter(player => player.game_account?.username)
        .map(player => ({
          title: `${player.game_account.username} (${player.game_account.ratings?.default})`,
          value: player.game_account.username,
      }));
    },

    gameName() {
      return this.gamesById[this.tournament.game_id].name
    },
  },

  mounted() {
    this.nominations = this.tournament?.prize_settings?.nominations
        ?.map(nom => {
          return {
            title: nom.title,
            winner: {
              title: 'Никнейм победителя',
              value: nom?.winner?.game_account?.username || null,
              type: 'search-select',
              options: this.tournamentNicknamesOptions,
              withEmptyValue: true,
            },
          }
        })
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onSendClick() {
      const prize_settings = this.tournament.prize_settings
      const nominations = []

      this.nominations.forEach((nom, i) => {
        nominations.push({
          ...prize_settings.nominations[i],
          winner_id: this.tournament.players.find(p => {
            return p.game_account && p.game_account.username === nom.winner.value;
          })?.id || null,
        })
      })

      try {
        await this.$axios.put(
          `/games/${this.gameName}/tournaments/${this.tournament.id}`,
          {
            prize_settings: { ...prize_settings, nominations },
          }
        )

        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            title: 'Успех',
            text: 'Информация о номинациях отправлена',
          },
        })

        this.$emit('change')
      } catch (e) {
        this.$error.handle(e)
      }
    },
    onResetClick() {
      const ok = confirm('Сбросить номинации?')
      if (!ok) return
      this.nominations = this.tournament?.prize_settings?.nominations
        ?.map(nom => {
          console.log(nom)
          return {
            title: nom.title,
            winner: {
              title: 'Никнейм победителя',
              value: null,
              type: 'search-select',
              options: this.tournamentNicknamesOptions,
              withEmptyValue: true,
            },
          }
        })
    }
  },
}
</script>

<style lang="scss">
.moderation-nominations {
  text-align: center;

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    width: fit-content;
    margin-bottom: 16px;

    @media screen and (max-width: $breakpointMobile) {
      grid-template-columns: unset;
      width: 100%;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: min-content;
    grid-gap: 20px;
    margin-top: 40px;
  }
}

.moderation-nomination {
  background-color: $blue-grey60;
  padding: 30px 22px;
  border-radius: 8px;
  max-width: 456px;

  &__title {
    @include heading4;

    color: $white;
    margin-bottom: 24px;
  }

  .search-select__input {
    background-color: $blue-grey50;
    width: 412px;

    @media screen and (max-width: $breakpointMobile) {
      width: 100%;
    }
  }
}
</style>