<template lang="pug">
    .action-ban
      SectionHeader(:header="header")
  
      .action__body
        .action__section
          .action__grid
            .action__form-field(v-for="(item, i) of items" :key="i")
              InputSmart(:item="item")
  
        .action__bottom
          Button(is-primary @click="onCheckButtonClick") Добавить
  
  </template>
  
  <script>
  import { mapActions } from 'vuex'
  import InputSmart from '@/components/InputSmart/InputSmart'
  import CheckboxInput from '@/components/CheckboxInput/CheckboxInput'
  import SectionHeader from '@/components/SectionHeader/SectionHeader'
  
  export default {
    name: 'ActionFaq',
  
    components: {
      InputSmart,
      CheckboxInput,
      SectionHeader,
    },
  
    data: () => ({
      items: {
        category: {
          title: 'Категория',
          value: 'Турнир',
          type: 'select',
          options: [
            { title: 'Турнир', value: 'Турнир' },
            { title: 'Подписка', value: 'Подписка' },
            { title: 'Сайт', value: 'Сайт' },
            { title: 'Реф. программа', value: 'Реф. программа' },

            { title: 'Tournaments', value: 'Tournaments' },
            { title: 'Subscription', value: 'Subscription' },
            { title: 'Site', value: 'Site' },
            { title: 'Ref. program', value: 'Ref. program' },
          ],
          withEmptyValue: false,
        },
        questionItem: {
            title: 'Вопрос',
            value: '',
            type: 'text',
            placeholder: 'Пользователь увидет её при выборке категории'
        },
        answerItem: {
            title: 'Ответ на вопрос',
            value: '',
            type: 'text',
            placeholder: 'Пользователь увидет её при выборе вопроса'
        },
        language: {
          title: 'Язык',
          value: 'ru',
          type: 'select',
          options: [
            { title: 'Русский', value: 'ru' },
            { title: 'English', value: 'en' },
          ],
          withEmptyValue: false,
        },
      },
    }),
  
    computed: {
      gameName() {
        return this.$route.params.section
      },
  
      header() {
        return {
          title: `${this.$gameNameText(this.gameName)} / FAQ`,
          game: this.gameName,
        }
      }
    },
  
    created() {
      this.$forceUpdate()
    },
  
    methods: {
      ...mapActions('modal', ['showModal']),
  
      async onCheckButtonClick() {
        this.$loading.start()
  
        try {
          const question = this.items.questionItem.value
          const answer = this.items.answerItem.value
          const category = this.items.category.value
          const language = this.items.language.value
  
          let result
  
          result = await this.$axios.post(`/jivo/faq`, {
              question,
              answer,
              category,
              language
          })
  
          if (result.data.data) {
            this.showModal({
              component: 'ModalMessage',
              data: {
                type: 'success',
                title: 'Успех',
                text: 'Всё прошло как нужно! :)',
              },
            })
          }
        } catch (e) {
          this.$error.handle(e)
        }
  
        this.$loading.finish()
      }
    },
  }
  </script>
  
  <style lang="scss">
  </style>
  