<template lang="pug">
  .icon-switcher
    .input-smart__title
      | Иконка и цвет

    .icon-switcher__body
      .icon-switcher__icon.icon-colored(@click="onIconClick" :class="iconClass")
        InlineSvg(:src="require(`@/assets/img/icons/${iconSrc}.svg`)")

      .icon-switcher__colors
        .icon-switcher__color(
          v-for="(hex, color) in colors"
          :class="{ '--active': activeColor === color, '--is-white': color === 'white' }"
          @click="onColorClick(color)"
        )
          .icon-switcher__color-inner(:style="{ 'background-color': hex }")

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'IconSwitcher',

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },

    index: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      activeColor: 'white',

      colors: {
        white: '#FFFFFF',
        grey: '#334153',
        primary: '#1E88E5',
        amber: '#FFB300',
        orange: '#FB8C00',
        cyan: '#00ACC1',
        'deep-blue': '#673AB7',
        green: '#43A047',
        indigo: '#3F51B5',
        purple: '#9C27B0',
        pink: '#D81B60',
        teal: '#009688',
        yellow: '#FDD835',
      }
    }
  },

  computed: {
    iconSrc() {
      return this.value?.src || 'crown'
    },

    iconClass() {
      const color = this.activeColor

      if (!color) {
        return null
      }

      return `--${color}`
    },
  },

  mounted() {
    if (this.value?.color) {
      this.activeColor = this.value.color
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    onIconClick() {
      this.showModal({
        component: 'IconModal',
        data: {
          index: this.index,
          iconClass: this.iconClass,
        },
      })
    },

    onColorClick(color) {
      this.activeColor = color
      this.value.color = this.colors[color]
      this.$emit('input', this.value)
    },
  },
}
</script>

<style lang="scss">
.icon-switcher {
  &__body {
    height: 48px;
    display: flex;
    align-items: center;
  }

  &__icon {
    padding: 8px;
    margin-right: 23px;
    border: 1px dashed #839ab5;
    border-radius: 8px;
    cursor: pointer;

    svg {
      height: 27px;
      width: 27px;
    }
  }

  &__colors {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__color {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;

    &-inner {
      height: 14px;
      width: 14px;
      border-radius: 50%;
    }

    &.--active {
      background-color: $white;

      &.--is-white {
        background-color: $blue-grey20;
      }
    }
  }
}

.icon-colored {
  &.--white svg {
    g, path, rect { fill: #FFFFFF; }
  }

  &.--grey svg {
    g, path, rect { fill: #334153; }
  }

  &.--primary svg {
    g, path, rect { fill: #1E88E5; }
  }

  &.--amber svg {
    g, path, rect { fill: #FFB300; }
  }

  &.--orange svg {
    g, path, rect { fill: #FB8C00; }
  }

  &.--cyan svg {
    g, path, rect { fill: #00ACC1; }
  }

  &.--deep-blue svg {
    g, path, rect { fill: #673AB7; }
  }

  &.--green svg {
    g, path, rect { fill: #43A047; }
  }

  &.--indigo svg {
    g, path, rect { fill: #3F51B5; }
  }

  &.--purple svg {
    g, path, rect { fill: #9C27B0; }
  }

  &.--pink svg {
    g, path, rect { fill: #D81B60; }
  }

  &.--teal svg {
    g, path, rect { fill: #009688; }
  }

  &.--yellow svg {
    g, path, rect { fill: #FDD835; }
  }
}
</style>