<template lang="pug">
  .tournaments-list(:class="{'--list': view === 'list', '--cells': view === 'cells'}")
    slot(name="title")

    .tournaments-list__body
      slot(name="empty" v-if="!tournaments.length")
      transition-group.tournaments-list__body-inner(v-else-if="withTransition" name="fade-scale" duration="200")
        template(v-for="(item, index) in list")
          //.tournaments-list__date(v-if="shouldShowDate(item, index)" :key="item.id + 'date'")
          //  | {{ getItemDate(item) }}
          TournamentCard(:key="item.id" :tournament="item" :view="view" :is-small="isSmall")

      .tournaments-list__body-inner(v-else)
        template(v-for="(item, index) in list")
          //.tournaments-list__date(v-if="shouldShowDate(item, index)" :key="item.id + 'date'")
          //  | {{ getItemDate(item) }}
          TournamentCard(:key="item.id" :tournament="item" :view="view" :is-small="isSmall")

</template>

<script>
import TournamentCard from '@/components/TournamentCard/TournamentCard'

export default {
  name: 'TournamentsList',

  components: {
    TournamentCard,
  },

  props: {
    tournaments: {
      type: Array,
      required: true,
    },

    view: {
      type: String,
      default: 'cells',
    },

    withTransition: {
      type: Boolean,
      default: true,
    },

    withDate: {
      type: Boolean,
      default: false,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    list() {
      return this.tournaments
    },
  },
}
</script>

<style lang="scss">
.tournaments-list {
  &__date {
    @include heading3;

    color: #fff;
    border-bottom: 1px solid $blue-grey50;
    padding: 4px 0;
    grid-column: 1 / span 3;

    .--list & {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    @media (max-width: $breakpointMobile) {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  &__body {
    width: 100%;

    &-inner {
      width: 100%;
      display: grid;
      column-gap: 32px;
      row-gap: 32px;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 32px;

      @media (max-width: $breakpointDesktop) {
        margin-top: 24px;
      }
    }
  }

  &.--list {
    .tournaments-list__body {
      &-inner {
        display: block;
      }
    }
  }

  &.--cells {
    @media (max-width: $breakpointDesktop) {
      .tournaments-list {
        &__body {
          &-inner {
            @include scroll-none;

            display: flex;
            overflow-x: auto;
            column-gap: 0;
            width: calc(100% + 64px);
            margin-left: -32px;

            &::after {
              content: '';
              min-width: 16px;
            }

            .tournament-card {
              margin-right: 16px;
              min-width: 340px;

              &:first-child {
                margin-left: 32px;
              }
            }
          }
        }
      }
    }

    @media (max-width: $breakpointTablet) {
      .tournaments-list {
        &__body {
          &-inner {
            width: calc(100% + 48px);
            margin-left: -24px;

            &::after {
              min-width: 16px;
            }

            .tournament-card {
              margin-right: 8px;

              &:first-child {
                margin-left: 24px;
              }
            }
          }
        }
      }
    }

    @media (max-width: $breakpointMobile) {
      .tournaments-list {
        &__body {
          &-inner {
            display: block;
            width: 100%;
            margin-left: 0;

            &::after {
              display: none;
            }

            .tournament-card {
              margin-left: 0;
              margin-right: 0;
              min-width: 0;
              width: 100%;
              margin-bottom: 16px;

              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
