<template lang="pug">
  .games-list
    .games-list__body
      .games-list__card(
        v-for="(section, s) of sectionList"
        @click="toActionRoute(section)"
        v-show="s !== sectionList.length-1"
      )
        img(:src="section.extra_data.image_url", :alt="section.name").games-list__card-img

        .games-list__card-body
          //.games-list__card-soon(v-if="!section.is_enabled")
          //  section Coming soon
          //.games-list__card-event(v-if="game.event_count")
            | {{ game.event_count }} events
          .games-list__card-name
            | {{ section.extra_data.title }}

          Button(is-bordered is-white icon="arrow-right") Перейти
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GamesList',

  props: {
    games: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters('navigation', ['sections']),

    sectionList() {
      /// const games = this.sections.filter(game => !game.is_tournament_enabled)
      return Object.values(this.sections)
    },
  },

  methods: {
    toActionRoute(section) {
      const firstActionRoute = this.sections?.[section.name]?.actions?.[0]?.route
      this.$router.push(`/${section.name}${firstActionRoute}`)
    }
  },
}
</script>

<style lang="scss">
.games-list {
  &__title {
    @include heading3;
    color: $white
  }

  &__body {
    margin-top: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 20px;
  }

  &__card {
    width: 100%;
    min-height: 291px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      min-height: 204px;
    }

    &-soon {
      color: $primary60;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      &::before {
        content: '';
        background: radial-gradient(
          50% 50% at 50% 50%,
          #1e88e5 0%,
          rgba(30, 136, 229, 0) 100%
        );
        position: absolute;
        width: 120%;
        margin-left: -10%;
        height: 36px;
        top: 50%;
        margin-top: -18px;
        left: 0;
        opacity: 0.4;
      }

      span {
        opacity: 0.9;
        position: relative;
        z-index: 2;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(11, 20, 32, 0) 0%,
        #0b1420 100%
      );
      opacity: 0;
      transition: 0.2s $easing;
    }

    &-body {
      width: 100%;
      height: 100%;
      padding: 16px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      background: transparent;
      top: 0;
      left: 0;
      transition: background 0.2s $easing;
    }

    &-img {
      width: 100%;
    }

    &-event {
      @include text-display100;

      color: $amber60;
    }

    &-name {
      @include heading4;

      color: $white;
      margin: auto 0 16px;
      transform: translateY(0);
      transition: 0.2s $easing;
    }

    .button {
      position: absolute;
      border-color: $primary60;
      width: calc(100% - 32px);
      left: 50%;
      transform: translate(-50%, 140%);
      bottom: 16px;
      transition: 0.2s $easing;

      &__body {
        justify-content: center;
      }
    }

    &:hover:not(.--soon) {
      &::before {
        opacity: 1;
      }

      .button {
        transform: translate(-50%, 0%);
        opacity: 1;
      }

      .games-list__card {
        &-name {
          transform: translateY(-52px);
        }
        .button {
          transform: translate(-50%, 0%);
        }
      }
    }

    &.--soon {
      cursor: default;

      img {
        opacity: 0.2;
      }
    }
  }

  @media (max-width: 1435px) {
    &__body {
      grid-template-columns: 18.7% 18.7% 18.7% 18.7% 18.7%;
    }
  }

  @media (max-width: 1220px) {
    &__body {
      grid-template-columns: 22.7% 22.7% 22.7% 22.7%;
      column-gap: 3%;
    }
  }

  @media (max-width: 360px) {
    &__body {
      grid-template-columns: 100% !important;
      column-gap: 0 !important;
    }
  }

  @media (max-width: 720px) {
    &__body {
      grid-template-columns: 31% 31% 31%;
      column-gap: 3%;
    }
  }

  @media (max-width: $phoneWidth) {
    &__body {
      grid-template-columns: 49% 49% !important;
      column-gap: 2% !important;
    }
  }
}
</style>
