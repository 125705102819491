<template lang="pug">
  router-link.tournament-card(
    :class="{ '--list-item': view === 'list', '--big': view === 'big', '--cell': view === 'cells', '--small': view === 'list' && isSmall }"
    :to="`/${game.name}/events/tournaments/${tournament.id}`"
  )
    .tournament-card__body
      .tournament-card__image
        img(v-if="tournament.image_url" :src="tournament.image_url", :alt="tournament.title")

        .tournament-card__status(:class="statusClass")
          InlineSvg(:src="require('@/assets/img/icons/status.svg')")
          span {{ statusText }}

        .tournament-card__freeroll(v-if="isFreeroll") Freeroll

        .tournament-card__labels(v-if="view === 'cells'")
          .tournament-card__label(v-for="label in labels" :title="label.title")
            PlayerRank(v-if="label.rank" :game="game" v-bind="label.rank")
            InlineSvg(v-else-if="label.icon" :src="require(`@/assets/img/icons/${label.icon}.svg`)" :class="`--${label.icon}`" height="20" width="20")

            Currency.tournament-card__label-value(v-if="label.currency" size="16" is-reversed v-bind="label.currency" ) {{ label.value }}
            .tournament-card__label-value(v-else-if="label.value") {{ label.value }}

      .tournament-card__content
        .tournament-card__content-top
          .tournament-card__booking
            Button(v-if="!tournament.created_by" is-primary @click="claim")
              | Book
            Button(v-else-if="tournament.created_by === userId" is-primary @click="unClaim")
              | Cancel booking
            .tournament-card__booked(v-else) Booked
          .tournament-card__info
            .tournament-card__date
              span {{ date }} в {{ time }}
              span ·
              span {{ game.extra_data.title }}
            .tournament-card__title
              | {{ tournament.title }}

          .tournament-card__players(v-if="view === 'list'")
            InlineSvg.tournament-card__participant-icon(:src="require('@/assets/img/icons/players.svg')")
            .tournament-card__players-count {{ playersCount }} / {{ playersMaxCount }}


        .tournament-card__labels(v-if="view !== 'cells' && !isSmall")
          .tournament-card__label(v-for="label in labels" :title="label.title")
            PlayerRank(v-if="label.rank" :game="game" v-bind="label.rank")
            InlineSvg(v-else-if="label.icon" :src="require(`@/assets/img/icons/${label.icon}.svg`)" :class="`--${label.icon}`" height="20" width="20")

            Currency.tournament-card__label-value(v-if="label.currency" size="16" is-reversed v-bind="label.currency" ) {{ label.value }}
            .tournament-card__label-value(v-else-if="label.value") {{ label.value }}

        .tournament-card__players(v-if="view !== 'list'") 
          InlineSvg.tournament-card__participant-icon(v-if="playersCount" :src="require('@/assets/img/icons/players.svg')")
          .tournament-card__players-count(v-if="playersCount") {{ playersCount }} / {{ playersMaxCount }}
          .caption(v-if="!playersCount && view === 'cells'")
            | Участников пока нет

          Button(v-if="view === 'big'" is-primary).tournament-card__participant-link
            | Узнать больше

    .tournament-card__labels(v-if="view === 'list' && isSmall")
      .tournament-card__label(v-for="label in labels" :title="label.title")
        PlayerRank(v-if="label.rank" :game="game" v-bind="label.rank")
        InlineSvg(v-else-if="label.icon" :src="require(`@/assets/img/icons/${label.icon}.svg`)" :class="`--${label.icon}`" height="20" width="20")

        Currency.tournament-card__label-value(v-if="label.currency" size="16" is-reversed v-bind="label.currency" ) {{ label.value }}
        .tournament-card__label-value(v-else-if="label.value") {{ label.value }}

</template>

<script>
import numeral from 'numeral'
import { mapState } from 'vuex'
import { EVENT_STATUSES } from '@/utils/constants'
import { getPrizePool } from '@/utils/utils'
import TournamentMixin from '@/mixins/TournamentMixin'
import PlayerRank from '@/components/PlayerRank/PlayerRank'
import Currency from "@/components/Currency/Currency";
import TournamentsService from "../../services/TournamentsService";

export default {
  name: 'TournamentCard',

  components: {
    PlayerRank,
    Currency,
  },

  mixins: [TournamentMixin],

  props: {
    tournament: {
      type: Object,
      required: true,
    },

    view: {
      type: String,
      default: 'cells',
    },

    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapState('games', ['gamesById']),
    ...mapState('user', ['user']),

    isFreeroll() {
      return (
          this.tournament.prize_settings?.prize_currency === 'money' &&
          this.tournament.prize_settings?.entry_fee_currency === 'coins'
      )
    },

    userId() {
      return this.user?.id
    },

    generalLabels() {
      let prizeIcon = 'trophy'
      let prizeTitle = 'Призовой фонд'
      let prizeValue = this.formatPrice(this.prizePool)

      const isNotFixedPrizePool = [
        'not_fixed_with_guarantee',
        'not_fixed',
      ].includes(this.tournament.prize_settings?.prize_pool_type)

      if (this.isDoublePrize) {
        prizeIcon = 'x2'
        prizeTitle = 'Выигрыш призера'
        prizeValue = this.formatPrice(this.doublePrize)
      } else if (this.tournament.prize_settings?.prize_pool_type === 'fixed') {
        prizeIcon = 'fixed'
        prizeTitle = 'Постоянный призовой фонд'
      } else if (isNotFixedPrizePool) {
        prizeIcon = 'not-fixed'
        prizeTitle = 'Растущий призовой фонд'
      }

      const items = [
        {
          title: 'Стоимость участия',
          icon: 'cost',
          value: this.formatPrice(this.entryFee),
          currency: {
            type: this.tournament.prize_settings?.entry_fee_currency,
          },
        },
        {
          title: prizeTitle,
          icon: prizeIcon,
          value: prizeValue,
          currency: {
            type: this.tournament.prize_settings?.prize_currency,
          },
        },
      ]

      if (this.withRewardsPoints) {
        let value = ''

        if (this.tournament.state !== 'completed') {
          value = ''
        } else if (this.tournament.prizes?.point) {
          value = (this.tournament.prize_settings?.prize_currency === 'money'
                  ? this.tournament.prizes?.point / 100
                  : this.tournament.prizes?.point
          )
              .toFixed(2)
              .replace('.00', '')
        }

        items.push({
          icon: 'target',
          title: 'Награда за килл',
          value,
          currency:
              this.tournament.prizes?.point &&
              this.tournament.state === 'completed'
                  ? {
                    type: this.tournament.prize_settings?.prize_currency,
                  }
                  : null,
        })
      }

      if (
          this.tournament.prize_settings.place_distribution_type !== 'point' &&
          ['top', 'percentage'].includes(
              this.tournament.prize_settings?.prize_winner_type
          )
      ) {
        const prizes = {
          icon: 'star-with-paddings',
        }
        const placesCount = this.tournament.prize_settings.win_place_count

        if (this.tournament.prize_settings.prize_winner_type === 'top') {
          prizes.title = 'Количество призовых мест'
          prizes.value = placesCount
        } else if (
            this.tournament.prize_settings.prize_winner_type === 'percentage'
        ) {
          prizes.title = 'Процент призовых мест'
          prizes.value = `${placesCount}%`
        }

        items.push(prizes)
      }

      const rank = this.tournament.rating_group

      if (rank && this.game.hasRanks) {
        items.push({
          title: 'Ранг',
          value: 'Ранг',
          rank: { rank },
        })
      }

      return items
    },

    lichessLabels() {
      let clock

      if (this.tournament.extra_data?.state?.clock?.limit) {
        clock = {
          limit: this.tournament.extra_data.state.clock.limit / 60,
          increment: this.tournament.extra_data.state.clock.increment,
        }
      } else if (this.tournament.extra_data?.config?.clockTime) {
        clock = {
          limit: this.tournament.extra_data.config.clockTime,
          increment: this.tournament.extra_data.config.clockIncrement,
        }
      }

      const labels = [...this.generalLabels]

      if (this.tournament.extra_data?.state?.perf?.name) {
        labels.push({
          icon: 'gamepad',
          title: 'Режим игры',
          value: this.tournament.extra_data?.state?.perf?.name,
        })
      }

      if (clock) {
        labels.push({
          title: 'Контроль времени',
          icon: 'time',
          value: `${clock.limit}+${clock.increment}`,
        })
      }

      if (this.tournament.extra_data.state?.minutes) {
        labels.push({
          icon: 'duration',
          title: 'Длительность в минутах',
          value: this.tournament.extra_data.state?.minutes,
        })
      }

      // if (this.tournament.extra_data.config?.berserkable) {
      //   labels.push({
      //     icon: 'berserk',
      //     title: 'Берсерк разрешён',
      //     value: 'Берсерк',
      //   })
      // }

      if (this.tournament.is_anticheat_required) {
        labels.push({
          title: 'Античит обязателен',
          icon: 'antichit-warning',
          value: `Античит`,
        })
      }

      if (this.webcamLink) {
        labels.push({
          title: 'Видеочат обязателен',
          icon: 'cam',
          value: `Видеочат`,
        })
      }

      return labels
    },

    pubgLabels() {
      return [
        ...this.generalLabels,
        {
          title: 'Режим игры',
          icon: 'gamepad',
          value: this.tournament.extra_data.config.mode,
        },
        {
          title: 'Карта',
          icon: 'world',
          value: this.tournament.extra_data.config.map,
        },
      ]
    },

    brawlStarsLabels() {
      return [
        ...this.generalLabels,
        {
          title: 'Режим игры',
          icon: 'gamepad',
          value: this.tournament.extra_data.config.mode,
        },
        {
          title: 'Карта',
          icon: 'world',
          value: this.tournament.extra_data.config.map,
        },
      ]
    },

    clashRoyaleLabels() {
      return [
        ...this.generalLabels,
        {
          title: 'Режим игры',
          icon: 'gamepad',
          value: this.tournament.extra_data.config.game_mode,
        },
        {
          title: 'Максимальное количество проигрышей',
          icon: 'dislike',
          value:
              this.tournament.extra_data?.config?.max_loses_count !== 0
                  ? this.tournament.extra_data?.config?.max_loses_count
                  : '∞',
        },
        {
          icon: 'duration',
          title: 'Длительность в минутах',
          value: this.tournament.extra_data.config.duration,
        },
      ]
    },

    labels() {
      if (this.game?.name === 'lichess') {
        return this.lichessLabels
      } else if (this.game?.name === 'pubg-mobile') {
        return this.pubgLabels
      } else if (this.game?.name === 'clash-royale') {
        return this.clashRoyaleLabels
      } else if (this.game?.name === 'brawl-stars') {
        return this.brawlStarsLabels
      }

      return []
    },

    statusText() {
      return EVENT_STATUSES[this.tournament.state]
    },

    playersCount() {
      return this.tournament.players_count
    },

    playersMaxCount() {
      return this.tournament.tournament_player_settings.max_players_count
    },

    date() {
      return this.$dateFns.format(this.startsAt, 'd MMMM')
    },

    time() {
      return this.$dateFns.format(this.startsAt, 'HH:mm')
    },

    prizePool() {
      return getPrizePool(this.tournament)
    },

    statusClass() {
      const state = this.tournament.state

      return {
        'tournament-card__status--opened': state === 'open',
        'tournament-card__status--closed': ['completed', 'cancelled'].includes(
            state
        ),
        'tournament-card__status--started': state === 'in_progress',
      }
    },

    game() {
      return this.gamesById[this.tournament.game_id]
    },
  },

  methods: {
    formatPrice(number) {
      return numeral(number).format('0,0').replace(/,/g, ' ')
    },

    claim() {
      TournamentsService.claimTournament(this.game.name, this.tournament.id)
        .then(() => {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'success',
              title: 'Success',
              text: 'The tournament is booked.',
            },
          })
        })
        .catch(() => {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'error',
              title: 'Error',
              text: 'Booking error.',
            },
          })
        })
    },

    unClaim() {
      TournamentsService.unClaimTournament(this.game.name, this.tournament.id)
          .then(() => {
            this.showModal({
              component: 'ModalMessage',
              data: {
                type: 'success',
                title: 'Success',
                text: 'Your booking is canceled.',
              },
            })
          })
          .catch(() => {
            this.showModal({
              component: 'ModalMessage',
              data: {
                type: 'error',
                title: 'Error',
                text: 'Booking canceling error.',
              },
            })
          })
    },
  },
}
</script>

<style lang="scss">
.tournament-card {
  width: 100%;
  border-radius: 8px;
  background: $blue-grey60;
  display: block;
  transition: box-shadow 0.2s $easing;

  &__participant-icon {
    margin-right: 10px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;
  }

  &__booking {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0;
    flex-wrap: wrap;
  }

  &__image {
    padding: 8px 8px 0;
    position: relative;
    width: 100%;
    height: 269px;
    min-height: 269px;
    overflow: hidden;
    border-radius: 8px;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center center;
      height: 100%;
      border-radius: 8px;
    }
  }

  &__freeroll {
    height: 26px;
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 0 10px;
    border-radius: 0 8px 0 8px;
    background: $amber60;
    color: #fff;
    line-height: 26px;
    font-size: 14px;
  }

  &__labels {
    position: absolute;
    left: 7px;
    right: 7px;
    bottom: -1px;
    display: flex;
    flex-wrap: wrap;
    background: $black80;
    backdrop-filter: blur(12px);
    border-radius: 8px;
    z-index: 1;
    padding: 4px 0 0;
  }

  &__label {
    display: flex;
    align-items: center;
    font-weight: $font-medium;
    font-size: 14px;
    color: #fff;
    padding: 4px 12px 8px;
    height: 20px;
    box-sizing: content-box;
    position: relative;

    &-value {
      margin-left: 4px;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    .currency.--reversed svg {
      margin-left: 0;
    }

    .player-rank > span {
      margin: 0;
    }

    &::after {
      content: '';
      position: absolute;
      border-right: 1px solid $blue-grey30;
      height: 12px;
      top: 8px;
      right: 0;
      width: 0;
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }

  &__status {
    height: 32px;
    position: absolute;
    left: 8px;
    top: 8px;
    background: $black-transparent;
    backdrop-filter: blur(8px);
    border-radius: 8px 0 8px 0;
    padding: 0 10px 0 8px;
    display: flex;
    align-items: center;

    &--opened {
      svg,
      path {
        fill: $green50;
      }
    }
    &--closed {
      svg,
      path {
        fill: $red50;
      }
    }
    &--started {
      svg,
      path {
        fill: $amber60;
      }
    }

    svg {
      margin-right: 6px;
      position: relative;
      top: -1px;
    }

    > span {
      @include heading7;

      color: $white;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;

    &-top {
      flex-grow: 1;
    }
  }

  &__info {
    padding: 28px 20px;
    border-bottom: 1px solid $blue-grey40;
    height: 100%;
  }

  &__date {
    @include caption;

    color: $blue-grey10;

    span {
      margin-right: 8px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    color: #fff;
    margin-top: 8px;
  }

  &__players {
    font-weight: bold;
    color: $white;
    padding: 16px 20px;
    min-height: 72px;
    max-height: 72px;
    display: flex;
    align-items: center;
  }

  &:hover {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 3px 10px 1px rgba(0, 0, 0, 0.14), 0 1px 14px 2px rgba(0, 0, 0, 0.12);
  }

  &.--list-item {
    margin-bottom: 24px;
    width: 100%;
    position: relative;

    .tournament-card {
      &__body {
        flex-direction: row;
      }

      &__image {
        width: 232px;
        min-width: 232px;
        min-height: 130px;
        height: 130px;
        padding: 8px;
        box-sizing: content-box;
      }

      &__content {
        margin-left: 24px;
        justify-content: initial;

        &-top {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          border-bottom: 1px solid $blue-grey40;
        }
      }

      &__info {
        margin: 0 24px 0 0;
        padding: 18px 0;
        border: none;
        flex-grow: 1;
        height: auto;
      }

      &__labels {
        position: static;
        margin: 16px 24px 12px 0;
        background: $blue-grey60;
        width: fit-content;
      }

      &__players {
        padding: 0;
        margin: 18px 24px 18px 0;
        height: auto;
        min-height: auto;
      }
    }

    &.--small {
      margin-bottom: 16px;
      padding-bottom: 12px;

      .tournament-card {
        &__content {
          margin-left: 16px;

          &-top {
            flex-direction: column;
            flex-wrap: nowrap;
            border: none;
          }
        }

        &__players {
          margin-top: 12px;
          min-height: 44px;
        }

        &__labels {
          margin: 12px 8px 0;
        }
      }
    }
  }

  &.--big {
    .tournament-card {
      &__body {
        flex-direction: row;
        border-bottom: none;
      }

      &__content {
        margin-left: 16px;

        &-top {
          flex-direction: column;
          flex-wrap: nowrap;
          border: none;
        }
      }

      &__status {
        left: 16px;
        top: 16px;
      }

      &__freeroll {
        right: 16px;
        top: 16px;
      }

      &__info {
        padding-left: 0;
        border: none;
      }

      &__title {
        font-size: 32px;
        line-height: 32px;
      }

      &__date {
        font-weight: $font-medium;
        font-size: 16px;
      }

      &__players {
        margin: 0;
        min-height: 44px;
        padding: 16px 16px 16px 0;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $blue-grey40;
      }

      &__labels {
        position: static;
        margin: 16px 16px 12px 0;
        background: $blue-grey60;
        width: fit-content;
      }

      &__image {
        width: 48%;
        min-width: 48%;
        height: auto;
        min-height: 270px;
        max-height: 405px;
        margin: 0;
        padding: 16px;

        img {
          height: 100%;
        }
      }
    }
  }

  &__booked {
    color: #ebebeb;
    font-size: 20px;
    border-left: 5px solid red;
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
  }
}
</style>
