<template lang="pug">
  .image-upload(:class="{'--password': type === 'password', '--error': error }")
    //.image-upload__title(v-if="title")
    //  | {{ title }}
    label.image-upload__label
      input(ref="input" type="file" @change="onFileChange" accept="image/png, image/jpeg, image/jpg, image/svg+xml, image/gif, image/webp")
      span(v-if="value") {{ value }}
      span.image-upload__placeholder(v-else) {{ placeholder }}
      Button(is-secondary) Выбрать файл
      .form-input__error(v-if="error")
        | {{ error }}

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ImageUpload',

  props: {
    title: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    error: {
      type: String,
      default: '',
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onFileChange() {
      const file = this.$refs.input
      const form = new FormData()
      form.append('image', file.files[0])

      if (file.files[0]) {
        this.$loading.start()

        try {
          const { data } = await this.$axios.post('/user/upload', form)

          this.$emit('input', data.data)
        } catch (e) {
          this.$errors.handle(e)
          console.error('file upload error', e)
        }

        this.$loading.finish()
      }

      this.$emit('blur')
    },
  },
}
</script>

<style lang="scss">
.image-upload {
  &__placeholder {
    color: $blue-grey20;
  }

  &__title {
    @include caption-small;

    color: $grey20;
    margin-bottom: 16px;
  }

  &__label {
    @include text-display100;

    position: relative;
    background: $blue-grey60;
    width: 100%;
    display: flex;
    border-radius: 8px;
    transition: background 0.2s $easing;
    cursor: pointer;
    height: 48px;
    border: 0;
    outline: none;
    color: $white;
    align-items: center;

    input {
      width: 100%;
      z-index: 3;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
      white-space: nowrap;
    }

    .--error > & {
      background: $red60-20;
    }

    > span {
      @include text-overflow;

      width: 100%;
      padding: 0 16px;
      white-space: nowrap;
      height: 100%;
      line-height: 48px;
    }
  }

  &__error {
    @include text-display100;

    position: absolute;
    bottom: -22px;
    right: 0;
    font-size: 18px;
    color: $red40;
  }
}
</style>
