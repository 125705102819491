<template lang="pug">
  span.player-game-name(
    v-if="account"
  )
    InlineSvg.player-game-name__verify(
      v-if="gameData.name === 'lichess' && isVerified"
      :src="require('./verify.svg')"
      @click.stop="onVerifyClick"
    )
    span.player-game-name__name(:class="{'--pointer': gameData.name === 'lichess'}" @click.stop="onGameAccountClick")
      | {{ username }}
    PlayerRank(
      v-if="gameData.name === 'lichess'"
      :rank="getRank('blitz')"
    )
    PlayerRank.player-game-name__bullet(
      v-if="gameData.name === 'lichess'"
      :rank="getRank('bullet')"
    )
</template>

<script>
import { mapState } from 'vuex'

import PlayerRank from '@/components/PlayerRank/PlayerRank'

export default {
  name: 'PlayerGameName',

  components: { PlayerRank },

  props: {
    account: {
      type: Object,
      required: true
    },

    game: {
      type: Object,
      default: null
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapState('games', ['gamesByName']),

    isVerified() {
      return this.account?.is_verified
    },

    username() {
      return this.account?.username
    },

    id() {
      return this.account?.id
    },

    gameData() {
      return (
        this.game ??
        this.gamesByName[this.$route.params.game || this.account.game]
      )
    }
  },

  methods: {
    getRank(mode = 'blitz') {
      return this.account?.rating_groups?.[mode]
    },

    onGameAccountClick() {
      if (this.gameData.canClickNickname) {
        if (this.gameData.name === 'lichess') {
          window.open('https://lichess.org/@/' + this.username)
        }

        if (this.gameData.name === 'clash-royale') {
          window.open(
            'https://royaleapi.com/player/' + this.id.replace('#', '')
          )
        }
      }
    },

    onVerifyClick() {
      const fideUrl = this.account.verification_data?.fideUrl

      if (fideUrl) {
        window.open(fideUrl)
      }
    }
  }
}
</script>

<style lang="scss">
.player-game-name {
  display: inline-flex;
  align-items: center;

  &__verify {
    margin-right: 6px;
  }

  &__name {
    &.--pointer {
      cursor: pointer;
      text-decoration: underline;
    }

    line-height: 20px;
  }

  > svg {
    cursor: pointer;
  }

  &__bullet.player-rank > span {
    width: 17px;
    min-width: 17px;
    height: 17px;
    line-height: 17px;
    font-size: 13px;
  }
}
</style>
