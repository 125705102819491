import axios from '@/plugins/axios';
class QuestsService {
  constructor() {
    if (QuestsService.instance) {
      return QuestsService.instance;
    }
    
    QuestsService.instance = this;

    return this;
  }

  getQuests() {
    return axios.get('/tasks/templates');
  }

  createQuest(quest) {
    return axios.post('/tasks/templates', [ quest ] )
  }

  deleteQuest(id) {
    return axios.delete(
      `/tasks/templates/${id}`
    );
  }
}

export default new QuestsService();
