<template lang="pug">
  .tournament-moderation
    Tabs.action__tabs(:tabs="tabs" v-model="activeTabIndex")

    .tournament-moderation__body
      .tournament-moderation__main(v-if="activeTabIndex === 0 && isManual")
        .tournaments-create__caption {{ infoMessage }}

        .tournaments-create__caption
          | Под зелёной линией находится список игроков, записавшихся на турнир.
          | Над зелёной линией будет находится конечный топ игроков, по которому и будут выданы призы за турнир

        .moderation__section
          .moderation__section-header
            span Место
            span Имя
            span Очки

          .moderation__section-body
            .moderation__players(v-if="placedWinners && placedWinners.length")
              WinnerRow(
                v-for="winner in placedWinners"
                :key="`placed_${winner.id}`"
                :winner="winner"
                @action="onWinnerMenuClick(winner, $event)"
              )

            .moderation__players(v-else)
              .moderation__done Пока что в топе нет победителей

            .moderation__players
              WinnerRow(
                v-for="winner in unplacedWinners"
                :key="`unplaced_${winner.id}`"
                :winner="winner"
                @action="onWinnerMenuClick(winner, $event)"
              )

        Button(is-primary @click="onSendResults") Отправить результаты

      .action__empty(v-else-if="activeTabIndex === 0 && !isManual")
        | Ручная модерация отключена

      template(v-else-if="activeTabIndex === 1")
        NominationsModeration(:tournament="tournament" @change="onNominationsChange")

      template(v-else-if="activeTabIndex === 2")
        ReportsModeration(:tournament="tournament")

      template(v-else-if="activeTabIndex === 3")
        TournamentResults(:tournament="tournament")

      .tournament-moderation__main(v-if="!isManual")
        Button(is-primary @click="endTournament") Завершить турнир

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Tabs from '@/components/Tabs/Tabs'
import NominationsModeration from '@/components/NominationsModeration/NominationsModeration'
import ReportsModeration from '@/components/ReportsModeration/ReportsModeration'
import WinnerRow from '@/components/WinnerRow/WinnerRow'
import TournamentResults from '@/components/TournamentResults/TournamentResults'

export default {
  name: 'TournamentModeration',

  props: {
    tournament: {
      type: Object,
      required: true,
    },
  },

  components: {
    Tabs,
    WinnerRow,
    NominationsModeration,
    ReportsModeration,
    TournamentResults,
  },

  data() {
    return {
      tabs: [
        { title: 'Победители', id: 'winners' },
        { title: 'Номинации', id: 'nominations' },
        { title: 'Репорты', id: 'reports' },
        { title: 'Присланные результаты', id: 'results' },
      ],
      activeTabIndex: 0,
    }
  },

  computed: {
    ...mapGetters('moderation', ['winners']),

    gameName() {
      return this.$route.params.section
    },

    isManual() {
      return !this.tournament.is_auto_results_enabled
    },

    isTeams() {
      return this.tournament.tournament_player_settings?.entry_type !== 'player'
    },

    winnerList() {
      return this.winners?.map((winner) => {
        const player = this.tournament?.players?.find(player => player.game_account?.username === winner.name)
        const rating = this.gameName === 'pubg-mobile' ? player?.game_account?.ratings?.default : null

        return {
          ...winner,
          rating,
        }
      })
    },

    placedWinners() {
      return this.winnerList?.filter(winner => winner.place && Number.isInteger(winner.points)) || []
    },

    unplacedWinners() {
      return this.winnerList.filter(winner => !winner.place || !Number.isInteger(winner.points))
    },

    infoMessage() {
      let message
      const distributionType = this.tournament?.prize_settings?.place_distribution_type
      const placesCount = this.tournament?.prizes?.places?.length

      switch (distributionType) {
        case 'place':
          message = `Турнир на ТОП! Для успешной модерации достаточно указать первые ${placesCount} мест`
          break

        case 'point':
          message = 'Турнир на киллы! Для успешной модерации нужно указать всех игроков, у которых есть хотя бы 1 килл'
          break

        default:
          message = `Обязательно нужно заполнить всю информацию о первых ${placesCount} мест топа и всех остальных игроках, у которых есть хотя бы 1 килл`
      }

      return message
    },
  },

  mounted() {
    this.initWinners()
  },

  watch: {
    tournament: {
      deep: true,
      handler() {
        this.initWinners()
      },
    },
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapMutations('moderation', ['setWinners', 'deleteWinner']),

    initWinners() {
      let winners = []

      if (this.isTeams) {
        winners = this.tournament?.teams?.map(team => {
          const membersNames = team.members?.map(member => member.game_account?.username)

          return {
            name: `${`«${team.name}»` || 'Команда'}: ${membersNames.join(', ')}`,
            points: team.points || null,
            place: null,
            members: team.members,
            teamId: team.id,
          }
        })
      } else {
        winners = this.tournament?.players?.map(player => {
          return {
            id: player.game_account?.id,
            name: player.game_account?.username,
            points: player.points || null,
            place: null
          }
        })
      }

      this.setWinners(winners)
    },

    onSendResults() {
      let winners = this.placedWinners.map(winner => {
        return {...winner, username: winner.name, platform_username: winner.name}
      })

      if (this.isTeams) {
        winners = []

        this.placedWinners.forEach((team) => {
          team.members.forEach((member) => {
            winners.push({
              username: member.game_account?.username,
              platform_username: member.game_account?.id,
              points: team.points,
              place: team.place,
              team_id: team.teamId,
            })
          })
        })
      }

      this.endTournament(winners)
    },

    async endTournament(winners = {}) {
      this.$loading.start()

      const game = this.$route.params.section
      const id = this.$route.params.id

      try {
        await this.$axios.post(`/games/${game}/tournaments/${id}/moderation`, { winners })

        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            title: 'Успех',
            text: 'Турнир завершен!',
          },
        })

        this.$emit('update')
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    onWinnerMenuClick(winner, actionType) {
      switch (actionType) {
        case 'delete': {
          const winnerIndex = this.winners?.findIndex(w => w.name === winner.name)
          this.deleteWinner(winnerIndex)
          break
        }

        case 'edit':
          this.showModal({ component: 'WinnerEdit', data: { winner } })
          break
      }
    },

    onNominationsChange() {
      this.activeTabIndex = 0
      this.$emit('update')
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/styles/include/participiants.scss';

.winner-row {
  margin-bottom: 12px;
}

.action__empty {
  text-align: center;
}

.moderation-nominations,
.reports-moderation {
  margin-top: 20px;
}

.tournament-moderation {
  width: 100%;

  &__tabs {
    margin-top: 16px;
  }

  &__players {
    height: 112px;
    width: 100%;
    background: #232F3F;
    border-radius: 8px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: $breakpointMobile) {
      height: auto;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    text-align: left;
  }

  &__body {
    margin: 18px 0;
    min-height: 420px;
  }

  .moderation {
    &__section {
      width: 100%;
      margin-bottom: 16px;

      &-header {
        display: flex;
        justify-content: space-around;
        padding: 16px 0;
        border-top: 1px solid $blue-grey50;
        border-bottom: 1px solid $blue-grey50;

        & span {
          @include caption-small;

          font-size: 14px;
          color: $blue-grey10;
        }
      }

      &-body {
        margin-top: 16px;
        //padding-right: 32px;
      }
    }

    &__players:not(:last-child) {
      padding-bottom: 16px;
      border-bottom: 2px solid $green60;
      margin-bottom: 16px;
    }

    &__done {
      @include heading4;

      color: $white;
    }
  }

  &__end {

  }

  .player-row:not(:last-child) {
    margin-bottom: 8px;
  }
}

.account-list {
  &__list {
    max-width: 660px;
    width: 100%;
  }

  &__element {
    padding: 20px 24px;
    background: $blue-grey60;
    border-radius: 8px;
    margin-top: 8px;
    color: $white;
  }
}
</style>