<template lang="pug">
  .notification
    .notification__item-top

      .notification__item-left
        img(:src="notification.image")

      .notification__item-right
        .notification__item-date {{ notification.date }}
        .notification__item-title {{ notification.title }}
        .notification__item-text {{ notification.text }}

    .notification__item-actions(v-if="notification.link_url")
      a.notification__item-action(class="--primary" @click="onActionClick")
        | Перейти

</template>

<script>
export default {
  name: 'Notification',

  props: {
    notification: {
      type: Object,
      required: true,
    },
  },

  methods: {
    onActionClick() {
      window.open(this.notification.link_url)
    }
  },
}
</script>

<style lang="scss">
.notification {
  background: $blue-grey70;
  color: $blue-grey05;
  border: 1px solid $blue-grey40;
  padding: 16px;
  max-width: 384px;
  border-radius: 8px;

  &__item {
    &-date {
      font-family: $mainFont;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      color: $blue-grey10;
      padding-bottom: 8px;
    }

    &:last-child {
      border-bottom: none;
    }

    &-actions {
      margin-top: 12px;
      text-align: right;
    }

    &-action {
      @include heading6;

      cursor: pointer;
      color: $blue-grey10;

      &.--primary {
        color: $primary60;
      }
    }

    &-action + &-action {
      margin-left: 16px;
    }

    &-top {
      display: flex;
    }

    &-left {
      margin-right: 16px;
      width: 56px;

      img {
        min-height: 56px;
        min-width: 56px;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    &-title {
      @include heading4;

      margin-bottom: 8px;
    }

    &-text {
      @include text-display100;
      line-height: 23px;
      color: $blue-grey10;
    }

    &:hover {
      background: $blue-grey60;
    }

    &.--not-read {
      background: $blue-grey50;
    }
  }
}
</style>
