import Vue from 'vue';

const state = () => ({
  nominations: [],
});

const mutations = {
  setNominations(state, payload) {
    state.nominations = payload;
  },

  setIcon(state, { index, icon }) {
    Vue.set(state.nominations[index].icon, 'src', icon);
  },
};

const getters = {
  nominations(state) {
    return state.nominations;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
