<template lang="pug">
    .user-payout
      SectionHeader(:header="header")
  
      .action__body
        .action__section
          .action__grid
            .action__form-field(v-for="(item, i) of items" :key="i")
              InputSmart(:item="item")
  
        .action__bottom
          Button(is-primary @click="onCheckButtonClick") Изменить
  
</template>
  
  <script>
  import { mapActions } from 'vuex'
  import InputSmart from '@/components/InputSmart/InputSmart'
  import SectionHeader from '@/components/SectionHeader/SectionHeader'
  
  export default {
    name: 'PayoutStatus',
  
    components: {
      InputSmart,
      SectionHeader,
    },
  
    data: () => ({
      items: {
  
        payoutStatus: {
          title: 'Действие',
          value: true,
          type: 'select',
          options: [
            { title: 'Разрешить вывод', value: true },
            { title: 'Заблокировать вывод', value: false },
          ],
          withEmptyValue: false,
        },
      },
    }),
  
    computed: {
      gameName() {
        return this.$route.params.section
      },
  
      header() {
        return {
          title: `${this.$gameNameText(this.gameName)} / Массовая блокировка вывода/ввода`,
          game: this.gameName,
        }
      },
    },
  
    created() {
      this.$forceUpdate()
    },
  
    methods: {
      ...mapActions('modal', ['showModal']),
  
      async onCheckButtonClick() {
        this.$loading.start()
  
        try {
          const payoutStatus = this.items.payoutStatus.value
  
          const { data } = await this.$axios.put(`/admin/payment/status`, {
            is_payout_enabled: payoutStatus,
          })
  
          if (data.data) {
            this.showModal({
              component: 'ModalMessage',
              data: {
                type: 'success',
                title: 'Успех',
                text: 'Статус автовывода изменен!',
              },
            })
          }
        } catch (e) {
          this.$error.handle(e)
        }
  
        this.$loading.finish()
      }
    },
}
</script>
  
<style lang="scss">
</style>
  