<template lang="pug">
  .player-rank(v-if="rankValue")
    span(:class="'--rank-' + rankValue") {{ rankValue }}

</template>

<script>
export default {
  name: 'PlayerRank',

  props: {
    rank: {
      type: String,
      default: '',
    },
  },

  computed: {
    rankValue() {
      return this.rank
    },
  },
}
</script>

<style lang="scss">
.player-rank {
  display: inline-flex;
  align-items: center;

  /* stylelint-disable-next-line */
  > span {
    @include caption-small;

    display: inline-block;
    width: 20px;
    min-width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    margin-left: 8px;
    color: $white;

    &.--rank-a {
      background: $red70;
    }
    &.--rank-b {
      background: $orange70;
    }
    &.--rank-c {
      background: $deep-blue70;
    }
    &.--rank-d {
      background: $green70;
    }
    &.--rank-e {
      background: $light-blue70;
    }
    &.--rank-f {
      background: $cyan70;
    }
    &.--rank-g {
      background: $purple70;
    }
    &.--rank-h {
      background: $blue-grey40;
    }
  }
}
</style>
