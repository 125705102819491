import axios from '@/plugins/axios'
import qs from 'qs'

const state = () => ({
  games: [],
  gamesByName: {},
  gamesById: {},
})

const actions = {
  async fetchGames({ commit }) {
    try {
      const { data } = await axios.get('/games')

      commit('setGames', data.data)
    } catch (e) {
      console.error('games fetch error', e)
    }
  },

  async fetchTournaments(_, query = {}) {
    const { game = 'pubg-mobile' } = query
    const url = query.url || `/games/${game}/tournaments`

    delete query.game
    delete query.url

    try {
      const { data } = await axios.get(url, {
        params: query,
        paramsSerializer: (params) => {
          return qs.stringify(params)
        },
      })

      return data
    } catch (e) {
      console.error('tournaments fetch error', e)
    }
  },

  async fetchDuels(_, query = {}) {
    const { game = 'pubg-mobile' } = query
    const url = query.url || `/games/${game}/duels`

    delete query.game
    delete query.url

    try {
      const { data } = await axios.get(url, {
        params: query,
        paramsSerializer: (params) => {
          return qs.stringify(params)
        },
      })

      return data
    } catch (e) {
      console.error('duels fetch error', e)
    }
  },
}

const mutations = {
  setGames(state, payload) {
    // const adminSupportGames = ['lichess', 'pubg-mobile', 'clash-royale', 'brawl-stars', 'pubg-desktop', 'standoff']
    const canClickNicknameGames = ['lichess']
    // const duelSupportGames = []

    state.games = payload
      .map((game) => ({
        ...game,
        // isAdminSupported: adminSupportGames.includes(game.name),
        isAdminSupported: true,
        canClickNickname: canClickNicknameGames.includes(game.name),
      }))
      .sort((a) => a.name === 'brawl-stars' ? 1 : -1)

    state.gamesById = state.games.reduce((result, game) => {
      result[game.id] = game

      return result
    }, {})

    state.gamesByName = state.games.reduce((result, game) => {
      result[game.name] = game

      return result
    }, {})

    this.dispatch('navigation/setSections', state.games, { root: true })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
