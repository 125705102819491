<template lang="pug">
  .create-pubg-tournament
    Steps(:steps="stepTitles" :current-step-index="currentStepIndex")

    .create-pubg-tournament__body
      .create-pubg-tournament__step(v-if="currentStepIndex === 0")
        .tournaments-create__caption Создайте лобби со следующими параметрами:

        CopyInput.create-pubg-tournament__field(
          v-for="input in copyInputs"
          :key="JSON.stringify(input)"
          :value="input.value"
          :title="input.title"
        )

        .tournaments-create__caption А затем впишите код от созданного лобби:

        InputSmart.create-pubg-tournament__field(
          v-for="field in fields"
          :key="field.title"
          :item="field"
          :type="field.type"
        )

        Button(is-primary @click="onNextStepButtonClick" :is-disabled="!isNextStepAvailable") Отправить

      .create-pubg-tournament__step(v-if="currentStepIndex === 1")
        .tournaments-create__caption Запустите бой в игре в это время — {{ tournamentDate }} — и после нажмите на кнопку ниже
        .tournaments-create__caption Минимальное необходимое для старта количество игроков — {{ minPlayersCount }}

        .create-pubg-tournament__buttons
          Button(is-primary @click="onNextStepButtonClick" :is-disabled="!isNextStepAvailable") Турнир начался
          Button(is-secondary @click="isEdit = true") Редактировать данные

        .moderation-tournament__section
          .moderation-tournament__section-header
            span Игроки

          .moderation-tournament__section-body
            .moderation-tournament__players
              WinnerRow(
                v-for="player in tournamentMembers"
                :key="player.id"
                :winner="player"
              )

      .create-pubg-tournament__step(v-if="currentStepIndex === 2")
        .tournaments-create__caption Как только матч в игре завершится, сохраните скриншоты всего ТОПа игроков и нажмите на эту кнопку
        Button(is-primary @click="onNextStepButtonClick") Отправить на модерацию

      .create-pubg-tournament__step(v-if="currentStepIndex === 3" style="max-width: unset;")
        TournamentModeration(:tournament="tournament" @update="updateTournament")


</template>

<script>
import { mapActions } from 'vuex'
import Steps from '@/components/Steps/Steps'
import CopyInput from '@/components/CopyInput/CopyInput'
import InputSmart from '@/components/InputSmart/InputSmart'
import TournamentModeration from '@/components/TournamentModeration/TournamentModeration'
import WinnerRow from '@/components/WinnerRow/WinnerRow'

export default {
  name: 'CreatePubgTournament',

  components: {
    Steps,
    CopyInput,
    InputSmart,
    TournamentModeration,
    WinnerRow,
  },

  props: {
    tournamentData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    tournament: {},

    fields: {
      lobbyIdInput: {
        title: 'ID созданного лобби',
        type: 'text',
        value: null
      }
    },

    isEdit: false,
  }),

  computed: {
    gameName() {
      return this.$route.params.section
    },

    tournamentId() {
      return this.$route.params.id
    },

    tournamentDate() {
      return this.$moment.format(this.tournament.date, 'lll')
    },

    copyInputs() {
      const view = this.tournament.extra_data.config.view

      return [
        { title: 'Title', value: this.tournament.extra_data.config.title },
        { title: 'Password', value: this.tournament.extra_data.config.password },
        { title: 'Map', value: this.tournament.extra_data.config.map },
        { title: 'Mode', value: this.tournament.extra_data.config.mode },
        {
          title: 'View',
          value: view === 'third_person' ? 'Third-person' : 'First-person'
        }
      ]
    },

    stepTitles() {
      return ['Создание', 'Старт', 'Завершение', 'Модерация']
    },

    currentStepIndex() {
      if (
          (this.tournament?.state === 'open' && !this.tournament?.extra_data?.state?.game?.extra_data?.id)
          || this.isEdit
      ) {
        return 0
      } else if (this.tournament?.state === 'open') {
        return 1
      } else if (this.tournament?.state === 'in_progress') {
        return 2
      }

      return 3
    },

    isNextStepAvailable() {
      if (this.currentStepIndex === 0 && !this.fields.lobbyIdInput.value) {
        return false
      }

      return true
    },

    tournamentPlayers() {
      return this.tournament?.players?.map(player => {
        return {
          username: player.game_account?.username
        }
      })
    },

    tournamentMembers() {
      if (this.isTeams) {
        return this.tournament?.teams?.map(team => {
          const membersNames = team.members?.map(member => member.game_account?.username)

          return {
            name: `Команда: ${membersNames.join(', ')}`,
            points: null,
            place: null,
          }
        })
      }

      const players = this.tournament?.players

      return players?.map(player => ({ name: player.game_account?.username }))
    },

    minPlayersCount() {
      return this.tournament.tournament_player_settings?.min_players_count
    }
  },

  watch: {
    tournamentData() {
      this.tournament = this.tournamentData
      // this.tournament.is_auto_results_enabled = true
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async updateTournament() {
      this.$loading.start()

      try {
        const { data } = await this.$axios.get(
          `/games/${this.gameName}/tournaments/${this.tournamentId}`
        )

        this.tournament = data.data
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    async onNextStepButtonClick() {
      this.$loading.start()

      try {
        if (this.currentStepIndex === 0) {
          await this.$axios.post(
            `/games/${this.gameName}/tournaments/${this.tournamentId}/lobby`,
            {
              id: this.fields.lobbyIdInput.value
            }
          )

          this.isEdit = false
        } else if (this.currentStepIndex === 1) {
          await this.$axios.put(
            `/games/${this.gameName}/tournaments/${this.tournamentId}`,
            { state: 'in_progress' }
          )
        } else if (this.currentStepIndex === 2) {
          await this.$axios.put(
              `/games/${this.gameName}/tournaments/${this.tournamentId}`,
              { state: 'moderation' }
          )
        }

        setTimeout(async () => {
          await this.updateTournament()
          this.$loading.finish()
        }, 1000)
      } catch (e) {
        this.$error.handle(e)
      }
    },

    onNominationsChange() {
      this.updateTournament()
    },
  }
}
</script>

<style scoped lang="scss">
.create-pubg-tournament {
  &__buttons {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .button:first-child {
      margin-bottom: 8px;
      width: 100%;
    }
  }

  &__step {
    //max-width: 350px;
    margin: 20px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__field {
    margin-bottom: 16px;
    width: 100%;
  }

  .moderation-tournament {
    &__section {
      width: 100%;
      margin-bottom: 16px;

      &-header {
        display: flex;
        justify-content: space-around;
        padding: 16px 0;
        border-top: 1px solid $blue-grey50;
        border-bottom: 1px solid $blue-grey50;

        & span {
          @include caption-small;

          font-size: 14px;
          color: $blue-grey10;
        }
      }

      &-body {
        margin-top: 16px;
        //padding-right: 32px;
      }
    }

    &__players:not(:last-child) {
      padding-bottom: 16px;
      border-bottom: 2px solid $green60;
      margin-bottom: 16px;
    }

    &__done {
      @include heading4;

      color: $white;
    }
  }

  &__end {
    margin-top: 8px;
  }

  .player-row:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
