import store from '@/store/index'

const ERROR_CODES = {
  1: `Токен не валидный`,
  2: `Ничего не найдено`,
  3: `Действия на платформе ограничены`,
  4: ({ gameName }) => `Бот для ${gameName} не найден`,
  5: ({ gameName }) => `Нет свободных ботов для ${gameName}`,
  6: ({ error }) => `Ошибка от Личесс: ${error}`,
  7: `Ошибка создания`,
  8: `Не привязан аккаунт Lichess`,
  9: `У оппонента не привязан аккаунт Lichess`,
  10: `Вы ввели неверный номер телефона`,
  11: `Неверные параметры запроса`,
  12: `Неправильные данные пользователя`,
  13: ({ ban_reason, ban_end }) =>
      `Действия на платформе ограничены: ${ban_reason}. Бан действителен до ${ban_end}`,
  14: `Такой логин уже занят`,
  15: `Такой e-mail уже зарегистрирован`,
  16: `Почта не подтверждена!`,
  17: `Вы использовали соц. сеть для входа`,
  18: `Пользователь не найден или введен неверный пароль!`,
  19: `Подтвердите телефон!`,
  20: `Верификация на рассмотрении`,
  21: `Код не найден или истек`,
  22: `На счету недостаточно денег!`,
  23: `Необходимо заполнить контактную информацию`,
  24: `В турнире допускаются только игроки 16+!`,
  25: `Ошибка конфигурации`,
  26: `Этап(ы) не найден!`,
  27: `Турнир не найден`,
  28: `Дуэль не найдена`,
  29: `Дата дуэли не может быть в прошлом`,
  30: `Не указана стоимость входа`,
  31: `Детали дуэли отсутсвуют`,
  32: `На вашем балансе недостаточно средств для участия`,
  33: `У вас есть неоконченные дуэли. Закончите дуэль перед созданием новой!`,
  34: `У вас недостаточный рейтинг для участия в дуэли!`,
  35: `У вас слишком большой рейтинг для участия в дуэли`,
  36: `Отсутствует оппонент`,
  37: `Недостаточно монет!`,
  38: `У соперника недостаточно монет`,
  39: `У вас или у соперника есть неоконченные дуэли. Закончите дуэль перед созданием новой!`,
  40: `Нельзя отменить дуэль, в которой есть оппонент!`,
  41: `Дуэль не найдена или не на модерации или не по Pubg Mobile`,
  42: `Отсутствует состояние`,
  43: `Пользователь состоит в незаконченных турнирах`,
  44: `Пользователь состоит в незаконченных дуэлях`,
  45: `Пользователь не найден`,
  46: ({ gameName }) => `Аккаунт ${gameName} не подтвержден`,
  47: `Аккаунт не найден`,
  48: `Аккаунт верифицирован или не имеет данных верификации`,
  49: ({ gameName }) => `Отсутствует аккаунт ${gameName}`,
  50: ({ username }) => `Этот аккаунт уже привязан к пользователю ${username}`,
  51: `Турнир закрыт!`,
  52: `Вход в лобби закрыт, до начала турнира осталось 15 минут.`,
  53: `Невозможно оплатить турнир на монеты!`,
  54: ({ ratingGroup, ratingType }) =>
      `Рейтинговая группа турнира '${ratingGroup}' - ваша '${ratingType}', вы не можете вступить в данный турнир`,
  55: ({ min_lichess_game }) =>
      `Вы должны сыграть минимум ${min_lichess_game} на Lichess, чтобы присоединиться к турниру!`,
  56: ({ minimal_level }) =>
      `Ваш уровень ниже ${minimal_level}, вы не можете участвовать в турнире!`,
  57: `В турнире максимальное число участников!`,
  58: `Вы уже учавствуете в турнире!`,
  59: `Вы уже участвуете в турнире, который проходит одновременно с этим турниром!`,
  60: `Турнир скоро начнется, вы не можете выйти!`,
  61: `Вы не участвуете в турнире!`,
  62: `Необходимо верифицировать аккаунт`,
  63: ({ limit }) => `Невозможно вывести менее ${limit}EUR`,
  64: `Необходимо пройти дополнительную верификацию для вывода более 15000 EUR`,
  65: `Игра не найдена`,
  66: `Недостаточно билетов на балансе`,
  67: `Недостаточно баланса билетов для участия!`,
  68: `Продукт не найден`,
  69: `Введите все данные`,
  70: `Турнир не найден или не на модерации или не по Pubg Mobile`,
  71: `Регистрация на турнир еще не началась`,
  72: `Вход в турнир закрыт`,
  73: `Необходимо верифицировать профиль FIDE для записи в турнир`,
  74: ({ os }) => `В турнир можно играть только с платформы ${os}`,
  75: ({ mimetype }) => `Unsupported mime type ${mimetype}`,
  76: `В вашей колоде недостаточно карт`,
  77: `Ошибка личесс аккаунта`,
  78: ({ gameName }) => `Ошибка бота ${gameName}`,
  79: `Игрок уже зарегистрирован в матче`,
  80: `Пользователь не зарегистрирован в матче`,
  81: `Дублирование события`,
  82: ({ gameName }) => `Игра ${gameName} не поддерживается`,
  83: `Отсутствует игровое событие`,
  84: `Событие не поддерживается`,
  85: `Метод не поддерживается`,
  86: `Отсутствует привязанный аккаунт для игры!`,
  87: `Автоматический вывод отключен`,
  88: `Это командный турнир!`,
  89: `Вы уже состоите в команде`,
  90: `Команда не найдена`,
  91: `Вы не состоите в команде или не являетесь капитаном!`,
  92: `Максимальное число участников в команде!`,
  93: `Параметры поиска не заданы`,
  94: `Турнир уже начинается, вы не можете выйти`,
  95: `Это индивидуальный турнир`,
  96: `Размер команды превышает требование турнира`,
  97: `Команда не участвует в турнире`,
  98: `Вы уже оплатили участие или не являетесь членом команды`,
  99: `Вы не являетесь капитаном`,
  100: `Ошибка сервера`,
  101: `Нужно подтвердить контактные данныe`,
  102: `Такой никнейм уже привязан к другому пользователю`,
  103: 'Игровой аккаунт не найден',
  104: 'Такой аккаунт уже зарегистрирован',
  105: 'Для входа в этот турнир необходим пароль',
  106: 'Неверный пароль турнира',
  107: 'Превышен размер файла',
  108: 'Не удалось сохранить файл',
  109: 'Неверный формат загружаемого файла',
  110: 'Турнир ещё не промодерирован, невозможно завершить',
  111: 'Социальная сеть уже привязана',
  112: 'Топ игроков не доступен',
  113: 'Вы исчерпали лимит фрироллов на сегодня. Купите подписку или приходите завтра :)',
  114: 'Не та дисциплина у отменяемого турнира',
  115: 'Требования к спонсорам не выполнены',
  116: 'Вывод средств в процессе',
  117: 'Неверный домен почты. Попробуйте @gmail.com, @yandex.ru или другой',
  10001: 'Google Pay отменил платёж',
  20001: 'Неверный срок окончания действия карты',
  20002: 'Неверный номер карты',
  20003: 'Пустое значение получателя при оплате картой',
  20004: 'Неверный номер электронного кошелька',
  20005: 'Неверный e-mail',
}

export default {
  handle(e) {
    store.dispatch('modal/showModal', {
      component: 'ModalMessage',
      data: {
        text: this.getText(e),
      },
    })
  },

  getText(e) {
    const errorData = e?.response?.data
    const error = ERROR_CODES[errorData?.code]

    if (!error) {
      return e?.message || 'Неизвестная ошибка ' + errorData?.code
    }

    if (typeof error === 'function') {
      return error(errorData.error_data)
    }

    return error
  },
}