<template lang="pug">
  .currency-switcher
    .currency__item(
      class="--tickets"
      :class="{ '--active': activeCurrency === 'tickets' }"
      @click="activeCurrency = 'tickets'"
    )
      InlineSvg(:src="require('@/assets/img/icons/ticket.svg')")

    .currency__item(
      class="--money"
      :class="{ '--active': activeCurrency === 'money' }"
      @click="activeCurrency = 'money'"
    )
      InlineSvg(:src="require('@/assets/img/icons/euro.svg')")

    .currency__item(
      class="--coins"
      :class="{ '--active': activeCurrency === 'coins' }"
      @click="activeCurrency = 'coins'"
    )
      InlineSvg(:src="require('@/assets/img/icons/coins.svg')")

</template>

<script>
export default {
  name: 'CurrencySwitcher',

  props: {
    activeCurrency: {
      type: String,
      default: 'coins',
    },
  },

  watch: {
    activeCurrency() {
      this.$emit('input', this.activeCurrency)
    },
  },
}
</script>

<style lang="scss">
.currency-switcher {
  padding: 8px;
  background-color: $blue-grey50;
  border-radius: 8px;
  height: 48px;
  display: flex;

  .currency {
    &__item {
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;

      svg {
        width: 24px;

        path {
          fill: $blue-grey10;
        }
      }

      &.--active.--coins {
        background: $amber60-20;
      }

      &.--active.--money {
        background: $green60-20;
      }

      &.--active.--tickets {
        background: $green60-10;
      }

      /* stylelint-disable-next-line */
      &.--active.--coins svg,
      &.--coins svg:hover {
        /* stylelint-disable-next-line */
        path {
          fill: $amber60;
        }
      }

      /* stylelint-disable-next-line */
      &.--active.--money svg,
      &.--money svg:hover {
        /* stylelint-disable-next-line */
        path {
          fill: $green60;
        }
      }

      /* stylelint-disable-next-line */
      &.--active.--tickets svg,
      &.--tickets svg:hover {
        /* stylelint-disable-next-line */
        path {
          fill: $teal60;
        }
      }
    }
  }
}
</style>