<template lang="pug">
  .paginations(v-if="pagesCount > 1 || withPerPage")
    .paginations__body
      .paginations__opt
        BaseSelect(v-if="withPerPage" v-bind="perPageSelect" :value="perPage.toString()" @input="onPerPageChange")

      ul.paginations__menu(v-if="pagesCount > 1")
        li.paginations__item(v-if="isPrevVisible")
          Button(is-bordered icon="arrow-left" @click="onPrevClick") Назад

        li.paginations__item(
          v-for="page in pagesList"
          :key="page.title || page.value"
        )
          Button(is-secondary :is-active="page.value === currentPage" @click="onPageClick(page)")
            | {{ page.title || page.value + 1 }}

        li.paginations__item(v-if="isNextVisible")
          Button(is-bordered icon="arrow-right" @click="onNextClick") Вперёд

</template>

<script>
import BaseSelect from '@/components/BaseSelect/BaseSelect'

export default {
  name: 'Pagination',

  components: { BaseSelect },

  props: {
    pagesCount: {
      type: Number,
      required: true
    },

    currentPage: {
      type: Number,
      default: 0
    },

    perPage: {
      type: Number,
      default: 0
    },

    withPerPage: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      perPageSelect: {
        name: 'perPage',
        options: [
          {
            title: 'По 6',
            value: '6',
            isLocalised: true
          },
          {
            title: 'По 12',
            value: '12',
            isLocalised: true
          },
          {
            title: 'По 24',
            value: '24',
            isLocalised: true
          },
          {
            title: 'По 48',
            value: '48',
            isLocalised: true
          }
        ],
        withEmptyValue: false
      }
    }
  },

  computed: {
    pagesList () {
      const pages = [{ value: this.currentPage }]

      if (this.currentPage - 1 >= 0) {
        pages.unshift({ value: this.currentPage - 1 })
      }

      if (this.currentPage + 1 < this.pagesCount) {
        pages.push({ value: this.currentPage + 1 })
      }

      if (this.currentPage === 0 && this.currentPage + 2 < this.pagesCount) {
        pages.push({ value: this.currentPage + 2 })
      }

      if (
        this.currentPage + 1 === this.pagesCount &&
        this.currentPage - 2 >= 0
      ) {
        pages.unshift({ value: this.currentPage - 2 })
      }

      if (pages[0].value !== 0) {
        pages.unshift({ value: 0, title: '1...' })
      }

      if (
        pages[pages.length - 1].value !== this.pagesCount - 1
      ) {
        pages.push({
          value: this.pagesCount - 1,
          title: `...${this.pagesCount}`
        })
      }

      return pages
    },

    isPrevVisible () {
      return this.currentPage > 0
    },

    isNextVisible () {
      return this.currentPage < this.pagesCount - 1
    }
  },

  methods: {
    onNextClick () {
      if (!this.isNextVisible) {
        return
      }

      this.$emit('pageChange', this.currentPage + 1)
    },

    onPrevClick () {
      if (!this.isPrevVisible) {
        return
      }

      this.$emit('pageChange', this.currentPage - 1)
    },

    onPageClick ({ value }) {
      if (value === this.currentPage) {
        return
      }

      this.$emit('pageChange', value)
    },

    onPerPageChange (value) {
      this.$emit('perPageChange', Number(value))
    }
  }
}
</script>

<style lang="scss">
.paginations {
  width: 100%;
  padding: 20px;
  border-top: 2px solid #2b38484d;
  border-bottom: 2px solid #2b38484d;

  &__body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__menu {
    display: flex;
    align-items: center;
  }

  &__item {
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .button--secondary {
    min-width: 48px;
  }
}

@media (max-width: $breakpointDesktopMedium) {
  .paginations {
    &__body {
      flex-direction: row-reverse;
      align-items: flex-end;
    }

    &__menu {
      margin-top: 24px;
    }
  }
}

@media (max-width: $breakpointTablet) {
  .paginations {
    &__body {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &__menu {
      margin-top: 0;
    }

    &__item {
      margin: 0 4px;
    }

    .button--bordered {
      .button {
        &__text {
          display: none;
        }

        &__icon {
          margin-right: 0;
        }
      }
    }

    .base-select {
      margin-top: 16px;
    }
  }
}

@media (max-width: $breakpointMobile) {
  .paginations {
    .button.button--bordered {
      width: 48px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
