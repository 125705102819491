import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'Home', component: () => import('@/views/Home') },

  {
    path: '/lichess',
    component: () => import('@/views/Section'),
    children: [
      {
        path: 'moderation/tournament/:id',
        component: () =>
          import('@/views/actions/LichessModerationTournament'),
      },
    ],
  },

  {
    path: '/:section',
    component: () => import('@/views/Section'),
    children: [
      {
        path: 'create',
        component: () => import('@/views/actions/CreateTournament'),
      },
      {
        path: 'crud',
        component: () => import('@/views/actions/CRUDTournament'),
      },
      {
        path: 'create-mega',
        component: () => import('@/views/actions/CreateMegaTournament'),
      },
      { path: 'rating', component: () => import('@/views/actions/Rank') },
      { path: 'ban', component: () => import('@/views/actions/Ban') },
      {
        path: 'multi-account',
        component: () => import('@/views/actions/MultiAccount'),
      },
      {
        path: 'check-account',
        component: () => import('@/views/actions/CheckGameAccounts'),
      },
      {
        path: 'cancel',
        component: () => import('@/views/actions/CancelEvent'),
      },
      {
        path: 'cancel-all-tournaments',
        component: () => import('@/views/actions/CancelAllTournaments'),
      },
      { path: 'coins', component: () => import('@/views/actions/Coins') },
      { path: 'money', component: () => import('@/views/actions/Money') },
      { path: 'g-cash', component: () => import('@/views/actions/Gcash') },
      { path: 'marketplace', component: () => import('@/views/actions/Marketplace') },
      {
        path: 'tickets',
        component: () => import('@/views/actions/Tickets'),
      },
      {
        path: 'notify',
        component: () => import('@/views/actions/Notify'),
      },
      {
        path: 'notify-telegram',
        component: () => import('@/views/actions/NotifyTelegram'),
      },
      {
        path: 'moderation',
        component: () => import('@/views/actions/ModerationList'),
      },
      {
        path: 'account-verification',
        component: () => import('@/views/actions/AccountVerification'),
      },
      {
        path: 'events',
        component: () => import('@/views/actions/EventsList'),
      },
      {
        path: 'events/tournaments/:id',
        component: () => import('@/views/actions/TournamentHolding'),
      },
      {
        path: 'events/duels/:password',
        component: () => import('@/views/actions/DuelsCreate'),
      },
      {
        path: 'verification',
        component: () => import('@/views/actions/UserVerification'),
      },
      {
        path: 'moderation/duel/:password',
        component: () => import('@/views/actions/ModerationDuel'),
      },
      {
        path: 'unlink-account',
        component: () => import('@/views/actions/UnlinkAccount'),
      },
      {
        path: 'reset-monitoring',
        component: () => import('@/views/actions/ResetMonitoring'),
      },
      {
        path: 'lawyers-report',
        component: () => import('@/views/actions/LawyersReport'),
      },
      {
        path: 'link-account',
        component: () => import('@/views/actions/LinkAccount'),
      },
      {
        path: 'edit-tournament',
        component: () => import('@/views/actions/EditTournament'),
      },
      {
        path: 'tournament-parser',
        component: () => import('@/views/actions/TournamentParser'),
      },
      {
        path: 'mega-tournament-parser',
        component: () => import('@/views/actions/TournamentParser'),
      },
      {
        path: 'daily-tournament-parser',
        component: () => import('@/views/actions/TournamentParser'),
      },
      {
        path: 'daily-instant-tournament-parser',
        component: () => import('@/views/actions/TournamentParser'),
      },
      {
        path: 'weekly-tournament-parser',
        component: () => import('@/views/actions/TournamentParser'),
      },
      {
        path: 'sync-translations',
        component: () => import('@/views/actions/SyncTranslations'),
      },
      {
        path: 'banners',
        component: () => import('@/views/actions/UpdateBanners'),
      },
      {
        path: 'members',
        component: () => import('@/views/actions/EditMembers'),
      },
      {
        path: 'sale',
        component: () => import('@/views/actions/TournamentSale'),
      },
      { path: 'payout', component: () => import('@/views/actions/UserPayout') },
      { path: 'payout-status', component: () => import('@/views/actions/PayoutStatus') },
      { path: 'email-verify', component: () => import('@/views/actions/EmailVerify') },
      { path: 'check-account-by-email', component: () => import('@/views/actions/CheckAccountByEmail') },
      { path: 'faq', component: () => import('@/views/actions/Faq') },
      {
        path: 'tickets-exchange',
        component: () => import('@/views/actions/TicketsExchange'),
      },
      {
        path: 'referral',
        component: () => import('@/views/actions/Referral'),
      },
      {
        path: 'quests',
        component: () => import('@/views/actions/QuestCenter'),
      },
      {
        path: 'influencers',
        component: () => import('@/views/actions/InfluencersVerification'),
      },
      {
        path: 'game-currencies',
        component: () => import('@/views/actions/GameCurrencies'),
      },
      {
        path: 'complete-offer',
        component: () => import('@/views/actions/OfferwallComplete'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
