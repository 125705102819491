<template lang="pug">
  .winner-row
    .winner-row__section.winner-row__number(v-if="winner.place")
      | {{ winner.place }}

    .winner-row__section.winner-row__number(v-else)
      | ?

    .winner-row__section.winner-row__middle
      .winner-row__section-inner
        | {{ winner.name }}
        span.winner-row__id(v-if="winner.rating") {{ winner.rating }}
        span.winner-row__id(v-else) {{ winner.id }}

    .winner-row__section.winner-row__number
      .winner-row__section-inner(v-if="Number.isInteger(points)")
        | {{ points }}

      .winner-row__section-inner(v-else)
        | ?

    .winner-row__section.winner-row__right
      WinnerMenu(:winner="winner" location="tournament" @action="onAction($event)")

</template>

<script>
import WinnerMenu from '@/components/WinnerMenu/WinnerMenu'

export default {
  name: 'WinnerRow',

  components: { WinnerMenu },

  props: {
    winner: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    points() {
      return this.winner.points
    },
  },

  methods: {
    onDrop(data) {
      this.$emit('drop', data)
    },

    onAction(type) {
      this.$emit('action', type)
    },
  },
}
</script>

<style lang="scss">
.winner-row {
  color: $white;
  background: $blue-grey60;
  border-radius: 8px;
  display: flex;
  padding: 12px 0;

  &__section {
    padding: 0 12px;
    margin: auto;
    border-right: 1px solid $blue-grey40;

    &:last-child {
      border-right: none;
    }
  }

  &__number {
    min-width: 60px;
    text-align: center;
  }

  &__id {
    padding-left: 10px;
    font-weight: bold;
    color: $wood;
  }

  &__middle {
    display: flex;
    justify-content: space-between;
    width: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__drop {
    width: 105px;
    height: 24px;
    border: 1px dashed $blue-grey20;
    border-radius: 4px;
  }
}
</style>
