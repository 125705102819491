<template lang="pug">
  .holding-lichess-tournament
    TournamentModeration(:tournament="tournament" @update="updateTournament")

</template>

<script>
import TournamentModeration from '@/components/TournamentModeration/TournamentModeration'

export default {
  name: 'HoldingLichessTournament',

  components: { TournamentModeration },

  props: {
    tournamentData: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    tournament: {
      players: []
    },
  }),

  watch: {
    tournamentData() {
      this.tournament = this.tournamentData
    },
  },
}
</script>

<style lang="scss">
</style>
