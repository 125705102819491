<template lang="pug">
  span.tooltip(
    :class="[`--${position}`, { '--pointer': !!link }]"
    @click="onClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  )
    slot
    transition(name="fade" duration="200")
      .tooltip__popup(v-if="isTooltipVisible")
        slot(name="tooltip")
</template>

<script>
export default {
  name: 'Tooltip',

  props: {
    link: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isTooltipVisible: false,
      position: 'top-right',
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll)
    this.onResize()
  },

  methods: {
    onClick() {
      if (this.link) {
        window.open(this.link)
      }
    },

    onScroll() {
      this.isTooltipVisible = false
    },

    onResize() {
      let position = ''

      const height = window.innerHeight
      const width = window.innerWidth

      const rect = this.$el.getBoundingClientRect()

      if (height - rect.top < height / 2) {
        position = 'top'
      } else {
        position = 'bottom'
      }

      if (width - rect.left < width / 2) {
        position += '-left'
      } else {
        position += '-right'
      }

      this.position = position
    },

    onMouseEnter() {
      this.onResize()
      this.isTooltipVisible = true
    },

    onMouseLeave() {
      this.isTooltipVisible = false
    },
  },
}
</script>

<style lang="scss">
.tooltip {
  position: relative;

  &.--pointer {
    cursor: pointer;
  }

  &__popup {
    position: absolute;
    z-index: 2;
    width: fit-content;
    height: fit-content;
    border: 1px solid $grey50;
    border-radius: 8px;
    background: $blue-grey70;

    > div {
      box-sizing: border-box;
      padding: 16px;
    }

    .--top-right & {
      bottom: 0;
      left: 100%;
    }

    .--top-left & {
      bottom: 0;
      right: 100%;
    }

    .--bottom-right & {
      top: 0;
      left: 100%;
    }

    .--bottom-left & {
      top: 0;
      right: 100%;
    }
  }
}
</style>
