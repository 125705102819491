<template lang="pug">
  .tournament-nominations
    .tournament-nomination(v-for="(nomination, i) in nominationsList")
      .tournament-nomination__close
        a.tournament-nomination__close-button(@click.prevent="onRemoveClick(i)")
          InlineSvg(:src="require('@/assets/img/icons/close.svg')")

      InputSmart(
        :item="nomination.title"
        v-model="nomination.title.value"
        :type="nomination.title.type"
      ).tournament-nomination__title

      IconSwitcher.tournament-nomination__icon(v-model="nomination.icon" :index="i")

      .tournament-nomination__prize
        InputSmart(
          :item="nomination.prize"
          v-model="nomination.prize.value"
          :type="nomination.prize.type"
        ).tournament-nomination__prize-value

        CurrencySwitcher(v-model="nomination.currency" :active-currency="nomination.currency")

    .tournament-nominations__add(@click="onAddClick")
      .tournament-nominations__add-text Добавить номинацию

</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import InputSmart from '@/components/InputSmart/InputSmart'
import CurrencySwitcher from '@/components/CurrencySwitcher/CurrencySwitcher'
import IconSwitcher from '@/components/IconSwitcher/IconSwitcher'

export default {
  name: 'TournamentNominations',

  components: { IconSwitcher, CurrencySwitcher, InputSmart },

  data() {
    return {
      nominationsList: [],
    }
  },

  computed: {
    ...mapGetters('nominations', ['nominations']),
  },

  mounted() {
    this.nominationsList = this.nominations
  },

  watch: {
    nominationsList: {
      handler() {
        this.setNominations(this.nominationsList)
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations('nominations', ['setNominations']),

    onAddClick() {
      this.nominationsList.push({
        title: {
          title: 'Название номинации',
          type: 'text',
          value: null,
        },
        prize: {
          title: 'Приз в номинации',
          type: 'number',
          value: null,
        },
        currency: null,
        icon: {
          src: 'crown',
          color: 'white',
        },
      })
    },

    onRemoveClick(index) {
      this.nominationsList.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
.tournament-nomination {
  border: 4px dashed #232f3f;
  padding: 18px 20px;
  border-radius: 16px;
  background: #ffffff05;

  &__prize {
    display: flex;
    align-items: flex-end;

    &-value {
      width: 100%;
      margin-right: 16px;
    }
  }

  &__icon {
    margin: 24px 0;
  }

  &__close {
    display: flex;
    justify-content: flex-end;

    &-button {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      background: $blue-grey50;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        transition: transform 0.2s $easing;
      }

      &:hover {
        background: $blue-grey30;
        svg {
          transform: rotate(90deg);
        }
      }

      &:active {
        background: $blue-grey20;
      }
    }
  }

  .input-form__label,
  .input-number__label {
    background-color: $blue-grey50;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &:last-child {
    margin-bottom: 40px;
  }
}

.tournament-nominations {
  &__add {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 14px 17px 15px;
    border: 2px solid #2187e5;
    width: fit-content;
    border-radius: 6px;
    background: #2b8de40d;

    &:hover {
      background: #2b8ee412;
    }

    &-icon {
      margin-right: 10px;

      svg {
        height: 32px;
        width: 32px;
      }
    }

    &-text {
      font-weight: 500;
      font-size: 19px;
      line-height: 20px;
      color: #1e88e5;
      font-family: 'Roboto', sans-serif;
      letter-spacing: .3px;
    }
  }
}
</style>