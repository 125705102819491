<template lang="pug">
  .game-sidebar
    .game-sidebar__body
      .game-sidebar__content

        router-link(
          v-for="(action, index) of actions"
          :key="index"
          :to="`/${sectionName}${action.route}`"
        ).game-sidebar__button

          .game-sidebar__text
            | {{ action.title }}

          img.game-sidebar__icon(:src="require('@/assets/img/icons/coins-yellow.svg')" v-show="action.title === 'Монеты'")
          img.game-sidebar__icon(:src="require('@/assets/img/icons/ticket.svg')" v-show="action.title === 'Билеты'")
          img.game-sidebar__icon(:src="require('@/assets/img/icons/euro.svg')" v-show="action.title === 'Евро'")
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SectionSidebar',

  computed: {
    ...mapState('navigation', ['sections']),

    sectionName() {
      return (
        this.$route.params.section || this.$route.path?.match(/\/(.*?)\//)?.[1]
      )
    },

    actions() {
      return this.sections[this.sectionName]?.actions
    }
  }
}
</script>

<style lang="scss">
.game-sidebar {
  min-width: fit-content;
  position: relative;
  top: -2px;
  background: $blue-grey80;

  &__body {
    width: 100%;
    color: $white;
  }

  &__content {
    position: relative;

    &:first-child {
      border-top: 0;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    margin: 2px 0;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    height: 56px;
    padding: 20px 24px;

    &.router-link-active,
    &:hover {
      background: $blue-grey60;
    }
  }

  &__icon {
    position: relative;
    bottom: 1px;
    width: 28px;
    height: 28px;
    padding-left: 6px;
  }

  &__text {
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    color: $white;
  }
}
</style>
