<template lang="pug">
  .tournament-sale
    SectionHeader(:header="header")

    .action__body
      .action__section
        .action__grid
          .action__form-field
            InputSmart(:item="game")
          .action__form-field
            InputSmart(:item="tournamentId")
          .action__form-field
            InputSmart(:item="bonusType")
          .action__form-field
            InputSmart(:item="showType")
          .action__form-field(v-if="showType.value === 'date'")
            InputSmart(:item="date")
          .action__form-field(v-if="showType.value === 'min_players'")
            InputSmart(:item="minPlayers")
          .action__form-field
            InputSmart(:item="bonusCurrency")
          .action__form-field(v-if="bonusCurrency.value === 'entry_fee'")
            InputSmart(:item="bonusPercentage")
          .action__form-field(v-else)
            InputSmart(:item="bonusAmount")

      .action__bottom
        Button(is-primary @click="onSaveClick") Добавить

</template>

<script>
import { mapActions } from 'vuex'
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import InputSmart from '@/components/InputSmart/InputSmart'

export default {
  name: 'TournamentSale',

  components: {
    InputSmart,
    SectionHeader,
  },

  data() {
    return {
      game: {
        title: 'Игра',
        value: 'pubg-mobile',
        type: 'select',
        options: [
          { title: 'Lichess', value: 'lichess' },
          { title: 'PUBG Mobile', value: 'pubg-mobile' },
          { title: 'Clash Royale', value: 'clash-royale' },
          { title: 'Brawl Stars', value: 'brawl-stars' },
          { title: 'PUBG', value: 'pubg-desktop' },
        ],
        withEmptyValue: false,
      },

      tournamentId: {
        title: 'ID турнира',
        value: null,
        type: 'number',
        placeholder: 'Введите ID',
      },

      bonusType: {
        title: 'Тип бонуса',
        value: 'discount',
        type: 'select',
        options: [
          { title: 'Скидка', value: 'discount' },
          { title: 'Кэшбэк', value: 'cashback' },
        ],
        withEmptyValue: false,
      },

      showType: {
        title: 'Срок продвижения',
        value: 'tournament_start',
        type: 'select',
        options: [
          { title: 'По старту турнира', value: 'tournament_start' },
          { title: 'Календарное время', value: 'date' },
          { title: 'По кол-ву регистраций на турнир', value: 'min_players' },
        ],
        withEmptyValue: false,
      },

      bonusAmount: {
        title: 'Размер бонуса',
        value: null,
        type: 'number',
        placeholder: 'Введите число',
      },

      bonusPercentage: {
        title: 'Процент от входного билета',
        value: null,
        type: 'number',
        placeholder: 'Введите число',
      },

      bonusCurrency: {
        title: 'Валюта бонуса',
        value: 'entry_fee',
        type: 'select',
        options: [
          { title: 'Percentage of the entrance ticket', value: 'entry_fee' },
          { title: 'Coins', value: 'coins' },
          { title: 'EUR', value: 'money' },
          { title: 'Tickets', value: 'tickets' },
        ],
        withEmptyValue: false,
      },

      date: {
        title: 'Дата',
        placeholder: 'До какого момента будет бонус',
        value: null,
        type: 'date',
        handler(date) {
          return date
        },
      },

      minPlayers: {
        title: 'Минимальное кол-во игроков',
        value: null,
        type: 'number',
        placeholder: 'Введите число',
      },
    }
  },

  computed: {
    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Бонус для турнира`,
        game: this.gameName,
      }
    },
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onSaveClick() {
      this.$loading.start()

      await this.sendSale()

      this.$loading.finish()
    },

    async sendSale() {
      try {
        const game = this.game.value
        const tournamentId = this.tournamentId.value
        const bonusType = this.bonusType.value
        const showType = this.showType.value
        const date = showType === 'date' ? this.date.value : null
        const minPlayers = this.showType.value === 'min_players' ? this.minPlayers.value : null
        const bonusCurrency = this.bonusCurrency.value
        let bonusAmount = bonusCurrency === 'entry_fee'
            ? this.bonusPercentage.value
            : this.bonusAmount.value

        if (!date) {
          return this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'warning',
              title: 'Ошибка',
              text: 'Введите дату',
            },
          })
        }

        if (bonusCurrency === 'money') {
          bonusAmount *= 100
        }

        const prizeSettings = {
          bonus_type: bonusType,
          currency: bonusCurrency,
          amount: bonusAmount,
        }

        const bonusConfiguration = {
          is_enabled: true,
          type: showType,
          date,
          min_players: minPlayers,
          prize_settings: prizeSettings,
        }

        await this.$axios.put(
            `/games/${game}/tournaments/${tournamentId}`,
            { bonus_configuration: bonusConfiguration },
        )

        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'success',
            title: 'Успех',
            text: 'Скидка добавлена!',
          },
        })
      } catch (e) {
        this.$error.handle(e)
      }
    }
  },
}
</script>

<style lang="scss">
</style>