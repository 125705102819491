<template lang="pug">
  .account-verification
    SectionHeader(:header="header")

    .action__body
      .account-verification__list.account-list(v-if="showList")
        .account-list__header
          .account-list__header-text Никнейм на платформе

        .account-list__list(v-if="accountList.length")
          .account-list__element(
            v-for="account of accountList"
            @click="selectAccount(account)"
          ) {{ account.extra_data.username }}

        .action__empty(v-else)
          | Ничего нет

      .account-verification__edit.account-edit(v-else)
        .account-edit__body
          .account-edit__data
            InputSmart.account-edit__input(
              v-for="input of inputFields"
              :key="JSON.stringify(input)"
              :item="input"
              v-model="input.value"
              :type="input.type"
            )

            .account-edit__buttons(v-if="actionButtons.length")
              Button(v-for="button of actionButtons" :key="JSON.stringify(button)" is-primary @click="button.action") {{ button.text }}

          .account-edit__screenshot
            figure.account-edit__screenshot-image(@mousemove="onImageMouseMove" :style="{ 'background-image': `url(${accountScreenshotUrl}` }")
              img(:src="accountScreenshotUrl")

            a.account-edit__screenshot-original(:href="accountScreenshotUrl" target="_blank")
              | Показать оригинал

        .account-edit__bottom
          .account-edit__bottom-heading
            | Причина отказа (если есть нарушения)

          .account-edit__bottom-reasons
            .account-edit__bottom-reason(
              v-for="(reason, i) of reasonsForDecline[gameName]"
              :class="{ '--active': reason.isActive }"
              @click="selectReason(i)"
            ) {{ reason.title }}
              InlineSvg(v-if="reason.isActive" :src="require('@/assets/img/icons/close.svg')")

    .section__footer(v-if="!showList")
      Button.account-verification__confirm(
        v-if="canConfirm"
        is-secondary
        @click="sendStatus(true)"
      ) Одобрить привязку

      Button.account-verification__decline(
        v-else
        is-secondary
        @click="sendStatus(false)"
      ) Отправить на доработку

</template>

<script>
import InputSmart from '@/components/InputSmart/InputSmart'
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import { mapActions } from 'vuex'

export default {
  name: 'AccountVerification',

  components: {
    InputSmart,
    SectionHeader,
  },

  data: () => ({
    accountList: [],
    selectedAccount: {},
    showList: true,
    reasonsForDecline: {
      'pubg-mobile': [
        { title: 'ID не соответствует скриншоту', code: 'verificationError1', isActive: false, },
        { title: 'Никнейм не соответствует скриншоту', code: 'verificationError2', isActive: false, },
        { title: 'Неверный или плохо читаемый скриншот', code: 'verificationError3', isActive: false, },
        { title: 'Уровень аккаунта ниже 25-ого', code: 'verificationError4', isActive: false, },
      ],

      'pubg-new-state': [
        { title: 'ID не соответствует скриншоту', code: 'verificationError1', isActive: false, },
        { title: 'Никнейм не соответствует скриншоту', code: 'verificationError2', isActive: false, },
        { title: 'Неверный или плохо читаемый скриншот', code: 'verificationError3', isActive: false, },
        { title: 'Уровень аккаунта ниже 10-ого', code: 'verificationError8', isActive: false, },
      ],

      lichess: [
        { title: 'Неверная ссылка FIDE', code: 'verificationError5', isActive: false, },
        { title: 'Некорректное фото', code: 'verificationError6', isActive: false, },
        { title: 'Твинк (повторный аккаунт)', code: 'verificationError7', isActive: false, },
      ],

      'free-fire': [
        { title: 'ID не соответствует скриншоту', code: 'verificationError1', isActive: false, },
        { title: 'Никнейм не соответствует скриншоту', code: 'verificationError2', isActive: false, },
        { title: 'Неверный или плохо читаемый скриншот', code: 'verificationError3', isActive: false, },
        { title: 'Уровень аккаунта ниже 25-ого', code: 'verificationError4', isActive: false, },
      ],

      'standoff': [
        { title: 'Никнейм или аватар игрового профиля не соответствуют регламенту', code: 'verificationError9', isActive: false, },
        { title: 'ID не соответствует скриншоту', code: 'verificationError1', isActive: false, },
        { title: 'Никнейм не соответствует скриншоту', code: 'verificationError2', isActive: false, },
        { title: 'Неверный или плохо читаемый скриншот', code: 'verificationError3', isActive: false, },
        { title: 'Уровень аккаунта ниже 25-ого', code: 'verificationError4', isActive: false, },
      ],
    },

  }),

  computed: {
    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Заявки на привязку`,
        game: this.gameName,
      }
    },

    inputFields() {
      if (this.gameName === 'lichess') {
        return [
          {
            title: 'Логин Lichess',
            type: 'text',
            value: this.selectedAccount?.extra_data?.username,
          },

          {
            title: 'Ранг',
            type: 'text',
            value: this.selectedAccount?.extra_data?.rating_groups?.default?.toUpperCase()
          },

          {
            title: 'Рейтинг Blitz',
            type: 'number',
            value: this.selectedAccount?.extra_data?.data?.perfs?.blitz?.rating
          },

          {
            title: 'Рейтинг Bullet',
            type: 'number',
            value: this.selectedAccount?.extra_data?.data?.perfs?.bullet?.rating
          },
        ]
      } else if (this.gameName === 'pubg-new-state') {
        return [
          {
            title: 'Никнейм в игре',
            type: 'text',
            value: this.selectedAccount?.extra_data?.username,
          },
        ]
      } else {
        return [
          {
            title: 'Никнейм в игре',
            type: 'text',
            value: this.selectedAccount?.extra_data?.username,
          },

          {
            title: 'ID аккаунта',
            type: 'number',
            value: this.selectedAccount?.extra_data?.id
          },
        ]
      }
    },

    actionButtons() {
      const buttons = []

      if (this.gameName === 'lichess') {
        buttons.push({
          text: 'Открыть Lichess',
          action: () => {
            window.open('https://lichess.org/@/' + this.selectedAccount?.extra_data?.username)
          }
        })

        const fideUrl = this.selectedAccount?.extra_data?.verification_data?.fideUrl
        if (fideUrl) {
          buttons.push({
            text: 'Открыть FIDE',
            action: () => {
              window.open(fideUrl)
            }
          })
        }
      }

      return buttons
    },

    accountScreenshotUrl() {
      if (this.gameName === 'lichess') {
        return this.selectedAccount?.extra_data?.verification_data?.personImageUrl
      } else {
        return this.selectedAccount?.extra_data?.data?.profile_image_url
      }
    },

    canConfirm() {
      let canConfirm = true

      this.reasonsForDecline[this.gameName].forEach(reason => {
        if (reason.isActive) {
          canConfirm = false
        }
      })

      return canConfirm
    },
  },

  mounted() {
    this.getAccountList()
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async getAccountList() {
      this.$loading.start()

      try {
        const { data } = await this.$axios.get(`/games/${this.gameName}/account/pending-verification`)
        this.accountList = data.data
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    selectAccount(account) {
      this.$loading.start()

      this.selectedAccount = account
      this.showList = false

      setTimeout(this.$loading.finish, 500)
    },

    selectReason(reasonIndex) {
      this.reasonsForDecline[this.gameName][reasonIndex].isActive = !this.reasonsForDecline[this.gameName][reasonIndex].isActive
    },

    async sendStatus(isConfirmed) {
      this.$loading.start()

      try {
        const id = this.selectedAccount.id
        const status = isConfirmed ? 'verified' : 'declined'
        const errors =
          this.reasonsForDecline[this.gameName].filter(reason => reason.isActive).map(reason => reason.code)

        const { data } = await this.$axios.post(`/games/${this.gameName}/account/pending-verification/verify`, {
          id,
          status,
          errors,
        })

        if (!data.error) {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'success',
              title: 'Успех',
              text: 'Всё прошло как нужно! :)',
            },
          })

          this.showList = true
          this.reasonsForDecline[this.gameName].forEach(reason => reason.isActive = false)
          await this.getAccountList()
        }
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    onImageMouseMove(e) {
      const zoomer = e.currentTarget
      let offsetX, offsetY, x, y

      e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
      e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX

      x = offsetX/zoomer.offsetWidth * 40
      y = offsetY/zoomer.offsetHeight * 40

      zoomer.style.backgroundPosition = x + '% ' + y + '%'
    },
  },
}
</script>

<style lang="scss">
.account-verification {
  height: 100%;

  &__list {
    font-family: "Futura PT";

    .account-list {
      &__header {
        border-top: 1px solid $blue-grey50;
        border-bottom: 1px solid $blue-grey50;
        width: 100%;
        padding: 16px 24px;

        &-text {
          @include caption-small;

          color: $blue-grey10;
        }
      }

      &__element {
        padding: 20px 24px;
        background: $blue-grey60;
        border-radius: 8px;
        margin-top: 8px;
        color: $white;
        cursor: pointer;
      }
    }
  }

  &__edit {
    font-family: "Futura PT";
    border-top: 1px solid $blue-grey50;

    .account-edit {
      &__body {
        display: flex;
        padding: 24px 0 40px;

        @media screen and (max-width: $breakpointMobile) {
          flex-direction: column;
        }
      }

      &__data {
        width: 100%;
        padding-right: 24px;

        @media screen and (max-width: $breakpointMobile) {
          padding-right: 0;
        }
      }

      &__input:not(:last-child) {
        margin-bottom: 24px;
      }

      &__buttons {
        display: flex;

        & .button {
          margin-right: 8px;
        }

        @media screen and (max-width: $breakpointMobile) {
          flex-direction: column;

          & .button:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }

      &__screenshot {
        max-width: 45%;

        &-image {
          background-position: 50% 50%;
          position: relative;
          overflow: hidden;
          cursor: zoom-in;

          border-radius: 8px;
          margin: 28px 0 12px;
          max-height: 277px;

          img {
            transition: opacity .5s;
            display: block;
            width: 100%;
          }

          & img:hover {
            opacity: 0;
          }
        }

        &-original {
          color: $primary60;
          cursor: pointer;
        }

        @media screen and (max-width: $breakpointMobile) {
          max-width: 100%;
        }
      }

      &__bottom {
        border-top: 2px solid $primary60;
        padding-top: 24px;

        &-heading {
          @include caption-small;

          color: $blue-grey10;
          margin-bottom: 12px;
        }

        &-reasons {
          display: flex;
          flex-wrap: wrap;
        }

        &-reason {
          background: $blue-grey60;
          border-radius: 8px;
          padding: 16px;
          color: $white;
          cursor: pointer;
          width: fit-content;
          margin: 0 16px 16px 0;

          & svg {
            position: relative;
            bottom: 1px;
            height: 18px;
            width: 18px;
          }
        }

        .--active {
          background: $primary60;
        }
      }
    }
  }

  &__confirm {
    background: $green60 !important;

    .button__text {
      color: $white !important;
    }

    &:hover {
      background: $green40 !important;
    }
  }

  &__decline {
    background: $red60;

    &:hover {
      background: $red40 !important;
    }
  }
}
</style>