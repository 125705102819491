<template lang="pug">
    .action-multi-account
      SectionHeader(:header="header")
  
      .action__body
        .action__section
          .action__grid(:key="gameName")
            .action__form-field(v-for="(item, i) of items" :key="")
              InputSmart(:item="item")
  
        .action__bottom
          Button(is-primary @click="onCheckButtonClick") Проверить
  
  </template>
  
  <script>
  import { mapActions } from 'vuex'
  import InputSmart from '@/components/InputSmart/InputSmart'
  import SectionHeader from '@/components/SectionHeader/SectionHeader'
  
  export default {
    name: 'ActionEmailAccount',
  
    components: {
      InputSmart,
      SectionHeader,
    },
  
    data: () => ({
      items: [
        {
          title: 'Почта',
          value: '',
          placeholder: 'Введите почту игрового аккаунта',
          type: 'text',
        },
      ],
    }),
  
    computed: {
      gameName() {
        return this.$route.params.section
      },
  
      header() {
        return {
          title: `Поиск айди по почте`,
          game: this.gameName,
        }
      },
    },
  
    created() {
      this.$forceUpdate()
    },
  
    methods: {
      ...mapActions('modal', ['showModal']),
  
      async onCheckButtonClick() {
        this.$loading.start()
  
        try {
          const { data } = await this.$axios.get(
            `/admin/check/account/email`,
            {
                params: { id: this.items[0].value},
            }
          )
  
          if (data.data) {
            this.showModal({
              component: 'ObjectView',
              data: {
                title: 'Найденные ники',
                text: 'Вот, что удалось найти',
                object: data.data,
              },
            })
          }
        } catch (e) {
          this.$error.handle(e)
        }
  
        this.$loading.finish()
      }
    },
  }
  </script>
  
  <style lang="scss">
  </style>