import moment from 'moment';

export default {
  title: 'Brawl Stars',

  sections: [
    { id: 'general', title: 'Основное' },
    { id: 'prize_pool', title: 'Призовой фонд' },
    { id: 'prize_pool_distribution', title: 'Распределение ПФ' },
    { id: 'game_process', title: 'Игра' },
    { id: 'other', title: 'Остальное' },
    { id: 'nominations', title: 'Номинации' },
  ],

  fields: function() {
    return [
      {
        title: 'Название',
        value: '',
        type: 'text',
        key: 'title',
        description: 'Турнир будет называться так и на платформе, и в игре',
        section: 'general',
      },

      {
        title: 'Дата начала',
        value: '',
        type: 'date',
        key: 'date',
        handler(date) {
          return moment(date)
            .subtract(3, 'hours')
            .format('YYYY-MM-DD HH:mm');
        },
        section: 'general',
        required: true,
      },

      {
        title: 'Мин. рейтинг',
        value: '',
        type: 'select',
        options: [
          { title: 'All', value: '' },
          { title: 'V', value: 'V' },
          { title: 'VV', value: 'VV' },
          { title: 'VVV', value: 'VVV' },
          { title: 'VVVV', value: 'VVVV' },
          { title: 'VVVVV', value: 'VVVVV' },
        ],
        withEmptyValue: false,
        key: 'min_rating_group',
        section: 'general',
      },

      {
        title: 'Макс. рейтинг',
        value: '',
        type: 'select',
        options: [
          { title: 'All', value: '' },
          { title: 'V', value: 'V' },
          { title: 'VV', value: 'VV' },
          { title: 'VVV', value: 'VVV' },
          { title: 'VVVV', value: 'VVVV' },
          { title: 'VVVVV', value: 'VVVVV' },
        ],
        withEmptyValue: false,
        key: 'max_rating_group',
        section: 'general',
      },

      {
        title: 'Регион',
        value: 'Europe',
        type: 'select',
        options: [
          { title: 'America', value: 'America' },
          { title: 'Asia', value: 'Asia' },
          { title: 'Europe', value: 'Europe' }
        ],
        withEmptyValue: false,
        key: 'region',
        section: 'general',
      },

      {
        title: 'Блокировать другие ГЕО',
        value: false,
        type: 'checkbox',
        key: 'extra_data.block_geo',
        section: 'general',
      },

      {
        title: 'Дата начала регистрации',
        value: null,
        type: 'date',
        key: 'registration_starts_at',
        section: 'general',
        handler(date) {
          return moment(date).subtract(3, 'hours').format('YYYY-MM-DD HH:mm')
        },
      },

      {
        title: 'Дата закрытия регистрации',
        value: null,
        type: 'date',
        key: 'registration_ends_at',
        section: 'general',
        handler(date) {
          return moment(date)
            .subtract(3, 'hours')
            .format('YYYY-MM-DD HH:mm');
        },
      },

      {
        title: 'Закрытый турнир',
        value: false,
        type: 'checkbox',
        key: 'is_private',
        section: 'general',
      },

      {
        title: 'Пароль для записи в турнир',
        value: '',
        type: 'text',
        show: {
          key: 'is_private',
          value: true,
        },
        required: true,
        key: 'private_password',
        section: 'general',
      },

      {
        title: 'Спонсорский турнир',
        value: false,
        type: 'checkbox',
        key: 'is_sponsored',
        section: 'general',
      },

      {
        title: 'ID спонсора',
        value: '',
        type: 'number',
        show: {
          key: 'is_sponsored',
          value: true,
        },
        required: true,
        key: 'sponsor_id',
        section: 'general',
      },
      // prize_pool

      {
        title: 'Стоимость входа',
        value: 100,
        type: 'number',
        key: 'entry_fee',
        currencyField: 'prize_settings.entry_fee_currency',
        section: 'prize_pool',
        required: true,
      },

      {
        title: 'Валюта входного билета',
        value: 'coins',
        type: 'select',
        options: [
          { title: 'Coins', value: 'coins' },
          { title: 'EUR', value: 'money' },
        ],
        withEmptyValue: false,
        key: 'prize_settings.entry_fee_currency',
        section: 'prize_pool',
      },

      {
        title: 'Тип призового фонда',
        value: 'not_fixed_with_guarantee',
        type: 'select',
        options: [
          { title: 'Фиксированный', value: 'fixed' },
          { title: 'Нефиксированный', value: 'not_fixed' },
          {
            title: 'Нефиксированный с гарантированным призом',
            value: 'not_fixed_with_guarantee',
          },
        ],
        withEmptyValue: false,
        key: 'prize_settings.prize_pool_type',
        section: 'prize_pool',
      },

      {
        title: 'Сумма фиксированного призового фонда',
        value: null,
        type: 'number',
        key: 'prize_pool',
        currencyField: 'prize_settings.prize_currency',
        show: {
          key: 'prize_settings.prize_pool_type',
          value: 'fixed',
        },
        section: 'prize_pool',
        required: true,
      },

      {
        title: 'Сумма гарантированного призового фонда',
        value: null,
        type: 'number',
        key: 'prize_settings.guaranted_prize',
        currencyField: 'prize_settings.prize_currency',
        show: {
          key: 'prize_settings.prize_pool_type',
          value: 'not_fixed_with_guarantee',
        },
        description: `Гарантированный приз — это сумма, которая в любом случае будет разыграна в турнире`,
        section: 'prize_pool',
        required: true,
      },

      {
        title: 'Валюта призового фонда',
        value: 'coins',
        type: 'select',
        options: [
          { title: 'Coins', value: 'coins' },
          { title: 'EUR', value: 'money' },
          { title: 'Tickets', value: 'tickets' },
        ],
        withEmptyValue: false,
        key: 'prize_settings.prize_currency',
        section: 'prize_pool',
      },

      {
        title: 'Утешительный приз',
        value: false,
        type: 'checkbox',
        key: 'has_consolation_prize',
        notSend: true,
        section: 'prize_pool',
      },

      {
        title: 'Валюта утешительного приза',
        value: 'coins',
        type: 'select',
        options: [
          { title: 'Coins', value: 'coins' },
          { title: 'EUR', value: 'money' },
          { title: 'Tickets', value: 'tickets' },
        ],
        withEmptyValue: false,
        show: {
          key: 'has_consolation_prize',
          value: true,
        },
        key: 'prize_settings.consolation_prize.currency',
        section: 'prize_pool',
      },

      {
        title: 'Сумма утешительного приза',
        value: null,
        type: 'number',
        key: 'prize_settings.consolation_prize.amount',
        show: {
          key: 'has_consolation_prize',
          value: true,
        },
        section: 'prize_pool',
      },

      // prize_pool_distribution

      {
        title: 'Распределение призов',
        value: 'place',
        type: 'select',
        options: [{ title: 'По месту в топе', value: 'place' }],
        withEmptyValue: false,
        key: 'prize_settings.place_distribution_type',
        section: 'prize_pool_distribution',
      },

      {
        title: 'Тип распределения через топ',
        value: 'top',
        type: 'select',
        options: [
          { title: 'Top', value: 'top' },
          { title: 'Percentage', value: 'percentage' },
        ],
        withEmptyValue: false,
        key: 'prize_settings.prize_winner_type',
        section: 'prize_pool_distribution',
      },

      {
        title: 'Количество призовых мест ',
        value: 5,
        type: 'number',
        key: 'prize_settings.win_place_count',
        show: {
          key: 'prize_settings.prize_winner_type',
          value: 'top',
        },
        section: 'prize_pool_distribution',
        required: true,
      },

      {
        title: 'Процент призовых мест',
        value: 20,
        type: 'number',
        key: 'prize_settings.win_place_count',
        show: {
          key: 'prize_settings.prize_winner_type',
          value: 'percentage',
        },
        section: 'prize_pool_distribution',
        required: true,
      },

      {
        title: 'Тип распределения ПФ',
        value: 'bezier',
        type: 'select',
        options: [
          { title: 'Bezier', value: 'bezier' },
          { title: 'Flat', value: 'flat' },
          { title: 'Double', value: 'double' },
        ],
        withEmptyValue: false,
        key: 'prize_settings.prize_distribution_type',
        section: 'prize_pool_distribution',
      },

      {
        title: 'Точки на Кривой Безье',
        value: '0,8/0,7/0,3/0,1',
        type: 'text',
        key: 'prize_settings.bezier_points',
        placeholder: 'x,y/x,y/x,y/x,y',
        description: `
        Формат для ввода: x,y/x,y/x,y/x,y<br><br>
        Шаблоны:<br><br>
        0,11/0,3/4.6,0.6/10.8,0.5
        <br>
        0,11/0,3/4.4,0.7/10.4,0.6
      `,
        handler(points) {
          let data = [];

          points.split('/').forEach((point) => {
            point = point.split(',');
            data.push({ x: parseFloat(point[0]), y: parseFloat(point[1]) });
          });

          return data;
        },
        valueToField(value) {
          return value.map((point) => `${point.x},${point.y}`).join('/');
        },
        show: {
          key: 'prize_settings.prize_distribution_type',
          value: 'bezier',
        },
        section: 'prize_pool_distribution',
        required: true,
      },

      {
        title: 'Награды',
        value: '',
        type: 'text',
        key: 'prize_settings.flat_prizes',
        show: {
          key: 'prize_settings.prize_distribution_type',
          value: 'flat',
        },
        handler(points) {
          let data = [];

          data = points.split(',').map(Number);

          return data;
        },
        valueToField(value) {
          return value?.join('/');
        },
        placeholder: 'Через запятую: xxx,yyy,zzz,hhh',
        section: 'prize_pool_distribution',
        required: true,
      },

      // other

      {
        title: 'Ссылка на картинку турнира',
        value:
          'https://cdn.vvvgamers.com/games/brawl-stars/images/tournament_solo.png',
        type: 'text',
        key: 'image_url',
        section: 'other',
        required: true,
      },

      {
        title: 'Тип турнира',
        value: 'player',
        type: 'select',
        options: [
          { title: 'Одиночный', value: 'player' },
          { title: 'Командный', value: 'team' },
        ],
        withEmptyValue: false,
        key: 'tournament_player_settings.entry_type',
        section: 'other',
      },

      {
        title: 'Игроков в команде',
        value: 2,
        type: 'number',
        key: 'tournament_player_settings.team_size',
        section: 'other',
        show: {
          key: 'tournament_player_settings.entry_type',
          value: 'team',
        },
        required: true,
      },

      {
        title: 'Минимальное кол-во участников',
        value: 4,
        type: 'number',
        key: 'tournament_player_settings.min_players_count',
        show: {
          key: 'tournament_player_settings.entry_type',
          value: 'player',
        },
        section: 'other',
        required: true,
      },

      {
        title: 'Максимальное кол-во участников',
        value: 9,
        max: 9,
        type: 'number',
        key: 'tournament_player_settings.max_players_count',
        show: {
          key: 'tournament_player_settings.entry_type',
          value: 'player',
        },
        section: 'other',
      },

      {
        title: 'Минимальное кол-во команд-участников',
        value: 2,
        type: 'number',
        key: 'tournament_player_settings.min_players_count',
        show: {
          key: 'tournament_player_settings.entry_type',
          value: 'team',
        },
        section: 'other',
        required: true,
      },

      {
        title: 'Максимальное кол-во команд-участников',
        value: 5,
        max: 5,
        type: 'number',
        key: 'tournament_player_settings.max_players_count',
        show: {
          key: 'tournament_player_settings.entry_type',
          value: 'team',
        },
        section: 'other',
      },

      {
        title: 'Комиссия платформы (в процентах)',
        value: 5,
        max: 100,
        type: 'number',
        key: 'prize_settings.rake',
        section: 'other',
        required: true,
      },

      {
        title: 'Правила (RU)',
        value: '',
        type: 'textarea',
        key: 'rules_ru',
        description: `Для выделения жирным определенного участка текста, возьмите его в звёздочки. Пример: *этот текст будет выделен*`,
        handler(rules) {
          const startsWithBold = rules.startsWith('*');
          if (startsWithBold) {
            rules = '^' + rules;
          }

          const boldWords = rules.split('*');
          if (boldWords.length) {
            boldWords.forEach((p, index) => {
              if (index % 2 !== 0) {
                boldWords[index] = '<b>' + p + '</b>';
              }
            });

            rules = boldWords.join('');
          }

          let paragraphs = rules.split('\n');
          if (paragraphs[0]) {
            rules = paragraphs.map((p) => `<p>${p}</p>`).join('');
          }

          if (startsWithBold) {
            rules = rules.replace('^', '');
          }

          return rules;
        },
        valueToField(value) {
          return value?.replace(/<p>|<\/p>/g, '').replace(/<b>|<\/b>/, '*');
        },
        section: 'other',
      },

      {
        title: 'Правила (EN)',
        value: '',
        type: 'textarea',
        key: 'rules_en',
        description: `Для выделения жирным определенного участка текста, возьмите его в звёздочки. Пример: *этот текст будет выделен*`,
        handler(rules) {
          const startsWithBold = rules.startsWith('*');
          if (startsWithBold) {
            rules = '^' + rules;
          }

          const boldWords = rules.split('*');
          if (boldWords.length) {
            boldWords.forEach((p, index) => {
              if (index % 2 !== 0) {
                boldWords[index] = '<b>' + p + '</b>';
              }
            });

            rules = boldWords.join('');
          }

          let paragraphs = rules.split('\n');
          if (paragraphs[0]) {
            rules = paragraphs.map((p) => `<p>${p}</p>`).join('');
          }

          if (startsWithBold) {
            rules = rules.replace('^', '');
          }

          return rules;
        },
        valueToField(value) {
          return value?.replace(/<p>|<\/p>/g, '').replace(/<b>|<\/b>/, '*');
        },
        section: 'other',
      },

      {
        title: 'Регламент',
        value:
          'https://www.notion.so/venividivicigaming/Brawl-Stars-37255bdd7b9642b899dd22ea666006bb',
        type: 'text',
        key: 'rules_link',
        section: 'other',
      },

      {
        title: 'YouTube трансляция',
        value: '',
        type: 'text',
        key: 'stream_links.youtube',
        placeholder: 'Ссылка будет видна игрокам на странице турнира',
        section: 'other',
      },

      {
        title: 'Twitch трансляция',
        value: '',
        type: 'text',
        key: 'stream_links.twitch',
        placeholder: 'Ссылка будет видна игрокам на странице турнира',
        section: 'other',
      },

      {
        title: 'Ссылка на TikTok',
        value: '',
        type: 'text',
        key: 'stream_links.tiktok',
        placeholder: 'Ссылка будет видна игрокам на странице турнира',
        section: 'other',
      },

      {
        title: 'Ссылка на Telegram',
        value: '',
        type: 'text',
        key: 'stream_links.telegram',
        placeholder: 'Ссылка будет видна игрокам на странице турнира',
        section: 'other',
      },

      {
        title: 'Короткая ссылка для турнира',
        value: '',
        type: 'text',
        key: 'alias',
        placeholder: 'По этой ссылке можно будет дополнительно открыть турнир',
        section: 'other',
      },

      // game_process

      {
        title: 'Игровой режим',
        value: 'solo',
        type: 'select',
        options: [
          { title: 'Solo', value: 'solo' },
          { title: 'Duo', value: 'duo' },
          { title: 'Trio', value: 'trio' },
        ],
        withEmptyValue: false,
        key: 'extra_data.config.mode',
        section: 'game_process',
      },

      {
        title: 'Map',
        value: 'Scorched Stone',
        type: 'select',
        options: [
          { title: 'Scorched Stone', value: 'Scorched Stone' },
          { title: 'Cavern Churn', value: 'Cavern Churn' },
          { title: 'Skull Creek', value: 'Skull Creek' },
          { title: 'Acid Lakes', value: 'Acid Lakes' },
          { title: 'Feast or Famine', value: 'Feast or Famine' },
          { title: 'Double Trouble', value: 'Double Trouble' },
          { title: 'Dark Passage', value: 'Dark Passage' },
          { title: 'Custom Map', value: 'Custom Map' },
        ],
        show: {
          key: 'extra_data.config.mode',
          value: 'solo',
        },
        withEmptyValue: false,
        key: 'extra_data.config.map',
        section: 'game_process',
      },

      {
        title: 'Map',
        value: 'Scorched Stone',
        type: 'select',
        options: [
          { title: 'Scorched Stone', value: 'Scorched Stone' },
          { title: 'Cavern Churn', value: 'Cavern Churn' },
          { title: 'Skull Creek', value: 'Skull Creek' },
          { title: 'Acid Lakes', value: 'Acid Lakes' },
          { title: 'Feast or Famine', value: 'Feast or Famine' },
          { title: 'Double Trouble', value: 'Double Trouble' },
          { title: 'Dark Passage', value: 'Dark Passage' },
          { title: 'Custom Map', value: 'Custom Map' },
        ],
        show: {
          key: 'extra_data.config.mode',
          value: 'duo',
        },
        withEmptyValue: false,
        key: 'extra_data.config.map',
        section: 'game_process',
      },

      {
        title: 'Map (search)',
        value: 'Custom Map',
        type: 'search-select',
        options: [
          { title: 'Custom Map', value: 'Custom Map' },
          { title: 'Hard Rock Mine', value: 'Hard Rock Mine' },
          { title: 'Crystal Arcade', value: 'Crystal Arcade' },
          { title: 'Deathcap Trap', value: 'Deathcap Trap' },
          { title: 'Gem Fort', value: 'Gem Fort' },
          { title: 'Deep Diner', value: 'Deep Diner' },
          { title: 'Chill Space', value: 'Chill Space' },
          { title: 'Double Swoosh', value: 'Double Swoosh' },
          { title: 'Spring Trap', value: 'Spring Trap' },
          { title: 'Minecraft Madness', value: 'Minecraft Madness' },
          { title: 'Flooded Dam', value: 'Flooded Dam' },
          { title: 'Sapphire Plains', value: 'Sapphire Plains' },
          { title: 'Open Space', value: 'Open Space' },
          { title: 'Gem Exchange', value: 'Gem Exchange' },
          { title: 'Opposing Forts', value: 'Opposing Forts' },
          { title: 'Kaboom Canyon', value: 'Kaboom Canyon' },
          { title: 'Safe Zone', value: 'Safe Zone' },
          { title: 'G.G Mortuary', value: 'G.G Mortuary' },
          { title: 'Hot Potato', value: 'Hot Potato' },
          { title: 'Rolling Rumble', value: 'Rolling Rumble' },
          { title: 'Tornado Ring', value: 'Tornado Ring' },
          { title: 'Cant touch this', value: 'Cant touch this' },
          { title: 'Snake Prairie', value: 'Snake Prairie' },
          { title: 'Shooting Star', value: 'Shooting Star' },
          { title: 'Canal Grande', value: 'Canal Grande' },
          { title: 'Layer Cake', value: 'Layer Cake' },
          { title: 'Dry Season', value: 'Dry Season' },
          { title: 'Overgrown Canyon', value: 'Overgrown Canyon' },
          { title: 'Heat Wave', value: 'Heat Wave' },
          { title: 'Pinhole Bowl', value: 'Pinhole Bowl' },
          { title: 'Pinhole Punt', value: 'Pinhole Punt' },
          { title: 'Sneaky Fields', value: 'Sneaky Fields' },
          { title: 'Super Beach', value: 'Super Beach' },
          { title: 'Center Stage', value: 'Center Stage' },
          { title: 'Beach Ball', value: 'Beach Ball' },
          { title: 'Field Goal', value: 'Field Goal' },
          { title: 'Post Haste', value: 'Post Haste' },
          { title: 'Penalty Kick', value: 'Penalty Kick' },
          { title: 'Well Cut', value: 'Well Cut' },
          { title: 'Well Cut', value: 'Well Cut' },
          { title: 'Off the Line', value: 'Off the Line' },
          { title: 'Extra Bouncy', value: 'Extra Bouncy' },
          { title: 'High Score', value: 'High Score' },
          { title: 'Ping Pong', value: 'Ping Pong' },
          { title: 'Bot Drop', value: 'Bot Drop' },
          { title: 'Some Assembly Required', value: 'Some Assembly Required' },
          { title: 'Nuts & Bolts', value: 'Nuts & Bolts' },
          { title: 'Junk Park', value: 'Junk Park' },
          { title: 'Assembly Attack', value: 'Assembly Attack' },
          { title: 'Robo Highway', value: 'Robo Highway' },
          { title: 'Factory Rush', value: 'Factory Rush' },
          { title: 'Temple of Boom', value: 'Temple of Boom' },
          { title: 'Parallel Plays', value: 'Parallel Plays' },
          { title: 'Split', value: 'Split' },
          { title: 'Mosh Pit', value: 'Mosh Pit' },
          { title: 'Ring of Fire', value: 'Ring of Fire' },
          { title: 'Dueling Beetles', value: 'Dueling Beetles' },
          { title: 'Open Zone', value: 'Open Zone' },
          { title: 'Goldarm Culch', value: 'Goldarm Culch' },
          { title: 'Belle`s Rock', value: 'Belle`s Rock' },
          { title: 'Crimewater', value: 'Crimewater' },
          { title: 'Flaring Phoenix', value: 'Flaring Phoenix' },
          { title: 'Riverside', value: 'Riverside' },
          { title: 'New Perspective', value: 'New Perspective' },
          { title: 'X Marks the Spot', value: 'X Marks the Spot' },
        ],
        show: {
          key: 'extra_data.config.mode',
          value: 'trio',
        },
        withEmptyValue: false,
        key: 'extra_data.config.map',
        section: 'game_process',
      },
    ];
  },
};
