<template lang="pug">
  .action-coins
    SectionHeader(:header="header")

    .action__body
      .action__profile(v-if="profile") 
        .action__profile-header
          img.action__profile-avatar(:src="profile.avatar")
          span.action__profile-name {{ profile.name }}
        .action__profile-bottom
          span.action__profile-label Баланс игрока
          .profile__currency
            img.action__profile-currency-icon(:src="require('@/assets/img/icons/coins-yellow.svg')" alt="Монеты") 
            span.action__profile-currency-value {{ profile.coins }}

      .action__section
        .action__grid
          .action__form-field(v-for="(item, i) of items" :key="i")
            InputSmart(:item="item")

      .action__bottom
        Button(is-primary @click="onCheckButtonClick") {{ buttonText }}

</template>

<script>
import { debounce } from '@/utils/utils.js'
import { mapActions } from 'vuex'
import InputSmart from '@/components/InputSmart/InputSmart'
import SectionHeader from '@/components/SectionHeader/SectionHeader'

export default {
  name: 'ActionCoins',

  components: {
    InputSmart,
    SectionHeader,
  },

  data: () => ({
    profile: undefined,
    coins: null,

    items: {
      playerId: {
        title: 'ID игрока',
        value: null,
        type: 'number',
        placeholder: 'Введите ID',
      },

      isAdd: {
        title: 'Действие',
        value: true,
        type: 'select',
        options: [
          { title: 'Начислить', value: true },
          { title: 'Списать', value: false },
        ],
        withEmptyValue: false,
      },

      coins: {
        title: 'Количество',
        value: null,
        type: 'number',
        placeholder: 'Сколько душа пожелает',
        error: ''
      },
    },
  }),

  computed: {
    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Списание и начисление монет`,
        game: this.gameName,
      }
    },

    debitedMoney() {
      return Math.min(this.profile?.coins, this.items?.coins.value)
    },

    buttonText() {
      if(this.profile !== undefined && this.items?.coins.value) {
        if(!this.items.isAdd.value) {
          return `Списать ${this.debitedMoney}`
        } else {
          return `Начислить ${this.items?.coins.value}`
        }
      } else {
        return 'Изменить'
      }
    }
  },

  created() {
    this.$forceUpdate()
  },

  watch: {
    'items.coins.value': {
      handler(newValue) {
        this.checkSum(this.profile?.coins, newValue)
      }
    },

    'items.isAdd.value': {
      handler() {
        this.checkSum(this.profile?.coins, this.items.coins.value)
      }
    },

    'profile.coins': {
      handler(newValue) {
        this.checkSum(newValue, this.items.coins.value)
      }
    },

    'items.playerId.value': debounce(function (value) {
      if(value) {
        this.getBalance();
      } else [
        this.profile = undefined
      ]
    }, 700)
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onCheckButtonClick() {
      this.$loading.start()

      try {
        const playerId = this.items.playerId.value
        const isAdd = this.items.isAdd.value
        this.coins = +this.items.coins.value

        if (!isAdd) {
          if(this.items.coins.error) {
            this.coins = -Math.abs(this.profile?.coins)
          } else {
            this.coins = -Math.abs(this.coins)
          }
        }

        if (!this.coins) {
            return this.$loading.finish()
        }

        const { data } = await this.$axios.post(`/user/${playerId}/update-coins`, {
          coins: this.coins
        })

        if (data.data) {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'success',
              title: 'Успех',
              text: 'Баланс игрока изменён!',
            },
          })

          this.items.coins.error = ''
          this.items.coins.value = null

          this.getBalance()
        }
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    async getBalance() {
      try {
        const { data } = await this.$axios.get(`/admin/user/${this.items.playerId.value}/balance`)
        const profile = data.data;

        const response = await this.$axios.get(`/user/${this.items.playerId.value}`)
        profile.name = response.data?.data?.full_name
        profile.avatar = response.data?.data?.avatar

        this.profile = profile

      } catch (e) {
        console.error('BS account error', e)
        this.items.playerId.value = null

        this.$error.handle(e)
      }
    },

    checkSum(profileValue, fieldValue) {
      this.items.coins.error = undefined
      if(!this.items.isAdd.value) {
        if(fieldValue > profileValue) {
          this.items.coins.error = `На счете игрока не хватает ${fieldValue - profileValue} монет`
        }
      }
    },

    debounceInput: debounce(function () {
      this.profile = this.getBalance;
    }, 500)   
  },
}
</script>

<style lang="scss" scoped>
.action {
  &__profile {
    background-color: $blue-grey60;
    border-radius: 8px;
    padding: 20px 16px;
    max-width: 380px;
    margin-bottom: 8px;

    &-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 21px;
    }

    &-avatar {
      width: 48px;
      height: 48px;
      object-fit: cover;
      margin-right: 8px;
      border-radius: 24px;
    }

    &-name {
      color: $white;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-label {
      color: $blue-grey10;
      font-weight: normal;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
    }

    &-currency-value {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $white;
    }
  }
}
</style>
