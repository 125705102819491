<template lang="pug">
  .clash-royale-account.brawl-stars-account
    .modal__header Аккаунт Brawl Stars

    .clash-royale-account__body
      Steps(:steps="stepsTitles" :current-step-index="stepIndex")

      .clash-royale-account__step(v-if="stepIndex === 0")
        .clash-royale-account__item
          FormInput(title="Введите игровой тег игрока" placeholder="#XXXXXX" v-model="playerTag" @input="onTagInput")
        .clash-royale-account__item
          ImageSlider(:slides="tagSlides" :slide-duration="2800")

      .clash-royale-account__step(v-if="stepIndex === 1")
        .fourth-step__first
          InfoMessage(type="success") Ура! Аккаунт успешно привязан

      InfoMessage(v-if="errorMessage" type="error")
        | {{ errorMessage }}

    .modal__footer
      Button(is-primary :is-disabled="isButtonDisabled" @click="onNextStepClick")
        | Далее

</template>

<script>
import { mapMutations } from 'vuex'
import ClashRoyaleAccount from '@/components/ClashRoyaleAccount/ClashRoyaleAccount'

const CDN_URL = 'https://cdn.vvvgamers.com/games/brawl-stars/verification/'

export default {
  name: 'BrawlStarsAccount',

  extends: ClashRoyaleAccount,

  props: {
    playerId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      stepIndex: 0,
      errorMessage: '',

      steps: [
        {
          title: 'Игровой тег',
          action: () => this.linkGameAccount(),
        },
        {
          title: 'Успех',
          action: () => this.toggle(),
        },
      ],

      tagSlides: [
        {
          text: 'Запустите Brawl Stars и нажмите на аватарку',
          image: CDN_URL + '/slider_tag/1.gif',
        },
        {
          text: 'Введите тег',
          image: CDN_URL + '/slider_tag/2.gif?',
        },
      ],
    }
  },

  computed: {
    isButtonDisabled() {
      return false
    },
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    async linkGameAccount() {
      let tag = this.playerTag
      const userId = this.playerId

      if (!tag.startsWith('#')) {
        tag = '#' + this.playerTag
      }

      tag = tag.toUpperCase()

      this.$loading.start()

      try {
        const { data } = await this.$axios.post('/games/brawl-stars/account/admin/link', {
          tag,
          userId,
        })

        if (!data.error) {
          this.stepIndex += 1
        }
      } catch (e) {
        console.error('BS account error', e)

        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    onTagInput() {
      this.errorMessage = ''
    },
  },
}
</script>

<style lang="scss">
.modal.modal--brawlstarsaccount {
  padding-bottom: 0;
  max-width: 736px;
}

.brawl-stars-account {
  .button__text {
    display: flex;
    align-items: center;
  }

  &__loader {
    animation: rotate 1s infinite $easing;
    transform-origin: 52% 38%;
    height: 20px;
    width: 22px;
    position: relative;
    margin-left: 10px;

    //&::after {
    //  content: '';
    //  width: 1px;
    //  height: 1px;
    //  left: 52%;
    //  top: 38%;
    //  background: red;
    //  position: absolute;
    //}
  }

  &__color {
    margin: 0 auto;
    display: block;
  }

  &__caption {
    @include hint;

    color: $white;
    text-align: center;
    margin-bottom: 31px;
  }

  .info-message.--error {
    margin-top: 16px;
  }
}
</style>
