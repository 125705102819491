export const RANKS = {
  a: { from: 1900, to: 2100, color: '#da1e27' },
  b: { from: 1700, to: 1900, color: '#f57c00' },
  c: { from: 1500, to: 1700, color: '#512da8' },
  d: { from: 1300, to: 1500, color: '#388e3c' },
  e: { from: 1100, to: 1300, color: '#0288d1' },
  f: { from: 900, to: 1100, color: '#0097a7' },
  n: { from: 0, to: 900, color: '#334153' },
}

export const GAME_RANKS = {
  lichess: RANKS,
  'clash-royale': {
    a: RANKS.a,
    b: RANKS.b,
    c: RANKS.c,
  },
}

export const TEAM_COMP_STATUSES = {
  open: 'Открыто',
  in_progress: 'В процессе',
  completed: 'Завершено',
  moderation: 'На модерации',
  cancelled: 'Отменено',
}

export const EVENT_STATUSES = {
  open: 'Open',
  in_progress: 'In progress',
  completed: 'Closed',
  moderation: 'In moderation',
  cancelled: 'Canceled',
}

export const DUEL_STATUSES = {
  open: 'Ожидание соперника',
  ready: 'Соперник найден',
  in_progress: 'В процессе',
  moderation: 'На модерации',
  completed: 'Дуэль завершена',
  cancelled: 'Дуэль отменена',
  template: 'Комната дуэли',
  date_template: 'Комната дуэли',
  waiting_for_lobby: 'Ожидание старта',
}

export const PER_PAGE_DEFAULT = 6

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
