import axios from 'axios'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_DOMAIN,
})

api.interceptors.request.use(request => {
  request.headers.Authorization = localStorage.getItem('token')

  return request
}, error => {
  return Promise.reject(error)
})

export default api
