import lichess from './lichess'
import pubg_mobile from './pubg-mobile'
import brawlStars from './brawl-stars'
import clashRoyale from './clash-royale'
import freeFire from './free-fire'

export default {
  lichess,
  'pubg-mobile': pubg_mobile,
  'brawl-stars': brawlStars,
  'clash-royale': clashRoyale,
  'free-fire': freeFire,
}
