import moment from 'moment'

export default {
  title: 'PUBG Mobile',

  sections: [
    { id: 'general', title: 'Основное', },
    { id: 'prize_pool', title: 'Призовой фонд', },
    { id: 'prize_pool_distribution', title: 'Распределение ПФ', },
    { id: 'stages', title: 'Этапы', },
  ],

  fields: function() {
    return [
      {
        title: 'Название турнира',
        value: '',
        type: 'text',
        key: 'title',
        description: `В названии запрещено использовать ссылки, специальные символы ($, * и тд.) и слово «Lichess»`,
        section: 'general',
      },

      {
        title: 'Даты проведения',
        type: 'double',
        section: 'general',
        fields: [
          {
            placeholder: 'Начало',
            value: null,
            type: 'date',
            key: 'date',
            section: 'general',
            handler(date) {
              return moment(date).format('YYYY-MM-DD HH:mm')
            },
            required: true,
          },

          {
            placeholder: 'Конец',
            value: null,
            type: 'date',
            key: 'ends_at',
            section: 'general',
            handler(date) {
              return moment(date).format('YYYY-MM-DD HH:mm')
            },
            required: true,
          },
        ],
      },

      {
        title: 'Рейтинг',
        type: 'double',
        section: 'general',
        fields: [
          {
            placeholder: 'Минимальный',
            value: null,
            type: 'number',
            key: 'min_rating',
            section: 'general',
          },

          {
            placeholder: 'Максимальный',
            value: null,
            type: 'number',
            key: 'max_rating',
            section: 'general',
          },
        ],
      },

      {
        title: 'Дата закрытия регистрации',
        value: null,
        type: 'date',
        key: 'registration_ends_at',
        section: 'general',
        handler(date) {
          return moment(date).subtract(3, 'hours').format('YYYY-MM-DD HH:mm')
        },
      },

      {
        type: 'double',
        section: 'general',
        fields: [
          {
            title: 'Картинка',
            value: 'https://cdn.vvvgamers.com/images/tournaments/lichess/1A.png',
            type: 'text',
            key: 'image_url',
            section: 'general',
            required: true,
          },

          {
            title: 'Регламент',
            value: 'https://cdn.vvvgamers.com/pdfs/tournament_reglament_standart.pdf',
            type: 'text',
            key: 'rules_link',
            section: 'general',
          },
        ],
      },

      {
        title: 'Правила (RU)',
        value: '',
        type: 'textarea',
        key: 'rules_ru',
        description: `Для выделения жирным определенного участка текста, возьмите его в звёздочки. Пример: *этот текст будет выделен*`,
        handler(rules) {
          const startsWithBold = rules.startsWith('*')
          if (startsWithBold) {
            rules = '^' + rules
          }

          const boldWords = rules.split('*')
          if (boldWords.length) {
            boldWords.forEach((p, index) => {
              if (index % 2 !== 0) {
                boldWords[index] = '<b>' + p + '</b>'
              }
            })

            rules = boldWords.join('')
          }

          let paragraphs = rules.split('\n')
          if (paragraphs[0]) {
            rules = paragraphs
              .map(p => `<p>${p}</p>`)
              .join('')
          }

          if (startsWithBold) {
            rules = rules.replace('^', '')
          }

          return rules
        },
        section: 'general',
      },

      {
        title: 'Правила (EN)',
        value: '',
        type: 'textarea',
        key: 'rules_en',
        description: `Для выделения жирным определенного участка текста, возьмите его в звёздочки. Пример: *этот текст будет выделен*`,
        handler(rules) {
          const startsWithBold = rules.startsWith('*')
          if (startsWithBold) {
            rules = '^' + rules
          }

          const boldWords = rules.split('*')
          if (boldWords.length) {
            boldWords.forEach((p, index) => {
              if (index % 2 !== 0) {
                boldWords[index] = '<b>' + p + '</b>'
              }
            })

            rules = boldWords.join('')
          }

          let paragraphs = rules.split('\n')
          if (paragraphs[0]) {
            rules = paragraphs
              .map(p => `<p>${p}</p>`)
              .join('')
          }

          if (startsWithBold) {
            rules = rules.replace('^', '')
          }

          return rules
        },
        section: 'general',
      },

      {
        title: 'Закрытый турнир',
        value: false,
        type: 'checkbox',
        key: 'is_private',
        section: 'general',
      },

      {
        title: 'Пароль для записи в турнир',
        value: '',
        type: 'text',
        show: {
          key: 'is_private',
          value: true,
        },
        required: true,
        key: 'private_password',
        section: 'general',
      },

      {
        title: 'Спонсорский турнир',
        value: false,
        type: 'checkbox',
        key: 'is_sponsored',
        section: 'general',
      },

      {
        title: 'ID спонсора',
        value: '',
        type: 'number',
        show: {
          key: 'is_sponsored',
          value: true,
        },
        required: true,
        key: 'sponsor_id',
        section: 'general',
      },

      {
        title: 'Map',
        value: 'Erangel',
        type: 'select',
        options: [
          { title: 'Erangel', value: 'Erangel' },
          { title: 'Sanhok', value: 'Sanhok' },
          { title: 'Miramar', value: 'Miramar' },
          { title: 'All weapons', value: 'All weapons' },
          { title: 'Vikendi', value: 'Vikendi' },
          { title: 'Inventory', value: 'Inventory' },
          { title: 'Ruins', value: 'Ruins' },
          { title: 'Livik', value: 'Livik' },
          { title: 'Santorini', value: 'Santorini' },
          { title: 'Town', value: 'Town' },
          { title: 'Library', value: 'Library' },
          { title: 'Karakin', value: 'Karakin' },
        ],
        withEmptyValue: false,
        key: 'extra_data.config.map',
        section: 'general'
      },

      {
        title: 'View',
        value: 'third_person',
        type: 'select',
        options: [
          { title: 'Third-person', value: 'third_person' },
          { title: 'First-person', value: 'first_person' },
        ],
        withEmptyValue: false,
        key: 'extra_data.config.view',
        section: 'general'
      },

      {
        title: 'Режим',
        value: 'solo',
        type: 'select',
        options: [
          { title: 'Solo', value: 'solo' },
          { title: 'Duo', value: 'duo' },
          { title: 'Squad', value: 'squad' },
        ],
        withEmptyValue: false,
        key: 'extra_data.config.mode',
        section: 'general'
      },

      {
        title: 'Тип турнира',
        value: 'player',
        type: 'select',
        options: [
          { title: 'Одиночный', value: 'player' },
          { title: 'Командный', value: 'team' },
        ],
        withEmptyValue: false,
        key: 'tournament_player_settings.entry_type',
        section: 'general',
      },

      {
        title: 'Игроков в команде',
        value: 2,
        type: 'number',
        key: 'tournament_player_settings.team_size',
        section: 'general',
        show: {
          key: 'tournament_player_settings.entry_type',
          value: 'team'
        },
        required: true,
      },

      {
        title: 'Количество участников',
        type: 'double',
        section: 'general',
        fields: [
          {
            placeholder: 'Минимум',
            value: null,
            type: 'number',
            key: 'tournament_player_settings.min_players_count',
            section: 'general',
            required: true,
          },

          {
            placeholder: 'Максимум',
            value: null,
            // max: 100,
            type: 'number',
            key: 'tournament_player_settings.max_players_count',
            section: 'general',
          },
        ],
      },

      {
        title: 'Ссылка на трансляции',
        type: 'double',
        section: 'general',
        fields: [
          {
            placeholder: 'YouTube',
            value: '',
            type: 'text',
            key: 'stream_links.youtube',
            section: 'general'
          },

          {
            placeholder: 'Twitch',
            value: '',
            type: 'text',
            key: 'stream_links.twitch',
            section: 'general'
          },
        ],
      },

      {
        title: 'Ссылка на TikTok',
        value: '',
        type: 'text',
        key: 'stream_links.tiktok',
        placeholder: 'Ссылка будет видна игрокам на странице турнира',
        section: 'general'
      },

      {
        title: 'Ссылка на Telegram',
        value: '',
        type: 'text',
        key: 'stream_links.telegram',
        placeholder: 'Ссылка будет видна игрокам на странице турнира',
        section: 'general'
      },

      {
        title: 'Короткая ссылка для турнира',
        value: '',
        type: 'text',
        key: 'alias',
        placeholder: 'По этой ссылке можно будет дополнительно открыть турнир',
        section: 'general',
      },

      // prize_pool

      {
        type: 'double',
        section: 'prize_pool',
        fields: [
          {
            title: 'Стоимость входа',
            value: 100,
            type: 'number',
            key: 'entry_fee',
            section: 'prize_pool',
            required: true,
          },

          {
            title: 'Валюта входного билета',
            value: 'coins',
            type: 'select',
            options: [
              { title: 'Coins', value: 'coins' },
              { title: 'EUR', value: 'money' },
            ],
            withEmptyValue: false,
            key: 'prize_settings.entry_fee_currency',
            section: 'prize_pool',
          },
        ],
      },

      {
        type: 'double',
        section: 'prize_pool',
        fields: [
          {
            title: 'Тип призового фонда',
            value: 'not_fixed_with_guarantee',
            type: 'select',
            options: [
              { title: 'Фиксированный', value: 'fixed' },
              { title: 'Нефиксированный', value: 'not_fixed' },
              { title: 'Нефикс. с гарантированным', value: 'not_fixed_with_guarantee' },
            ],
            withEmptyValue: false,
            key: 'prize_settings.prize_pool_type',
            section: 'prize_pool',
          },

          {
            title: 'Валюта призового фонда',
            value: 'coins',
            type: 'select',
            options: [
              { title: 'Coins', value: 'coins' },
              { title: 'EUR', value: 'money' },
              { title: 'Tickets', value: 'tickets' },
            ],
            withEmptyValue: false,
            key: 'prize_settings.prize_currency',
            section: 'prize_pool',
          },
        ],
      },

      {
        title: 'Сумма фиксированного призового фонда',
        value: null,
        type: 'number',
        key: 'prize_pool',
        show: {
          key: 'prize_settings.prize_pool_type',
          value: 'fixed'
        },
        section: 'prize_pool',
        required: true,
      },

      {
        title: 'Сумма гарантированного призового фонда',
        value: null,
        type: 'number',
        key: 'prize_settings.guaranted_prize',
        show: {
          key: 'prize_settings.prize_pool_type',
          value: 'not_fixed_with_guarantee'
        },
        description: `Гарантированный приз — это сумма, которая в любом случае будет разыграна в турнире`,
        section: 'prize_pool',
        required: true,
      },

      // prize_pool_distribution

      {
        type: 'double',
        section: 'prize_pool_distribution',
        fields: [
          {
            title: 'Распределение призов',
            value: 'place',
            type: 'select',
            options: [
              { title: 'По месту в топе', value: 'place' },
            ],
            withEmptyValue: false,
            key: 'prize_settings.place_distribution_type',
            section: 'prize_pool_distribution',
          },

          {
            title: 'Комиссия платформы (в процентах)',
            value: 5,
            max: 100,
            type: 'number',
            key: 'prize_settings.rake',
            section: 'prize_pool_distribution',
            required: true,
          },
        ],
      },

      {
        title: 'Тип распределения через топ',
        value: 'top',
        type: 'select',
        options: [
          { title: 'Top', value: 'top' },
          { title: 'Percentage', value: 'percentage' },
        ],
        withEmptyValue: false,
        key: 'prize_settings.prize_winner_type',
        section: 'prize_pool_distribution',
      },

      {
        title: 'Количество призовых мест ',
        value: 10,
        type: 'number',
        key: 'prize_settings.win_place_count',
        show: {
          key: 'prize_settings.prize_winner_type',
          value: 'top'
        },
        section: 'prize_pool_distribution',
        required: true,
      },

      {
        title: 'Процент призовых мест',
        value: 10,
        type: 'number',
        key: 'prize_settings.win_place_count',
        show: {
          key: 'prize_settings.prize_winner_type',
          value: 'percentage'
        },
        section: 'prize_pool_distribution',
        required: true,
      },

      {
        title: 'Суммируются ли очки между этапами',
        value: 'single',
        type: 'select',
        options: [
          { title: 'Не суммируются', value: 'single' },
          { title: 'Суммируются', value: 'sequence' },
        ],
        withEmptyValue: false,
        key: 'type',
        section: 'prize_pool_distribution'
      },

      {
        title: 'Тип распределения ПФ',
        value: 'bezier',
        type: 'select',
        options: [
          { title: 'Bezier', value: 'bezier' },
          { title: 'Flat', value: 'flat' },
          { title: 'Double', value: 'double' },
        ],
        withEmptyValue: false,
        key: 'prize_settings.prize_distribution_type',
        section: 'prize_pool_distribution',
      },

      {
        title: 'Точки на Кривой Безье',
        value: '0,8/0,7/0,3/0,1',
        type: 'text',
        key: 'prize_settings.bezier_points',
        placeholder: 'x,y/x,y/x,y/x,y',
        description: `
        Формат для ввода: x,y/x,y/x,y/x,y<br><br>
        Шаблоны:<br><br>
        0,11/0,3/4.6,0.6/10.8,0.5
        <br>
        0,11/0,3/4.4,0.7/10.4,0.6
      `,
        handler(points) {
          let data = []

          points.split('/').forEach(point => {
            point = point.split(',')
            data.push({ x: parseFloat(point[0]), y: parseFloat(point[1]) })
          })

          return data
        },
        show: {
          key: 'prize_settings.prize_distribution_type',
          value: 'bezier'
        },
        section: 'prize_pool_distribution',
        required: true,
      },

      {
        title: 'Награды',
        value: '',
        type: 'text',
        key: 'prize_settings.flat_prizes',
        show: {
          key: 'prize_settings.prize_distribution_type',
          value: 'flat'
        },
        handler(points) {
          let data = []

          data = points.split(',').map(Number)

          return data
        },
        placeholder: 'Через запятую: xxx,yyy,zzz,hhh',
        section: 'prize_pool_distribution',
        required: true,
      },
    ]
  },
}
