<template lang="pug">
  .action-events-list
    SectionHeader(:header="header")

    Tabs(:tabs="sections" v-model="activeTabIndex").action__tabs

    TournamentsFilters.action__filters(v-if="isTournaments" @setFilters="setFilters")

    .pagination
      Pagination(:itemsCount="itemsCount" :itemsPerPage="itemsPerPage" :currentPage="currentPage" @setCurrentPage="setPage")

    .action__body
      .action-events-list__items(v-if="list.length && isTournaments")
        TournamentsList(:tournaments="tournamentsList")

      .action-events-list__items(v-else-if="(tournamentsAsDuels.length || list.length) && !isTournaments")
        .action-events-list__items(v-if="tournamentsAsDuels.length")
          TournamentsList(:tournaments="tournamentsAsDuels")

        .action-events-list__item(v-for="duel of duelsList")
          DuelCard(:duel="duel" view="item")

      .action__empty(v-else) No results
</template>

<script>
import Pagination from '@/components/Pagination/Pagination'
import Tabs from '@/components/Tabs/Tabs';
import TournamentCard from '@/components/TournamentCard/TournamentCard';
import DuelCard from '@/components/DuelCard/DuelCard';
import SectionHeader from '@/components/SectionHeader/SectionHeader';
import { mapActions, mapState } from 'vuex';
import TournamentsList from '@/components/TournamentsList/TournamentsList';
import TournamentsFilters from '@/components/TournamentsFilters/TournamentsFilters';

export default {
  name: 'ActionEventsList',

  components: {
    Pagination,
    Tabs,
    TournamentCard,
    DuelCard,
    SectionHeader,
    TournamentsList,
    TournamentsFilters,
  },

  data: () => ({
    activeTabIndex: 0,
    sections: [
      { id: 'tournaments', title: 'Tournaments' },
      { id: 'duels', title: 'Duels' },
    ],
    tournamentsList: [],
    duelsList: [],
    tournamentsAsDuels: [],
    currentPage: 1,
    itemsPerPage: 12,
    tournamentsCount: 1,
    duelsCount: 1,
    tournamentsFilters: [],
  }),

  computed: {
    ...mapState('games', ['gamesByName']),

    game() {
      return this.gamesByName[this.gameName]
    },

    gameName() {
      return this.$route.params.section;
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / События`,
        game: this.gameName,
      };
    },

    list() {
      return this.isTournaments ? this.tournamentsList : this.duelsList;
    },

    itemsCount() {
      return this.isTournaments ? this.tournamentsCount : this.duelsCount;
    },

    isTournaments() {
      return this.activeTabIndex === 0;
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions('games', ['fetchTournaments', 'fetchDuels']),

    setPage(pageNumber) {
      this.currentPage = pageNumber
      this.getTournaments()
    },

    async init() {
      this.$loading.start();

      await this.getTournaments();
      await this.getDuels();

      this.$loading.finish();
    },

    setFilters(filters) {
      this.tournamentsFilters = filters
      this.getTournaments()
    },

    async getTournaments() {
      let values;

      if (this.gameName === 'lichess') {
        values = [{ text: 'moderation' }];
      } else {
        values = [
          { text: 'open' },
          { text: 'moderation' },
          { text: 'in_progress' },
        ];
      }

      try {
        const filters = [
          {
            name: 'state',
            type: 'string_list',
            values,
          },
          ...this.tournamentsFilters,
        ]

        const { data, meta } = await this.fetchTournaments({
          game: this.$route.params.section,
          query: {
            limit: this.itemsPerPage,
            offset: (this.currentPage - 1) * this.itemsPerPage,
            filters: filters,
            sort: {
              name: 'date',
              order: 'asc',
            },
          },
        });

        this.tournamentsList = data.slice(0, 21);
        this.tournamentsCount = meta.count;
      } catch (e) {
        this.$error.handle(e);
      }
    },

    async getDuels() {
      if (!this.game.is_duel_enabled) {
        return;
      }

      try {
        const req = await this.fetchTournaments({
          game: this.$route.params.section,
          query: {
            limit: this.itemsPerPage,
            offset: (this.currentPage - 1) * this.itemsPerPage,
            filters: [
              {
                name: 'state',
                type: 'string',
                values: [{ text: 'open' }],
              },
            ],
            special_filters: [
              {
                name: 'extra_data.config.is_duel',
                type: 'string',
                values: [{ text: 'true' }],
              },
            ],
            sort: { name: 'date', order: 'asc' },
          },
        });

        const { data } = await this.$axios.get(
          `/admin/games/${this.gameName}/duels/waiting-for-lobby`
        );

        this.tournamentsAsDuels = req?.data || [];
        this.duelsList = data?.data || [];
        this.duelsCount = data?.meta?.count || 0;
      } catch (e) {
        this.$error.handle(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.action-events-list {
  &__items {
    width: 100%;
    padding-top: 12px;

    .tournament-card {
      max-width: 100%;
    }
  }

  &__item {
    margin-bottom: 24px;
    cursor: pointer;
  }
}
</style>
