<template lang="pug">
  aside.main-sidebar(:class="{'--opened': isOpened}")
    .main-sidebar__body

      .main-sidebar__hide-btn
        .main-sidebar__hide-btn-close(@click="toggle")
          .main-sidebar__hide-btn-icon
            InlineSvg(:src="require('@/assets/img/icons/sidebar.svg')")
        .main-sidebar__hide-btn-open(@click="toggle")
          .main-sidebar__hide-btn-icon
            img(src="@/assets/img/icons/arrow-left.svg")
          .main-sidebar__hide-btn-text
            | Скрыть

      router-link.main-sidebar-link(v-if="sectionList.length" v-for="(section, index) in sectionList" :class="{active: isActive(section) }" :key="index" :to="getActionRoute(section)")
        .main-sidebar-link__body
          .main-sidebar-link__icon
            img(v-if="section.extra_data.icon" :src="section.extra_data.icon", :alt="section.name")
            img(v-if="section.id === 6" src="https://seeklogo.com/images/C/csgo-headshot-logo-D55BFE7334-seeklogo.com.png" :alt="section.name")
          .main-sidebar-link__name
            | {{ section.extra_data.title }}

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

const FIXED_SIDEBAR_WINDOW_WIDTH = 1200

export default {
  name: 'Sidebar',

  data: () => ({
    isOpened: false,
  }),

  computed: {
    ...mapGetters('navigation', ['sections']),

    sectionList() {
      return Object.values(this.sections).reverse()
    },
  },

  mounted() {
    document.addEventListener('click', this.onClickOutside)
  },

  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside)
  },

  methods: {
    ...mapActions('games', ['fetchGames']),

    toggle() {
      this.isOpened = !this.isOpened
    },

    isActive(section) {
      return this.$route.params.section === section?.name
    },

    onClickOutside({ target }) {
      const shouldHideSidebar = window.innerWidth <= FIXED_SIDEBAR_WINDOW_WIDTH

      if (this.isOpened && shouldHideSidebar && !this.$el.contains(target)) {
        this.toggle(false)
      }
    },

    getActionRoute(section) {
      const firstActionRoute = this.sections[section.name].actions[0].route

      return `/${section?.name}${firstActionRoute}`
    }
  },
}
</script>

<style lang="scss">
.main-sidebar {
  position: fixed;
  top: 80px;
  left: 0;
  height: calc(100vh - 80px);
  padding: 8px;
  width: 65px;
  transition: width 0.3s $easing;
  background: $blue-grey90;
  z-index: 3;

  &__hide-btn-close {
    transform-origin: center;
    transform: scale(1);
    transition: 0.3s $easing;
    position: absolute;
  }

  &__hide-btn-open {
    display: flex;
    align-items: center;
    transition: 0.3s $easing;
    transform: translateX(20px) scale(0);
    transform-origin: left;
  }

  &__hide-btn {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: 0.3s $easing;
  }

  &__hide-btn-icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__hide-btn-text {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin-left: 9px;
    transition: 0.3s ease-in-out;
    transform: translateX(-30px);
  }

  .main-sidebar-link {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 8px;
    transition: 0.3s $easing;
    background: $blue-grey80;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &__icon {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 4;

      svg,
      path,
      rect {
        transition: 0.3s $easing;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__name {
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      margin-left: 18px;
      min-width: 132px;
      width: 132px;
      transition: 0.3s ease-in-out;
    }

    &__btn-icon {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: flex-start;

      svg,
      path,
      rect {
        transition: 0.3s $easing;
      }
    }

    &__body {
      display: flex;
      align-items: center;
      height: 48px;
      transition: 0.3s $easing;
      border-radius: 8px;
      padding: 0 45px 0 12px;
      width: 100%;
    }

    &__btn {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      margin-right: 8px;
      transition: 0.3s $easing;
      cursor: pointer;
      position: absolute;
      border-radius: 8px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      z-index: -1;
    }

    @media screen and (max-width: $breakpointHeaderMobile) {
      width: fit-content;
      height: fit-content;
      background: transparent;
      border-radius: 0;
      margin: 0 4px;
      padding: 0 4px;

      &__btn {
        display: none;
      }
      &__body {
        flex-direction: column;
        width: fit-content;
        height: fit-content;
        padding: 0;
        border-radius: 0;
      }
      &__icon {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
      }
      &__name {
        width: fit-content;
        height: fit-content;
        min-width: auto;
        opacity: 1;
        margin-left: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        margin-top: 2px;
      }
    }
  }

  &.--opened {
    width: 255px;

    .main-sidebar-link {
      overflow: initial;
      &__body {
        padding-left: 24px;
      }
      &__name {
        opacity: 1;
      }
      &__btn {
        opacity: 1;
        z-index: 1;
      }

      background: transparent;
    }

    .main-sidebar {
      &__hide-btn {
        padding: 12px 12px 12px 24px;
      }
      &__hide-btn-close {
        transform: scale(0);
      }
      &__hide-btn-open {
        transform: translateX(0) scale(1);
      }
      &__hide-btn-text {
        transform: translateX(0);
      }
    }
  }

  .main-sidebar-link__body:hover {
    background: rgb(34, 47, 63);

    @media screen and (max-width: $breakpointHeaderMobile) {
      background: transparent;
    }
  }

  @media screen and (max-width: $breakpointHeaderMobile) {
    top: auto;
    bottom: 0;
    width: 100%;
    height: 48px;
    padding: 6px 0;
    border-top: 1px solid $blue-grey50;
    overflow-x: auto;
    overflow-y: hidden;

    &__body {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: max-content;
    }

    &__hide-btn {
      display: none;
    }
  }

  .router-link-active, .active {
    background: #1e88e5 !important;
    transition: width 0.3s $easing;

    @media screen and (max-width: $breakpointMobile) {
      border-radius: 4px;
    }
  }
}
</style>
