const state = () => ({
  isVisible: false,
  component: null,
  componentData: null,
  canClose: true,
})

const actions = {
  showModal({ commit }, { component, data, canClose = true }) {
    commit('toggle', true)
    commit('setComponent', { component, data, canClose })
  },
}

const mutations = {
  toggle(state, isVisible) {
    if (isVisible !== undefined) {
      state.isVisible = isVisible
    } else {
      state.isVisible = !state.isVisible
    }

    if (state.isVisible) {
      document.body.classList.add('overlay')
    } else {
      document.body.classList.remove('overlay')
    }
  },

  setComponent(state, { component, data, canClose }) {
    state.component = component
    state.componentData = data
    state.canClose = canClose
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
