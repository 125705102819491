<template lang="pug">
  .date-picker.input-form(:class="{'--password': type === 'password', '--error': error }")
    label.input-form__label(@click="onLabelClick")
      input(
        type="text"
        :value="inputValue"
        :placeholder="placeholder"
        @input="onInput"
        @blur="onBlur"
        @keypress="onKeypress"
        disabled
      )

      .input-form__icon
        InlineSvg(:src="require(`@/assets/img/icons/calendar.svg`)")
      .input-form__error(v-if="error")
        | {{ error }}

      transition(name="slide-up" duration="200")
        picker(
          v-if="isCalendarVisible"
          class="date-picker__calendar"
          :class="{ '--months-only': isMonthOnly }"
          :value="calendarValue"
          is-dark
          :max-date="new Date()"
          @dayclick="onDayClick"
          is-range
        )


</template>

<script>
import FormInput from '@/components/InputForm/InputForm'
import picker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'DatePicker',

  extends: FormInput,

  components: {
    picker,
  },

  props: {
    isRange: {
      type: Boolean,
      default: false,
    },

    isMonthOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isCalendarVisible: false,
    }
  },

  computed: {
    inputValue() {
      if (!this.value) {
        return this.title
      }

      if (!this.isMonthOnly) {
        return this.value
      } else {
        const monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
        const month = this.value.split('.')[1]
        const year = this.value.split('.')[2]

        return `${monthNames[+month - 1]} ${year}`
      }
    },

    calendarValue() {
      return this.$dateFns.parse(this.value, 'dd.MM.yyyy', new Date())
    },
  },

  mounted() {
    document.addEventListener('click', this.onClickOutside)
  },

  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside)
  },

  methods: {
    onLabelClick() {
      if (!this.isCalendarVisible) {
        this.$emit('focus')
      }

      this.isCalendarVisible = true
    },

    onDayClick({ date }) {
      this.$emit('input', this.$dateFns.format(date, 'dd.MM.yyyy'))

      this.isCalendarVisible = false
      this.$emit('blur')
    },

    onClickOutside({ target }) {
      const innerPopup = this.$el.querySelector('.vc-popover-content-wrapper')

      // закрывать календарь только если внутри не открыт еще внутренний попап с месяцами или датами
      if (
        !this.$el.contains(target) &&
        (!innerPopup || innerPopup.offsetWidth === 0)
      ) {
        if (this.isCalendarVisible) {
          this.$emit('blur')
        }

        this.isCalendarVisible = false
      }
    },
  },
}
</script>

<style lang="scss">
.date-picker {
  &__calendar {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 4px;
    z-index: 4;

    .vc-pane {
      min-width: 276px;
    }

    .vc-weekday {
      font-family: $mainFont !important;
      padding: 0 0 12px;
      color: $blue-grey20 !important;
      font-size: 16px;
    }

    .vc-arrows-container {
      height: 24px;
      margin: 18px 0 0;
      padding: 0;
      left: 14px;
      right: 14px;
      width: auto;
    }

    .vc-arrow {
      border: none;
      width: 24px;
      height: 24px;
      transition: 0.2s $easing;

      &:hover {
        background: $blue-grey50;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .vc-title {
      text-transform: capitalize;
      font-size: 16px;
      height: 20px;
      line-height: 20px;
      transition: 0.2s $easing;
    }

    .vc-day-content {
      font-size: 16px;
      border-radius: 4px;
      transition: 0.2s $easing;
      font-weight: $font-bold !important;
      font-family: $mainFont !important;

      &:hover {
        background: $blue-grey50;
      }
    }

    .vc-day {
      .vc-highlight {
        border-radius: 4px !important;
        background: $primary60 !important;
      }
    }

    .vc-nav-popover-container {
      background: $blue-grey50;
      color: #fff;
      border: none;
      padding: 8px 12px 12px;
    }

    .vc-nav-header {
      height: 28px;
      line-height: 28px;
      margin-bottom: 12px;
    }

    .vc-nav-title {
      height: 28px;
      border: none;
      font-size: 16px !important;
      color: #fff;
      transition: 0.2s $easing;

      &:hover {
        background: $blue-grey30;
      }
    }

    .vc-nav-arrow {
      height: 28px;
      transition: 0.2s $easing;
      border: none;

      svg {
        width: 20px;
        height: 20px;
      }

      &:hover {
        background: $blue-grey30;
      }
    }

    .vc-nav-items {
      grid-row-gap: 4px;
      grid-column-gap: 4px;
    }

    .vc-nav-item {
      padding: 4px 8px;
      font-size: 16px;
      line-height: 16px;
      border: none;
      font-weight: $mainFont;
      transition: 0.2s $easing;
      width: 57px;

      &:hover {
        background: $blue-grey30;
        color: #fff !important;
      }

      &.is-current {
        color: #fff !important;
      }

      &.is-active {
        background: $primary60;
      }
    }

    .vc-header {
      padding: 20px 22px 0;
    }

    &.vc-container {
      position: absolute;
      font-family: $mainFont !important;
      font-weight: $font-bold !important;
      border-radius: 12px;
      background: $blue-grey70;
      border-color: $blue-grey50;
    }

    &.--months-only {
      .vc-weeks {
        grid-template-columns: auto;

        .is-first-day span {
          color: transparent;

          &::before {
            content: 'Выбрать';
            color: $white;
            background-color: $green50;
            padding: 0 8px;
            border-radius: 4px;
            position: absolute;
          }
        }

        & > div:not(.is-first-day) {
          display: none;
        }
      }
    }
  }

  .input-form {
    &__label {
      input[disabled] {
        opacity: 1;
        cursor: pointer;
      }
    }

    &__icon {
      left: auto;
      right: 12px;
    }
  }
}
</style>
