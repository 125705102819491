<template lang="pug">
    .action-cancel-tournament
      SectionHeader(:header="header")
  
      .action__body
        .action__section
          .action__grid
            .action__form-field(v-for="(item, i) of items" :key="i")
              InputSmart(:item="item")
  
        .action__bottom
          Button(is-primary @click="onCheckButtonClick") Отменить
  
  </template>
  
  <script>
  import { mapActions } from 'vuex'
  import InputSmart from '@/components/InputSmart/InputSmart'
  import SectionHeader from '@/components/SectionHeader/SectionHeader'
  
  export default {
    name: 'ActionCanceAllTournamentslEvent',
  
    components: {
      InputSmart,
      SectionHeader,
    },
  
    data: () => ({
      items: {
        type: {
          title: 'Тип события',
          value: 'tournaments',
          type: 'select',
          options: [
            { title: 'Турнир', value: 'tournaments' },
            { title: 'Дуэли', value: 'duels' },
          ],
          withEmptyValue: false,
        },

        reason: {
          title: 'Причина отмены',
          value: '',
          type: 'text',
          placeholder: 'Можно оставить пустым',
        },
      },
    }),
  
    computed: {
      gameName() {
        return this.$route.params.section
      },
  
      header() {
        return {
          title: `${this.$gameNameText(this.gameName)} / Отмена события`,
          game: this.gameName,
        }
      },
    },
  
    created() {
      this.$forceUpdate()
    },
  
    methods: {
      ...mapActions('modal', ['showModal']),
  
      async onCheckButtonClick() {
        this.$loading.start()
  
        try {
          const type = this.items.type.value
          const reason = this.items.reason.value
  
          let params = {
            reason,
          }
  
  
          const { data } = await this.$axios.post(`/admin/games/${this.gameName}/${type}/cancel`, params)
  
          if (data.data) {
            this.showModal({
              component: 'ModalMessage',
              data: {
                type: 'success',
                title: 'Успех',
                text: 'Отмена прошла успешно',
              },
            })
          }
        } catch (e) {
          this.$error.handle(e)
        }
  
        this.$loading.finish()
      }
    },
  }
  </script>
  
  <style lang="scss">
  </style>
  