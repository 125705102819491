<template lang="pug">
  .action-ban
    SectionHeader(:header="header")

    .action__body
      .action__section
        .action__grid
          .action__form-field(v-for="(item, i) of items" :key="i")
            InputSmart(:item="item")

          .action__form-field(v-if="isBan")
            InputSmart(:item="reasonItem")

          //.action__form-field(v-if="isBan")
          //  InputSmart(:item="foreverItem")

          .action__form-field(v-if="isBan")
            InputSmart(:item="daysItem")

      .action__bottom
        Button(is-primary @click="onCheckButtonClick") Изменить

</template>

<script>
import { mapActions } from 'vuex'
import InputSmart from '@/components/InputSmart/InputSmart'
import CheckboxInput from '@/components/CheckboxInput/CheckboxInput'
import SectionHeader from '@/components/SectionHeader/SectionHeader'

export default {
  name: 'ActionBan',

  components: {
    InputSmart,
    CheckboxInput,
    SectionHeader,
  },

  data: () => ({
    items: {
      playerId: {
        title: 'ID игрока',
        value: null,
        type: 'number',
        placeholder: 'Введите ID'
      },

      action: {
        title: 'Действие',
        value: true,
        type: 'select',
        options: [
          { title: 'Забанить', value: true },
          { title: 'Разбанить', value: false },
        ],
        withEmptyValue: false,
      },
    },

    // foreverItem: {
    //   title: 'Навсегда',
    //   value: true,
    //   type: 'checkbox',
    // },

    daysItem: {
      title: 'Количество дней',
      value: null,
      type: 'number',
      placeholder: 'От 1 до 10000',
      max: 10000
    },

    reasonItem: {
      title: 'Причина',
      value: '',
      type: 'text',
      placeholder: 'Пользователь увидет её при попытке входа на сайт'
    },
  }),

  computed: {
    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Бан и разбан`,
        game: this.gameName,
      }
    },

    isBan() {
      return this.items.action.value
    },

    isForever() {
      return this.foreverItem.value
    }
  },

  created() {
    this.$forceUpdate()
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onCheckButtonClick() {
      this.$loading.start()

      try {
        const isBan = this.items.action.value
        const playerId = this.items.playerId.value
        const days = +this.daysItem.value
        const reason = this.reasonItem.value
        const is_forever = false

        let result

        if (isBan) {
          result = await this.$axios.post(`/user/${playerId}/ban`, {
            days,
            reason,
            is_forever,
          })
        } else {
          result = await this.$axios.post(`/user/${playerId}/un-ban`)
        }

        if (result.data.data) {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'success',
              title: 'Успех',
              text: 'Всё прошло как нужно! :)',
            },
          })
        }
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    }
  },
}
</script>

<style lang="scss">
</style>
