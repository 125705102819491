import dot from 'dot-object';
import axios from '@/plugins/axios';
class TournamentsService {
  constructor() {
    if (TournamentsService.instance) {
      return TournamentsService.instance;
    }
    this.templates = {
      'pubg-mobile': [
        { id: 35872, name: 'На удвоение 50р' },
        { id: 36203, name: 'Дуо топ 3-100-1800' },
        { id: 35960, name: 'Аркада топ 7 вход 50р на 20 чел' },
        { id: 36205, name: 'Войди в топ 2 (DUO) 50р для 14 чел' },
      ],
      lichess: [
        { id: 35912, name: 'Freeroll' },
        { id: 35917, name: 'Knockout' },
        { id: 35595, name: '1 Winner' },
        { id: 36221, name: 'X2' },
        { id: 36459, name: 'X2 Bullet' },
        { id: 36204, name: 'TOP-50%' },
        { id: 36258, name: 'TOP-30%' },
      ],
      'brawl-stars': [
        { id: 37009, name: 'Coin' },
        { id: 37003, name: 'Freeroll' },
        { id: 37320, name: '50 €' },
        { id: 37321, name: '150 €' },
      ],
      'clash-royale': [
        { id: 37111, name: 'Coins T' },
        { id: 37114, name: 'Coins D' },
        { id: 37117, name: 'Freeroll T' },
        { id: 37107, name: 'NON-PRO Freeroll D' },
        { id: 37115, name: 'Triple 50' },
        { id: 36017, name: 'Draft 50' },
        { id: 37113, name: 'Arena 50' },
        { id: 37119, name: 'Arena 100' },
        { id: 37109, name: 'Triple 100' },
        { id: 35756, name: 'Draft 100' },
        { id: 37103, name: 'NON-PRO Draft 100' },
        { id: 37118, name: 'Triple 150' },
        { id: 37152, name: 'Draft 150' },
      ],
    };
    this.megaTemplates = {
      'brawl-stars': [
        { id: 301, name: 'Freeroll' },
        { id: 307, name: '50 €' },
      ],
    };
    this.tournamentCopySettings = {
      'pubg-mobile': {
        0: { key: 'title' },
        1: { key: 'password' },
        3: { key: 'min_rating' },
        4: { key: 'max_rating' },
        6: { key: 'is_private' },
        9: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.entry_fee / 100
              : tournament.entry_fee,
        },
        10: { key: 'prize_settings.entry_fee_currency' },
        11: { key: 'prize_settings.prize_pool_type' },
        12: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_pool / 100
              : tournament.prize_pool,
        },
        13: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_pool / 100
              : tournament.prize_pool,
        },
        14: { key: 'prize_settings.prize_currency' },
        15: { key: 'prize_settings.place_distribution_type' },
        16: { key: 'prize_settings.prize_winner_type' },
        17: { key: 'prize_settings.win_place_count' },
        18: { key: 'prize_settings.win_place_count' },
        19: { key: 'prize_settings.prize_distribution_type' },
        21: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_settings.flat_prizes?.map((item) => item / 100)
              : tournament.prize_settings.flat_prizes + '',
        },
        22: { key: 'image_url' },
        23: { key: 'tournament_player_settings.entry_type' },
        24: { key: 'tournament_player_settings.team_size' },
        25: { key: 'tournament_player_settings.min_players_count' },
        26: { key: 'tournament_player_settings.max_players_count' },
        27: { key: 'prize_settings.rake' },
        28: { key: 'rules' },
        29: { key: 'rules_link' },
        36: { key: 'extra_data.config.map' },
        37: { key: 'extra_data.config.view' },
        38: { key: 'extra_data.config.mode' },
      },
      lichess: {
        0: { key: 'title' },
        1: { key: 'password' },
        3: { key: 'min_rating' },
        4: { key: 'max_rating' },
        31: { key: 'rules' },
        32: { key: 'rules_link' },
      },
      'brawl-stars': {
        0: { key: 'title' },
        2: { key: 'min_rating' },
        3: { key: 'max_rating' },
        5: { key: 'is_private' },
        7: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.entry_fee / 100
              : tournament.entry_fee,
        },
        8: { key: 'prize_settings.entry_fee_currency' },
        9: { key: 'prize_settings.prize_pool_type' },
        10: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_pool / 100
              : tournament.prize_pool,
        },
        11: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_pool / 100
              : tournament.prize_pool,
        },
        12: { key: 'prize_settings.prize_currency' },
        13: { key: 'prize_settings.place_distribution_type' },
        14: { key: 'prize_settings.prize_winner_type' },
        15: { key: 'prize_settings.win_place_count' },
        16: { key: 'prize_settings.win_place_count' },
        17: { key: 'prize_settings.prize_distribution_type' },
        19: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_settings.flat_prizes?.map((item) => item / 100)
              : tournament.prize_settings.flat_prizes + '',
        },
        20: { key: 'image_url' },
        21: { key: 'tournament_player_settings.entry_type' },
        22: { key: 'tournament_player_settings.team_size' },
        23: { key: 'tournament_player_settings.min_players_count' },
        24: { key: 'tournament_player_settings.max_players_count' },
        25: { key: 'prize_settings.rake' },
        26: { key: 'rules' },
        27: { key: 'rules_link' },
        30: { key: 'extra_data.config.mode' },
        31: { key: 'extra_data.config.map' },
      },
      'clash-royale': {
        0: { key: 'title' },
        1: { key: 'extra_data.config.password' },
        3: { key: 'min_rating' },
        4: { key: 'max_rating' },
        6: { key: 'is_rated' },
        7: { key: 'is_private' },
        9: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.entry_fee / 100
              : tournament.entry_fee,
        },
        10: { key: 'prize_settings.entry_fee_currency' },
        11: { key: 'prize_settings.prize_pool_type' },
        12: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_pool / 100
              : tournament.prize_pool,
        },
        13: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_pool / 100
              : tournament.prize_pool,
        },
        14: { key: 'prize_settings.prize_currency' },
        15: { key: 'prize_settings.place_distribution_type' },
        16: { key: 'prize_settings.prize_winner_type' },
        17: { key: 'prize_settings.win_place_count' },
        18: { key: 'prize_settings.win_place_count' },
        19: { key: 'prize_settings.prize_distribution_type' },
        21: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_settings.flat_prizes?.map((item) => item / 100)
              : tournament.prize_settings.flat_prizes + '',
        },
        22: { key: 'image_url' },
        23: { key: 'tournament_player_settings.entry_type' },
        24: { key: 'tournament_player_settings.team_size' },
        25: { key: 'tournament_player_settings.min_players_count' },
        26: { key: 'tournament_player_settings.max_players_count' },
        27: { key: 'prize_settings.rake' },
        28: { key: 'rules' },
        29: { key: 'rules_link' },
        32: { key: 'extra_data.config.game_mode' },
        33: { key: 'extra_data.config.level' },
        34: { key: 'extra_data.config.duration' },
        35: { key: 'extra_data.config.max_loses_count' },
      },
      'free-fire': {
        0: { key: 'title' },
        1: { key: 'password' },
        3: { key: 'min_rating' },
        4: { key: 'max_rating' },
        6: { key: 'is_private' },
        8: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.entry_fee / 100
              : tournament.entry_fee,
        },
        9: { key: 'prize_settings.entry_fee_currency' },
        10: { key: 'prize_settings.prize_pool_type' },
        11: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_pool / 100
              : tournament.prize_pool,
        },
        12: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_pool / 100
              : tournament.prize_pool,
        },
        13: { key: 'prize_settings.prize_currency' },
        14: { key: 'prize_settings.place_distribution_type' },
        15: { key: 'prize_settings.prize_winner_type' },
        16: { key: 'prize_settings.win_place_count' },
        17: { key: 'prize_settings.win_place_count' },
        18: { key: 'prize_settings.prize_distribution_type' },
        20: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_settings.flat_prizes?.map((item) => item / 100)
              : tournament.prize_settings.flat_prizes + '',
        },
        21: { key: 'image_url' },
        22: { key: 'tournament_player_settings.entry_type' },
        23: { key: 'tournament_player_settings.team_size' },
        24: { key: 'tournament_player_settings.min_players_count' },
        25: { key: 'tournament_player_settings.max_players_count' },
        26: { key: 'prize_settings.rake' },
        27: { key: 'rules_ru' },
        28: { key: 'rules_en' },
        29: { key: 'rules_link' },
        35: { key: 'extra_data.config.map' },
        36: { key: 'extra_data.config.view' },
        37: { key: 'extra_data.config.mode' },
      },
    };
    this.megaTournamentCopySettings = {
      'brawl-stars': {
        0: { key: 'title' },
        2: {
          mapFunction: (tournament) => [
            tournament.min_rating,
            tournament.max_rating,
          ],
        },
        4: {
          mapFunction: (tournament) => [
            tournament.image_url,
            tournament.rules_link,
          ],
        },
        5: { key: 'general' },
        6: { key: 'is_private' },
        8: { key: 'extra_data.config.mode' },
        9: { key: 'extra_data.config.map' },
        10: { key: 'tournament_player_settings.entry_type' },
        11: { key: 'tournament_player_settings.team_size' },
        12: {
          mapFunction: (tournament) => [
            tournament.tournament_player_settings.min_players_count,
            tournament.tournament_player_settings.max_players_count,
          ],
        },
        14: {
          mapFunction: (tournament) => [
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_pool / 100
              : tournament.prize_pool,
            tournament.prize_settings.entry_fee_currency,
          ],
        },
        15: {
          mapFunction: (tournament) => [
            tournament.prize_settings.prize_pool_type,
            tournament.prize_settings.prize_currency,
          ],
        },
        16: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_pool / 100
              : tournament.prize_pool,
        },
        17: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.guaranted_prize / 100
              : tournament.guaranted_prize,
        },
        18: {
          mapFunction: (tournament) => [
            tournament.prize_settings.place_distribution_type,
            tournament.prize_settings.rake,
          ],
        },
        19: { key: 'prize_settings.prize_winner_type' },
        20: { key: 'prize_settings.win_place_count' },
        21: { key: 'prize_settings.win_place_count' },
        22: { key: 'type' },
        23: { key: 'prize_settings.prize_distribution_type' },
        25: {
          mapFunction: (tournament) =>
            tournament.prize_settings.prize_currency === 'money'
              ? tournament.prize_settings.flat_prizes?.map((item) => item / 100)
              : tournament.prize_settings.flat_prizes + '',
        },
        26: { key: 'stages.0.title' },
        28: {
          mapFunction: (tournament) => [
            tournament.stages?.[0]?.round_type,
            tournament.stages?.[0]?.room_count,
          ],
        },
        29: {
          mapFunction: (tournament) => [
            tournament.stages?.[0]?.prize_settings.prize_pool_type,
            tournament.stages?.[0]?.prize_settings.prize_currency,
          ],
        },
        30: { key: 'stages.0.prize_pool' },
        31: {
          mapFunction: (tournament) =>
            tournament.stages?.[0]?.prize_settings.prize_currency === 'money'
              ? tournament.stages?.[0]?.prize_settings.guaranted_prize / 100
              : tournament.stages?.[0]?.prize_settings.guaranted_prize,
        },
        32: {
          mapFunction: (tournament) => [
            tournament.stages?.[0]?.prize_settings.place_distribution_type,
            tournament.stages?.[0]?.prize_settings.prize_winner_type,
          ],
        },
        33: { key: 'stages.0.prize_settings.win_place_count' },
        34: { key: 'stages.0.prize_settings.win_place_count' },
        35: { key: 'stages.0.prize_settings.prize_distribution_type' },
        37: {
          mapFunction: (tournament) =>
            tournament.stages?.[0]?.prize_settings.prize_currency === 'money'
              ? tournament.stages?.[0]?.prize_settings.flat_prizes?.map(
                  (item) => item / 100
                )
              : tournament.stages?.[0]?.prize_settings.flat_prizes + '',
        },
        38: {
          mapFunction: (tournament) => [
            tournament.stages?.[0]?.extra_data.config.mode,
            tournament.stages?.[0]?.extra_data.config.map,
          ],
        },
      },
    };
    TournamentsService.instance = this;

    return this;
  }

  getTournament(gameName, tournamentId) {
    return axios.get(`/games/${gameName}/tournaments/${tournamentId}`);
  }

  updateTournament(gameName, tournamentId, tournament) {
    return axios.put(
      `/games/${gameName}/tournaments/${tournamentId}`,
      tournament
    );
  }

  getMegaTournament(gameName, tournamentId) {
    return axios.get(`/games/${gameName}/mega-tournaments/${tournamentId}`);
  }

  // Пока шаблоны не на бэкенд проде, не надо менять адрес
  getTournamentTemplate(gameName) {
    return axios.get(`/games/${gameName}/tournaments/templates`);
  }

  getTournamentTemplates(mega = false) {
    return new Promise((resolve) => {
      if (mega) resolve(this.megaTemplates);
      else resolve(this.templates);
    });
  }

  getTournamentCopySettings(mega = false) {
    return new Promise((resolve) => {
      if (mega) resolve(this.megaTournamentCopySettings);
      else resolve(this.tournamentCopySettings);
    });
  }

  tournamentFieldsToStandardForm(tournamentFields, gameFields) {
    const tournamentKeys = Object.keys(tournamentFields);

    tournamentKeys.forEach((key) => {
      // prize_pool
      if (
        key.includes('prize_settings.prize_currency') &&
        tournamentFields[key] === 'money'
      ) {
        if (key.startsWith('stages')) {
          const stageIndex = key.split('.')[1];
          const prizeType =
            tournamentFields[
              `stages.${stageIndex}.prize_settings.prize_pool_type`
            ];

          switch (prizeType) {
            case 'not_fixed_with_guarantee':
              tournamentFields[
                `stages.${stageIndex}.prize_settings.guaranted_prize`
              ] *= 100;
              break;
            case 'fixed':
              tournamentFields[`stages.${stageIndex}.prize_pool`] *= 100;
          }
        } else {
          const prizeType = tournamentFields[`prize_settings.prize_pool_type`];

          switch (prizeType) {
            case 'not_fixed_with_guarantee':
              tournamentFields[`prize_settings.guaranted_prize`] *= 100;
              break;
            case 'fixed':
              tournamentFields[`prize_pool`] *= 100;
          }
        }
      }

      // entry fee
      if (
        key.includes('prize_settings.entry_fee_currency') &&
        tournamentFields[key] === 'money'
      ) {
        if (key.startsWith('stages')) {
          const stageIndex = key.split('.')[1];

          tournamentFields[`stages.${stageIndex}.entry_fee`] *= 100;
        } else {
          tournamentFields[`entry_fee`] *= 100;
        }
      }

      // flat prizes
      if (
        key.includes('prize_settings.prize_distribution_type') &&
        tournamentFields[key] === 'flat'
      ) {
        if (key.startsWith('stages')) {
          const stageIndex = key.split('.')[1];

          if (
            tournamentFields[
              `stages.${stageIndex}.prize_settings.prize_currency`
            ] === 'money'
          ) {
            tournamentFields[
              `stages.${stageIndex}.prize_settings.flat_prizes`
            ] = tournamentFields[
              `stages.${stageIndex}.prize_settings.flat_prizes`
            ]
              .split(',')
              .map((value) => value * 100)
              .join(',');
          }
        } else {
          if (tournamentFields[`prize_settings.prize_currency`] === 'money') {
            tournamentFields[`prize_settings.flat_prizes`] = tournamentFields[
              `prize_settings.flat_prizes`
            ]
              .split(',')
              .map((value) => value * 100)
              .join(',');
          }
        }
      }

      if (!tournamentFields[key] && tournamentFields[key] != '0') {
        delete tournamentFields[key];
      }
    });

    gameFields.forEach((field) => {
      if (field.notSend) {
        delete tournamentFields[field.key];
      }

      if (tournamentFields[field.key] && field.handler) {
        tournamentFields[field.key] = field.handler(
          tournamentFields[field.key]
        );
      }
    });

    dot.object(tournamentFields); // dot notation to nested object

    return tournamentFields;
  }

  claimTournament(gameName, id) {
    return axios.post(`/games/${gameName}/tournaments/${id}/claim`)
  }

  unClaimTournament(gameName, id) {
    return axios.delete(`/games/${gameName}/tournaments/${id}/un-claim`)
  }
}

export default new TournamentsService();
