<template lang="pug">
  .search-select(:class="{ '--opened': isOpened }")
    input(
      type="text"
      v-model="value"
      :placeholder="placeholder"
      @click="onClickInput"
    ).search-select__input

    transition(name="slide-up" duration="200")
      .search-select__drop(v-if="isOpened")
        .search-select__drop-body
          .search-select__drop-body-inner(v-if="filteredOptions.length")
            .search-select__drop-item(
              v-for="option in filteredOptions"
              :class="{'--active': option.value === currentValue }"
              @click="onClickOption(option)"
            )
              template
                | {{ option.title }}

          .search-select__drop-body-inner.lol(v-else)
            .search-select__drop-item
              | Не найдено

</template>

<script>
export default {
  name: 'BaseSelect',

  props: {
    placeholder: {
      type: String,
      default: 'Введите для поиска',
    },

    options: {
      type: Array,
      required: true,
    },

    value: {
      required: true,
      default: '',
    },

    description: {
      type: String,
    },

    icon: {
      type: String,
      default: '',
    },

    withEmptyValue: {
      type: Boolean,
      default: true,
    },

    error: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      currentValue: null,
      currentTitle: null,
      isOpened: false,
    }
  },

  computed: {
    filteredOptions() {
      return this.options.filter(opt => {
        const title = opt.title.toLowerCase()
        const value = opt.value.toLowerCase()
        const currentValue = this.value ?? ''

        return title.includes(currentValue.toLowerCase()) || value.includes(currentValue.toLowerCase())
      })
    },
  },

  watch: {
    value: {
      handler(value) {
        const option = this.options.find((item) => item.value === value)
        this.currentValue = option ? value : null
        this.currentTitle = option ? option.title : this.placeholder
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside)
  },

  mounted() {
    document.addEventListener('click', this.onClickOutside)
  },

  methods: {
    onClickInput() {
      this.isOpened = !this.isOpened

      if (!this.isOpened) {
        this.$emit('blur')
      }
    },

    onClickOption(option) {
      this.isOpened = false

      if (this.value === option.value) {
        return
      }

      this.$emit('input', option.value, option)
      this.$emit('blur')
    },

    onClickOutside({ target }) {
      if (!this.$el.contains(target)) {
        if (this.isOpened) {
          this.$emit('blur')
        }

        this.isOpened = false
      }
    },
  },
}
</script>

<style lang="scss">
.search-select {
  position: relative;
  width: fit-content;

  &__input {
    border-radius: 8px;
    padding: 0 36px 0 16px;
    height: 48px;
    width: 100%;
    border: 0;
    outline: none;
    background: $blue-grey60;
    color: $white;
    @include text-display100;
    font-weight: 500;

    &::placeholder {
      @include hint;

      color: $white20;
    }

    &:-webkit-autofill {
      background: transparent !important;
    }

    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &__body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px 0 16px;
    border-radius: 8px;
    background: $blue-grey60;
    cursor: pointer;
    transition: background 0.2s $easing;
    height: 48px;
    min-width: 165px;

    &:hover {
      background: $blue-grey40;
    }

    &.--error {
      background: $red60-20;
    }

    .--opened & {
      background: $gradient-primary;
    }
  }

  &__name {
    @include text-display100;

    color: $white;

    svg:first-child {
      margin-right: 16px;
    }
  }

  &__error {
    @include text-display100;

    position: absolute;
    bottom: -22px;
    right: 0;
    font-size: 18px;
    color: $red40;
  }

  &__drop {
    position: absolute;
    z-index: 4;
    max-width: 400px;
    min-width: 100%;
    width: auto;
    border-radius: 8px;
    top: 100%;
    overflow: hidden;
  }

  &__drop-body {
    border: 1px solid $blue-grey30;
    border-radius: 8px;
    margin-top: 8px;
    background: $blue-grey70;
    padding: 8px 0;
  }

  &__drop-body-inner {
    @include scroll;

    padding: 8px 16px;
    max-height: 265px;
  }

  &__drop-item {
    @include heading6;

    background: $blue-grey60;
    cursor: pointer;
    transition: background 0.2s $easing;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    color: $white;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: $blue-grey40;
    }

    &.--active {
      background: $gradient-primary;
    }

    svg:first-child {
      margin-right: 16px;
    }
  }

  &__select {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;

    @media screen and (min-width: $phoneWidth) {
      pointer-events: none;
    }
  }

  &__arrow {
    transition: 0.2s $easing;
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -10px;
    height: 20px;
    width: 20px;
    display: flex;

    .--opened & {
      transform: rotate(-180deg);

      svg path {
        fill: white;
      }
    }
  }

  /*
  &__search {
    display: flex;
    position: relative;
    width: 100%;
    padding-bottom: 1px;
    &::before {
      content: '';
      position: absolute;
      display: block;
      left: -1px;
      width: calc(100%+2px);
      height: 1px;
      background: $blue-grey30;
      bottom: 0;
    }
    input {
      @include text-display100;
      color: $blue-grey05;
      background: transparent;
      width: 100%;
      padding: 19px 80px 19px 56px;
      &::placeholder {
        @include text-display100;
        color: $blue-grey05;
      }
    }
  }
  */

  /*
  &__search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }

  &__search-wrap {
    position: absolute;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
  }

  &__search-number {
    @include heading6;

    color: $white;
  }

  &__search-remove {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    cursor: pointer;
    background: $blue-grey60;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  &__search-clear {
    @include heading6;

    background-color: $gradient-primary;
    color: $amber60;
  }

   */
}
</style>
