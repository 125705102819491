import moment from 'moment'

export default {
  title: 'Brawl Stars',

  sections: [
    {id: 'general', title: 'Основное',},
    {id: 'prize_pool', title: 'Призовой фонд',},
    {id: 'prize_pool_distribution', title: 'Распределение ПФ',},
    {id: 'stages', title: 'Этапы',},
  ],

  fields: function() {
    return [
      {
        title: 'Название этапа',
        value: '',
        type: 'text',
        key: 'stages.<index>.title',
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        title: 'Даты проведения',
        type: 'double',
        section: 'stages',
        isStaged: true,
        stageId: 0,
        fields: [
          {
            placeholder: 'Начало',
            value: null,
            type: 'date',
            key: 'stages.<index>.date',
            section: 'stages',
            handler(date) {
              return moment(date).subtract(3, 'hours').format('YYYY-MM-DD HH:mm')
            },
            required: true,
            isStaged: true,
            stageId: 0,
          },

          {
            placeholder: 'Конец',
            value: null,
            type: 'date',
            key: 'stages.<index>.ends_at',
            section: 'stages',
            handler(date) {
              return moment(date).subtract(3, 'hours').format('YYYY-MM-DD HH:mm')
            },
            required: true,
            isStaged: true,
            stageId: 0,
          },
        ],
      },

      {
        type: 'double',
        section: 'stages',
        isStaged: true,
        stageId: 0,
        fields: [
          {
            title: 'Тип этапа',
            value: 'group',
            type: 'select',
            options: [
              { title: 'Группа', value: 'group' },
              { title: 'Четвертьфинал', value: 'quarter' },
              { title: 'Полуфинал', value: 'semi-final' },
              { title: 'Финал', value: 'final' },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.round_type',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },

          {
            title: 'Количестко комнат',
            value: 3,
            type: 'number',
            key: 'stages.<index>.room_count',
            section: 'stages',
            required: true,
            isStaged: true,
            stageId: 0,
          },
        ],
      },

      {
        type: 'double',
        section: 'stages',
        isStaged: true,
        stageId: 0,
        fields: [
          {
            title: 'Тип призового фонда',
            value: 'not_fixed_with_guarantee',
            type: 'select',
            options: [
              { title: 'Фиксированный', value: 'fixed' },
              { title: 'Нефиксированный', value: 'not_fixed' },
              { title: 'Нефикс. с гарантированным', value: 'not_fixed_with_guarantee' },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.prize_settings.prize_pool_type',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },

          {
            title: 'Валюта призового фонда',
            value: 'coins',
            type: 'select',
            options: [
              { title: 'Coins', value: 'coins' },
              { title: 'EUR', value: 'money' },
              { title: 'Tickets', value: 'tickets' },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.prize_settings.prize_currency',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },
        ]
      },

      {
        title: 'Сумма фиксированного призового фонда',
        value: null,
        type: 'number',
        key: 'stages.<index>.prize_pool',
        show: {
          key: 'stages.<index>.prize_settings.prize_pool_type',
          value: 'fixed'
        },
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        title: 'Сумма гарантированного призового фонда',
        value: null,
        type: 'number',
        key: 'stages.<index>.prize_settings.guaranted_prize',
        show: {
          key: 'stages.<index>.prize_settings.prize_pool_type',
          value: 'not_fixed_with_guarantee'
        },
        description: `Гарантированный приз — это сумма, которая в любом случае будет разыграна в турнире`,
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        type: 'double',
        section: 'stages',
        isStaged: true,
        stageId: 0,
        fields: [
          {
            title: 'Распределение призов',
            value: 'place',
            type: 'select',
            options: [
              { title: 'По месту в топе', value: 'place' },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.prize_settings.place_distribution_type',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },

          {
            title: 'Тип распределения фонда',
            value: 'top',
            type: 'select',
            options: [
              { title: 'Количество', value: 'top' },
              { title: 'Проценты', value: 'percentage' },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.prize_settings.prize_winner_type',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },
        ]
      },

      {
        title: 'Количество призовых мест ',
        value: 10,
        type: 'number',
        key: 'stages.<index>.prize_settings.win_place_count',
        show: {
          key: 'stages.<index>.prize_settings.prize_winner_type',
          value: 'top'
        },
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        title: 'Процент призовых мест',
        value: 10,
        type: 'number',
        key: 'stages.<index>.prize_settings.win_place_count',
        show: {
          key: 'stages.<index>.prize_settings.prize_winner_type',
          value: 'percentage'
        },
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        title: 'Тип распределения ПФ',
        value: 'bezier',
        type: 'select',
        options: [
          { title: 'Bezier', value: 'bezier' },
          { title: 'Flat', value: 'flat' },
          { title: 'Double', value: 'double' },
        ],
        withEmptyValue: false,
        key: 'stages.<index>.prize_settings.prize_distribution_type',
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        title: 'Точки на Кривой Безье',
        value: '0,8/0,7/0,3/0,1',
        type: 'text',
        key: 'stages.<index>.prize_settings.bezier_points',
        placeholder: 'x,y/x,y/x,y/x,y',
        description: `
        Формат для ввода: x,y/x,y/x,y/x,y<br><br>
        Шаблоны:<br><br>
        0,11/0,3/4.6,0.6/10.8,0.5
        <br>
        0,11/0,3/4.4,0.7/10.4,0.6
      `,
        handler(points) {
          let data = []

          points.split('/').forEach(point => {
            point = point.split(',')
            data.push({ x: parseFloat(point[0]), y: parseFloat(point[1]) })
          })

          return data
        },
        show: {
          key: 'stages.<index>.prize_settings.prize_distribution_type',
          value: 'bezier'
        },
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        title: 'Награды',
        value: '',
        type: 'text',
        key: 'stages.<index>.prize_settings.flat_prizes',
        show: {
          key: 'stages.<index>.prize_settings.prize_distribution_type',
          value: 'flat'
        },
        handler(points) {
          let data = []

          data = points.split(',').map(Number)

          return data
        },
        placeholder: 'Через запятую: xxx,yyy,zzz,hhh',
        section: 'stages',
        required: true,
        isStaged: true,
        stageId: 0,
      },

      {
        type: 'double',
        section: 'stages',
        isStaged: true,
        stageId: 0,
        fields: [
          {
            title: 'Игровой режим',
            value: 'Solo',
            type: 'select',
            options: [
              { title: 'Solo', value: 'Solo' }
              // { title: 'Парное столкновение', value: 'Парное столкновение' },
              // { title: 'Захват кристаллов', value: 'Захват кристаллов' },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.extra_data.config.mode',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },

          {
            title: 'Карта',
            value: 'Scorched Stone',
            type: 'select',
            options: [
              { title: 'Scorched Stone', value: 'Scorched Stone' },
              { title: 'Cavern Churn', value: 'Cavern Churn' },
              { title: 'Skull Creek', value: 'Skull Creek' },
              { title: 'Acid Lakes', value: 'Acid Lakes' },
              { title: 'Feast or Famine', value: 'Feast or Famine' },
              { title: 'Double Trouble', value: 'Double Trouble' },
              { title: 'Dark Passage', value: 'Dark Passage' },
              { title: 'Custom Map', value: 'Custom Map' },
            ],
            withEmptyValue: false,
            key: 'stages.<index>.extra_data.config.map',
            section: 'stages',
            isStaged: true,
            stageId: 0,
          },
        ],
      },
    ]
  }
}
