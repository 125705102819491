<template lang="pug">
  .input-number
    label.input-number__label(:class="{'--error': error}")
      Currency(v-if="currency" :type="currency")
        input.input-number__input(ref="input" type="text" v-model="innerValue" :placeholder="placeholder" v-on:keypress="isNumber($event, innerValue)")

      input.input-number__input(v-else ref="input" type="text" v-model="innerValue" :placeholder="placeholder" v-on:keypress="isNumber($event, innerValue)")

    .input-number__error(v-if="error") {{ error }}

      //a.input-number__arrow-up(@click="onIncrement")
      //  InlineSvg(:src="require('@/assets/img/icons/filter-arrow.svg')")
      //a.input-number__arrow-down(@click="onDecrement")
      //  InlineSvg(:src="require('@/assets/img/icons/filter-arrow.svg')")

</template>

<script>
export default {
  name: 'InputNumber',

  props: {
    title: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    currency: {
      type: String,
      default: '',
    },

    value: {
      type: [Number],
      default: null,
    },

    fixed: {
      type: Number,
      default: 0
    },

    increment: {
      type: Number,
      default: 1,
    },

    max: {
      type: Number,
      default: null,
    },

    min: {
      type: Number,
      default: null,
    },

    error: {
      type: String,
      default: '',
    },
  },

  data() {
    return {}
  },

  computed: {
    innerValue: {
      get() {
        return this.value;
      },

      set(value) {
        if (this.max && value > this.max) {
          this.$emit('input', +this.max)
          this.$refs.input.value = +this.max

          return
        }

        value = +(value.toString().replace(',', '.'))

        this.$emit('input', value)
      },
    },
  },

  methods: {
    isNumber(event) {
      if (!/\d/.test(event.key) && ![',', '.'].includes(event.key)) {
        return event.preventDefault()
      }

      return true
    },

    onIncrement() {
      this.onInput((Number(this.value) + this.increment))
    },

    onDecrement() {
      let newValue = Number(this.value) - this.increment

      if (newValue < 0) {
        newValue = 0
      }

      this.onInput(+newValue)
    },
  },
}
</script>

<style lang="scss">
.input-number {
  width: fit-content;

  &__error {
    color: $red40;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    margin-top: 4px;
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 130px;
    width: 100%;
    padding: 0 16px;
    background: $blue-grey60;
    color: $white;
    border-radius: 8px;
    height: 48px;
    line-height: 48px;

    &.--error {
      background: rgba(236, 43, 45, 0.2);
    }
  }

  .currency {
    margin-left: -5px;
    width: calc(100% + 5px);
    max-width: calc(100% + 5px);
  }

  &__text {
    @include text-info100;

    color: $white;
  }

  input {
    @include text-info100;

    width: 100%;
    background: transparent;
    color: $white;
    -moz-appearance: textfield;
    min-width: 0;
    padding: 0;
    border: 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &::placeholder {
      @include hint;

      color: $white20;
    }
  }

  &__arrow-up,
  &__arrow-down {
    position: absolute;
    right: 14px;
    top: 8px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s $easing;

    svg {
      transform: rotate(180deg);
    }

    &:active {
      transform: translateY(-2px);
    }
  }

  /* stylelint-disable-next-line */
  &__arrow-down {
    top: auto;
    bottom: 8px;

    svg {
      transform: none;
    }

    &:active {
      transform: translateY(2px);
    }
  }

  &__title {
    @include caption-small;

    color: $blue-grey10;
    margin-right: 12px;
  }
}
</style>
