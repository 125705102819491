<template lang="pug">
  .tournament-results
    .action__empty(v-if="!results.length") Пользовательских результатов нет

    .tournament-results__body(v-else)
      .tournament-results__result(v-for="result in results")
        .tournament-results__result-link(@click="onImageClick(result.image)")
          InlineSvg(:src="require('@/assets/img/icons/arrow-up-right.svg')")

        img(:src="result.image").tournament-results__result-image

        .tournament-results__result-text
          .tournament-results__result-sender От:&nbsp;
            span(@click="onPlayerClick(result.player)") {{ result.player.full_name }} ({{ result.player.game_account.username }})

          .tournament-results__result-comment(v-if="result.comment") Комментарий:&nbsp;
            span {{ result.comment }}

</template>

<script>
export default {
  name: 'TournamentResults',

  props: {
    tournament: {
      type: Object,
      required: true,
    },
  },

  computed: {
    results() {
      const results = this.tournament?.extra_data?.state?.game?.moderation_data

      return results?.map(result => {
        const parsedData = JSON.parse(result.url)
        const player = this.tournament?.players.find(player => player.id === result.id)

        return { ...parsedData, player }
      })
    },
  },

  methods: {
    onPlayerClick(player) {
      window.open(process.env.VUE_APP_DOMAIN + `/user/id/${player.id}`, '_blank')
    },

    onImageClick(imageUrl) {
      window.open(imageUrl, '_blank')
    }
  },
}
</script>

<style lang="scss">
.tournament-results {
  margin: 24px 0;

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 18px;

    @media (max-width: $breakpointMobile) {
      grid-template-columns: 1fr;
    }
  }

  &__result {
    background-color: $blue-grey50;
    border-radius: 8px;
    position: relative;

    &-link {
      background-color: $blue-grey40;
      border-radius: 8px;
      position: absolute;
      z-index: 2;
      right: 12px;
      top: 12px;
      cursor: pointer;

      svg {
        height: 32px;
        width: 32px;
      }
    }

    &-image {
      max-height: 360px;
      min-height: 160px;
      width: 100%;
      object-fit: cover;
      border-radius: 8px 8px 0 0;
    }

    &-text {
      text-align: left;
      padding: 16px;
    }

    &-sender,
    &-comment {
      @include hint;

      color: $blue-grey10;

      span {
        color: $white;
      }
    }

    &-comment {
      margin-top: 8px;
    }

    &-sender span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>