<template lang="pug">
  .global-loader(:class="[isLoading && 'global-loader--loading']")
    .global-loader__loader
      InlineSvg(:src="require('@/assets/img/VVV-logo.svg')" width="64" height="58")

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GlobalLoader',

  // data: () => ({
  //   isLoading: true,
  // }),

  computed: {
    ...mapGetters(['isLoading']),
  },

  // methods: {
  //   finish() {
  //     this.isLoading = false
  //   },
  //
  //   start() {
  //     this.isLoading = true
  //   },
  // },
}
</script>

<style lang="scss">
.global-loader {
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  background-color: rgba(0, 0, 0, 0.66);
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.3s $easing;
  visibility: hidden;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  &__loader {
    animation: rotate 1s infinite $easing;
    transform-origin: 52% 38%;
    width: 64px;
    height: 58px;
    position: relative;

    /*
    &::after {
      position: absolute;
      content: '';
      width: 2px;
      height: 2px;
      background: red;
      left: 50%;
      margin-left: -1px;
      top: 50%;
      margin-top: -1px;
    }
     */
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &--loading {
    opacity: 1;
    visibility: visible;
  }
}
</style>
