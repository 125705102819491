<template lang="pug">
  .file_upload(:class="{'--password': type === 'password', '--error': error }")
    //.file_upload__title
    //  | {{ name }}
    label.file_upload__label
      input(ref="input" type="file" @change="onFileChange" accept=".xlsx, .xls, .csv")
      //span(v-if="value") {{ value }}
      span.file_upload__placeholder(v-if="name") {{ name }}
      Button(is-secondary) Выбрать файл
      .form-input__error(v-if="error")
        | {{ error }}
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FileUpload',

  props: {
    title: {
      type: String,
      default: 'Тайтл',
    },

    placeholder: {
      type: String,
      default: 'Лол',
    },

    value: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    error: {
      type: String,
      default: '',
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    url: {
      type: String,
      default: '/admin/update-tournament-templates',
    },
  },

  data() {
    return {
      name: '',
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onFileChange() {
      const file = this.$refs.input
      const form = new FormData()
      form.append('file', file.files[0])

      if (file.files[0]) {
        this.$loading.start()

        this.name = file.files[0].name

        try {
          const { data } = await this.$axios.post(this.url, form)

          if (data) {
            this.showModal({
              component: 'ModalMessage',
              data: {
                type: 'success',
                title: 'Файл отправлен',
                text: 'Ответ сервера можно посмотреть по кнопке ниже',
                buttons: [
                  {
                    title: 'Ответ сервера',
                    props: { isSecondary: true },
                    shouldClose: false,
                    action: () => this.showModal({ component: 'ObjectView', data: { object: data } })
                  }
                ]
              },
            })
          }
        } catch (e) {
          this.$error.handle(e)
          console.error('file upload error', e)
        }

        this.$loading.finish()
      }

      // this.$emit('blur')
    },
  },
}
</script>

<style lang="scss">
.file_upload {
  &__placeholder {
    color: $blue-grey20;
  }

  &__title {
    @include caption-small;

    color: $grey20;
    margin-bottom: 16px;
  }

  &__label {
    @include text-display100;

    position: relative;
    background: $blue-grey60;
    width: 100%;
    display: flex;
    border-radius: 8px;
    transition: background 0.2s $easing;
    cursor: pointer;
    height: 48px;
    border: 0;
    outline: none;
    color: $white;
    align-items: center;

    input {
      width: 100%;
      z-index: 3;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
      white-space: nowrap;
    }

    .--error > & {
      background: $red60-20;
    }

    > span {
      @include text-overflow;

      width: 100%;
      padding: 0 16px;
      white-space: nowrap;
      height: 100%;
      line-height: 48px;
    }
  }

  &__error {
    @include text-display100;

    position: absolute;
    bottom: -22px;
    right: 0;
    font-size: 18px;
    color: $red40;
  }
}
</style>
