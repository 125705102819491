import plural from 'plural-ru'
import qs from 'qs'

export function pay(direction, $store) {
  $store.dispatch('modal/showModal', {
    component: 'ModalCheckRequirements',
    data: {
      isPayIn: direction === 'in',
      isPayOut: direction === 'out',
      onSuccess: () => {
        $store.dispatch('modal/showModal', {
          component: direction === 'in' ? 'PayInModal' : 'PayOutModal',
        })
      },
    },
    shouldShow: false,
  })
}

export function isUserAdult(verification, $dateFns) {
  if (!verification) {
    return false
  }

  const age = $dateFns.differenceInYears(
    new Date(),
    $dateFns.parse(verification.birthday, 'dd.MM.yyyy', new Date())
  )

  return age >= 16
}

export function secondsToMinutes(time) {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)

  let result =
    minutes > 0
      ? minutes + ' ' + plural(minutes, 'минута', 'минуты', 'минут')
      : ''

  if (seconds > 0) {
    result +=
      ' ' + seconds + ' ' + plural(seconds, 'секунда', 'секунды', 'секунд')
  }

  if (time === 0) {
    result = '0 секунд'
  }

  return result.trim()
}

export function timeConvert(num) {
  const hours = Math.floor(num / 60)
  const minutes = Math.round(num % 60)

  let result =
    hours > 0 ? hours + ' ' + plural(hours, 'час', 'часа', 'часов') : ''

  if (minutes > 0) {
    result += ' ' + minutes + ' ' + plural(minutes, 'минута', 'минуты', 'минут')
  }

  if (num === 0) {
    result = '0 часов'
  }

  return result
}

export function getPrizePool(event) {
  let pool = event.prize_pool
  const DUEL_DEFAULT_RAKE = 5

  // for duel
  if (['template'].includes(event.state) || event.account_id) {
    const rake = event.rake || DUEL_DEFAULT_RAKE
    const rakeValue = Math.ceil((event.entry_fee / 100) * rake)
    const cents = event.currency === 'money' ? 100 : 1

    pool = Math.floor(event.entry_fee / cents - rakeValue / cents) * 2
  }

  if (event.prize_settings?.prize_currency === 'money') {
    pool = Math.floor(pool / 100)
  }

  return pool
}

export function notify(title, options) {
  if (!('Notification' in window))
    console.log(
      'Ваш браузер не поддерживает HTML Notifications, его необходимо обновить.'
    )
  else if (Notification.permission === 'granted') {
    const notification = new Notification(title)
    notification.onclick = () => {
      options.onClick()
    }
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission((permission) => {
      if (permission === 'granted') {
        const notification = new Notification(title)
        notification.onclick = () => {
          options.onClick()
        }
      }
    })
  }
}

export async function showRecommendedTournaments(store, { game, count = 3 }) {
  await store.dispatch('games/fetchRecommendedTournaments', game)

  if (store.state.games.gameRecommendedTournaments[game].length) {
    store.dispatch('modal/showModal', {
      component: 'RecommendedTournamentsModal',
      data: {
        game,
        count,
      },
    })
  }
}

export function sendPostback($axios, $config, tid = 'regs') {
  let query = sessionStorage.getItem('utmLabels')

  console.log('postaback query', query)

  if (!query) {
    return
  }

  query = qs.parse(query)

  if (['media_buying', 'affiliate'].includes(query.utm_source)) {
    const params = {
      subid: 'vvvcash',
      tid,
      payout: 3,
      sub2: 1,
      creative_id: query.utm_content,
      source: query.utm_source,
    }
    console.log('postback sending', params, qs.stringify(params))

    $axios.get(`${$config.WWW_HOST}/postback`, {
      params,
    })
  }
}

export function debounce (fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}
