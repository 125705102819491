<template lang="pug">
  .icon-modal
    .modal__header Выберите иконку

    .icon-modal__body
      .icon-modal__icon.icon-colored(
        v-for="icon in icons"
        @click="onIconClick(icon)"
        :class="iconClass"
      )
        InlineSvg(:src="require(`@/assets/img/icons/${icon}.svg`)")

</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'IconModal',

  props: {
    index: {
      type: Number,
      required: true,
    },

    iconClass: {
      type: String,
      default: '--white',
    },
  },

  data() {
    return {
      icons: [
        'binocular',
        'bullit',
        'crown',
        'crown2',
        'earth',
        'fire',
        'globe',
        'group',
        'heart',
        'hourglass',
        'medal',
        'sad',
        'smile',
        'target',
        'thunder-move',
        'thunder',
        'timer',
        'trophy',
        'visible',
      ]
    }
  },

  methods: {
    ...mapMutations('nominations', ['setIcon']),

    onIconClick(icon) {
      this.setIcon({ icon, index: this.index })
      this.$store.commit('modal/toggle')
    },
  },
}
</script>

<style lang="scss">
.modal.modal--iconmodal {
  max-width: 472px;
}

.icon-modal {
  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  &__icon {
    margin-top: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>
