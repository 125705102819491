<template lang="pug">
  .page__content
    GamesList(v-if="games && games.length" :games="games")
</template>

<script>
import { mapState } from 'vuex'
import GamesList from '@/components/GamesList/GamesList'
import HomeStatistics from '@/components/HomeStatistics/HomeStatistics'

export default {
  name: 'Home',

  components: {
    GamesList,
    HomeStatistics
  },

  computed: {
    ...mapState('games', ['games'])
  },
}
</script>

<style lang="scss">
  .page__content {
    padding: 24px 32px 0 32px;
    margin-bottom: 100px;

    .statistics {
      margin-bottom: 32px;
    }

    @media (max-width: $breakpointMobile) {
      padding: 24px 32px 0 32px;
    }
  }
</style>
