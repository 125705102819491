<template lang="pug">
  .input-date(:class="{'--error': error }")
    .input-date__label
      DatePicker(v-model="date" mode="dateTime" is24hr is-dark timezone="Europe/Moscow" @input="onInput")
        template(v-slot="{ inputValue, inputEvents }")
          input(
            class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
            :value="inputValue"
            :placeholder="placeholder"
            v-on="inputEvents"
            @input="onInput"
            @blur="onBlur"
            @keypress="onKeypress"
          )


</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
  name: 'InputDate',

  components: {
    DatePicker,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    description: {
      type: String,
    },

    icon: {
      type: String,
      default: '',
    },

    value: {
      type: [String, Date],
      default: '',
    },

    error: {
      type: String,
      default: '',
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isEditable: {
      type: Boolean,
      default: false,
    },
  },

  data({ isDisabled }) {
    return {
      isPasswordVisible: false,
      localDisabled: isDisabled,
      date: '',
    };
  },

  created() {
    this.localDisabled = this.isEditable || this.isDisabled;
    this.date = this.value || '';
  },

  methods: {
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },

    onInput(value) {
      this.$emit('input', value);
    },

    onBlur() {
      this.$emit('blur');
    },

    onKeypress(event) {
      this.$emit('keypress', event);
    },

    edit() {
      this.localDisabled = !this.localDisabled;
    },
  },
};
</script>

<style lang="scss">
.input-date {
  &__title {
    @include caption-small;

    color: $grey20;
    margin-bottom: 16px;
  }

  &__label {
    position: relative;
    // background: $blue-grey70;
    background: $blue-grey60;
    width: 100%;
    display: block;
    border-radius: 8px;
    transition: background 0.3s $easing;

    .--error > & {
      background: $red60-20;
    }

    input,
    textarea {
      border-radius: 8px;
      padding: 0 36px 0 16px;
      height: 48px;
      width: 100%;
      border: 0;
      outline: none;
      background: transparent;
      color: $white;
      @include text-display100;
      font-weight: 500;

      &::placeholder {
        @include hint;

        color: $white20;
      }

      &:-webkit-autofill {
        background: transparent !important;
      }

      &[disabled] {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }

    /* stylelint-disable-next-line */
    textarea {
      height: auto;
      min-height: 96px;
      padding-top: 16px;
      padding-bottom: 16px;
      line-height: 100%;
    }
  }

  &__icon {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    cursor: pointer;

    .--password & {
      left: auto;
      right: 12px;
    }
  }

  &__pass-icon {
    position: absolute;
    top: 12px;
    right: 16px;
    cursor: pointer;
  }

  &__error {
    @include text-display100;

    position: absolute;
    bottom: -22px;
    right: 0;
    font-size: 18px;
    color: $red40;
  }

  &__edit {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 48px;
    height: 48px;
    margin: 0;
    padding: 0;
    border: 0;
    background: 0;
    cursor: pointer;
    transition: opacity 0.3s $easing;

    &:hover {
      opacity: 0.7;
    }

    .--password & {
      right: 36px;
    }
  }
}
</style>
