<template lang="pug">
  header.header
    router-link.main-link(to="/")
      .header__logo-box
        InlineSvg.header__logo(:src="require('@/assets/img/VVV-logo.svg')")

      span.header__product-name VVVGAMERS Admin

    .header__button__platform(@click="openSite")
      | На платформу

    .header__ui-box

      .header__auth-box(v-if="!isAuthorized")
        Button.header__sign-in(is-primary) Вернуться на главную

      .header__profile-box(v-else @click.stop="toggleMenu")
        .header__profile-pic-wrap
          img.header__profile-pic(:src="user.avatar", alt="")
        span.header__user-name {{ user.full_name || user.alias }}
        img(src="./img/ar-sm-d.svg", alt="")

        transition(name="slide-up")
          HeaderMenu(v-if="isMenuVisible" @close="toggleMenu")
</template>

<script>
import { mapState, mapActions } from 'vuex'
import HeaderMenu from './HeaderMenu'

export default {
  name: 'Header',

  components: {
    HeaderMenu,
  },

  data() {
    return {
      isMenuVisible: false,
      isCoinsIncrement: false,
      isCoinsDecrement: false,
    }
  },

  computed: {
    ...mapState('user', ['user']),

    isAuthorized() {
      return !!this.user
    },
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    openSite() {
      window.open('https://vvvgamers.com', '_blank').focus()
    },

    toggleMenu() {
      if (!this.isAuthorized) {
        return
      }

      this.isMenuVisible = !this.isMenuVisible
    },

    onSignInClick() {
      this.showModal({ component: 'Auth' })
      this.isMenuVisible = false
    },

    formatCoins(coins) {
      return Math.round(coins)
    },
  },
}
</script>

<style lang="scss">
.header {
  width: 100% !important;
  display: flex;
  font-size: $text-display;
  height: 80px;
  font-weight: $mainFont;
  background: $blue-grey90;
  color: $blue-grey05;
  position: fixed;
  top: 0;
  justify-content: space-between;
  z-index: 5;

  &__logo-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: transparentize($color: black, $amount: .8);
    width: 65px;

    @media screen and (max-width: 900px) {
      width: 64px;
      border-right: none;

      svg {
        width: 32px;
        height: 32px;
      }

      span {
        display: none;
      }
    }
  }

  &__logo {
    transform: scaleY(-1);
    position: relative;
    bottom: 2px;

    path {
      fill: $primary60;
    }
  }

  &__coins-ico path {
    fill: $amber50;
  }

  &__money-ico path {
    fill: $green50;
  }

  &__lang {
    display: none;
    width: 48px;
    height: 48px;

    /* display: flex; */
    align-items: center;
    justify-content: center;
    margin: 0 16px 0 auto;
  }

  &__product-name {
    padding-left: 24px;
    font-weight: 500;
    color: white;
    padding-top: 27px;
    font-size: 22px;
    letter-spacing: .4px;
  }

  &__coin-box {
    padding-right: 24px;
    height: 100%;
    display: flex;
    @include flex-center;

    & svg {
      fill: $amber50;
      position: relative;
      z-index: 2;
    }

    @keyframes coins-up {
      0% {
        opacity: 0;
        transform: translateY(-150%);
      }

      100% {
        transform: translateY(10%);
        opacity: 1;
      }
    }

    @keyframes coins-down {
      0% {
        opacity: 1;
        transform: translateY(-10%);
      }

      100% {
        transform: translateY(150%);
        opacity: 0;
      }
    }

    .currency__icon {
      position: relative;
    }

    .currency__icon::after {
      content: '';
      width: 48%;
      height: 48%;
      background: $amber50;
      position: absolute;
      right: 17%;
      top: 15%;
      border-radius: 50%;
      z-index: 1;
      opacity: 0;
    }

    &.--increment .currency__icon::after {
      animation: coins-up 0.2s ease-in-out;
      animation-iteration-count: 5;
    }

    &.--decrement .currency__icon::after {
      animation: coins-down 0.2s ease-in-out;
      animation-iteration-count: 5;
      right: auto;
      top: auto;
      left: 13%;
      bottom: 10%;
    }
  }

  &__value-wrap {
    display: flex;
    height: 24px;
  }

  &__money-box {
    display: flex;
    @include flex-center;

    & svg {
      fill: $green50;
    }

    .header__value-wrap {
      margin-right: 16px;
    }
  }

  &__ico-box {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    @include flex-center;

    margin: 0 8px;
    cursor: pointer;
    background: $blue-grey70;

    & svg {
      fill: $white;
      & rect {
        fill: #e9ecf2;
      }
    }

    &:hover {
      background: $blue-grey90;
    }

    &:active {
      background: $blue-grey40;
    }
  }

  .social-links {
    flex-grow: 1;

    @media screen and (max-width: $breakpointDesktopMedium) {
      display: none;
    }
  }

  &__ui-box {
    display: flex;
    width: auto;
    padding-left: 16px;
    // border-left: 1px solid $blue-grey50;

    @media screen and (max-width: $breakpointDesktopMedium) {
      border-left: none;
    }
  }

  &__currency-wrap {
    display: flex;
    @include flex-center;

    border-right: 1px solid $blue-grey50;
    & img {
      padding: 0 8px 3px 0;
    }
  }

  &__refill-btn {
    margin-right: 16px;
  }

  &__fav-wrap {
    display: flex;
    @include flex-center;

    padding: 0 16px;
    border-right: 1px solid $blue-grey50;
  }

  &__notification-box {
    position: relative;
  }

  &__notification-counter {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 20px;
    height: 20px;
    background: $gradient-primary;
    color: $white;
    font-size: 12px;
    font-weight: $mainFont;
    border-radius: 50%;
    display: flex;
    @include flex-center;

    line-height: 121.5%;
    text-align: center;
  }

  &__profile-box {
    height: 100%;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:hover {
      background: #1c27354a;
    }

    &:active {
      background: #1c27354a;
    }
  }

  &__auth-box {
    height: 100%;
    padding: 0 24px 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
  }

  &__profile-pic-wrap {
    width: 56px;
    height: 56px;
    min-width: 56px;
    display: flex;
    background: $white;
    margin-right: 16px;
    box-sizing: border-box;
    border-radius: 50%;
  }

  &__profile-pic {
    width: 100%;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
  }

  &__user-name {
    margin-right: 8px;
    color: white;
    font-weight: 500;
    font-size: 19px;
  }

  &__sign-in.button {
    .button__icon {
      display: none;
    }
  }

  @media screen and (max-width: $breakpointHeaderMobile) {
    height: 48px;

    &__auth-box {
      padding-right: 16px;
    }

    &__product-name {
      display: none;
    }

    &__button__platform {
      display: none !important;
    }

    &__sign-in.button {
      height: 32px;
      max-width: 32px;
      width: 32px;
      padding: 0;
      border-radius: 50%;

      &::before {
        border-radius: 50%;
      }

      .button__icon {
        margin: 0;
        display: initial;
      }

      .button__body {
        height: 100%;
      }

      .button__text {
        display: none;
      }
    }

    &__fav-wrap {
      padding: 0 12px;
    }

    &__coin-box {
      display: flex;
      padding-right: 12px;
    }

    &__refill-btn.button {
      display: none;
    }

    &__ico-box {
      width: 32px;
      height: 32px;
    }

    &__profile-pic-wrap {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      border-radius: 8px;
    }
  }

  @media screen and (max-width: $breakpointHeaderMobileSmall) {
    &__user-name {
      display: none;
    }

    &__profile-box {
      padding: 0 12px;
    }

    &__profile-pic-wrap {
      margin-right: 12px;
    }
  }

  @media screen and (max-width: $breakpointMobileSmall) {
    &__money-box {
      display: none;
    }

    &__coin-box {
      /* display: none; */
    }
  }
}

.header__button__platform {
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
  color: white;
  padding: 10px 18px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: .3px;
  border-radius: 4px;
  border: 3px solid #1e88e5;
  display: flex;
  position: absolute;
  top: 16px;
  left: 310px;
  cursor: pointer;
  background: #2b8de40d;

  &:hover {
    background: #2b8ee427;
  }
}

.main-link {
  display: flex;
}
</style>
