<template lang="pug">
  .create-quest
    .create-quest__section
      InputSmart(:item="title_en")
      InputSmart(:item="title_ru")
      InputSmart(:item="duration")
      InputSmart(:item="prize_currency")
      InputSmart(:item="prize_amount")
      InputSmart(:item="order")
      InputSmart(:item="type")

    .create-quest__section(v-if="showOptionsSection")
      InputSmart(v-for="(item, i) of items[selectedType]" :key="i" :item="item")

    Button(is-primary @click="onCreateButtonClick") Create

</template>
<script>
import { mapActions } from 'vuex'
import InputSmart from '@/components/InputSmart/InputSmart'
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import QuestsService from '@/services/QuestsService'

const EUR_TASKS = ['offerwall_task_completed']

export default {
  name: 'CreateQuest',

  props: {
    quests: {
      type: Array,
      default: () => ([]),
    },
  },

  components: {
    InputSmart,
    SectionHeader,
  },

  data() {
    return {
      title_en: {
        title: 'Название EN',
        value: '',
        placeholder: 'Придумайте название для задания',
        type: 'text',
      },
      title_ru: {
        title: 'Название RU',
        value: '',
        placeholder: 'Придумайте название для задания',
        type: 'text',
      },
      duration: {
        title: 'Длительность задания',
        value: 'daily',
        type: 'select',
        options: [
          { title: 'Ежедневное', value: 'daily' },
          { title: 'Еженедельное', value: 'weekly' },
          { title: 'Разовое', value: 'forever' },
        ],
        withEmptyValue: false,
      },
      order: {
        title: 'Приоритет задания (меньше – приоритетнее)',
        value: null,
        type: 'number',
        placeholder: 'Введите число',
      },
      prize_currency: {
        title: 'Валюта',
        value: 'coins',
        type: 'select',
        options: [
          { title: 'Монеты', value: 'coins' },
          { title: 'EUR', value: 'money' },
          { title: 'Билеты', value: 'tickets' },
        ],
        withEmptyValue: false,
      },
      prize_amount: {
        title: 'Размер приза',
        value: null,
        type: 'number',
        placeholder: 'Введите число',
        min: 0,
        increment: 0.1,
        fixed: 2
      },
      type: {
        title: 'Тип задания',
        value: 'brought_referrals',
        type: 'select',
        options: [
          { title: 'Привести рефералов', value: 'brought_referrals' },
          { title: 'Совершить покупку в магазине', value: 'shop_purchase' },
          { title: 'Уведомление в Telegram', value: 'telegram_connected' },
          { title: 'Пройти верификацию', value: 'verification_done' },
          { title: 'Привязать игровой аккаунт', value: 'game_accounts_connected' },
          { title: 'Достигнуть X рейтинга', value: 'rating_reached' },
          { title: 'Сыграть X дуэлей', value: 'duels_played' },
          { title: 'Сыграть X турниров', value: 'tournaments_played' },
          { title: 'Сделать ввод средств', value: 'pay_in_completed' },
          { title: 'Сделать вывод средств', value: 'pay_out_completed' },
          { title: 'Сыграть дуэль на деньги', value: 'duel_for_money_played' },
          { title: 'Выполнить оффервол заданий на сумму N евро', value: 'offerwall_task_completed' },
        ],
        withEmptyValue: false,
      },

      items: {
        brought_referrals: {
          count: {
            title: 'Количество рефералов',
            value: null,
            type: 'number',
            placeholder: 'Введите количество',
          },
        },
        game_accounts_connected: {
          count: {
            title: 'Количество для выполнения задания',
            value: null,
            type: 'number',
            placeholder: 'Введите количество',
          },
        },
        rating_reached: {
          count: {
            title: 'Количество для выполнения задания',
            value: null,
            type: 'number',
            placeholder: 'Введите количество',
          },
        },
        duels_played: {
          count: {
            title: 'Количество для выполнения задания',
            value: null,
            type: 'number',
            placeholder: 'Введите количество',
          },
        },
        tournaments_played: {
          count: {
            title: 'Количество для выполнения задания',
            value: null,
            type: 'number',
            placeholder: 'Введите количество',
          },
          tournamentType: {
            title: 'Тип турниров',
            value: 'paid',
            type: 'select',
            options: [
              { title: 'Любой', value: 'any' },
              { title: 'Фриролл', value: 'freeroll' },
              { title: 'Платный', value: 'paid' },
              { title: 'Монетный', value: 'coins' },
            ],
            withEmptyValue: false,
          },
        },
        pay_out_completed: {
          count: {
            title: 'Количество в EUR для выполнения задания',
            value: null,
            type: 'number',
            placeholder: 'Введите количество',
          },
        },
        duel_for_money_played: {
          count: {
            title: 'Количество для выполнения задания',
            value: null,
            type: 'number',
            placeholder: 'Введите количество',
          },
        },
        offerwall_task_completed: {
          count: {
            title: 'Количество в EUR для выполнения задания',
            value: null,
            type: 'number',
            placeholder: 'Введите количество',
          },
        },
        pay_in_completed: {
          count: {
            title: 'Количество в EUR для выполнения задания',
            value: null,
            type: 'number',
            placeholder: 'Введите количество',
          },
        }
      },
    }
  },
  computed: {
    selectedType() {
      return this.type.value
    },

    showOptionsSection() {
      const obj = this.items?.[this.selectedType] || {}

      return !!Object.keys(obj).length
    },
  },

  created() {
    this.$forceUpdate()
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onCreateButtonClick() {
      this.$loading.start()

      if (!this.prize_amount?.value) {
        return this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'error',
            title: 'Error',
            text: 'Укажите сумму выполнения задания',
          },
        })
      }

      try {
        const currency = this.prize_currency.value
        const amount = currency === 'money' ? (this.prize_amount.value *= 100) : this.prize_amount.value
        let count = +this.items[this.selectedType]?.count?.value || null

        const type = this.items[this.selectedType]?.tournamentType?.value
        const tournamentType = type === 'any' ? null : type

        if (EUR_TASKS.includes(this.selectedType)) {
          count *= 100
        }

        const newTemplate = {
          type: this.type.value,
          prize_currency: currency,
          prize_amount: +amount.toFixed(2),
          extra_data: {
            image_url: 'group',
            settings: { count, tournamentType: tournamentType || null },
            text: {
              ru: { title: this.title_ru.value },
              en: { title: this.title_en.value },
            },
          },
          period: this.duration.value,
          order: +this.order.value,
        }

        const questResponse = await QuestsService.createQuest(newTemplate)

        if (questResponse.data?.data) {
          this.showModal({
            component: 'ModalMessage',
            data: {
              type: 'success',
              title: 'Success',
              text: 'New task was created successfully',
            },
          })

          this.$emit('showQuestsList')
        }
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    }
  },
}
</script>
<style lang="scss">
.create-quest {
  padding-top: 24px;

  &__section {
    background-color: #ffffff05;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;

    & .input-smart:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>