<template lang="pug">
  .create-bs-tournament
    Steps(:steps="stepTitles" :current-step-index="currentStepIndex")

    .create-bs-tournament__body
      .create-bs-tournament__step(v-if="currentStepIndex === 0")
        .tournaments-create__caption Создайте лобби в дружеской игре с этой картой:

        CopyInput.create-bs-tournament__field(:value="tournamentMap" title="Карта")

        .tournaments-create__caption А затем впишите код от созданного лобби:

        InputSmart.create-bs-tournament__field(
          v-for="field in fields"
          :key="field.title"
          :item="field"
          :type="field.type"
        )

        Button(is-primary @click="sendLobbyData" :is-disabled="isButtonDisabled") Отправить

      .create-bs-tournament__step(v-if="currentStepIndex === 1")
        .tournaments-create__caption Теперь необходимо запустить матч в лобби в следующую дату: {{ tournamentDate }}
        Button(is-primary @click="onStartTournamentClick" style="margin-top: 8px;") Турнир начался
        Button(is-secondary @click="isEdit = true" style="margin-top: 8px;") Редактировать данные]

        .moderation-tournament__section
          .moderation-tournament__section-header
            span Игроки

          .moderation-tournament__section-body
            .moderation-tournament__players
              WinnerRow(
                v-for="player in tournamentMembers"
                :key="player.id"
                :winner="player"
              )

      .create-bs-tournament__step(v-if="currentStepIndex === 2")
        .tournaments-create__caption Игра в процессе! Как только матч закончится, нажмите на кнопку ниже
        Button(is-secondary @click="onEndTournamentClick" style="margin-top: 8px;") Завершить

      .create-bs-tournament__step(v-if="currentStepIndex === 3" style="max-width: unset;")
        TournamentModeration(:tournament="tournament" @update="updateTournament")

      .create-bs-tournament__step(v-if="currentStepIndex === 4")
        .tournaments-create__caption Турнир завершен!

</template>

<script>
import { mapActions, mapState } from 'vuex'
import Steps from '@/components/Steps/Steps'
import CopyInput from '@/components/CopyInput/CopyInput'
import InputSmart from '@/components/InputSmart/InputSmart'
import TournamentModeration from '@/components/TournamentModeration/TournamentModeration'
import WinnerRow from '@/components/WinnerRow/WinnerRow'

export default {
  name: 'CreateBrawlStarsTournament',

  components: {
    Steps,
    CopyInput,
    InputSmart,
    TournamentModeration,
    WinnerRow,
  },

  props: {
    tournamentData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tournament: {},

      stepTitles: ['Создание', 'Старт', 'В процессе', 'Завершение'],

      fields: {
        //     { title: 'VVV Bot 1', value: '#98G8YGYQU' },
        //     { title: 'VVV Bot 2', value: '#Y8LJQ0Q00' },
        //     { title: 'VVV Bot 3', value: '#VQV9280V' },
        //     { title: 'VVV Bot 4', value: '#VQVYGRRJ' },

        code: {
          title: 'Код для вступления в лобби',
          type: 'text',
          value: '',
        },
      },

      isEdit: false,

      interval: null,
    }
  },

  computed: {
    ...mapState('user', ['user']),

    currentStepIndex() {
      if (
          (this.tournament.state === 'open' && !this.tournamentCode)
          || this.isEdit
      ) {
        return 0
      } else if (this.tournament.state === 'open' && this.tournamentCode) {
        return 1
      } else if (this.tournament.state === 'in_progress' && !this.tournament.is_auto_results_enabled) {
        return 2
      } else if (this.tournament.state === 'completed' || this.tournament.state === 'cancelled') {
        return 4
      }

      return 3
    },

    tournamentCode() {
      return this.tournament?.extra_data?.state?.game?.extra_data?.code
    },

    tournamentDate() {
      return this.$moment.format(this.tournament.date, 'lll')
    },

    tournamentMap() {
      return this.tournament?.extra_data?.config?.map
    },

    userGameTag() {
      return this.user?.game_accounts?.find(acc => acc.game === 'brawl-stars')?.id
    },

    isButtonDisabled() {
      return this.fields.code.value.length < 5
    },

    tournamentMembers() {
      if (this.isTeams) {
        return this.tournament?.teams?.map(team => {
          const membersNames = team.members?.map(member => member.game_account?.username)

          return {
            name: `Команда: ${membersNames.join(', ')}`,
            points: null,
            place: null,
          }
        })
      }

      const players = this.tournament?.players

      return players?.map(player => ({ name: player.game_account?.username }))
    },
  },

  mounted() {
    this.interval = setInterval(this.intervalUpdate, 20000)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  watch: {
    tournamentData() {
      this.tournament = this.tournamentData
    },
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    intervalUpdate() {
      if (this.currentStepIndex !== 1) {
        return
      }

      this.updateTournament()
    },

    async sendLobbyData() {
      if (!this.userGameTag) {
        return this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'error',
            title: 'Ошибка',
            text: 'Для создания турнира вам нужно привязать игровой аккаунт на сайте',
          },
        })
      }

      try {
        const tag = this.userGameTag.toUpperCase()

        await this.$axios.post(`/games/brawl-stars/tournaments/${this.tournament.id}/lobby`, {
          link: this.fields.code.value,
          tag,
        })

        setTimeout(async () => {
          await this.updateTournament()
        }, 1000)
      } catch(e) {
        this.$error.handle(e)
      }

      this.isEdit = false
    },

    async onStartTournamentClick() {
      this.$loading.start()

      try {
        await this.$axios.put(`/games/brawl-stars/tournaments/${this.tournament.id}`, { state: 'in_progress' })
        await this.updateTournament()
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    async onEndTournamentClick() {
      this.$loading.start()

      try {
        await this.$axios.put(`/games/brawl-stars/tournaments/${this.tournament.id}`, { state: 'moderation' })
        await this.updateTournament()
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    async updateTournament() {
      this.$loading.start()

      try {
        const { data } = await this.$axios.get(`/games/brawl-stars/tournaments/${this.tournament.id}`)
        this.tournament = data.data
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },
  },
}
</script>

<style scoped lang="scss">
.create-bs-tournament {
  &__step {
    //max-width: 350px;
    margin: 20px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__field {
    margin-bottom: 16px;
    width: 100%;
  }

  &__end {
    margin-top: 8px;
  }
}

.moderation-tournament {
  &__section {
    margin-top: 16px;
    width: 100%;
    margin-bottom: 16px;

    &-header {
      display: flex;
      justify-content: space-around;
      padding: 16px 0;
      border-top: 1px solid $blue-grey50;
      border-bottom: 1px solid $blue-grey50;

      & span {
        @include caption-small;

        font-size: 14px;
        color: $blue-grey10;
      }
    }

    &-body {
      margin-top: 16px;
      //padding-right: 32px;
    }
  }

  &__players:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 2px solid $green60;
    margin-bottom: 16px;
  }

  &__done {
    @include heading4;

    color: $white;
  }
}
</style>
