<template lang="pug">
    .action-notify
      SectionHeader(:header="header")
  
      .action__body
        .action-notify__body
          .action__section
            .action__grid
              .action__form-field(v-for="(item, i) of items" :key="i")
                InputSmart(:item="item")
  
              .action__form-field(v-if="isForAll")
                InputSmart(:item="gameNameItem")

              .action__form-field(v-if="isForAll")
                InputSmart(:item="offlineDaysItem")
  
              .action__form-field
                InputSmart(:item="isForAllItem")
  
              .action__form-field(v-if="!isForAll")
                InputSmart(:item="idsItem")
  
          .action-notify__notification
            Notification(:notification="notification")
  
        .action__bottom
          Button(is-primary @click="onCheckButtonClick") Отправить
  
  </template>
  
  <script>
  import { mapActions } from 'vuex'
  import InputSmart from '@/components/InputSmart/InputSmart'
  import CheckboxInput from '@/components/CheckboxInput/CheckboxInput'
  import Notification from '@/components/Notification/Notification'
  import SectionHeader from '@/components/SectionHeader/SectionHeader'
  
  export default {
    name: 'ActionNotifyTeleram',
  
    components: {
      InputSmart,
      CheckboxInput,
      Notification,
      SectionHeader,
    },
  
    data: () => ({
      items: {
        title: {
          title: 'Заголовок',
          value: '',
          type: 'text',
          placeholder: 'Не более 45 символов (не может быть пустым)'
        },
  
        text: {
          title: 'Сообщение',
          value: '',
          type: 'text',
          placeholder: 'Не более 85 символов (не может быть пустым)'
        },
  
        image_url: {
          title: 'Картинка',
          value: '',
          type: 'text',
          placeholder: 'Ссылка на изображение (не может быть пустым)'
        },
  
        link_url: {
          title: 'Ссылка для кнопки',
          value: '',
          type: 'text',
          placeholder: 'Не может быть пустым'
        },
      },
  
      isForAllItem: {
        title: 'Отправить всем',
        value: true,
        type: 'checkbox',
      },
  
      idsItem: {
        title: 'Telegram ID игроков',
        value: '',
        type: 'text',
        placeholder: 'ID (если несколько — через запятую)'
      },
  
      gameNameItem: {
        title: 'Выборка по привязанному игровому аккаунту',
        value: '',
        type: 'select',
        options: [
          { title: 'Не задано', value: '' },
          { title: 'Lichess', value: 'lichess' },
          { title: 'PUBG Mobile', value: 'pubg-mobile' },
          { title: 'Clash Royale', value: 'clash-royale' },
          { title: 'Brawl Stars', value: 'brawl-stars' },
          { title: 'PUBG', value: 'pubg-desktop' },
          { title: 'Fortnite', value: 'fortnite' },
          { title: 'Free-Fire', value: 'free-fire' },
        ],
        withEmptyValue: false,
        description: 'Уведомление получат только игроки с привязанным аккаунтом в игре',
      },

      offlineDaysItem: {
        title: 'Выборка дней для оффлайн юзеров',
        value: '',
        type: 'select',
        options: [
          { title: 'Не задано', value: '' },
          { title: '3-7 дней', value: '3-7' },
          { title: '7-14 дней', value: '7-14' },
          { title: '14-30 дней', value: '14-30' },
          { title: 'более 30 дней', value: '31' }
        ],
        withEmptyValue: false,
        description: 'Уведомление получат только игроки которые были оффлайн на протяжении указанного времени',
      }
    }),
  
    computed: {
      header() {
        return {
          title: 'Отправка уведомлений',
        }
      },
  
      isForAll() {
        return this.isForAllItem.value
      },
  
      notification() {
        return {
          date: '1 день назад',
          title: this.items.title.value || 'Уведомление',
          text: this.items.text.value || 'Приветствуем тебя на самой лучшей игровой платформе во всём интернете',
          image: this.items.image_url.value || require('@/assets/img/VVV-logo.svg'),
          link_url: this.items.link_url.value,
        }
      },
    },
  
    created() {
      this.$forceUpdate()
    },
  
    methods: {
      ...mapActions('modal', ['showModal']),
  
      async onCheckButtonClick() {
        this.$loading.start()
  
        try {
          let ids = this.idsItem.value || []
          ids = ids.toString().includes(',') ? ids.split(',') : [ids]
  
          const title = this.items.title.value
          const text = this.items.text.value
          const image_url = this.items.image_url.value
          const link_url = this.items.link_url.value
          const offline_days = this.offlineDaysItem.value
          let gameName = ''
  
          if (this.isForAll) {
            ids = []
  
            if (this.gameNameItem.value) {
              gameName = this.gameNameItem.value
            }
          }
  
          const { data } = await this.$axios.post(`/notifications/create-admin-telegram-notification`, {
            ids,
            title,
            text,
            image_url,
            link_url,
            gameName,
            offline_days
          })
  
          if (data.data) {
            this.showModal({
              component: 'ModalMessage',
              data: {
                type: 'success',
                title: 'Успех',
                text: 'Всё прошло как нужно! :)',
              },
            })
          }
        } catch (e) {
          this.$error.handle(e)
        }
  
        this.$loading.finish()
      }
    },
  }
  </script>
  
<style lang="scss" scoped>
.action-notify {
    &__body {
      display: flex;
  
      @media screen and (max-width: $breakpointMobile) {
        flex-direction: column;
      }
    }
  
    &__notification {
      min-width: 384px;
  
      @media screen and (max-width: $breakpointMobile) {
        min-width: 0;
      }
    }
  }
  
  .action__section {
    &:first-child {
      margin-right: 24px;
  
      @media screen and (max-width: $breakpointTablet) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }
  }
  </style>
  