<template lang="pug">
  .input-smart
    .input-smart__header
      Tooltip(v-if="item.description")
        .input-smart__title {{ item.title }}
        template(v-slot:tooltip)
          .input-smart__description(v-html="item.description")
      .input-smart__title(v-else-if="item.title && item.type !== 'checkbox'")
        | {{ itemTitle }}

      .input-smart__buttons(v-if="item.actions")
        .input-smart__button(
          v-for="(button, index) of item.actions" :key="index"
          @click="button.action()"
        )
          Tooltip.input-smart__button-tooltip
            InlineSvg(:src="require(`@/assets/img/icons/${button.icon}.svg`)")
            template(v-slot:tooltip)
              .input-smart__description(v-html="button.description")


    .input-smart__body
      BaseSelect(
        v-if="item.type === 'select'"
        :description="item.description"
        v-model="item.value"
        :options="item.options"
        :with-empty-value="item.withEmptyValue"
        @input="onFieldBlur(item)"
      )

      InputDate(
        v-else-if="item.type === 'date'"
        v-bind="item"
        v-model="item.value"
        :description="item.description"
        :type="item.type"
        @input="onFieldBlur(item)"
      )

      CheckboxInput(
        v-else-if="item.type === 'checkbox'"
        v-model="item.value"
        v-bind="item"
        @input="onFieldBlur(item)"
      ) {{ item.title }}

      InputNumber(
        v-else-if="item.type === 'number'"
        v-bind="item"
        v-model="item.value"
        :type="item.type"
        :error="item.error"
        :min="item.min"
        :increment="item.increment"
        :fixed="item.fixed"
        @input="onFieldBlur(item)"
      )

      ImageUpload(
        v-else-if="item.type === 'image'"
        v-bind="item"
        v-model="item.value"
        @input="onFieldBlur(item)"
      )

      SearchSelect(
        v-else-if="item.type === 'search-select'"
        v-bind="item"
        v-model="item.value"
        @input="onFieldBlur(item)"
      )

      InputForm(
        v-else
        v-bind="item"
        v-model="item.value"
        :type="item.type"
        @input="onFieldBlur(item)"
      )
</template>

<script>
import Tooltip from '@/components/Tooltip/Tooltip'
import BaseSelect from '@/components/BaseSelect/BaseSelect'
import InputDate from '@/components/InputDate/InputDate'
import CheckboxInput from '@/components/CheckboxInput/CheckboxInput'
import InputNumber from '@/components/InputNumber/InputNumber'
import InputForm from '@/components/InputForm/InputForm'
import ImageUpload from '@/components/ImageUpload/ImageUpload'
import SearchSelect from '@/components/SearchSelect/SearchSelect'

import crypto from 'crypto'

export default {
  name: 'InputSmart',

  components: {
    InputForm,
    Tooltip,
    BaseSelect,
    InputDate,
    CheckboxInput,
    InputNumber,
    ImageUpload,
    SearchSelect,
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    itemTitle() {
      return this.item.required ? this.item.title + ' *' : this.item.title
    },
  },

  methods: {
    onFieldBlur(item) {
      this.$emit('blur', item)
    },

    random(type = 'num') {
      if (type === 'num') {
        const min = 1000
        const max = 9999

        return Math.floor(Math.random() * (max - min + 1) ) + min // both inclusive
      }

      return crypto.randomBytes(3).toString('hex')
    }
  },

  created() {
    this.item.actions = this.item.actions || []

    if ((this.item.key === 'password' || this.item.key === 'extra_data.config.password') && this.item.actions.length === 0) {
        this.item.value = this.random()

        this.item.actions.push({
        icon: 'refresh',
        description: 'Новый пароль',
        action: () => {
          this.item.value = this.random()
        },
      })
    }
  },

  beforeDestroy() {
    this.item.actions = []
  }
}
</script>

<style lang="scss">
  .input-smart {
    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      color: #829ab5;
      margin: 0 8px 8px 4px;
      letter-spacing: .3px;
      font-family: 'Roboto', sans-serif;
    }

    &__description {
      width: max-content;
      max-width: 500px;
      font-weight: normal;
      font-size: 18px;
      line-height: 100%;
      color: $blue-grey05;
    }

    &__buttons {
      display: flex;
      position: relative;
      bottom: 7px;
    }

    &__button {
      svg path {
        fill: $grey20;
      }

      .tooltip {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: $breakpointMobile) {
    .input-smart__description {
      width: auto;
    }
  }
  
</style>
