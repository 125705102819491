<template lang="pug">
  .duel-players__players
    .duel-players__players-wrapper
      .duel-players__players-left
        .duel-players__players-left-nickname {{ accountGameUsername || 'Нет аккаунта' }}

        .duel-players__players-left-selected(v-if="activePlayer === accountGameUsername" @click="onPlayerClick('')")
          InlineSvg(:src="require('@/assets/img/icons/double-check.svg')")
        img.duel-players__players-left-image(v-else @click="onPlayerClick(accountGameUsername)" :src="accountAvatar")

      .duel-players__players-center
        .duel-players__players-center-text VS

      .duel-players__players-right
        .duel-players__players-left-selected(v-if="activePlayer === opponentGameUsername" @click="onPlayerClick('')")
          InlineSvg(:src="require('@/assets/img/icons/double-check.svg')")
        img.duel-players__players-right-image(v-else @click="onPlayerClick(opponentGameUsername)" :src="opponentAvatar")

        .duel-players__players-right-nickname {{ opponentGameUsername || 'Нет аккаунта' }}

</template>

<script>
export default {
  name: 'DuelPlayers',

  props: {
    account: {
      type: Object,
      required: true,
    },

    opponent: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    activePlayer: '',
  }),

  computed: {
    accountGameUsername() {
      return this.account?.game_username
    },

    opponentGameUsername() {
      return this.opponent?.game_username
    },

    accountAvatar() {
      return this.account?.avatar
    },

    opponentAvatar() {
      return this.opponent?.avatar
    },
  },

  methods: {
    onPlayerClick(username) {
      if (username === undefined) {
        return
      }

      this.$emit('input', username)
      this.activePlayer = username
    },
  }
}
</script>

<style lang="scss">
  .duel-players {
    &__players {
      height: 112px;
      width: 100%;
      background: #232F3F;
      border-radius: 8px;
      margin-bottom: 24px;
      display: flex;
      justify-content: center;

      &-wrapper {
        display: flex;
        height: 100%;

        @media screen and (max-width: $breakpointMobile) {
          flex-direction: column;
          padding: 16px;
          align-items: center;
        }
      }

      &-left, &-right {
        display: flex;
        align-items: center;
        margin: 0 30px;

        &-nickname {
          @include heading4;

          color: $white;
          margin: 0 16px;
        }

        &-image {
          object-fit: cover;
          width: 71px;
          height: 71px;
          border-radius: 50%;
          cursor: pointer;
        }

        &-selected {
          width: 71px;
          height: 71px;
          background: #1C2735;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media screen and (max-width: $breakpointMobile) {
          margin: 0;
        }
      }

      &-center {
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1c2735;
        transform: skew(-20deg);

        &-text {
          @include heading3;

          color: $white;
          transform: skew(20deg);
        }

        @media screen and (max-width: $breakpointMobile) {
          margin: 12px 0;
        }
      }

      @media screen and (max-width: $breakpointMobile) {
        height: auto;
      }
    }
  }
</style>