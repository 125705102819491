<template lang="pug">
  .action-ban
    SectionHeader(:header="header")

    Tabs(:tabs="tabs" v-model="activeTabIndex").action__tabs

    .action__body
      .action__section
        .action__grid
          Quests(v-if="activeTabIndex === 0" :quests="quests" @updateQuests="getTemplates")
          CreateQuest(v-else :quests="quests" @showQuestsList="activeTabIndex = 0")

</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader'
import Tabs from '@/components/Tabs/Tabs'
import Quests from '@/components/Quests/Quests'
import CreateQuest from '@/components/CreateQuest/CreateQuest'
import QuestsService from "@/services/QuestsService";

export default {
  name: 'QuestCenter',

  components: {
    SectionHeader,
    Tabs,
    Quests,
    CreateQuest,
  },

  data() {
    return {
      activeTabIndex: 0,
      quests: [],

      tabs: [
        { id: 'general', title: 'Список шаблонов' },
        { id: 'prize_pool', title: 'Создание нового задания' }
      ]
    }
  },

  computed: {
    gameName() {
      return this.$route.params.section
    },

    header() {
      return {
        title: `${this.$gameNameText(this.gameName)} / Центр заданий`,
        game: this.gameName,
      }
    },
  },

  watch: {
    activeTabIndex(newValue) {
      if (newValue === 0) {
        this.getTemplates()
      }
    },
  },

  mounted() {
    this.getTemplates()
  },

  methods: {
    async getTemplates() {
      this.$loading.start()

      try {
        const { data } = await QuestsService.getQuests()

        this.quests = data.data
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },
  },
}
</script>

<style lang="scss">
//
</style>
