<template lang="pug">
  .checkbox-input
    label.checkbox-input__label
      .checkbox-input__fake(:class="{ '--checked': value }")
        div
      .checkbox-input__text
        slot
      input.checkbox-input__input(
        type='checkbox'
        :class="{ '--checked': value }"
        @change="onChange"
      )

</template>

<script>
export default {
  name: 'CheckboxInput',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  methods: {
    onChange(event) {
      this.$emit('input', event.target.checked)
    },
  },
}
</script>

<style lang="scss">
.checkbox-input {
  display: flex;
  align-items: center;
  user-select: none;
  min-height: 20px;
  line-height: 20px;

  &__label {
    display: flex;
    align-items: center;
  }

  &__text {
    @include text-display100;

    color: $blue-grey10;
    min-width: fit-content;
    line-height: 20px;

    a {
      color: $primary60;
      text-decoration: underline;
    }
  }

  &__fake {
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 2px solid $blue-grey20;
    position: relative;
    transition: border 0.2s $easing;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      border: 2px solid white;
    }

    div {
      width: 20px;
      height: 20px;
      background: white;
      position: absolute;
      left: -2px;
      top: -2px;
      border-radius: 4px;
      transition: all 0.1s $easing;
      transform: scale(0.15, 0.15);
      opacity: 0;
    }

    &.--checked div {
      transform: scale(0.5, 0.5);
      opacity: 1;
    }
  }

  &__input {
    display: none;
  }
}
</style>
