import Vue from 'vue'
import InlineSvg from 'vue-inline-svg'
import { Drag, Drop } from 'vue-easy-dnd'
import VCalendar from 'v-calendar';

import App from '@/App'
import router from '@/router'
import store from '@/store'
import axios from '@/plugins/axios'
import moment from '@/plugins/moment'
import error from '@/utils/errors'
import clipboard from '@/plugins/clipboard'
import loading from '@/plugins/loading'
import dateFns from '@/plugins/date-fns'

Vue.prototype.$axios = axios
Vue.prototype.$moment = moment
Vue.prototype.$error = error
Vue.prototype.$clipboard = clipboard
Vue.prototype.$loading = loading
Vue.prototype.$dateFns = dateFns
Vue.prototype.$gameNameText = gameNameText

Vue.component('inline-svg', InlineSvg)
Vue.component('InlineSvg', InlineSvg)
Vue.component('Button', () => import('@/components/Button/Button'))

Vue.component('Drag', Drag)
Vue.component('Drop', Drop)

Vue.use(VCalendar, {
  componentPrefix: 'vc',
})

function gameNameText(gameName) {
  const games = {
    'pubg-mobile': 'PUBG Mobile',
    'lichess': 'Lichess',
    'brawl-stars': 'Brawl Stars',
    'clash-royale': 'Clash Royale',
    'free-fire': 'FreeFire',
    'cs-go': 'CS:GO',
    'dota2': 'Dota2',
    'pubg-desktop': 'PUBG',
  }

  return games[gameName]
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
