<template lang="pug">
  .object-view
    .modal__header
      | {{ title }}

    .object-view__body
      p.object-view__text
        | {{ text }}

    JsonViewer(v-model="object")
</template>

<script>
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'ObjectView',

  components: {
    JsonViewer,
  },

  props: {
    title: {
      type: String,
      default: 'Результат',
    },

    text: {
      type: String,
      default: '',
    },

    object: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
}
</script>

<style lang="scss">
.modal {
  &--objectview {
    max-width: 500px;
  }

  .jv-container {
    margin-top: 24px;
  }
}

.object-view {
  &__body {
    margin-top: 12px;
  }

  &__text {
    @include text-display120;

    color: $white;
  }
}

.vue-object-view {
  color: $white;
  width: 100%;
}
</style>
