<template lang="pug">
  .create-bs-tournament
    Steps(:steps="stepTitles" :current-step-index="currentStepIndex")

    .create-bs-tournament__body
      .create-bs-tournament__step(v-if="currentStepIndex === 0")
        .tournaments-create__caption Создайте турнир со следующими параметрами:

        CopyInput.create-bs-tournament__field(:value="title" title="Название")
        CopyInput.create-bs-tournament__field(:value="password" title="Пароль")
        CopyInput.create-bs-tournament__field(:value="maxPlayersCount" title="Кол-во участников")
        CopyInput.create-bs-tournament__field(:value="duration" title="Длительность")
        CopyInput.create-bs-tournament__field(:value="gameMode" title="Режим игры")
        CopyInput.create-bs-tournament__field(:value="level" title="Уровень")
        CopyInput.create-bs-tournament__field(:value="maxLosesCount" title="Макс. кол-во проигрышей")

        .tournaments-create__caption А затем впишите тег турнира сюда:

        InputSmart.create-bs-tournament__field(
          v-for="field in fields"
          :key="field.title"
          :item="field"
          :type="field.type"
        )

        Button(is-primary @click="sendLobbyData" :is-disabled="isButtonDisabled") Отправить

      .create-bs-tournament__step(v-if="currentStepIndex === 1")
        .tournaments-create__caption Теперь необходимо запустить матч в лобби в следующую дату: {{ tournamentDate }}
        Button(is-primary @click="onStartTournamentClick" style="margin-top: 8px;") Турнир начался
        Button(is-secondary @click="isEdit = true" style="margin-top: 8px;") Редактировать данные

      .create-bs-tournament__step(v-if="currentStepIndex === 2")
        .tournaments-create__caption Турнир в процессе! Наш сервер автоматически завершит его

    //.create-clash-royale-account__inner(v-if="tournamentData.state !== 'completed'")
    //  TournamentModeration(:tournament="tournamentData" @update="updateTournament")
    //
    //.create-clash-royale-account__inner(v-else)
      .tournaments-create__caption Турнир завершён!

</template>

<script>
import TournamentModeration from '@/components/TournamentModeration/TournamentModeration'
import InputSmart from '@/components/InputSmart/InputSmart'
import CreateBrawlStarsTournament from '@/components/CreateBrawlStarsTournament/CreateBrawlStarsTournament'
import Steps from '@/components/Steps/Steps'

export default {
  name: 'CreateClashRoyaleTournament',

  extends: CreateBrawlStarsTournament,

  props: {
    tournamentData: {
      type: Object,
      required: true,
    },
  },

  components: {
    TournamentModeration,
    InputSmart,
    Steps,
  },

  data() {
    return {
      stepTitles: ['Создание', 'Старт', 'В процессе'],

      fields: {
        code: {
          title: 'Код от турнира',
          type: 'text',
          value: '#',
        },
      },
    }
  },

  computed: {
    currentStepIndex() {
      if (
          (this.tournament.state === 'open' && !this.tournamentCode)
          || this.isEdit
      ) {
        return 0
      } else if (this.tournament.state === 'open' && this.tournamentCode) {
        return 1
      }

      return 2
    },

    tournamentCode() {
      return this.tournament?.extra_data?.state?.game?.tag
    },

    title() {
      return this.tournament?.extra_data?.config?.title
    },

    gameMode() {
      return this.tournament?.extra_data?.config?.game_mode
    },

    duration() {
      return this.tournament?.extra_data?.config?.duration
    },

    level() {
      return this.tournament?.extra_data?.config?.level
    },

    maxLosesCount() {
      return this.tournament?.extra_data?.config?.max_loses_count
    },

    password() {
      return this.tournament?.extra_data?.config?.password
    },

    maxPlayersCount() {
      return this.tournament?.tournament_player_settings?.max_players_count
    },

    isButtonDisabled() {
      return this.fields.code.value.length < 3
    },
  },

  methods: {
    async sendLobbyData() {
      let tag = this.fields.code.value

      if (!tag.includes('#')) {
        tag = '#' + tag
      }

      try {
        await this.$axios.post(`/games/clash-royale/tournaments/${this.tournamentData.id}/lobby`, {
          tag: tag.toUpperCase(),
        })

        setTimeout(async () => {
          await this.updateTournament()
        }, 1000)
      } catch(e) {
        this.$error.handle(e)
      }

      this.isEdit = false
    },

    async onStartTournamentClick() {
      this.$loading.start()

      try {
        await this.$axios.put(`/games/clash-royale/tournaments/${this.tournament.id}`, { state: 'in_progress' })

        setTimeout(async () => {
          await this.updateTournament()
        }, 1000)
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    async updateTournament() {
      this.$loading.start()

      const { data } = await this.$axios.get(`/games/clash-royale/tournaments/${this.tournamentData.id}`)
      this.tournamentData = data.data

      this.$loading.finish()
    },
  }
}
</script>

<style lang="scss">
.create-clash-royale-account__bottom {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>